import _ from 'lodash'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'

import { ExecutionAPI } from '~/api'
import { ConvertLocalTimeGet, ConvertLocalTimePost, GetDurationMinutes, getDurationBetweenTwoTime } from '~/utils'

import constants from '../constants.activity'

const calculateTableWidth = (columns) => {
  const calculatedColumns = columns
    .filter(column => column.width != '*')
    .map(column => {
      column.width.replace('px', '')
      return parseInt(column.width)
    })
  return calculatedColumns.reduce((column, index) => column + index, 0)
}

const useCalculateTableWidth = (columns, ) => {
  const width = window.innerWidth - (true ? 0 : 234)

  return calculateTableWidth(columns) > width ? calculateTableWidth(columns) : width
}

// This custom hook is used to fetch data unit and equipment labour data
const useFetchDropDownOptions = (srCode, initvalDropDown, locationCode) => {
  const [dropDownOptions, setDropDownOptions] = useState(initvalDropDown)

  const fetchData = async () => {
    const queryUnit = {
      filters:[{
        value:locationCode,
        field:'location_code',
        type:'text'
      }]
    }
    try {
      const unitNameResponse = await ExecutionAPI.getAllMasterUnit(queryUnit)
      const equipLabourResponse = await ExecutionAPI.getEquipmentLabourBySRCode(srCode)

      setDropDownOptions({
        unit_name_code: (unitNameResponse.data.message.data ?? []),
        estimation_detail_code: (equipLabourResponse.data.message.data ?? []),
      })
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (srCode) {
      fetchData()
    }
  }, [srCode])

  return dropDownOptions
}

const createForm = (inEditValues) => {
  return useFormik({
    initialValues: inEditValues,
    enableReinitialize: true,
  })
}

const addNewTaskRowHOF = (addTaskForm, taskDetailList, setInEditValues, setTaskDetailList) => {
  return () => {
    try {
      addTaskForm.resetForm()
      const newTask = { ...constants.initValTask, edit: true, isNew: true }
      const updatedData = [newTask, ...taskDetailList].map((item, index) => ({ ...item, editIndex: index }))
      setInEditValues(newTask)
      setTaskDetailList(updatedData)
    } catch (error) {
      return error
    }
  }
}

const updateTask = async (task, location) => {
  try {
    const break_time = !isNaN(parseFloat(task.break_time)) ?
      parseFloat(task.break_time)
      :0
    task = {
      ...task,
      break_time:break_time,
      start_time: ConvertLocalTimePost(location, task.start_time),
      finish_time: ConvertLocalTimePost(location, task.finish_time),
    }
    const response = await ExecutionAPI.updateProgressTaskDetail(task)
    return new Promise(function(resolve, reject) {
      resolve(response?.data?.message?.data ?? false)
      reject(new Error('Failed to update task detail'))
    })
  } catch(error) {
    return error
  }
}

const insertTask = async (task, location) => {
  try {
    const break_time = !isNaN(parseFloat(task.break_time)) ?
      parseFloat(task.break_time)
      :0
    task = {
      ...task,
      break_time:break_time,
      start_time: task.start_time ? ConvertLocalTimePost(location, task.start_time) : null,
      finish_time: task.finish_time ? ConvertLocalTimePost(location, task.finish_time) : null,
    }

    const response = await ExecutionAPI.insertProgressTaskDetail(task)
    return new Promise(function(resolve, reject) {
      resolve(response?.data?.message?.data ?? false)
      reject(new Error('Failed to insert task detail'))
    })
  } catch(error) {
    return error
  }
}

const fetchTaskDetail = async (activityCode, taskHeaderCode, location) => {
  try {
    const response = await ExecutionAPI.getProgressTaskDetailByTaskHeaderCode(activityCode, taskHeaderCode)
    const newResponse = (response?.data?.message?.data ?? []).map((e) => {
      return {
        ...e,
        start_time : ConvertLocalTimeGet(location, e.start_time, 'YYYY-MM-DD HH:mm'),
        finish_time : ConvertLocalTimeGet(location, e.finish_time, 'YYYY-MM-DD HH:mm'),
      }
    })

    return new Promise(function(resolve, reject) {
      resolve(newResponse)
      reject(new Error('Failed to fetch task detail'))
    })
  } catch(error) {
    return error
  }
}

const useFetchTaskDetailList = (activityCode, taskHeaderCode, setIsLoading, location) => {
  const [taskDetailList, setTaskDetailList] = useState([])

  const fetchData = async () => {
    try {
      setIsLoading(true)
      const result = await fetchTaskDetail(activityCode, taskHeaderCode, location)
      setTaskDetailList(result)
      setIsLoading(false)
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (taskHeaderCode) {
      fetchData()
    }
  }, [taskHeaderCode])

  return [taskDetailList, setTaskDetailList]
}

const handleSaveHOF = (addTaskForm, dropDownOptions, showModalStatus, taskDetailList, setTaskDetailList, location) => {
  return async (item) => {
    try {
      let newOrUpdatedTask
      taskDetailList.forEach((val, index) => {
        if (item.editIndex === val.editIndex) {
          const findEstimationHeader = dropDownOptions.estimation_detail_code.find(
            est => est.estimation_detail_code === addTaskForm.values.estimation_detail_code
          )
          newOrUpdatedTask = {
            ...addTaskForm.values,
            editIndex: index,
            task_header_code: showModalStatus.taskCode,
            activity_code: showModalStatus.activityCode,
            duration_time: getDurationBetweenTwoTime(addTaskForm.values.start_time, addTaskForm.values.finish_time, addTaskForm.values.break_time),
            estimation_header_code: !_.isUndefined(findEstimationHeader)
              ? findEstimationHeader.estimation_header_code : ''
          }
          return newOrUpdatedTask
        }
        return { ...val, editIndex: index }
      })
      if (item.isNew) {
        await insertTask({ ...newOrUpdatedTask, edit: false, isNew: false }, location)
      } else {
        await updateTask({ ...newOrUpdatedTask, edit: false }, location)
      }

      const newlyFetchedTaskDetailList = await fetchTaskDetail(showModalStatus.activityCode, showModalStatus.taskCode, location)
      setTaskDetailList(newlyFetchedTaskDetailList)
    } catch (error) {
      return error
    }
  }
}

const handleCancelHOF = (taskDetailList, setInEditValues, setTaskDetailList) => {
  return (item) => {
    const newInitVals = { ...constants.initValCargo, edit: false, isNew: false }
    const updatedData = taskDetailList.map((val, index) => {
      if (item.editIndex === val.editIndex) return { ...val, edit: false, editIndex: index }
      return { ...val, editIndex: index }
    })

    updatedData.filter((e, index) => e.isNew && updatedData.splice(index, 1))
    setInEditValues(newInitVals)
    setTaskDetailList(updatedData)
  }
}

const handleEditHOF = (taskDetailList, setInEditValues, setTaskDetailList) => {
  return (item) => {
    const wantTobeUpdatedItem = { ...item, edit: true, isNew: false }
    const updatedData = taskDetailList.map((val, index) => {
      if (item.id === val.id) return { ...val, edit: true, editIndex: index }
      return { ...val, editIndex: index }
    })
    setInEditValues(wantTobeUpdatedItem)
    setTaskDetailList(updatedData)
  }
}

const deleteTask = async (taskId) => {
  try {
    const response = await ExecutionAPI.deleteProgressTaskDetail(taskId)
    return response
  } catch(error) {
    return error
  }
}

const handleRemoveHOF = (taskDetailList, setTaskDetailList) => {
  return (item) => {
    const filteredData = taskDetailList.filter(val => val.id !== item.id)

    deleteTask(item.id)
    setTaskDetailList(filteredData)
  }
}

const getDurationTimeHOF = () => {
  return (item) => {
    return GetDurationMinutes(item.start_time, item.finish_time, item.break_time)
  }
}

export default {
  createForm,
  handleSaveHOF,
  handleEditHOF,
  handleCancelHOF,
  handleRemoveHOF,
  addNewTaskRowHOF,
  getDurationTimeHOF,
  useFetchTaskDetailList,
  useCalculateTableWidth,
  useFetchDropDownOptions,
}