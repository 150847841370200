import { useState } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Alerts, ModalDel } from '~/components'

import SC from './styled.activity'
import FC from './factory-components.activity'
import AddEquipmentTaskModal from './add-task'

import Funcs from './functions.activity'
import constants from './constants.activity'

const ActivityModal = ({
  dataSrCode,
  dataAll,
  codeVessel,
  dataPlanArrival,
  getDataExecution
}) => {
  const { Location: location } = dataAll

  const [task, setTask] = useState({})
  const [dataSort, setDataSort] = useState([])
  const [activity, setActivity] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [notConfirm, setNotConfirm] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)
  const [isInProgress, setIsInProgress] = useState(false)
  const [toggleDelete, setToggleDelete] = useState(false)
  const [totalPercentage, setTotalPercentage] = useState(0)
  const [showModalStatus, setShowModalStatus] = useState(
    constants.showModalStatus
  )
  const [showAlert, setShowAlert] = useState({
    visible: false,
    msg: '',
    success: false,
    type: ''
  })

  // If SR form is valid (user has filled all required fields), then user can tick the checkbox
  const { isValid } = useSelector(
    (state) => state.executionValidateSRFormReducer
  )
  const [activityList, setActivityList] = Funcs.useFetchActivityBySRCode(
    dataSrCode,
    setIsLoading,
    setTotalPercentage
  )

  // HOF: Higher order function will create the approriate function to use
  const form = Funcs.createForm(dataAll)
  const toggleModal = Funcs.toggleModalHOF(setShowModalStatus)
  const updateActivity = Funcs.updateActivityHOF(setActivityList)
  const addNewTask = Funcs.addNewTaskHOF(setActivityList, location)
  const onDragEnd = Funcs.onDragEndHOF(activityList, setActivityList)
  const toggleEditMode = Funcs.toggleEditModeHOF(activityList, setActivityList)
  const handleOpenActivityDetail = Funcs.handleOpenActivityDetailHOF(
    activityList,
    setActivityList
  )
  const deleteTask = Funcs.deleteTaskHOF(
    setActivityList,
    location,
    activity,
    task,
    setToggleDelete,
    setShowAlert
  )
  const updateActivityAndTaskHeader = Funcs.updateActivityAndTaskHeaderHOF(
    activityList,
    setActivityList,
    setIsLoading
  )
  const handleCheckProgressActivity = Funcs.handleCheckProgressActivityHOF(
    dataAll,
    dataSrCode,
    isInProgress,
    isValid,
    setShowAlert,
    setNotConfirm,
    setActivityList,
    dataPlanArrival,
    setOpenWarning,
    getDataExecution
  )

  Funcs.checkIsInProgress(codeVessel, setIsInProgress)

  const handleRemoveModal = () => (
    <div>
      <ModalDel
        id='m_actv_delete_mdl'
        toggleDialog={() => setToggleDelete(!toggleDelete)}
        submitDelete={deleteTask}
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const deleteData = (act, tsk) => {
    setTask(tsk)
    setActivity(act)
    setToggleDelete(!toggleDelete)
  }

  return (
    <SC.Container>
      {isLoading ? (
        <SC.CircularProgressWrapper>
          <CircularProgress />
        </SC.CircularProgressWrapper>
      ) : (
        <>
          {toggleDelete && handleRemoveModal()}
          <FC.TopForm form={form} />
          <FC.ActivityGrid
            location={location}
            onDragEnd={onDragEnd}
            addNewTask={addNewTask}
            activityList={activityList}
            setActivityList= {setActivityList}
            handleShowModal={toggleModal}
            updateActivity={updateActivity}
            toggleEditMode={toggleEditMode}
            deleteTask={deleteData}
            handleOpenActivityDetail={handleOpenActivityDetail}
            updateActivityAndTaskHeader={updateActivityAndTaskHeader}
            handleCheckProgressActivity={handleCheckProgressActivity}
            dataSort={dataSort}
            setDataSort= {setDataSort}
          />
        </>
      )}
      {showModalStatus.isOpen && (
        <AddEquipmentTaskModal
          srCode={dataSrCode}
          dataAll={dataAll}
          handleClose={toggleModal}
          activityList={activityList}
          showModalStatus={showModalStatus}
        />
      )}
      <Alerts
        msg={showAlert.msg}
        type={showAlert.type}
        open={showAlert.visible}
        success={showAlert.success}
        close={() => {
          setShowAlert({ ...showAlert, visible: false })
        }}
      />
      {openWarning && (
        <FC.WarningModal
          dataAll={dataAll}
          notConfirm={notConfirm}
          openWarning={openWarning}
          isInProgress={isInProgress}
          setNotConfirm={setNotConfirm}
          setOpenWarning={setOpenWarning}
          totalPercentage={totalPercentage}
        />
      )}
    </SC.Container>
  )
}

export default ActivityModal
