import { useState } from 'react'

import { Card } from '../../components'
import { WrapperContent } from '../../styles/global.styled'

import ReportFilter from './report-filter.container'
import ReportTabular from './report-tabular.container'


import { Header, Container, HeaderWrapper } from './report.styled'

const Report = () => {
  const [isTabular, setIsTabular] = useState(false)

  return (
    <WrapperContent>
      <Card>
        <Container>
          <HeaderWrapper>
            <Header>{ !isTabular ? 'FILTER REPORT' : 'REPORT' }</Header>
          </HeaderWrapper>
          {
            !isTabular ?
              <ReportFilter setIsTabular={setIsTabular} /> :
              <ReportTabular setIsTabular={setIsTabular} />
          }
        </Container>
      </Card>
    </WrapperContent>
  )
}

export default Report