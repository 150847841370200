import * as Yup from 'yup'
import moment from 'moment'
import Link from 'next/link'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'

import { ExecutionAPI } from '../../../api'
import { MasterModal, InputSelect, InputDate } from '../../../components'

import { Form, WrapperCheckBox, WrapBox } from './scheduler.styled.container'

const SchedulerPrintOutModal = (props) => {
  const [locationList, setLocationList] = useState([])
  const [clientList, setClientList] = useState([])

  const [categoryList, setCategoryList] = useState([])

  const usersLoginReducer = useSelector((state) => state.usersLoginReducer)

  useEffect(() => {
    getLocation()
    getClient()
    getCategory()
  }, [])

  const getLocation = async () => {
    try {
      const result = await ExecutionAPI.GetDataLocations()
      if (
        usersLoginReducer &&
        usersLoginReducer.UserLocations
      ) {
        const filteredLocation = result.data.data.filter((e) =>
          usersLoginReducer.UserLocations.find((ele) => ele.LocationCode === e.Location_Code)
        )
        setLocationList(filteredLocation)
      } else {
        setLocationList(result.data.data)
      }
    } catch (err) {
      console.log('err', err)
    }
  }
  const getClient = async () => {
    try {
      const result = await ExecutionAPI.GetDataCLients()
      setClientList([...result.data.data, { Customer_Name: 'All Client', Code: 'all' }])
    } catch (err) {
      console.log('err', err)
    }
  }
  const getCategory = async () => {
    try {
      const result = await ExecutionAPI.GetDataCategory()
      setCategoryList([...result.data.data, { Value: 'All Category', Code: 'all' }])
    } catch (err) {
      console.log('err', err)
    }
  }
  const PrintForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      Start_Period: null,
      End_Period: null,
      Paper: '',
      Location: '',
      Client: '',
      Category: '',
      Status: [],
      Info: [],
    },
    validationSchema: Yup.object({
      Start_Period: Yup.date().required('Required*').nullable().typeError('Invalid date*'),
      End_Period: Yup.date()
        .required('Required*')
        .nullable('Required*')
        .typeError('Invalid date*')
        .min(Yup.ref('Start_Period'), 'Must More Than Start Period*')
        .max(moment(Yup.ref('Start_Period')).add(90, 'days').format(), 'Maximal 90 Days*'),
      Paper: Yup.string().required('Required*'),
      Location: Yup.string().required('Required*'),
      Client: Yup.string().required('Required*'),
      Category: Yup.string().required('Required*'),
      Status: Yup.array(),
      Info: Yup.array(),
    }),
    onSubmit: async () => {
      document.getElementById('sch-print-preview-btn').click()
    },
  })

  const onChangeBox = (field, value) => {
    if (!PrintForm.values[field].includes(value)) {
      PrintForm.setFieldValue(field, [...PrintForm.values[field], value])
    } else {
      let newValue = PrintForm.values[field].filter((e) => e !== value)
      PrintForm.setFieldValue(field, newValue)
    }
  }
  return (
    <div>
      <MasterModal
        id='sch_print_mdl'
        maxWidth='lg'
        width={40}
        showModal={props.showModal}
        handleClose={() => {
          props.closeModalPrint()
          PrintForm.resetForm()
        }}
        title={'Printout Execution'}
        subTitle={'PRINTOUT SETTING'}
        isPrintOut={true}
        handlePreview={PrintForm.handleSubmit}
        isStatic={true}
      >
        <div style={{ padding: '10px 10px 20px 10px' }}>
          <div style={{ display: 'flex' }}>
            <Form>
              <InputDate
                form={PrintForm}
                name='Start_Period'
                label='Start Period'
                id='sch-mdl-start_period-tf'
                placeholder='DD/MM/YYYY'
                value={PrintForm.values.Start_Period}
                onChange={(v) => PrintForm.setFieldValue('Start_Period', moment(v).format())}
              />
              <InputSelect
                form={PrintForm}
                name='Location'
                label='Location'
                id='sch-mdl-location-tf'
                options={locationList}
                optionText='Location_Text'
                optionValueKey='Id'
              />
            </Form>
            <Form>
              <InputDate
                form={PrintForm}
                name='End_Period'
                label='End Period'
                id='sch-mdl-end_period-tf'
                placeholder='DD/MM/YYYY'
                value={PrintForm.values.End_Period}
                onChange={(v) => PrintForm.setFieldValue('End_Period', moment(v).format())}
              />
              <InputSelect
                form={PrintForm}
                name='Client'
                label='Client'
                id='sch-mdl-client-tf'
                options={clientList}
                optionText='Customer_Name'
                optionValueKey='Code'
              />
            </Form>
            <Form>
              <InputSelect
                form={PrintForm}
                name='Paper'
                label='Paper'
                id='sch-mdl-paper-tf'
                options={[{ Value: 'A3' }, { Value: 'A4' }]}
                optionText='Value'
                optionValueKey='Value'
              />
              <InputSelect
                form={PrintForm}
                name='Category'
                label='Category'
                id='sch-mdl-category-tf'
                options={categoryList}
                optionText='Value'
                optionValueKey='Code'
              />
            </Form>
          </div>
          <div style={{ padding: '5px 30px' }}>
            <div style={{ margin: '0px 0px 10px' }}> Status</div>
            <WrapperCheckBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Status'].includes('Allocated')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Status', 'Allocated')
                      }}
                    />
                  }
                  label='Allocated'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Status'].includes('In Progress')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Status', 'In Progress')
                      }}
                    />
                  }
                  label='In Progress'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Status'].includes('Completed')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Status', 'Completed')
                      }}
                    />
                  }
                  label='Completed'
                />
              </WrapBox>
            </WrapperCheckBox>
            <div style={{ margin: '10px 0px' }}>Checklist Info</div>
            <WrapperCheckBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('SR No')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'SR No')
                      }}
                    />
                  }
                  label='SR No'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Plan Arrival')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Plan Arrival')
                      }}
                    />
                  }
                  label='Plan Arrival'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Plan Depart')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Plan Depart')
                      }}
                    />
                  }
                  label='Plan Depart'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Location')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Location')
                      }}
                    />
                  }
                  label='Location'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Vessel')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Vessel')
                      }}
                    />
                  }
                  label='Vessel Name'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Client')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Client')
                      }}
                    />
                  }
                  label='Client'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Category')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Category')
                      }}
                    />
                  }
                  label='Category'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Agent')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Agent')
                      }}
                    />
                  }
                  label='Agent'
                />
              </WrapBox>
              <WrapBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PrintForm.values['Info'].includes('Duration')}
                      color='primary'
                      onChange={() => {
                        onChangeBox('Info', 'Duration')
                      }}
                    />
                  }
                  label='Plan Berthing Duration'
                />
              </WrapBox>
            </WrapperCheckBox>
          </div>
        </div>
        <div style={{ display: 'none' }}>
          <Link
            href={{
              pathname: '/printout',
              query: {
                ...PrintForm.values,
              },
            }}
          >
            <a target='_blank' id='sch-print-preview-btn'>
              click me
            </a>
          </Link>
        </div>
      </MasterModal>
    </div>
  )
}

export default SchedulerPrintOutModal
