import { MasterModal } from '../../components'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { GridWrapper } from './settlement.styled'

const ModalWorkPermitCCU = (props) => {
  const heightFactor = 525
  const documentGridHeight =
    window.innerHeight - heightFactor > 200 ? window.innerHeight - heightFactor : 200

  return (
    <>
      <MasterModal
        showModal={true}
        width={55}
        maxWidth='lg'
        isStatic={true}
        hasSubtitle={false}
        title={props.title}
        handleClose={props.handleClose}
        // whiteheader={'true'}
        isDetail={true}
      >
        <GridWrapper>
          <Grid
            id={`sr-${props.modalPermitCCU.type === 'ccu' ? 'ccu' : 'permit'}_mdl-all-data`}
            data={props.data}
            total={0}
            style={{ height: documentGridHeight }}
            rowHeight={20}
            pageSize={8}
          >
            {props.DocGrid.map((e, index) =>
              e.render === 'checklist' ? (
                <Column
                  key={index}
                  title={e.title}
                  width={e.width}
                  cell={props.checklistCell}
                  field={e.field.toLowerCase()}
                  className={'e-grid-mid-width'}
                  headerCell={() => <div></div>}
                />
              ) : e.render === 'planning_start' || e.render === 'planning_end' ? (
                <Column
                  key={index}
                  title={e.title}
                  width={e.width}
                  cell={(data) => {
                    return props.planStartEndCell(data, e.field)
                  }}
                  field={e.field.toLowerCase()}
                />
              ) : (
                <Column
                  key={index}
                  title={e.title}
                  field={e.field}
                  width={e.width}
                  cell={props.commonCell}
                />
              )
            )}
          </Grid>
        </GridWrapper>
      </MasterModal>
    </>
  )
}

export default ModalWorkPermitCCU
