export default {
  initValUOM: {
    uom_volume: '',
    uom_weight: '',
    product_type: '',
    uom_diameter: '',
    uom_quantity: '',
    dangerous_goods: '',
  },
  initValCargo : {
    post:'new',
    width: 0,
    volume: 0,
    weight: 0,
    height: 0,
    length: 0,
    quantity: 0,
    diameter: 0,
    uom_lwh: '',
    uom_volume: '',
    uom_weight: '',
    product_name: '',
    product_type: '',
    uom_diameter: '',
    uom_quantity: '',
    dangerous_goods: '',
    cargo_detail_id: 0,
    cargo_header_id: 0,
    created_date:'',
    modified_date:'',
    product_number: '',
    material_status:'',
    material_date: '',
  }
}