import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { CircularProgressbar } from 'react-circular-progressbar'

import { Colors, FontSize } from '../../../styles'

export const Wrapper = styled.div`
  width: ${(props) => props.width || '1920px'};
  height: ${(props) => props.height || '960px'};
  background: ${Colors.seaBlue} url('${(props) => props.backgroundImage}');
  background-size: auto ${(props) => props.height};
`

export const DashboardLocation = styled.img`
  position: absolute;
  right: 0;
  z-index: ${(props) => props.zIndex || 0};
  height: ${(props) => props.targetHeight || '960px'};
`

export const Jetty = styled.img`
  position: absolute;
  right: 0;
  z-index: ${(props) => props.zIndex || 0};
  height: ${(props) => props.targetHeight || '960px'};
`
export const LocationSide = styled.div`
  left: 1.5626vw;
  top: 12.82vh;
  position: absolute;
  width: 19.27vw;
  height: 1.5626vw;
  border-radius: 0.5vh 0.5vh 0px 0px;
  background-color: ${Colors.pureWhite};
`
export const Legend = styled.div`
  left: 1.5626vw;
  top: ${(props) => (props.top ? props.top : '12.82vh')};
  width: 19.27vw;
  height: 4vh;
  position: absolute;
  border-radius: 0.5vh 0.5vh 0px 0px;
  background-color: ${Colors.pureWhite};

  padding: 0.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

export const LegendDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const LegendStatus = styled.div`
  width: 1.5vh;
  height: 1.5vh;
  margin-right: 1vh;
  border-radius: 20px;
  background-color: ${(props) => props.color};
`

export const ChartContainer = styled.div`
  left: 1.5626vw;
  top: ${(props) => (props.top ? props.top : '17.9vh')};
  width: 19.27vw;
  height: 72.5%;
  max-height: ${(props) => props.innerHeight}px;
  min-height: 375px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  border-radius: 0px 0px 5px 5px;
  background-color: ${Colors.pureWhite};
  font-size: 1.2vh;
`

export const Overflow = styled.div`
  overflow-x: hidden;
  max-height: 83.5%;
  overflow-y: auto;
  padding-bottom: 5px;
`

export const TabTitle = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  width: 50%;
  height: 5.12vh;
  padding: 1vh;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.6vh;
  color: ${(props) => (props.active ? Colors.pureWhite : Colors.grey)};
  ${(props) =>
  !props.active
    ? 'box-shadow: 0 0px 0px 0 rgba(0,0,0,0.2), 0 3px 5px 0 rgba(0,0,0,0.19);'
    : `background-color: ${Colors.softBlue};`}
`

export const HeaderWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
`

export const HeaderTitle = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
`

export const JettyBox = styled.div`
  width: 17.18vw;
  height: 4.61vh;
  display: flex;
  font-size: 1.4vh;
  font-weight: bold;
  padding-left: 20px;
  border-radius: 0.5vh;
  flex-direction: row;
  align-items: center;
  color: ${Colors.pureWhite};
  background-color: ${Colors.softBlue};
`

export const JettyWrapper = styled.div`
  height: 6.14vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const DetailWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.current ? '0 1vw' : '0')};
`

export const Width = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
`

export const Detail = styled.p`
  text-align: center;
`

export const Circular = styled(CircularProgressbar)`
  width: 5.12vh;
  height: 5.12vh;

  & .CircularProgressbar-path {
    stroke: ${Colors.softBlue} !important;
  }

  &.CircularProgressbar-text {
    font-size: ${FontSize.medium}px;
    color: ${Colors.black};
  }
`

export const Vessel = styled.img`
  transform: scale(${(props) => props.scaleFactor || 1});
  transform-origin: ${(props) => props.transformOrigin || '50% 50% 0'};
  &:hover {
    cursor: pointer;
  }
`

export const TooltipsWrapper = styled.div`
  transform: rotate(${(props) => props.deg}deg);
`

export const Box = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Tooltips = styled(ReactTooltip)`
  min-width: 175px;
`

export const TittleTooltips = styled.span`
  color: ${Colors.grey};
`

export const ContentTooltips = styled.div`
  padding-left: 10px;
  color: ${Colors.black};
`

export const Jetty12 = styled.div`
  top: 31%;
  right: 40%;
  z-index: 2;
  width: 49%;
  height: 36%;
  display: flex;
  object-fit: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: rotate(-59deg);
  &:hover {
    z-index: 3;
  }
`

export const Jetty1a = styled.div`
  top: 23%;
  right: 42%;
  z-index: 2;
  width: 24%;
  height: 18%;
  display: flex;
  object-fit: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: rotate(-59deg);
  &:hover {
    z-index: 3;
  }
`

export const Jetty1b = styled.div`
  top: 14%;
  right: 50%;
  z-index: 2;
  width: 24%;
  height: 18%;
  display: flex;
  object-fit: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: rotate(-59deg);
  &:hover {
    z-index: 3;
  }
`

export const Jetty2a = styled.div`
  top: 66%;
  right: 55.25%;
  z-index: 2;
  width: 24%;
  height: 18%;
  object-fit: none;
  position: absolute;
  transform: rotate(
    120deg
  );
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    z-index: 3;
  }
`

export const Jetty2b = styled.div`
  top: 58%;
  right: 62.25%;
  z-index: 2;
  width: 24%;
  height: 18%;
  object-fit: none;
  position: absolute;
  transform: rotate(
    120deg
  );
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    z-index: 3;
  }
`

export const Jetty3a = styled.div`
  top: 29%;
  right: 19.5%;
  z-index: 2;
  height: 11%;
  width: 24.5%;
  object-fit: none;
  position: absolute;
  transform: rotate(-180deg);

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    z-index: 3;
  }
`

export const Jetty3b = styled.div`
  top: 18%;
  right: 19.5%;
  z-index: 2;
  height: 11%;
  width: 24.5%;
  object-fit: none;
  position: absolute;
  transform: rotate(-180deg);

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    z-index: 3;
  }
`

export const JettyRoroa = styled.div`
  top: 27%;
  right: 1.5%;
  z-index: 2;
  height: 17.5%;
  width: 16.75%;
  object-fit: none;
  position: absolute;
  transform: rotate(-60deg);

  display: flex;
  align-items: center;
  justify-content: center;
`

export const JettyRorob = styled.div`
  top: -1%;
  right: -6.5%;
  z-index: 2;
  height: 17.5%;
  width: 16.75%;
  object-fit: none;
  position: absolute;
  transform: rotate(-60deg);

  display: flex;
  align-items: center;
  justify-content: center;
`
