import DatePicker from 'react-datepicker'
import Dialog from '@material-ui/core/Dialog'
import EditIcon from '@material-ui/icons/Edit'
import styled, { css } from 'styled-components'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import { CircularProgressbar } from 'react-circular-progressbar'

import { Colors, FontSize } from '../../styles'
import { InputDateTime, Input, InputSelect } from '../../components'

export const ControlWrapper = styled.div`
  display: flex;
  margin: 12px 0px;
  align-items: center;
  justify-content: space-between;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
  & div.left {
    margin: 0px 5px 0px 0px;
  }
  & .estimated-time {
    font-size: 10px;
    color: ${Colors.softGrey};
    margin: 0px 5px 0px 0px;
  }
  & .datetime {
    font-size: 10px;
  }
`

export const Button = styled.div`
  cursor: pointer;
  min-width: 70px;
  padding: 5px 5px;
  text-align: center;
  background: ${(p) => (p.active ? Colors.softBlue : Colors.pureWhite)};
  color: ${(p) => (p.active ? Colors.pureWhite : Colors.softGrey)};
    ${(p) =>
  p.position === 'first' &&
      css`
        border: 2px solid ${Colors.softBlue};
        border-radius: 5px 0px 0px 5px;
      `}
    ${(p) =>
        p.position === 'middle' &&
      css`
        border-top: 2px solid ${Colors.softBlue};
        border-bottom: 2px solid ${Colors.softBlue};
      `}
    ${(p) =>
        p.position === 'last' &&
      css`
        border: 2px solid ${Colors.softBlue};
        border-radius: 0px 5px 5px 0px;
      `};
`
export const ButtonPrint = styled.div`
  cursor: pointer;
  min-width: 70px;
  padding: 2px 10px;
  text-align: center;
  margin-left: 10px;
  background: #f4f6f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000033;
  border: 2px solid #707070;
  border-radius: 5px;
  display: flex;
  align-items: center;
`
export const FlexLegend = styled.div`
  display: flex;
  min-width: 100px;
  padding: 0px 10px;
  align-items: center;
`

export const Iconlegend = styled.div`
  width: 10px;
  height: 10px;
  background: ${(p) =>
  p.type === 'overTime'
    ? '#F43838'
    : p.type === 'inProgress'
      ? '#6DB200'
      : p.type === 'allocated'
        ? Colors.purple
        : Colors.aliceBlue};
  border-radius: 4px;
`
export const Titlelegend = styled.div`
  margin: 0px 5px;
`

export const DivDropdownLocOption = styled.div`
  flex: 4;
  & div.MuiFormControl-root {
    &
      div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
      width: auto;
      &.MuiInput-underline:before {
        display: none;
      }
      &.MuiInput-underline:after {
        display: none;
      }
    }
  }
`
export const DivDropdownLocation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 10px;
  height: 35px;
  margin-top: 10px;
  margin-bottom: -40px;
  box-shadow: 0 3px 6px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const DivIconLocation = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  border-right-color: ${Colors.aliceBlue};
  border-right-style: solid;
`
export const UnallocatedCard = styled.div`
  padding: 15px;
  margin-top: 55px;
  border-radius: 5px;
  background: ${Colors.backgroundColor};
  box-shadow: 0px 0px 6px #00000029;
`
export const TitleUnallocated = styled.div`
  font-weight: 600;
`
export const SearchWrapper = styled.div`
  margin: 15px 0px;
`

export const CustomDatepicker = styled(DatePicker)`
  height: 0px;
`
export const DatepickerWrapper = styled.div`
  width: 0px;
  height: 0px;
  & .react-datepicker-wrapper {
    visibility: hidden;
    height: 0px;
  }
`

export const DatetitleWrapper = styled.div`
  text-align: center;
  width: 200px;
  margin: 0px 5px;
  cursor: pointer;
`
export const IconWrapper = styled.div`
  cursor: pointer;
`
export const ListWrapper = styled.div`
  height: ${(p) => (p.height ? p.height : 300)}px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const CardUnallocated = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin: 10px 0px 10px 0px;
  font-size: ${FontSize.medium}px;
`

export const GreenDiv = styled.div`
  background: #136500;
  width: 5px;
  height: 100%;
`
export const FieldWrapper = styled.div`
  padding: 5px 0px 5px 0px;
`

export const TitleWrapper = styled.div`
  font-weight: 600;
  display: flex;
  font-size: ${FontSize.small}px;
  & div.left {
    padding: 0px 5px 0px 0px;
    border-right: 2px solid ${Colors.grey};
  }
  & div.right {
    padding: 0px 0px 0px 5px;
  }
`
export const TimeWrapper = styled.div`
  margin: 10px 0px;
`
export const Nav = styled.div`
  height: 40px;
  display: flex;
  z-index: 1000;
  color: ${Colors.grey};
  justify-content: flex-start;
  background: ${Colors.pureWhite};
  box-shadow: 0px 2px 8px #00000033;
`
export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
`
export const MenuList = styled.div`
  height: 100%;
  cursor: pointer;
  min-width: 70px;
  margin: 0px 10px;
  line-height: 30px;
  padding: 5px 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  ${(p) =>
  p.active &&
    css`
      color: ${Colors.darkBlue};
      border-bottom: 3px solid ${Colors.darkBlue};
    `}
`
export const ContentList = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
  height: ${(p) => p.appSize.height - 337}px;
  overflow: auto;
  background: #f4f6f9;
`
export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 10px 20px 10px;
`
export const FormContainerDelay = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
`
export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;

  & div.MuiFormControl-root.MuiTextField-root.sc-ainUS.jstCnf {
    & p.MuiFormHelperText-root {
      color: ${(p) => (p.typeHelper === 'redColor' ? 'red' : 'black')};
    }
  }
`
export const FieldInput = styled(TextField)`
  margin: 10px;
`
export const FieldInputFlex = styled(TextField)`
  margin: 10px;
  margin-bottom: 0;
`
export const FieldInputDelay = styled(TextField)`
  margin-top: -5px;
  width: 240px;
`
export const InputField = styled(Input)`
  margin: 10px;
  margin-bottom: -10px;
`
export const InputFieldMiniFlex = styled(Input)`
  margin: 10px;
  margin-top: 0;
  margin-bottom: -10px;
`
export const InputFieldDelay = styled(Input)`
  margin-bottom: -5px;
`
export const InputDate = styled(InputDateTime)`
  margin: 10px;
`
export const InputDateFlex = styled(InputDateTime)`
  margin: 10px;
  margin-bottom: -10px;
`
export const InputDateDelay = styled(InputDateTime)`
  margin-top: -5px;
  margin-bottom: -10px;
`
export const SelectFieldDelay = styled(InputSelect)`
  margin: 10px;
  margin-bottom: 10px;
`
export const SelectField = styled(InputSelect)`
  margin: 10px;
  margin-bottom: -10px;
`
export const SelectFieldFlex = styled(InputSelect)`
  margin: 100px;
  margin-bottom: -10px;
`

export const ExeModalGridContainer = styled.div`
  padding: 10px 20px;
`
export const ExeModalGridContainer2 = styled.div`
  padding: 10px 20px;
  & div.k-widget.k-grid {
    & div.k-grid-container {
      & div.k-grid-content.k-virtual-content {
        & div {
          & table.k-grid-table {
            & tbody {
              background: #e1edca;
            }
          }
        }
      }
    }
  }
`
export const ModalDocContainer = styled.div`
  width: 100%;
`
export const DocumentUploadButtonContainer = styled.div`
  width: 584px;
  height: 70px;
  display: flex;
  margin: 30px auto;
  padding: 20px auto;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  border: 1px dashed #707070;
`
export const UploadButton = styled.label`
  width: 129px;
  height: 29px;
  display: flex;
  cursor: pointer;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  background: #eaeaea 0% 0% no-repeat padding-box;
`
export const DocumentDownloadAllButtonContainer = styled.div`
  padding: 0;
  display: flex;
  margin: 0 20px;
  height: 50px;
  padding: 20px auto;
  align-items: center;
  justify-content: flex-end;
`
export const DownloadAllButton = styled.div`
  width: 132px;
  height: 32px;
  display: flex;
  color: #0a81ad;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const DownloadButton = styled.div`
  width: 98px;
  height: 26px;
  display: flex;
  color: #0a81ad;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const ActionCell = styled.div`
  cursor: pointer;
`
export const IconDel = styled(DeleteIcon)`
  color: ${(p) => (p.type === 'noneditable' ? 'gray' : 'black')};
`
export const RowIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`
export const IconEdit = styled(EditIcon)`
  color: #71af00;
  color: ${(p) => (p.type === 'noneditable' ? 'gray' : '#71af00')};
`
export const IconSave = styled(CheckIcon)`
  color: #71af00;
`
export const IconClear = styled(CloseIcon)`
  color: #ff0000;
`
export const buttonAct = styled.button`
  background-color: transparant;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  outline: none;
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`
export const TotalPackageText = styled.div`
  text-align: right;
  padding: 10px 0 10px 0;
  font-weight: bold;
`
export const WrapperGrid = styled.div`
  & div.k-widget.k-grid {
    & div.k-grid-header {
      & div.k-grid-header-wrap {
        & table {
          & thead {
            & tr {
              & th.k-header {
                color: black;
                background: #dddddd !important;
              }
            }
          }
        }
      }
    }
    & div.k-grid-container {
      & div.k-grid-content.k-virtual-content {
        & div {
          & table.k-grid-table {
            & tbody {
              background: white;
            }
          }
        }
      }
    }
  }
`
export const WrapperSelectTask = styled.div`
  display: flex;
  margin-top: 8px;
  & div.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    & div.MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
      & p.MuiFormHelperText-root {
        display: none;
      }
    }
  }
`
export const WrapperOthers = styled.div`
  margin-top: 10px;
  margin-left: -10px;
  width: 1040px;
  background: 'white';
`
export const CheckBoxAct = styled(Checkbox)`
  margin-top: 5px;
  margin-left: 20px;
  float: left;
  flex: 1;
`

export const TextLink = styled.p`
  color: blue;
  text-decoration: underline;
  width: 250px;
  cursor: pointer;
`
export const ButtonTabSr = styled.button`
  box-shadow: 0px 0px 10px grey;
  width: 110px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`
export const Circular = styled(CircularProgressbar)`
  width: 50px;
  height: 50px;

  & .CircularProgressbar-path {
    stroke: ${Colors.softBlue} !important;
  }

  &.CircularProgressbar-text {
    font-size: ${FontSize.medium}px;
    color: ${Colors.black};
  }
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`
export const StatusCell = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 0px 5px;
  border-radius: 5px;
  justify-content: center;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
  border: solid ${(p) => (p.color == Colors.black ? '1px' : '0px')} black;
`

export const ButtonAddContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
`

export const InboundButton = styled.div`
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`
export const CustomModal = styled(Dialog)`
    .MuiDialog-paperWidthLg {
        min-width: 853px;
        min-height: 650px;
    }
    .MuiDialog-paperWidthXl {
        width: 1501px;
        min-height: 650px;
    }
    .MuiDialog-paperWidthSm {
        width: 600px;
        min-height: auto;
    }
`

export const GridWrapper = styled.div`
  margin-top:10px;
  & div.k-widget.k-grid{
    & div.k-grid-header{
      & div.k-grid-header-wrap{
        & table{
          & thead{
            & tr{
              & th{
                background:#DDEAFF !important;
                color:#333333;
              }
            }
          }
        }
      }
    }
  }
`