import React from 'react'

import Select from '@material-ui/core/Select'
import { FormControl, makeStyles } from '@material-ui/core'

import MenuItem from '@material-ui/core/MenuItem'

import {
  DivDropdownLocation,
  DropdownStyled,
  DropdownStyledSide,
  IconLocation,
} from './dashboard-dropdown.styled'
import { Colors } from '../../styles'
import { Images } from '../../assets'

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 300,
  },
}))

export const DropdownLoc = ({ location, dataList, setLocation, main }) => {
  const classes = useStyles()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',

        width: '390px',
        height: '30px',
        position: 'absolute',
        borderRadius: '30px',

        boxShadow: '0 3px 6px #00000029',
        background: '#ffffff 0% 0% no-repeat padding-box',
      }}
    >
      <div
        style={{
          flexGrow: 1,
          marginTop: '8px',
          marginLeft: '10px',
        }}
      >
        <img
          style={{
            color: '#95c123',
            height: '35px',
          }}
        />
      </div>
      <div
        style={{
          flexGrow: 1,
          marginTop: 'auto',
          marginLeft: '10px',
        }}
      >
        <div
          style={{
            borderLeft: '1px solid black',
            backgroundClip: 'padding-box',
            height: '50px',
          }}
        />
      </div>

      <DropdownStyled>
        <FormControl className={classes.formControl}>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            id='demo-simple-select'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            {main && <MenuItem value={''}>Port Location</MenuItem>}
            {dataList &&
              dataList.map((e, i) => (
                <MenuItem key={i} value={e.LocationDescription}>
                  {e.LocationDescription}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DropdownStyled>
    </div>
  )
}

export const DropdownLocSide = ({ location, dataList, setLocation }) => {
  const locationDescription =
    location && location.LocationDescription ? location.LocationDescription : ''

  return (
    <DivDropdownLocation
    // style={{   display: 'flex',   justifyContent: 'space-between',   position: 'absolute',   flexDirection: 'row',   borderRadius: '10px',   height: '30px',   width: '370px',   boxShadow: '0 3px 6px #00000029',   background: '#ffffff 0% 0% no-repeat padding-box', }}
    >
      <IconLocation>
        <img
          src={Images.LocationIcon}
          style={{
            color: Colors.aliceBlue,
            height: '2.56vh',
            marginTop: '0.3vh',
          }}
        />
      </IconLocation>
      <DropdownStyledSide

      // display: 'flex', flexDirection: 'row', width: '370px', height: '30px', position: 'absolute', borderRadius: '10px', boxShadow: '0 3px 6px #00000029',background: '#ffffff 0% 0% no-repeat padding-box',
      >
        <FormControl style={{ width: '100%' }}>
          <Select
            readOnly={dataList.length <= 1}
            style={{
              paddingLeft: '10px',
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            id='demo-simple-select'
            value={locationDescription}
            onChange={(e) => setLocation(e.target.value)}
          >
            {/* <MenuItem value={null}>Port Location</MenuItem> */}
            {dataList &&
              dataList.map((e, i) => (
                <MenuItem key={i} value={e.LocationDescription}>
                  {e.LocationDescription}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DropdownStyledSide>
    </DivDropdownLocation>
  )
}
