import _ from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'

import { ExecutionAPI } from '~/api'
import {
  GetDurationMinutes,
  ConvertLocalTimePost,
  ConvertLocalTimeGet,
  getDurationBetweenTwoTime,
} from '~/utils'

const createForm = () => {
  return useFormik({
    enableReinitialize: true,
    initialValues: {
      id: -1,
      code: '',
      remarks: '',
      start_date: '',
      delay_code: '',
      finish_date: '',
      activity_code: '',
      delay_duration: 0,
      delay_dur_hour: 0,
      delay_dur_minute: 0,
      delay_type_code: '',
      task_header_code: '',
    },
    validationSchema: Yup.object({
      id: Yup.number(),
      code: Yup.string(),
      remarks: Yup.string(),
      finish_date: Yup.date(),
      delay_duration: Yup.number(),
      start_date: Yup.date().required('Start date cannot be empty'),
      delay_code: Yup.string().required('Delay desc cannot be empty'),
      task_header_code: Yup.string().required('Task cannot be empty'),
      activity_code: Yup.string().required('Activity cannot be empty'),
      delay_type_code: Yup.string().required('Delay type cannot be empty'),
    }),
  })
}

const insertDelay = async (delayData) => {
  try {
    const response = await ExecutionAPI.insertProgressDelay({ ...delayData, id: 0 })  // Make sure the id is not a string
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to insert delay')
    })
  } catch(error) {
    return error
  }
}

const updateDelay = async (delayData) => {
  try {
    const response = await ExecutionAPI.updateProgressDelay(delayData)
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to update delay')
    })
  } catch(error) {
    return error
  }
}

const handleInsertOrUpdateDelayHOF = (srCode, isInEdit, form, setDelayList, location, setIsInEdit) => {
  return async () => {
    const delayData = {
      ...form.values,
      start_date: form.values.start_date ? ConvertLocalTimePost(location, form.values.start_date) : null,
      finish_date: form.values.finish_date ? ConvertLocalTimePost(location, form.values.finish_date) : null,
    }
    if (isInEdit) await updateDelay(delayData)
    else await insertDelay(delayData)

    const activityList = await fetchActivityList(srCode)
    const delayList = await processDelayList(activityList)
    setDelayList(delayList)
    form.resetForm()
    setIsInEdit(false)
  }
}

const deleteDelay = async (delayId) => {
  try {
    const response = await ExecutionAPI.deleteProgressDelay(delayId)
    return response
  } catch(error) {
    return error
  }
}

const handleDeleteDelayHOF = (delayList, setDelayList, delay, setShowAlert, setToggleDelete) => {
  return async () => {
    const delayFiltered = delayList.filter(d => d.id !== delay.id)
    setDelayList(delayFiltered)
    const response= await deleteDelay(delay.id)
    if (response) {
      setShowAlert({
        msg: 'Delete data has been successful',
        success: true,
        visible: true,
        type: 'delete',
      })
      setToggleDelete(false)
    }
    else {
      setShowAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      setToggleDelete(false)
    }
  }
}

const handleToggleEditHOF = (form, setIsInEdit, location, delayList, setDelayList) => {
  return (delay) => {
    setIsInEdit(prev => !prev)

    form.setFieldValue('id', delay.id)
    form.setFieldValue('code', delay.Code)
    form.setFieldValue('activity_code', delay.activity_code)
    form.setFieldValue('task_header_code', delay.task_header_code)
    form.setFieldValue('delay_type_code', delay.delay_type_code)
    form.setFieldValue('delay_code', delay.delay_code)
    form.setFieldValue('start_date', ConvertLocalTimeGet(location, delay.start_date, 'YYYY-MM-DD HH:mm'))
    if (delay.finish_date === '0001-01-01T00:00:00Z') {
      form.setFieldValue('finish_date', '')
    } else {
      form.setFieldValue('finish_date', ConvertLocalTimeGet(location, delay.finish_date, 'YYYY-MM-DD HH:mm'))
    }
    form.setFieldValue('delay_duration', delay.delay_duration)
    form.setFieldValue('remarks', delay.remarks)

    // Tgggle the selected delay to be in edit mode
    const updatedDelayList = delayList.map(dly => {
      if (dly.code === delay.code) return { ...dly, isInEdit: true }
      return dly
    })
    setDelayList(updatedDelayList)
  }
}

const handleToggleCancelHOF = (form, setIsInEdit, delayList, setDelayList) => {
  return () => {
    setIsInEdit(prev => !prev)

    form.resetForm()

    // Toggle back every delay in edit mode to false (view mode)
    const updatedDelayList = delayList.map(dly => ({ ...dly, isInEdit: false }))
    setDelayList(updatedDelayList)
  }
}


const useFetchDelayTypeOptions = () => {
  const [delayTypeOptions, setDelayTypesOptions] = useState([])

  const fetchData = async () => {
    try {
      const response = await ExecutionAPI.getMasterDelayType()
      setDelayTypesOptions(response?.data?.message?.data || [])
      return response.data.data
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return delayTypeOptions
}

const useFetchDelayDescOptions = (delayTypeCode) => {
  const [delayDescOptions, setDelayDescOptions] = useState([])

  const fetchData = async () => {
    try {
      const response = await ExecutionAPI.getMasterDelay(delayTypeCode)
      setDelayDescOptions(response?.data?.message?.data || [])
      return response.data.data
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (delayTypeCode) {
      fetchData()
    }
  }, [delayTypeCode])

  return delayDescOptions
}

const fetchActivityList = async (srCode) => {
  try {
    const response = await ExecutionAPI.getProgressActivityTaskHeaderDelay(srCode)
    return new Promise((resolve, reject) => {
      const data = response?.data?.message?.data ?? []
      const sortedData = _.sortBy(data, function(o) {
        return o.sequence_no
      })

      resolve(sortedData)
      reject('Failed to fetch activity list')
    })
  } catch (error) {
    return error
  }
}

const useFetchActivityListBySRCode = (srCode) => {
  const [activityList, setActivityList] = useState([])

  const fetchData = async () => {
    try {
      const result = await fetchActivityList(srCode)
      setActivityList(result)
      return result
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (srCode) {
      fetchData()
    }
  }, [srCode])

  return activityList
}

const useFindTaskList = (activityList, activityCode) => {
  const [taskList, setTaskList] = useState([])

  const findTaskList = () => {
    const activityFound = (activityList ?? []).find(activity => activity.code === activityCode)

    setTaskList(activityFound?.progress_task_header_list ?? [])
  }

  useEffect(() => {
    if (activityList && activityCode) {
      findTaskList()
    }
  }, [activityCode])

  return taskList
}

const formatDatetime = (datetime) => {
  if (!datetime) {  // if datetime is a falsy return empty string
    return ''
  }
  return moment(datetime).format('DD MMMM YYYY HH:mm')
}

const useCheckChangeOfDuration = (form) => {
  const adjustDuration = () => {
    const durationInFormattedString = GetDurationMinutes(form.values.start_date, form.values.finish_date, 0)
    const splitted = durationInFormattedString.split(' ') // Let's find a better way to get the duration in correct form later
    form.setFieldValue('delay_dur_hour', parseInt(splitted[0]))
    form.setFieldValue('delay_dur_minute', parseInt(splitted[2]))
    form.setFieldValue('delay_duration', getDurationBetweenTwoTime(form.values.start_date, form.values.finish_date))
  }

  useEffect(() => {
    adjustDuration()
  }, [form.values.start_date, form.values.finish_date])
}

const useFetchDelayList = (activityCode, taskHeaderCode, setDelayList) => {
  const fetchData = async () => {
    try {
      const payload = {
        activity_code: activityCode,
        task_header_code: taskHeaderCode
      }
      const response = await ExecutionAPI.getProgressDelayByActivityAndTaskHeader(payload)
      setDelayList(response.data.message.data)
      return response.data.message.data
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (activityCode && taskHeaderCode) {
      fetchData()
    }
  }, [activityCode, taskHeaderCode])
}

const durationToFormattedString = (duration) => {
  return parseInt(duration)
    ? `${Math.floor(duration / 60)} Hours ${duration % 60} Minutes`
    : '0 Hours 0 Minutes'
}

const processDelayList = (activityList) => {
  let concatenatedDelayList = []
  activityList.forEach(activity => {
    (activity.progress_task_header_list ?? []).forEach(task => {
      concatenatedDelayList = [...concatenatedDelayList, ...(task.progress_delay_list ?? [])]
    })
  })

  // By default all delay is not in edit condition
  return concatenatedDelayList.map(delay => ({ ...delay, isInEdit: false }))
}

const useConcatAllDelayList = (activityList) => {
  const [delayList, setDelayList] = useState([])

  const processData = () => {
    const processed = processDelayList(activityList)

    setDelayList(processed)
  }

  useEffect(() => {
    processData()
  }, [activityList])

  return [delayList, setDelayList]
}

export default {
  createForm,
  formatDatetime,
  useFindTaskList,
  useFetchDelayList,
  handleToggleEditHOF,
  handleToggleCancelHOF,
  handleDeleteDelayHOF,
  useConcatAllDelayList,
  useCheckChangeOfDuration,
  useFetchDelayTypeOptions,
  useFetchDelayDescOptions,
  durationToFormattedString,
  handleInsertOrUpdateDelayHOF,
  useFetchActivityListBySRCode,
}