
import React from 'react'

import { MasterModal, ModalHistory } from '../../components'

const DetailModelProgress = (props) => {
  const { data, isEdit, isDetail, isApproved, isWaitApproved, isProgress, progressItem } = props

  return (
    <div style={{ maxWidth: '40vw' }}>
      <MasterModal
        step={props.data ? props.data.Sr_Status : ''}
        width={45}
        maxWidth='lg'
        add={!isDetail}
        isStatic={true}
        isSrModal={true}
        fullWidth={true}
        isProgress={isProgress}
        isApproved={isApproved}
        isWaitApproved={isWaitApproved}
        isDetailSrModal={isDetail}
        title='Detail Status Progress'
        showModal={props.showModal}
        handleClose={() => props.closeSrModal(!props.showModal)}
        subTitle={
          isEdit
            ? 'Edit Service Request'
            : isDetail
              ? 'Detail Service Request'
              : 'Add Service Request'
        }
      >
        <div style={{ background: '#F6F9FC', height : '47rem', overflowY : 'auto' }}>
          <ModalHistory
            data={data}
            progressItem={progressItem}
          />
        </div>
      </MasterModal>
    </div>
  )
}
export default DetailModelProgress