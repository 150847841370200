import _ from 'lodash'
import moment from 'moment'
import { useState, useEffect } from 'react'

import { ExecutionAPI, SettlementAPI } from '~/api'
import { getDurationBetweenTwoTime, ConvertLocalTimePost, ConvertLocalTimeGet } from '~/utils'

const fetchActivityData = async (srCode, location) => {
  try {
    const response = await ExecutionAPI.getActivityList(srCode)
    return new Promise((resolve, reject) => {
      // set the activity carousel opened by default
      const updatedActivityList = (response?.data?.message?.data || []).map(act => {
        const updatedTaskList = (act.progress_task_header_list ?? []).map(ts => ({
          ...ts,
          isOpen: false,
          actual_start_time: ts.actual_start_time ? ConvertLocalTimeGet(location, ts.actual_start_time, 'DD MMMM YYYY HH:mm') : null,
          actual_finish_time: ts.actual_finish_time ? ConvertLocalTimeGet(location, ts.actual_finish_time, 'DD MMMM YYYY HH:mm') : null,
          billng_start_time: ts.billng_start_time ? ConvertLocalTimeGet(location, ts.billng_start_time, 'DD MMMM YYYY HH:mm') : null,
          billng_finish_time: ts.billng_finish_time ? ConvertLocalTimeGet(location, ts.billng_finish_time, 'DD MMMM YYYY HH:mm') : null,
        }))

        return {
          ...act,
          start_date: act.start_date ? ConvertLocalTimeGet(location, act.start_date, 'DD MMMM YYYY HH:mm'): null,
          finish_date: act.finish_date ?ConvertLocalTimeGet(location, act.finish_date, 'DD MMMM YYYY HH:mm'): null,
          isOpen: true,
          progress_task_header_list: updatedTaskList,

        }
      })

      resolve(updatedActivityList)
      reject(new Error('Failed to fetch activity data'))
    })
  } catch(error) {
    return error
  }
}


const useFetchActivityBySRCode = (srCode, location, setIsLoading) => {
  const [activityList, setActivityList] = useState([])

  const fetchData = async () => {
    setIsLoading(true)
    const activityData = await fetchActivityData(srCode, location)
    setActivityList(activityData)
    setIsLoading(false)
  }

  useEffect(() => {
    if (srCode) {
      fetchData()
    }
  }, [srCode])

  return [activityList, setActivityList]
}

const formatDatetime = (datetime) => {
  if (!datetime) {  // if datetime is a falsy return empty string
    return ''
  }
  return moment(datetime).format('DD MMMM YYYY HH:mm')
}

const calculateTableWidth = (columns) => {
  const calculatedColumns = columns
    .filter(column => column.width != '*')
    .map(column => {
      column.width.replace('px', '')
      return parseInt(column.width)
    })
  return calculatedColumns.reduce((column, index) => column + index, 0)
}

const useCalculateTableWidth = (columns, ) => {
  const width = window.innerWidth - (true ? 0 : 234)

  return calculateTableWidth(columns) > width ? calculateTableWidth(columns) : width
}

const useFindTaskDetailList = (activityList, activityCode, taskHeaderCode) => {
  const [taskDetailList, setTaskDetailList] = useState([])

  const findTaskDetailList = () => {
    const activityFound = (activityList ?? []).find(activity => activity.code === activityCode)
    const taskHeaderFound = (activityFound?.progress_task_header_list ?? []).find(taskHeader => taskHeader.code === taskHeaderCode)
    const taskDetail = taskHeaderFound?.progress_task_detail_list ?? []
    setTaskDetailList(taskDetail.map(detail => ({ ...detail, isNew: false })))
  }

  useEffect(() => {
    if (activityList && activityCode && taskHeaderCode) {
      findTaskDetailList()
    }
  }, [activityCode, taskHeaderCode])

  return [taskDetailList, setTaskDetailList]
}

// This custom hook is used to fetch data unit and equipment labour data
const useFetchDropDownOptions = (srCode, initvalDropDown, locationCode) => {
  const [dropDownOptions, setDropDownOptions] = useState(initvalDropDown)

  const fetchData = async () => {
    try {
      const queryUnit = {
        filters:[{
          value:locationCode,
          field:'location_code',
          type:'text'
        }]
      }
      const unitNameResponse = await ExecutionAPI.getAllMasterUnit(queryUnit)
      const equipLabourResponse = await ExecutionAPI.getEquipmentLabourBySRCode(srCode)

      setDropDownOptions({
        unit_name_code: (unitNameResponse.data.message.data ?? []),
        estimation_detail_code: (equipLabourResponse.data.message.data ?? []),
      })
    } catch(error) {
      return error
    }
  }

  useEffect(() => {
    if (srCode) {
      fetchData()
    }
  }, [srCode])

  return [dropDownOptions, setDropDownOptions]
}

const handleOpenActivityDetailHOF = (activityList, setActivityList) => { // HOF: higher-order-function
  return (activity) => {
    const updated = activityList.map(item => {
      if (activity.id === item.id) return { ...item, isOpen: !item.isOpen }

      return item
    })

    setActivityList(updated)
  }
}

const handleOpenTaskDetailHOF = (activityList, setActivityList) => {
  return (activity, task) => {
    const updatedActivityList = activityList.map(act => {
      if (activity.id === act.id) {
        const updatedTaskList = (activity.progress_task_header_list ?? []).map(ts => {
          if (task.id === ts.id) {
            return { ...ts, isOpen: !ts.isOpen }
          }

          return ts
        })

        return { ...act, progress_task_header_list: updatedTaskList }
      }

      return act
    })

    setActivityList(updatedActivityList)
  }
}

const deleteTask = async (taskId, modifiedDate) => {
  try {
    return await ExecutionAPI.deleteProgressTaskHeader(taskId, modifiedDate)
  } catch(error) {
    return error
  }
}

const deleteTaskHOF = (location, setActivityList, activity, task, setToggleDelete, setShowAlert, setIsLoading) => {   // HOF: higher-order-function
  return async () => {
    try {
      // Remove out the will be deleted task and recalculate activity progress
      const filteredTaskHeaderList = (activity.progress_task_header_list ?? []).filter(ts => ts.code !== task.code)
      let willBeUpdatedActivity
      if (filteredTaskHeaderList.length === 0) {
        const start = moment(activity.start_date).toISOString()
        const finish = moment(activity.finish_date).toISOString()

        willBeUpdatedActivity = {
          ...activity,
          start_date: start !== '1970-01-01T07:00:00+07:00' ? ConvertLocalTimePost(location, start) : null,
          finish_date: finish !== '1970-01-01T07:00:00+07:00' ? ConvertLocalTimePost(location, finish) : null,
          progress_task_header_list: null,  // progress_task_header_list is not used on the backend when delete task
          modified_date: ConvertLocalTimePost(location, new Date()),
        }
      } else {
        const { start_date, finish_date } = findTheEarliestAndLatestDateFromTasksList(filteredTaskHeaderList)

        willBeUpdatedActivity = {
          ...activity,
          start_date: ConvertLocalTimePost(location, start_date),
          finish_date: ConvertLocalTimePost(location, finish_date),
          progress_task_header_list: null,  // progress_task_header_list is not used on the backend when delete task
          modified_date: ConvertLocalTimePost(location, new Date()),
        }
      }

      await deleteTask(task.id, ConvertLocalTimePost(location, new Date()))
      await SettlementAPI.updateActivitySettlement(willBeUpdatedActivity)
      setIsLoading(true)
      const result = await fetchActivityData(activity.sr_code, location)
      setActivityList(result)
      if (result) {
        setShowAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
        setToggleDelete(false)
      }
      else {
        setShowAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
        setToggleDelete(false)
      }
      setIsLoading(false)
    } catch(error) {
      setToggleDelete(false)
      setIsLoading(false)
      return error
    }
  }
}

const durationToFormattedStringHours = (duration) => {
  return parseInt(duration)
    ? `${Math.floor(duration / 60)} Hours ${duration % 60} Minutes`
    : '0 Hours 0 Minutes'
}

const durationtoFormattedStringDays = (duration) => {
  return parseFloat(duration)
    ? `${((duration / 60) /24).toFixed(1)} Days`
    : '0 Days'
}

const findMaxSortNoTaskList = (tasks) => {
  // Find the max sort number in a list of progress header tasks
  const taskSortList = tasks.map(task => task.sort_no)
  const maxSortNo = _.max(taskSortList)

  return maxSortNo || 0
}

const insertNewTask = async (taskHeaderData) => {
  try {
    const response = await ExecutionAPI.insertProgressTaskHeader(taskHeaderData)
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to insert task')
    })
  } catch(error) {
    return error
  }
}

const addNewTaskHOF = (location, setActivityList, setIsLoading) => {   // HOF: higher-order-function
  return async (activity, taskDescription) => {
    try {
      setIsLoading(true)
      const newTask = {
        percentage: 0,
        sr_code: activity.sr_code,
        task: taskDescription,
        activity_code: activity.code,
        sort_no: findMaxSortNoTaskList(activity.progress_task_header_list || []) + 1
      }

      await insertNewTask(newTask)  // id set to be 0 because id is supposed to be int when send to backend
      const result = await fetchActivityData(activity.sr_code, location)
      setActivityList(result)
      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      return error
    }
  }
}

const updateTask = async (taskHeaderData) => {
  try {
    const response = await ExecutionAPI.updateProgressTaskHeader(taskHeaderData)
    return new Promise((resolve, reject) => {
      resolve(response?.data?.message?.data ?? true)
      reject(new Error('Failed to update task'))
    })
  } catch (error) {
    return error
  }
}

const updateTaskHOF = () => {
  return async (taskHeaderData) => {
    try {
      const result = await updateTask(taskHeaderData)
      return result
    } catch (error) {
      return error
    }
  }
}

const toggleModalHOF = (srCode, location, isModalOpen, setShowModalStatus, setIsLoading, setActivityList) => {
  return async (activityCode, taskCode, taskName) => {
    if (isModalOpen) {
      setShowModalStatus(prev => ({
        ...prev,
        isOpen: false,
      }))

      setIsLoading(true)
      const activityData = await fetchActivityData(srCode, location)
      setActivityList(activityData)
      setIsLoading(false)

      return
    }

    setShowModalStatus(prev => ({
      ...prev,
      taskCode,
      taskName,
      activityCode,
      isOpen: !prev.isOpen,
    }))
  }
}

const updateActivityAndTaskHeader = async (activityPayload, taskHeaderPayload) => {
  try {
    const response = await SettlementAPI.updateActivityAndTaskHeaderForSettlement(activityPayload, taskHeaderPayload)
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to update activity and task header')
    })
  } catch(error) {
    throw new Error('Failed to update activity and task header => ' +  error.toString())
  }
}

const updateActivityAndTaskHeaderHOF = (activityList, setActivityList, location) => {
  return async (activity, task) => {
    try {
      let updatedTask
      let updatedActivity

      const updatedList = activityList.map(act => {
        if (activity.code === act.code) {
          const updatedTaskList = (act.progress_task_header_list || []).map(ts => {
            if (ts.code === task.code) {
              const actualDurTime = getDurationBetweenTwoTime(task.actual_start_time, task.actual_finish_time)
              const billDurTime = getDurationBetweenTwoTime(task.billng_start_time, task.billng_finish_time)
              updatedTask = {
                ...ts,
                ...task,
                actual_dur_time: actualDurTime,
                billng_dur_time: billDurTime,
                inEdit: false
              }
              return updatedTask
            }

            return ts
          })

          const start_date = findTheEarliestAndLatestDateFromTasksList(updatedTaskList).start_date
          const finish_date = findTheEarliestAndLatestDateFromTasksList(updatedTaskList).finish_date

          updatedActivity = {
            ...act,
            ...activity,
            progress_task_header_list: updatedTaskList,
            start_date: start_date,
            finish_date: finish_date,
          }
          return updatedActivity
        }

        return act
      })

      setActivityList(updatedList)

      // Set progress_task_header_list to null to enhance the marshaling performance on the Backend
      await updateActivityAndTaskHeader({
        ...updatedActivity,
        start_date: ConvertLocalTimePost(location, updatedActivity.start_date),
        finish_date: ConvertLocalTimePost(location, updatedActivity.finish_date),
        modified_date: ConvertLocalTimePost(location, new Date()),
      }, {
        ...updatedTask,
        actual_start_time: ConvertLocalTimePost(location, updatedTask.actual_start_time),
        actual_finish_time: ConvertLocalTimePost(location, updatedTask.actual_finish_time),
        billng_start_time: ConvertLocalTimePost(location, updatedTask.billng_start_time),
        billng_finish_time: ConvertLocalTimePost(location, updatedTask.billng_finish_time),
        modified_date: ConvertLocalTimePost(location, new Date()),
      })
    } catch(error) {
      return error
    }
  }
}

const findTheEarliestAndLatestDateFromTasksList = (tasks) => {
  // Since there is no tasks in the activity, then make the finish time null
  if (tasks.length === 0) {
    return null
  }

  // Find the latest actual finish time in a list of progress task headers
  const taskStartDateList = tasks.filter(task => task.actual_start_time).map(task => new Date(task.actual_start_time))
  const taskFinishDateList = tasks.filter(task => task.actual_finish_time).map(task => new Date(task.actual_finish_time))

  const startDate = moment(_.min(taskStartDateList)).toISOString()
  const finishDate = moment(_.max(taskFinishDateList)).toISOString()

  return {
    start_date: startDate !== '1970-01-01T07:00:00+07:00' ? startDate : null,
    finish_date: finishDate !== '1970-01-01T07:00:00+07:00' ? finishDate : null
  }
}


const updateSort = async ({ dataSort, data, location, handleClose, setActivityList }) => {
  try {
    const sort = []
    dataSort.forEach((item) => {
      sort.push({ Field: item.field, Dir: item.dir })
    })
    const response = await ExecutionAPI.updateTaskHeaderSort(sort, data)
    if (response.data) {
      const result = await fetchActivityData(data.sr_code, location)
      setActivityList(result)
      handleClose()
    }
  } catch (err) {
    console.log(err)
    handleClose()
  }
}

export default {
  updateSort,
  addNewTaskHOF,
  deleteTaskHOF,
  updateTaskHOF,
  toggleModalHOF,
  formatDatetime,
  useFindTaskDetailList,
  useCalculateTableWidth,
  useFetchDropDownOptions,
  handleOpenTaskDetailHOF,
  useFetchActivityBySRCode,
  durationToFormattedStringHours,
  durationtoFormattedStringDays,
  handleOpenActivityDetailHOF,
  updateActivityAndTaskHeaderHOF,
}