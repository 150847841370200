import styled from 'styled-components'
import { Colors, FontSize } from '../../../styles'

export const WrapperAppointment = styled.div`
  width: 100%;
  font-size: ${FontSize.medium}px;
  height: 100%;
  position: relative;
  display: flex;
  line-height: 36px;
  background: ${Colors.purple};
`

export const Caution = styled.div`
  font-size: ${FontSize.extraLarge}px;
  margin: 0px 5px;
  color: ${Colors.red};
`

export const HeaderWrapper = styled.div`
  height: ${(p) => (p.isMonth ? 90 : 60)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.pureWhite};
  font-size: ${FontSize.small}px;
`

export const DayWrapper = styled.div`
  font-weight: 600;
  font-size: ${FontSize.medium}px;
`

export const ResourceWrapper = styled.div`
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.pureWhite};
  font-weight: 600;
  font-size: ${FontSize.medium}px;
`
export const CloseButton = styled.div`
  position: absolute;
  top: 2.5px;
  right: 2.5px;
  color: white;
  background: red;
  border-radius: 10px;
  width: 17.5px;
  height: 17.5px;
  text-align: center;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ToolTip = styled.div`
  font-size: ${FontSize.extraSmall}px;
`
export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`
export const WrapperCheckBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`
export const WrapBox = styled.div`
  min-width: 130px;
`
