import { FormControl, MenuItem, Select } from '@material-ui/core'

import { Images } from '../../assets'
import { Colors } from '../../styles'

import { DivDropdownLocation, DivDropdownLocOption, DivIconLocation } from './execution.styled'

const ExecutionLocation = ({ location, dataList, setLocation }) => {
  return (
    <DivDropdownLocation
    // style={{   padding: '5px   display: 'flex',   justifyContent: 'space-between',   position: 'absolute   flexDirection: 'row',   borderRadius: '10px',   height: '35px',   width: '290px',   marginTop: '10px',   boxShadow: '0 3px 6px #00000029',   background: '#ffffff 0% 0% no-repeat padding-box', }}
    >
      <DivIconLocation //style={{  width: '60px',  display: 'flex',  justifyContent: 'center',  alignItems: 'center',}}
      >
        <img
          src={Images.LocationIcon}
          style={{
            color: Colors.aliceBlue,
            width: '40%',
          }}
        />
      </DivIconLocation>
      <DivDropdownLocOption>
        <FormControl //className={classes.formControl}
          style={{
            width: '98%',
            height: '25px',
            margin: '5px',
          }}
        >
          <Select
            readOnly={dataList.length <= 1}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            style={{ height: '40px' }}
            id='demo-simple-select'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            {
              ///*
              dataList &&
                dataList.map((e, iLoc) => (
                  <MenuItem id={iLoc} key={e.LocationID} value={e.LocationDescription}>
                    {e.LocationDescription}
                  </MenuItem>
                ))

              // */
            }
            )
          </Select>
        </FormControl>
      </DivDropdownLocOption>
    </DivDropdownLocation>
  )
}

export default ExecutionLocation
