import SC from './styled.cargo-equipment'

import CargoGrid from './cargo'
import EquipmentLabourGrid from './equipment-labour'

const CargoEquipmentModal = (props) => {
  return (
    <SC.Container>
      <CargoGrid
        dataAll={props.dataAll}
      />
      <EquipmentLabourGrid
        agreement={props.agreement}
        dataAll={props.dataAll}
      />
    </SC.Container>
  )
}

export default CargoEquipmentModal