const calculateTableWidth = (columns) => {
  const calculatedColumns = columns
    .filter(column => column.width != '*')
    .map(column => {
      column.width.replace('px', '')
      return parseInt(column.width)
    })
  return calculatedColumns.reduce((column, index) => column + index, 0)
}

export const useCalculateTableWidth = (columns, ) => {
  const width = window.innerWidth - (true ? 0 : 234)

  return calculateTableWidth(columns) > width ? calculateTableWidth(columns) : width
}