import _ from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import RestorePageIcon from '@material-ui/icons/RestorePage'

import NotifHistory from './notification.history.container'
import SettlementModal from '../settlement/settlement.modal.container'
import ExecutionModal from '../execution/modal/execution.modal.container'
import { Card, ModalReject, Alerts, ModalApprove } from '../../components'
import ServiceRequestModal from '../service_request/service-request.modal.container'
import {
  ExecutionAPI,
  SettlementAPI,
  ServiceRequestAPI,
  DashboardAPI
} from '../../api'
import {
  WrapperNotification,
  StatusDiv,
  ButtonAction,
  ButtonTab,
  CardNeedAction,
  ButtonAddContainer
} from './notification.styled.container'

import { Images } from '../../assets'
import { WrapperContent, Title, TitleMenu } from '../../styles/global.styled'
import {
  ConvertLocalTimeGet,
  ConvertLocalTimePostUTC,
  Lookup,
  messageErrorApproval
} from '../../utils'
import NotificationLoader from './loader'

const Notification = () => {
  const router = useRouter()
  const param = router.query
  const [show, setShow] = useState(false)
  const [action, setAction] = useState(false)
  const [dataExct, setDataExct] = useState({})
  const [dataSettle, setDataSettle] = useState({})
  const [dataSrCode, setDataSrCode] = useState('')
  const [active, setActive] = useState(true)
  const [listVessel, setListVessel] = useState([])
  const [dataAllNotif, setDataAllNotif] = useState([])
  const [statusDetail, setStatusDetail] = useState('')
  const [showColumnOption, setShowColumnOption] = useState(false)
  const [dataServiceRequest, setDataServiceRequest] = useState({})

  const [approved, setApproved] = useState(false)
  const [waitApproved, setWaitApproved] = useState(false)

  const [dataNotif, setDataNotif] = useState({})
  const [doReject, setDoReject] = useState(false)
  const [remarkRevise, setRemarkRevise] = useState()
  const [doApprove, setDoApprove] = useState(false)
  const [statusCodes, setStatusCodes] = useState({})
  const [doConfirmExct, setDoConfirmExct] = useState(false)
  const [doReviseSettle, setDoReviseSettle] = useState(false)
  const [planArrivalNotif, setPlanArrivalNotif] = useState('')
  const [doConfirmSettle, setDoConfirmSettle] = useState(false)
  const [showAlert, setAlert] = useState({
    visible: false,
    msg: '',
    success: false
  })
  const [dataRevise, setDataRevise] = useState({})
  const [reviseCode, setReviseCode] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { roleMapping } = useSelector((state) => state.usersLoginReducer)
  const dataUserProfile = useSelector((state) => state.usersProfileReducer)
  const isInternal = localStorage.getItem('userType')
    ? localStorage.getItem('userType').toLowerCase() === 'internal'
    : true

  const pathRoleSettlement = roleMapping.find((e) => e.path === '/settlement')
  const pathRoleServiceRequest = roleMapping.find(
    (e) => e.path === '/service-request'
  )

  const _calculateBerthDurMask = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : ''
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : ''
    return hour || minute ? `${hour} ${minute}` : '0'
  }

  // Get Data //
  useEffect(() => {
    getAllNotifications()
    _getStatusCodes()
    openPageNotification()
  }, [param])

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllNotifications()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [])

  const getAllNotifications = async () => {
    try {
      const result = await ExecutionAPI.getAllNotification(
        '[{"type": "bool", "field" : "Is_Action_Taken", "value": "false"}]'
      )
      if (result.data && result.data.data) {
        await setDataAllNotif(result.data.data)
        if (param.id !== undefined) {
          document
            .getElementById(`m-notif-status-txt-${param.id}`)
            .scrollIntoView({
              behavior: 'smooth'
            })
        }
      }
    } catch (err) {
      console.log('eror get list product', err)
    }
  }

  const getReviseData = async () => {
    try {
      const response = await ExecutionAPI.getAllNotification(
        `[{"type": "text", "field" : "Sr_Code", "value": "${reviseCode}"}, {"type": "text", "field" : "Notification_Type_Text", "value": "SettlementRevised"}, {"type": "bool", "field" : "Is_Action_Taken", "value": "false"}]`
      )
      if (response.data && response.data.data) {
        await setDataRevise(response.data.data)
      }
    } catch (err) {
      console.log('error get revise data', err)
    }
  }

  useEffect(() => {
    if (reviseCode !== '') {
      getReviseData()
    }
  }, [reviseCode])

  useEffect(() => {
    getListJetty()
  }, [show])

  const getListJetty = async () => {
    try {
      const result = await ExecutionAPI.getAllJetty(
        dataUserProfile.Location.LocationDescription
      )
      if (result.data && result.data.data) {
        setListVessel(result.data.data)
      }
    } catch (err) {
      console.log('eror get list jetty', err)
    }
  }

  const getDataExecution = async (srCode) => {
    try {
      const result = await ExecutionAPI.getDataExecution(srCode)
      if (result.data && result.data.data) {
        let dataTemp = result.data.data
        let dataConst = {
          ...dataTemp,
          Plan_Arrival:
            moment(dataTemp.Plan_Arrival).format('DD/MM/YYYY HH:mm') ===
            'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  dataTemp.Location,
                  dataTemp.Plan_Arrival,
                  'YYYY/MM/DD HH:mm'
                ),
          Plan_Depart:
            moment(dataTemp.Plan_Depart).format('DD/MM/YYYY HH:mm') ===
            'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  dataTemp.Location,
                  dataTemp.Plan_Depart,
                  'YYYY/MM/DD HH:mm'
                )
        }
        setDataExct(dataConst)
        setDoConfirmExct(true)
        setShow(true)
      }
    } catch (err) {
      console.log('eror get list product', err)
    }
  }

  const getDataSettlement = async (srCode) => {
    try {
      const result = await SettlementAPI.GetAllSettlement(0, 1000, '[]')
      if (result.data) {
        let dataFormat = {}
        dataFormat = result.data.map((e) => {
          e['Ata_No_Format'] = e['Ata']
          e['Atd_No_Format'] = e['Atd']
          e['Eta_No_Format'] = e['Eta']
          e['Etd_No_Format'] = e['Etd']
          e['Created_Date_No_Format'] = e['Created_Date']
          e['Modified_Date_No_Format'] = e['Modified_Date']
          e['Created_Date'] =
            moment(e.Created_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  e.Location,
                  e.Created_Date,
                  'DD/MM/YYYY HH:mm'
                )
          e['Atd'] =
            moment(e.Atd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Atd, 'DD/MM/YYYY HH:mm')
          e['Ata'] =
            moment(e.Ata).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm')
          e['Etd'] =
            moment(e.Etd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Etd, 'DD/MM/YYYY HH:mm')
          e['Eta'] =
            moment(e.Eta).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Eta, 'DD/MM/YYYY HH:mm')
          e['Modified_Date'] =
            moment(e.Modified_Date).format('DD/MM/YYYY HH:mm') ===
            'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  e.Location,
                  e.Modified_Date,
                  'DD/MM/YYYY HH:mm'
                )
          e['Sr_Completed_Date'] =
            moment(e.Sr_Completed_Date).format('DD/MM/YYYY HH:mm') ===
            'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  e.Location,
                  e.Sr_Completed_Date,
                  'DD/MM/YYYY HH:mm'
                )
          e['Sr_Finalized_Date'] =
            moment(e.Sr_Finalized_Date).format('DD/MM/YYYY HH:mm') ===
            'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  e.Location,
                  e.Sr_Finalized_Date,
                  'DD/MM/YYYY HH:mm'
                )
          e['Estimate_Berth_Dur_Detail'] = _calculateBerthDurMask(
            e.Estimate_Berth_Dur
          )
          e['Actual_Berth_Dur_Detail'] = _calculateBerthDurMask(
            e.Actual_Berth_Dur
          )
          return e
        })
        const dataGetSettle = dataFormat.find((e) => e.Code === srCode)
        await setDataSettle(dataGetSettle)
        setDoConfirmSettle(true)
        setDoReviseSettle(true)
        setShow(true)
      }
    } catch (err) {
      console.log('eror get list settlement', err)
    }
  }

  const getDataServiceRequest = async (srCode) => {
    try {
      const result = await ServiceRequestAPI.GetAllDataServiceRequest(
        0,
        1000,
        '[]'
      )
      if (result.data) {
        let dataFormat = {}
        dataFormat = result.data.map((e) => {
          e['Etd_Non_Formatted'] = e['Etd']
          e['Eta_Non_Formatted'] = e['Eta']
          e['Created_Date_Non_Formatted'] = e['Created_Date']
          e['Created_Date'] =
            moment(e.Created_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  e.Location,
                  e.Created_Date,
                  'DD/MM/YYYY HH:mm'
                )
          e['Atd'] =
            moment(e.Atd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Atd, 'DD/MM/YYYY HH:mm')
          e['Ata'] =
            moment(e.Ata).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm')
          e['Etd'] =
            moment(e.Etd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Etd, 'DD/MM/YYYY HH:mm')
          e['Eta'] =
            moment(e.Eta).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Eta, 'DD/MM/YYYY HH:mm')
          e['Modified_Date'] =
            moment(e.Modified_Date).format('DD/MM/YYYY HH:mm') ===
            'Invalid date'
              ? ''
              : ConvertLocalTimeGet(
                  e.Location,
                  e.Modified_Date,
                  'DD/MM/YYYY HH:mm'
                )
          e['Estimate_Berth_Dur_Detail'] = _calculateBerthDurMask(
            e.Estimate_Berth_Dur
          )
          e['Actual_Berth_Dur_Detail'] = _calculateBerthDurMask(
            e.Actual_Berth_Dur
          )
          return e
        })
        const dataGetSR = dataFormat.find((e) => e.Code === srCode)
        await setDataServiceRequest(dataGetSR)

        setApproved(dataGetSR.Sr_Status.toLowerCase() === 'approved')
        setWaitApproved(
          dataGetSR.Sr_Status.toLowerCase() === 'waiting for approval'
        )
        setDoApprove(true)
        setDoReject(true)
        setShow(true)
      }
    } catch (err) {
      console.log('eror get list settlement', err)
    }
  }

  // GET FOR SERVICE REQUEST
  const _getStatusCodes = async () => {
    try {
      // const filter ='[{"field":"type","type":"text","value":"SR_Status"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetSRStatus()
      if (data) {
        const obj = {}
        data.forEach((v) => (obj[v.Value] = v.Code))
        setStatusCodes(obj)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // GET FOR SETTLEMENT
  useEffect(() => {
    _getConfirmable()
  }, [dataSettle])

  const _getConfirmable = async () => {
    try {
      const filter = `[{"field":"code_ref","type":"text","value":"${dataSettle.Sr_No}"},{"field":"is_action_taken","type":"bool","value":"false"},{"field":"Notification_Type_Text","type":"text","value":"confirmation"}]`
      const { data } = await SettlementAPI.GetNotification(filter)
      if (data && data.length) {
        setDataNotif(data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Modal & Card Notification

  const closeExeModal = async () => {
    setReviseCode('')
    setDataRevise({})
    setDataExct({})
    setShow(false)
    setRemarkRevise()
    setStatusDetail('')
    setApproved(false)
    setWaitApproved(false)
    setDoApprove(false)
    setDoReject(false)
    setDoConfirmSettle(false)
    setDoConfirmExct(false)
    setDoReviseSettle(false)
    ConfirmExctForm.resetForm()
    ServiceRequestRejectForm.resetForm()
    SettlementReviseForm.resetForm()
    setAlert({ msg: '', success: false, visible: false })
    if (param.id != null) {
      await router.push('/notification')
    }
    getAllNotifications()
    setIsLoading(false)
  }

  const _getNotif = async (dataItem) => {
    try {
      const filter = `[{"field":"code_ref","type":"text","value":"${dataItem.Code_Ref}"},{"field":"Is_Action_Taken","type":"bool","value":"false"},{"field":"Notification_Type_Text","type":"text","value":"NeedToBeRevised"}]`
      const { data } = await ExecutionAPI.getAllNotification(filter)
      if (data.data && data.data.length) {
        setRemarkRevise(data.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _header = () => {
    return (
      <TitleMenu>
        <Title>Notifications</Title>
      </TitleMenu>
    )
  }

  const _cardNotification = () => {
    return (
      <div>
        <Grid container>
          <Grid item md={12} style={{ background: '#FFFFFF' }}>
            <div
              style={{
                maxHeight: '75vh'
              }}
            >
              {dataAllNotif.map((data, index) => (
                <WrapperNotification
                  status={data.Notification_Type_Text.split('|')[0]}
                  key={index}
                  id={`m-notif-status-txt-${data.ID}`}
                >
                  <div
                    style={{
                      padding: '25px',
                      background: data.Is_Read ? '#FFFFFF' : '#e2f7ff'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: '10px'
                      }}
                    >
                      <StatusDiv
                        type={data.Notification_Type_Text.split('|')[0]}
                      >
                        {data.Notification_Type_Text.split('|')[0] ===
                        'NeedsApproval'
                          ? 'Needs Approval [Service Request]'
                          : data.Notification_Type_Text.split('|')[0] ===
                            'NeedConfirmation'
                          ? 'Needs Approval [Settlement]'
                          : data.Notification_Type_Text.split('|')[0] ===
                            '2hoursbeforeETA'
                          ? 'Planning Arrival'
                          : data.Notification_Type_Text.split('|')[0] ===
                            'NeedToBeRevised'
                          ? 'Need to be Revised [Service Request]'
                          : data.Notification_Type_Text.split('|')[0] ===
                              'SettlementRevised' ||
                            data.Notification_Type_Text.split('|')[0] ===
                              'Unfinalized'
                          ? 'Need to be Revised [Settlement]'
                          : data.Notification_Type_Text.split('|')[0] ===
                            'Pre Finalized'
                          ? 'Need Finalize'
                          : null}
                      </StatusDiv>
                      <div style={{ marginRight: '10px', float: 'right' }}>
                        {ConvertLocalTimeGet(
                          data.Location,
                          data.Created_Date,
                          'MMMM Do YYYY, HH:mm'
                        )}
                      </div>
                    </div>
                    <div style={{ display: 'block', marginTop: '10px' }}>
                      <div style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                        {data.User_Name_Sender} : <b>#{data.Code_Ref}</b>
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        {data.Notification_Type_Text.split('|')[0] ===
                        'NeedsApproval'
                          ? 'Create new request and need for approval'
                          : data.Notification_Type_Text.split('|')[0] ===
                            'NeedConfirmation'
                          ? 'Finished the task and need for approval'
                          : data.Notification_Type_Text.split('|')[0] ===
                            '2hoursbeforeETA'
                          ? `Please prepare vessel berthing before 2 hours Plan Arrival with Service Request No ${data.Code_Ref}`
                          : data.Notification_Type_Text.split('|')[0] ===
                              'NeedToBeRevised' ||
                            data.Notification_Type_Text.split('|')[0] ===
                              'SettlementRevised' ||
                            data.Notification_Type_Text.split('|')[0] ===
                              'Unfinalized'
                          ? `Comment Revise : ${data.Remarks}`
                          : data.Notification_Type_Text.split('|')[0] ===
                            'Pre Finalized'
                          ? 'Finished the task and need for finalized'
                          : null}
                      </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <Grid container>
                        <Grid item md={8}>
                          <div style={{ display: 'flex' }}>
                            <Grid item md={4}>
                              <div style={{ margin: '10px' }}>
                                <b>Vessel Name</b> : {data.Vessel_Name}
                              </div>
                              <div style={{ margin: '10px' }}>
                                <b>Port Location</b> : {data.Location}{' '}
                              </div>
                            </Grid>
                            <Grid item md={5}>
                              <div style={{ margin: '10px' }}>
                                <b>
                                  {data.Notification_Type_Text.split('|')[0] ===
                                    'NeedConfirmation' ||
                                  data.Notification_Type_Text.split('|')[0] ===
                                    'Pre Finalized'
                                    ? 'ATA'
                                    : data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'NeedsApproval'
                                    ? 'ETA'
                                    : data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === '2hoursbeforeETA'
                                    ? 'Planning Arrival'
                                    : data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'NeedToBeRevised' ||
                                      data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'SettlementRevised' ||
                                      data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'Unfinalized'
                                    ? 'ETA'
                                    : null}
                                </b>{' '}
                                :
                                {data.Notification_Type_Text.split('|')[0] ===
                                  'NeedConfirmation' ||
                                data.Notification_Type_Text.split('|')[0] ===
                                  'Pre Finalized'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Ata,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'NeedsApproval'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Eta,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === '2hoursbeforeETA'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Plan_Arrival,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'NeedToBeRevised' ||
                                    data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'SettlementRevised' ||
                                    data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'Unfinalized'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Eta,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : null}{' '}
                              </div>
                              <div style={{ margin: '10px' }}>
                                <b>
                                  {data.Notification_Type_Text.split('|')[0] ===
                                    'NeedConfirmation' ||
                                  data.Notification_Type_Text.split('|')[0] ===
                                    'Pre Finalized'
                                    ? 'ATD'
                                    : data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'NeedsApproval'
                                    ? 'ETD'
                                    : data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === '2hoursbeforeETA'
                                    ? 'Planning Departure'
                                    : data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'NeedToBeRevised' ||
                                      data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'SettlementRevised' ||
                                      data.Notification_Type_Text.split(
                                        '|'
                                      )[0] === 'Unfinalized'
                                    ? 'ETD'
                                    : null}
                                </b>{' '}
                                :
                                {data.Notification_Type_Text.split('|')[0] ===
                                  'NeedConfirmation' ||
                                data.Notification_Type_Text.split('|')[0] ===
                                  'Pre Finalized'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Atd,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'NeedsApproval'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Etd,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === '2hoursbeforeETA'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Plan_Depart,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'NeedToBeRevised' ||
                                    data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'SettlementRevised' ||
                                    data.Notification_Type_Text.split(
                                      '|'
                                    )[0] === 'Unfinalized'
                                  ? ConvertLocalTimeGet(
                                      data.Location,
                                      data.Etd,
                                      'MMMM Do YYYY, HH:mm'
                                    )
                                  : null}{' '}
                              </div>
                            </Grid>
                            <Grid item md={3}>
                              <div style={{ margin: '10px' }}>
                                <b>Service Request Type</b> : {data.SR_Type}{' '}
                              </div>
                              <div style={{ margin: '10px' }}>
                                <b>Category</b> : {data.SR_Category}{' '}
                              </div>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          <div style={{ float: 'right' }}>
                            {data.Notification_Type_Text.split('|')[0] ===
                              'NeedsApproval' && (
                              <div style={{ margin: '10px', display: 'flex' }}>
                                <ButtonAction
                                  id={`btn_approve_sr_${index}`}
                                  type='Approve'
                                  onClick={() => {
                                    setIsLoading(true)
                                    setStatusDetail('doApprove')
                                    getDataServiceRequest(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <img
                                      src={Images.check}
                                      style={{ width: '18px' }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>APPROVE</p>
                                  </div>{' '}
                                </ButtonAction>
                                <ButtonAction
                                  id={`btn_revise_sr_${index}`}
                                  type='Revise'
                                  onClick={() => {
                                    setIsLoading(true)
                                    setStatusDetail('revise')
                                    getDataServiceRequest(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <RestorePageIcon
                                      style={{
                                        color: 'white',
                                        fontSize: '20px'
                                      }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>REVISE</p>
                                  </div>
                                </ButtonAction>
                                <ButtonAction
                                  id={`btn_reject_sr_${index}`}
                                  type='Reject'
                                  onClick={() => {
                                    setIsLoading(true)
                                    setStatusDetail('doReject')
                                    getDataServiceRequest(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <img
                                      src={Images.remove}
                                      style={{ width: '18px' }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>REJECT</p>
                                  </div>
                                </ButtonAction>
                              </div>
                            )}
                            {data.Notification_Type_Text.split('|')[0] ===
                              'NeedConfirmation' && (
                              <div style={{ margin: '10px', display: 'flex' }}>
                                <ButtonAction
                                  id={`btn_confirm_set_${index}`}
                                  type='Approve'
                                  onClick={() => {
                                    setIsLoading(true)
                                    setStatusDetail('doConfirmSettlement')
                                    getDataSettlement(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <img
                                      src={Images.check}
                                      style={{ width: '18px' }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>APPROVE</p>
                                  </div>
                                </ButtonAction>
                                <ButtonAction
                                  id={`btn_revise_set_${index}`}
                                  type='Revise'
                                  onClick={() => {
                                    setStatusDetail('doReviseSettlement')
                                    getDataSettlement(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <RestorePageIcon
                                      style={{
                                        color: 'white',
                                        fontSize: '20px'
                                      }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>REVISE</p>
                                  </div>
                                </ButtonAction>
                              </div>
                            )}
                            {data.Notification_Type_Text.split('|')[0] ===
                              '2hoursbeforeETA' && (
                              <div style={{ margin: '10px', display: 'flex' }}>
                                <ButtonAction
                                  id={`btn_confirm_befor_eta_${index}`}
                                  type='Confirm'
                                  onClick={() => {
                                    setIsLoading(true)
                                    setPlanArrivalNotif(data.Plan_Arrival)
                                    setStatusDetail('doConfirmExct')
                                    getDataExecution(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <img
                                      src={Images.check}
                                      style={{ width: '18px' }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>CONFIRM</p>
                                  </div>
                                </ButtonAction>
                              </div>
                            )}
                            {data.Notification_Type_Text.split('|')[0] ===
                              'Pre Finalized' && (
                              <div style={{ margin: '10px', display: 'flex' }}>
                                <ButtonAction
                                  id={`btn_confirm_set_${index}`}
                                  type='Approve'
                                  onClick={() => {
                                    setIsLoading(true)
                                    setStatusDetail('doConfirmSettlementFnlz')
                                    getDataSettlement(data.SR_Code)
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      height: '23px',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <img
                                      src={Images.check}
                                      style={{ width: '18px' }}
                                    />
                                    <p style={{ marginLeft: '5px' }}>
                                      FINALIZED
                                    </p>
                                  </div>
                                </ButtonAction>
                              </div>
                            )}
                          </div>
                        </Grid>
                        <div style={{ marginLeft: '10px' }}>
                          <ButtonAction
                            id={`btn_view_detail_${index}`}
                            type='Detail'
                            onClick={async () => {
                              if (!data.Is_Read) {
                                const dataObj = {
                                  ID: data.ID,
                                  Is_Read: true
                                }
                                await DashboardAPI.UpdateIsRead(dataObj)
                              }
                              if (
                                data.Notification_Type_Text.split('|')[0] ===
                                '2hoursbeforeETA'
                              ) {
                                getDataExecution(data.SR_Code)
                                setStatusDetail('2hoursbeforeETA')
                                setDataSrCode(data.SR_Code)
                              } else if (
                                data.Notification_Type_Text.split('|')[0] ===
                                'NeedConfirmation'
                              ) {
                                setStatusDetail('confirmation')
                                getDataSettlement(data.SR_Code)
                              } else if (
                                data.Notification_Type_Text.split('|')[0] ===
                                'NeedsApproval'
                              ) {
                                setStatusDetail('approval')
                                getDataServiceRequest(data.SR_Code)
                              } else if (
                                data.Notification_Type_Text.split('|')[0] ===
                                'NeedToBeRevised'
                              ) {
                                setReviseCode(data.SR_Code)
                                _getNotif(data)
                                setStatusDetail('revised')
                                getDataServiceRequest(data.SR_Code)
                              } else if (
                                data.Notification_Type_Text.split('|')[0] ===
                                'SettlementRevised'
                              ) {
                                setReviseCode(data.SR_Code)
                                getDataSettlement(data.SR_Code)
                                setStatusDetail('revisedSettlement')
                              }
                            }}
                          >
                            View Detail &gt;
                          </ButtonAction>
                        </div>
                      </Grid>
                    </div>
                  </div>
                  <NotificationLoader pending={isLoading} />
                </WrapperNotification>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  // HANDLE SERVICE REQUEST APPROVE
  const _approveServiceRequest = async () => {
    const dataTemp = await {
      Code: dataServiceRequest.Code,
      SR_No: dataServiceRequest.Sr_No,
      SR_Status_Code: statusCodes['Waiting for Approval'],
      Action: 'Approved',
      Head_Client: dataServiceRequest.User_Id,
      Remarks: dataServiceRequest.Remarks,
      Location_Code: dataServiceRequest.Location_Code
    }
    const response = await ServiceRequestAPI.SubmitConfirm(dataTemp)
    if (response.data) {
      await setDoApprove(false)
      getAllNotifications()
      setAction(true)
      await setAlert({
        msg:
          'Service Request No ' +
          dataServiceRequest.Sr_No +
          ' has been Approved',
        success: true,
        visible: true
      })
    } else {
      setAlert({
        msg:
          'Service Request No ' +
          dataServiceRequest.Sr_No +
          ' Failed to Approve',
        success: false,
        visible: true
      })
    }
  }

  // HANDLE SERVICE REQUEST REJECT
  const ServiceRequestRejectForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      RejectRemarks: ''
    },
    validationSchema: Yup.object({
      RejectRemarks: Yup.string().required('Required*')
    }),
    onSubmit: async (values) => {
      setIsClicked(true)
      statusDetail === 'revise' ? _handleRevise(values) : _handleReject(values)
    }
  })

  const _handleRevise = async (insert) => {
    const tempData = {
      Code: dataServiceRequest.Code,
      SR_No: dataServiceRequest.Sr_No,
      SR_Status_Code: statusCodes['Waiting for Approval'],
      Action: 'Revised',
      Head_Client: dataServiceRequest.User_Id,
      Remarks: insert.RejectRemarks,
      Location_Code: dataServiceRequest.Location_Code
    }
    try {
      const response = await ServiceRequestAPI.SubmitConfirm(tempData)
      if (response.data) {
        await setStatusDetail('')
        setAction(true)
        await setAlert({
          msg: 'Data has been Returned to be Revised',
          success: true,
          visible: true
        })
      } else {
        setAlert({
          msg:
            'Service Request No ' +
            dataServiceRequest.Sr_No +
            ' Failed to revise',
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (err) {
      setIsClicked(false)
      console.log(err)
      return err
    }
  }

  const _handleReject = async (insert) => {
    const tempData = {
      Code: dataServiceRequest.Code,
      SR_No: dataServiceRequest.Sr_No,
      SR_Status_Code: statusCodes['Waiting for Approval'],
      Action: 'Rejected',
      Head_Client: dataServiceRequest.User_Id,
      Remarks: insert.RejectRemarks,
      Location_Code: dataServiceRequest.Location_Code
    }
    try {
      const response = await ServiceRequestAPI.SubmitConfirm(tempData)
      if (response.data) {
        await setDoReject(false)
        getAllNotifications()
        setAction(true)
        await setAlert({
          msg:
            'Service Request No ' +
            dataServiceRequest.Sr_No +
            ' has been rejected',
          success: true,
          visible: true
        })
      } else {
        setAlert({
          msg:
            'Service Request No ' +
            dataServiceRequest.Sr_No +
            ' Failed to rejected',
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (err) {
      setIsClicked(false)
      console.log(err)
      return err
    }
  }

  // HANDLE CONFIRM SETTLEMENT
  const SettlementReviseForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      RejectRemarks: ''
    },
    validationSchema: Yup.object({
      RejectRemarks: Yup.string().required('Required*')
    }),
    onSubmit: async (values) => {
      setIsClicked(true)
      _handleSettlementRevise(values)
    }
  })

  const _handleSettlementRevise = async (values) => {
    try {
      const dataObj = {
        Approval_Level: _.isEmpty(dataNotif) ? '' : dataNotif.Approval_Level,
        SR_No: dataSettle.Sr_No,
        SR_Status_Code: dataSettle.Sr_Status_Code,
        Location_Code: parseInt(dataSettle.Location_Code),
        Action: 'Revised',
        Remarks: values.RejectRemarks,
        Modified_Date: ConvertLocalTimePostUTC(new Date())
      }
      const response = await SettlementAPI.ConfirmSettlement(dataObj)
      if (response?.data?.message == '') {
        await setDoReviseSettle(false)
        getAllNotifications()
        setAction(true)
        await setAlert({
          msg: 'Data has been Returned to be Revised',
          success: true,
          visible: true
        })
      } else {
        await setDoReviseSettle(false)
        setAlert({
          msg: messageErrorApproval(response?.data?.message ?? ''),
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      console.log(error)
    }
  }

  const _handleSettlementConfirm = async () => {
    try {
      const dataObj = {
        Approval_Level: dataNotif.Approval_Level,
        SR_No: dataSettle.Sr_No,
        SR_Status_Code: dataSettle.Sr_Status_Code,
        Location_Code: parseInt(dataSettle.Location_Code),
        Action: 'Approved',
        Modified_Date: ConvertLocalTimePostUTC(new Date())
      }
      const response = await SettlementAPI.ConfirmSettlement(dataObj)
      if (response?.data?.message == '') {
        await setDoConfirmSettle(false)
        getAllNotifications()
        setAction(true)
        await setAlert({
          msg: `${
            statusDetail === 'doConfirmSettlementFnlz'
              ? 'Data Has Been Finalize'
              : 'Data Has Been Approved'
          }`,
          success: true,
          visible: true
        })
      } else {
        await setDoConfirmSettle(false)
        setAlert({
          msg: messageErrorApproval(response?.data?.message ?? ''),
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      console.log(error)
    }
  }

  // HANDLE CONFIRM 2 HOURS BEFORE PLANNING ARRIVAL ConfirmPlanningNotif
  const ConfirmExctForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      RejectRemarks: ''
    },
    validationSchema: Yup.object({
      RejectRemarks: Yup.string().required('Required*')
    }),
    onSubmit: async (values) => {
      setIsClicked(true)
      _handleExctConfirm(values)
    }
  })

  const _handleExctConfirm = async (values) => {
    const dataObj = {
      SR_No: dataExct.Sr_No,
      Plan_Arrival: planArrivalNotif,
      Remarks: values.RejectRemarks,
      Modified_Date: ConvertLocalTimePostUTC(new Date())
    }
    try {
      const response = await ExecutionAPI.ConfirmPlanningNotif(dataObj)
      if (response && response.data) {
        await setDoConfirmExct(false)
        getAllNotifications()
        setAction(true)
        await setAlert({
          msg: 'Service Request No ' + dataExct.Sr_No + ' has been Confirmed',
          success: true,
          visible: true
        })
      } else {
        await setDoConfirmExct(false)
        setAlert({
          msg: 'Service Request No ' + dataExct.Sr_No + ' failed to confirmed',
          success: false,
          visible: true
        })
      }
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      console.log(error)
    }
  }

  const openPageNotification = (open) => {
    if (open === undefined) {
      setActive(true)
      document.getElementById('need_action').style.display = 'block'
      document.getElementById('history').style.display = 'none'
    } else if (open) {
      setActive(true)
      document.getElementById('need_action').style.display = 'block'
      document.getElementById('history').style.display = 'none'
    } else if (open === false) {
      setActive(false)
      document.getElementById('need_action').style.display = 'none'
      document.getElementById('history').style.display = 'block'
    }
  }

  return (
    <>
      <WrapperContent>
        {_header()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              width: '40%'
            }}
          >
            <ButtonTab
              style={
                active
                  ? {
                      boxShadow: 'inset 0px -3px #0A81AD',
                      backgroundColor: '#FFFFFF',
                      color: '#0A81AD'
                    }
                  : { backgroundColor: '#FFFFFF', color: '#343434' }
              }
              onClick={() => {
                openPageNotification(true)
              }}
              id='tab_btn_notif_need_action'
            >
              Needs Action
            </ButtonTab>
            <ButtonTab
              style={
                !active
                  ? {
                      boxShadow: 'inset 0px -3px #0A81AD',
                      backgroundColor: '#FFFFFF',
                      color: '#0A81AD'
                    }
                  : { backgroundColor: '#FFFFFF', color: '#343434' }
              }
              // className='tablink'
              onClick={() => {
                openPageNotification(false)
              }}
              id='tab_btn_notif_history'
            >
              History
            </ButtonTab>
          </div>
          {!active && (
            <ButtonAddContainer
              id='notif-add-option-btn'
              onClick={() => setShowColumnOption(true)}
            >
              <ArtTrackIcon />
              &nbsp;{'Column Option'}
            </ButtonAddContainer>
          )}
        </div>
        <div
          style={{
            marginBottom: '2vh',
            display: 'flex',
            width: '100%',
            height: '1px',
            backgroundColor: '#E2E2E2'
          }}
        />
        <div style={{ marginTop: '2vh' }}>
          <div id='need_action' className='tabcontent'>
            <CardNeedAction>
              <div
                style={{
                  maxHeight: '75vh',
                  overflowY: 'auto'
                }}
              >
                {dataAllNotif.length > 0 ? (
                  _cardNotification()
                ) : (
                  <div style={{ minHeight: '70vh' }}>
                    <p>No Record Available</p>
                  </div>
                )}
              </div>
            </CardNeedAction>
          </div>
          <div id='history' className='tabcontent'>
            <Card>
              <div style={{}}>
                {!active && (
                  <NotifHistory
                    action={action}
                    setAction={setAction}
                    showColumnOption={showColumnOption}
                    setShowColumnOption={setShowColumnOption}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </WrapperContent>
      {statusDetail === '2hoursbeforeETA' && show && (
        <ExecutionModal
          show={show}
          dataAll={dataExct}
          dataSrCode={dataSrCode}
          listVessel={listVessel}
          isDetail={true}
          closeExeModal={closeExeModal}
        />
      )}
      {statusDetail === 'confirmation' && show && (
        <SettlementModal
          data={dataSettle}
          dataRevise={dataRevise}
          isEdit={false}
          handleConfirmSettlement={_handleSettlementConfirm}
          pathRole={pathRoleSettlement}
          isInternal={isInternal}
          showModal={show}
          closeSettlementModal={closeExeModal}
        />
      )}
      {statusDetail === 'revisedSettlement' && show && (
        <SettlementModal
          data={dataSettle}
          dataRevise={dataRevise}
          isEdit={false}
          handleConfirmSettlement={_handleSettlementConfirm}
          pathRole={pathRoleSettlement}
          isInternal={isInternal}
          showModal={show}
          closeSettlementModal={closeExeModal}
        />
      )}
      {statusDetail === 'approval' && show && (
        <ServiceRequestModal
          data={dataServiceRequest}
          isEdit={false}
          pathRole={pathRoleServiceRequest}
          isApproved={approved}
          isWaitApproved={waitApproved}
          isFromQueryParam={false}
          isDelete={false}
          isDetail={true}
          isProgress={false}
          showModal={show}
          closeSrModal={closeExeModal}
          // fetchData={() => fetchData(dataState)}
        />
      )}
      {statusDetail === 'revised' && show && (
        <ServiceRequestModal
          data={dataServiceRequest}
          isEdit={true}
          pathRole={pathRoleServiceRequest}
          // isApproved={approved}
          // isWaitApproved={waitApproved}
          isRevise={true}
          isFromQueryParam={false}
          isDelete={false}
          isDetail={false}
          isProgress={false}
          showModal={show}
          closeSrModal={closeExeModal}
          remarkRevise={remarkRevise}
          // fetchData={() => fetchData(dataState)}
        />
      )}
      {showAlert.visible && (
        <Alerts
          id='m_dly_alert'
          msg={showAlert.msg}
          close={() => closeExeModal()}
          open={showAlert.visible}
          success={showAlert.success}
        />
      )}
      {statusDetail === 'doApprove' && doApprove && (
        <ModalApprove
          handleApprove={() => {
            setIsClicked(true)
            _approveServiceRequest()
          }}
          isClicked={isClicked}
          approveText='Are you sure to approve?'
          toggleDialog={() => closeExeModal()}
        />
      )}
      {(statusDetail === 'doReject' && doReject && (
        <ModalReject
          typeLog={Lookup.LOG_MODULE.NOTIFICATION}
          subtypeLog={Lookup.LOG_SUB_MODULE.REJECT}
          srCode={dataServiceRequest.Code}
          isClicked={isClicked}
          ServiceRequestRejectForm={ServiceRequestRejectForm}
          rejectText='Are you sure to reject?'
          toggleDialog={() => closeExeModal()}
        />
      )) ||
        (statusDetail === 'revise' && (
          <ModalReject
            typeLog={Lookup.LOG_MODULE.NOTIFICATION}
            subtypeLog={Lookup.LOG_SUB_MODULE.REVISE}
            srCode={dataServiceRequest.Code}
            typeModal={'revise'}
            isClicked={isClicked}
            ServiceRequestRejectForm={ServiceRequestRejectForm}
            rejectText='Are you sure to revise?'
            toggleDialog={() => closeExeModal()}
          />
        ))}
      {((statusDetail === 'doConfirmSettlement' && doConfirmSettle) ||
        (statusDetail === 'doConfirmSettlementFnlz' && doConfirmSettle)) && (
        <ModalApprove
          handleApprove={() => {
            setIsClicked(true)
            _handleSettlementConfirm()
          }}
          isClicked={isClicked}
          titleText={
            statusDetail === 'doConfirmSettlementFnlz'
              ? 'FINALIZE !'
              : 'APPROVE !'
          }
          approveText={
            statusDetail === 'doConfirmSettlementFnlz'
              ? 'Are you sure to finalize?'
              : 'Are you sure to approve?'
          }
          toggleDialog={() => closeExeModal()}
        />
      )}
      {statusDetail === 'doConfirmExct' && doConfirmExct && (
        <ModalReject
          isClicked={isClicked}
          typeModal={statusDetail}
          typeLog={Lookup.LOG_MODULE.NOTIFICATION}
          subtypeLog={Lookup.LOG_SUB_MODULE.CONFIRM}
          srCode={dataExct.Code}
          ServiceRequestRejectForm={ConfirmExctForm}
          rejectText='Are you sure to confirm?'
          toggleDialog={() => closeExeModal()}
        />
      )}
      {statusDetail === 'doReviseSettlement' && doReviseSettle && (
        <ModalReject
          isClicked={isClicked}
          typeModal={'revise'}
          typeLog={Lookup.LOG_MODULE.NOTIFICATION}
          subtypeLog={Lookup.LOG_SUB_MODULE.REVISE}
          srCode={dataServiceRequest.Code}
          ServiceRequestRejectForm={SettlementReviseForm}
          rejectText='Are you sure to revise?'
          toggleDialog={() => closeExeModal()}
        />
      )}
    </>
  )
}

export default Notification
