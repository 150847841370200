import styled from 'styled-components'

import { Colors, FontSize } from '~/styles'
import { Edit, Save, Cancel } from '@material-ui/icons'

const Container = styled.div`
  padding: 20px;
  padding-top: 5px;
  background-color: ${Colors.backgroundColor};
`

const AddItemWrapper = styled.div`
  display: flex;
  cursor: ${(p) => (p.disable ? 'not-allowed' : 'pointer')};
  align-items: center;
  color: ${Colors.softBlue};
`

const TopContent = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 30px;
  margin-bottom: 10px;
  justify-content: flex-end;
`

const TopContentCargo = styled.div`
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
  justify-content: space-between;
`

const Title = styled.p`
  font-weight: 600;
  font-size: ${FontSize.large};
`

const ContractText = styled.p`
  font-weight: 500;
  color: ${Colors.grey};
  font-size: ${FontSize.medium};
`

const ContractTextSpan = styled.span`
  color: ${Colors.grey};
  font-size: ${FontSize.mediumLarge};
`

const WrapperButton = styled.div`
    display: flex;
    cursor: pointer;
`

const IconButton = styled.img`
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    user-select: none;
    font-size: 1.5rem;
    fill: currentColor;
    margin-right: 3px;
    `
const UploaderWrapper = styled.div`
    display: flex;
    cursor: pointer;
    margin-right: 25px;
    align-items: center;
    color: ${Colors.newGreen};
  `
const TextFieldContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
`
const IconContainer = styled.div`
  width: 22px;
  cursor: pointer;
  margin-right: 10px;
`
const CancelIcon = styled(Cancel)`
  cursor: pointer;
  color: ${Colors.red};
`
const EditIcon = styled(Edit)`
  cursor: pointer;
  color: ${Colors.newGreen};
`
const SaveIcon = styled(Save)`
  cursor: pointer;
  color: ${Colors.softBlue};
`
export default {
  Title,
  EditIcon,
  SaveIcon,
  CancelIcon,
  Container,
  TopContent,
  IconButton,
  ContractText,
  WrapperButton,
  IconContainer,
  AddItemWrapper,
  TopContentCargo,
  UploaderWrapper,
  ContractTextSpan,
  TextFieldContainer,
}