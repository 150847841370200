import Alert from '@material-ui/lab/Alert'
import { Colors, FontSize } from '../../styles'
import styled, { css } from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'

export const BtnAddNew = styled.div`
  cursor: pointer;
  font-size: 14px;
  justify-content: space-between;
  margin: 15px auto 90px auto;
`

export const PipeLine = styled.div`
  font-size: 14px;
  transform: rotate(90deg);
  justify-content: space-between;
  margin: 15px auto 90px auto;
`

export const TextLink = styled.p`
  color: blue;
  text-decoration: underline;
  width: 250px;
  cursor: pointer;
`
export const BtnColOption = styled.div`
  cursor: pointer;
  font-size: 14px;
  justify-content: space-between;
  margin: 15px auto 90px auto;
`
export const SmallSvgIconWrapper = styled.span`
  top: 7px;
  margin-right: 5px;
  position: relative;
`
export const RowIcon = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`

export const Nav = styled.div`
  height: 40px;
  display: flex;
  z-index: 1000;
  color: ${Colors.grey};
  justify-content: flex-start;
  background: ${Colors.white};
  /* box-shadow: 0px 2px 8px #00000033; */
`
export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
`
export const MenuList = styled.div`
  height: 100%;
  cursor: pointer;
  min-width: 70px;
  margin: 0px 10px;
  line-height: 30px;
  padding: 5px 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  ${(p) =>
    p.active &&
    css`
      color: ${Colors.darkBlue};
      border-bottom: 3px solid ${Colors.darkBlue};
    `}
`
export const ContentList = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
  height: ${(p) => p.appSize.height - (p.isDetail ? 385 : 330)}px;
`

export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: ${(p) => p.padding ?? '10px 30px'};
  flex-direction: column;
`

export const FormContainer = styled.div`
  width: 100%;
  height: ${(p) => p.height || 100}%;
  display: flex;
  padding: 0px 10px 0px 10px;
  overflow: ${(p) => p.overflow || 'hidden'};
`

export const LeftFormContainer = styled.div`
  width: 60%;
  display: flex;
  background-color: #f4f6f9;
  padding: 0px 10px 5px 10px;
  overflow: auto;
`
export const RightFormContainer = styled.div`
  width: 40%;
  display: flex;
  background-color: ${Colors.white};
  padding: 0px 10px 5px 10px;
  overflow: auto;

  &.scrollable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`
export const ModalDocContainer = styled.div`
  width: 100%;
`
export const DocumentUploadButtonContainer = styled.div`
  width: 584px;
  height: 50px;
  display: flex;
  margin: 25px auto 5px auto;
  /* padding: 20px auto;
  align-items: center;
  justify-content: center; */
  background-color: #f8f8f8;
  border: 1px dashed #707070;
`
export const UploadButton = styled.label`
  width: 129px;
  height: 25px;
  display: flex;
  font-size: ${FontSize.small}px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  background: #eaeaea 0% 0% no-repeat padding-box;
`
export const DocumentDownloadAllButtonContainer = styled.div`
  padding: 0;
  display: flex;
  margin: 0 20px;
  padding-top: 20px;
  padding: 0 auto;
  align-items: center;
  justify-content: flex-end;
`
export const DownloadAllButton = styled.div`
  width: 132px;
  height: 32px;
  display: flex;
  color: #0a81ad;
  cursor: pointer;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const DownloadButton = styled.div`
  width: 98px;
  height: 26px;
  display: flex;
  color: #0a81ad;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const InfoText = styled.span`
  font-size: 12px;
  padding: 10px 10px 20px 10px;
`
export const FormEmptyFlex = styled.div`
  flex: 1;
`
export const AccordionContainer = styled.div`
  display: flex;
  padding: 0px 20px;
  padding-bottom: 0px;
  box-shadow: 0px 2px 8px #00000033;
`
export const TotalPackageText = styled.div`
  text-align: right;
  padding: 10px 0 10px 0;
  font-weight: bold;
`
export const CargoNoContainer = styled.div`
  margin-bottom: -30px;
  padding: 20px 20px 0 35px;
`

export const SrModalGridContainer = styled.div`
  padding: 0px 20px;
`

export const StatusCell = styled.div`
  width: 100%;
  display: flex;
  cursor: ${(p) => p.cursor};
  padding: 0px 5px;
  border-radius: 5px;
  justify-content: center;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
  border: solid ${(p) => (p.color == Colors.black ? '1px' : '0px')} black;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const Circular = styled(CircularProgressbar)`
  width: 50px;
  height: 50px;

  & .CircularProgressbar-path {
    stroke: ${Colors.softBlue} !important;
  }

  &.CircularProgressbar-text {
    font-size: ${FontSize.medium}px;
    color: ${Colors.black};
  }
`
export const ReviseRemark = styled.div`
  & div.MuiFormControl-root.MuiTextField-root {
    &
      div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline {
      &
        textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline {
        line-height: 20px;
      }
    }
  }
`

export const AlertLabel = styled(Alert)`
  color: #343f54;
  border-radius: 34%;
  background-color: #71af00;
`

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  color: ${Colors.modalGrey};
  align-items: ${(p) => p.align};
  margin-bottom: 18px;
`
export const TextTop1 = styled.p`
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  margin-right: 8px;
`
export const TextTop2 = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`
export const TextTop3 = styled.p`
  font-size: 14px;
  margin-left: 4px;
  margin-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.modalGrey};
`

export const TopIcon = styled.img`
  width: 20;
  height: auto;
`
export const WrapperValue = styled.div`
  display: flex;
  margin: 0 0 18px;
  align-items: center;
  flex-direction: row;
`

export const TitleValue = styled.p`
  line-height: 0;
  color: ${Colors.modalGrey};
  font-size: ${FontSize.medium}px;
  ${(p) =>
    p.top &&
    css`
      margin-top: 20px;
    `}
`
export const SVGIcon = styled.img`
  height: auto;
  margin-left: 10px;
  width: ${(p) => p.width || 20}px;
  ${(p) =>
    p.absolute &&
    css`
      left: 0;
      position: absolute;
    `}
`
export const TitleBerthing = styled.p`
  padding: 6px 8px;
  border-radius: 4px;
  color: ${Colors.black};
  font-size: ${FontSize.medium}px;
  background: #f2f5fa;
  margin: 0;
`
export const Text = styled.p`
  font-size: ${FontSize.smallMedium}px;
  color: ${(p) => (p.index ? Colors.softBlue : Colors.hintGrey)};
  ${(p) =>
    p.regular &&
    css`
      margin: 0 10px;
      color: ${Colors.black};
      font-size: ${FontSize.medium}px;
    `}
  ${(p) =>
    p.bold &&
    css`
      font-weight: 600;
    `}
`
export const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  padding: 0px 16px 15px;
  background-color: #f4f6f9;
  justify-content: space-between;
`
export const CargoInformationWrapper = styled.div`
  width: 49%;
  padding: 15px 0;
  &.left {
    margin-right: 15px;
  }
  &.right {
    margin-left: 15px;
  }
`
export const CargoInformationCard = styled.div`
  height: 230px;
  padding: 20px 20px 10px 20px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  &.bottom {
    height: 311px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const InfoTitle = styled.h1`
  color: ${Colors.cancelTextColor};
  font-size: ${FontSize.extraLarge}px;
  margin: 0;
`
export const InfoSubtitle = styled.p`
  margin: 0;
  color: #777777;
  display: inherit;
  align-items: baseline;
  font-size: ${FontSize.medium}px;
`
export const InfoCounter = styled.p`
  margin: 0;
  padding-left: 5px;
  color: ${Colors.black};
  font-size: ${FontSize.medium}px;
`
export const CargoList = styled.ul`
  padding: 0;
  width: 100%;
  list-style: none;
  max-height: 230px;
`
export const CargoItem = styled.li`
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid #dfe7f6;
  background-color: ${Colors.white};
`
export const ItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ItemTitle = styled.p`
  font-size: ${FontSize.mediumLarge}px;
  color: ${Colors.cancelTextColor};
  margin: 0;
`
export const ItemSubtitle = styled.p`
  font-size: ${FontSize.small}px;
  color: #777777;
  margin: 0;
`
export const ItemAmount = styled.p`
  font-size: ${FontSize.mediumLarge}px;
  color: ${Colors.softBlue};
  margin: 0;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Container = styled.div`
  width: 100%;
  margin-top: 15px;
`
export const BorderLine = styled.div`
  height: 25px;
  width: 94%;
  border-top: 2.5px solid ${Colors.lightBlue};
  margin: auto;
  /* background: #0082ad; */
`