import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { Input } from '../../components'
import {
  Header,
  Status,
  ModalWrapper,
  Tabs,
  Tab,
  Content,
  Form,
  FormTab,
  Close,
  ActWrapper,
  ActHeader,
  ActContent,
  DelayHeader,
  DelContent,
  DelContentBorder,
  TaskHeader,
  TaskContent,
} from './dashboard-jetty-modal.styled'
import { DashboardJettyAPI } from '../../api'
import { Colors, FontSize } from '../../styles'
import getConfig from 'next/config'
import { ConvertLocalTimeGet } from '../../utils'

const DashboardJettyModal = (props) => {
  const { publicRuntimeConfig } = getConfig()
  const RUN_OUTSCOPE_FUNCTION = publicRuntimeConfig.RUN_OUTSCOPE_FUNCTION === 'true'
  const { detail, current } = props

  const [tab, setTab] = useState(1)
  const [delay, setDelay] = useState(null)
  const [task, setTask] = useState(null)
  const [activity, setActivity] = useState(null)
  const [dataDelay, setDataDelay] = useState([])
  const [dataActivity, setDataActivity] = useState([])
  const [currentActivity, setCurrentActivity] = useState(null)

  const _getActivity = async () => {
    const { data: act } = await DashboardJettyAPI.GetActivity(detail.Code_Tsr)
    if (act && act.length > 0) {
      setDataActivity(act)
      const current = act.filter(
        (ac) => moment(ac.Start_Date).format('DD/MM/YYYY') !== '01/01/0001'
      )
      if (current.length > 0) {
        setCurrentActivity(current[current.length - 1])
      }
      if (tab === 2) {
        setActivity(act[0])
        setTask(act[0].Task.length > 0 ? act[0].Task[0] : null)
      }
    } else {
      setDataActivity([])
    }
    const { data: dly } = await DashboardJettyAPI.GetDelay(detail.SrNo)

    if (dly && dly.length > 0) {
      const delay = _.chain(dly)
        .groupBy('Code_Tpa')
        .map((dt) => dt)
        .value()
        .map((item) => {
          const detail = _.chain(item)
            .groupBy('Delay_Type')
            .map((dtl) => dtl)
            .value()
            .map((det) => {
              return Object.assign({
                delays: det,
                type: det[0].Delay_Type,
                act_code: det[0].Code_Tpa,
                activity_name: det[0].Activity_Name,
              })
            })
          return Object.assign({
            delay: detail,
            activity_name: item[0].Activity_Name,
            activity_progress_code: item[0].Code_Tpa,
          })
        })
      setDataDelay(delay)
      if (tab === 3) {
        setActivity(delay[0])
        setDelay(delay[0].delay[0])
      }
    } else {
      setDataDelay([])
    }
  }

  const _calculateTime = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : ''
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : ''
    return hour || minute ? `${hour} ${minute}` : '0'
  }

  useEffect(() => {
    _getActivity()
  }, [tab, detail])

  useEffect(() => {
    if (tab === 2) {
      const data = dataActivity.map((act) => {
        act.open = activity ? act.Activity_Name === activity.Activity_Name : false
        return act
      })
      setDataActivity(data)
    }
    if (tab === 3) {
      const data = dataDelay.map((act) => {
        act.open = activity ? act.activity_name === activity.activity_name : false
        return act
      })
      setDataDelay(data)
    }
  }, [activity])

  useEffect(() => {
    const data = dataDelay.map((act) => {
      act.open = activity ? act.activity_name === activity.activity_name : false
      if (activity.activity_name === act.activity_name) {
        act.delay.map((dly) => {
          dly.open = delay ? dly.act_code === delay.act_code && dly.type === delay.type : false
          return dly
        })
      }
      return act
    })
    setDataDelay(data)
  }, [delay])

  const InputComponent = (props) => {
    return (
      <Input
        {...props}
        InputProps={{
          ...props.InputProps,
          style: { marginTop: '1.6vh' },
        }}
        inputProps={{
          style: { padding: '0.6vh 0 0.7vh' }
        }}
      />
    )
  }

  const _sr = () => {
    return (
      <Content overflow={'hidden'}>
        <FormTab>
          <Form>
            <InputComponent
              id='d_sr_no'
              value={detail.SrNo}
              label='Service Request No'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_category'
              value={detail.Sr_Category}
              label='Category'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_vessel_name'
              value={detail.Vessel_Name}
              label='Vessel Name'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_agent'
              value={detail.Agent_Name}
              label='Agent'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_etd'
              value={ConvertLocalTimeGet(detail.Location, moment(detail.Plan_Depart), 'DD-MM-YYYY HH:mm')}
              label='Planning Time Departure'
              InputProps={{ readOnly: true }}
            />
          </Form>
          <Form>
            <InputComponent
              id='d_sr_type'
              value={detail.Sr_Type}
              label='Service Request Type'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_port'
              value={detail.Location}
              label='Port Location'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_client'
              value={detail.Client_Name}
              label='Client Name'
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_eta'
              value={ConvertLocalTimeGet(detail.Location, moment(current ? detail.Ata : detail.Plan_Arrival), 'DD-MM-YYYY HH:mm')}
              label={current ? 'Actual Time Arrival' : 'Planning Time Arrival'}
              InputProps={{ readOnly: true }}
            />
            <InputComponent
              id='d_etb'
              value={_calculateTime(current ? detail.Estimate_Berth_Dur : detail.Plan_Berth_Dur)}
              label='Planning Berthing Duration'
              InputProps={{ readOnly: true }}
            />
          </Form>
        </FormTab>
        <Form margin={'-20px 0px'}>
          <Input
            id='d_remarks'
            value={detail.Remarks}
            label='Remarks'
            rowsMax={5}
            multiline={true}
            InputProps={{ readOnly: true }}
            variant='outlined'
          />
        </Form>
      </Content>
    )
  }

  const _activity = () => {
    return (
      <Content>
        {dataActivity.map((dt) => {
          const activityColor =
            moment(dt.Start_Date).format('DD/MM/YYYY') !== '01/01/0001'
              ? Colors.green
              : Colors.pureWhite
          return (
            <ActWrapper key={dt.Activity_Name}>
              <ActHeader
                id={dt.Activity_Name.replace(/\s/, '')}
                data-activity-color-value={activityColor === Colors.green ? 'green' : 'white'}
              >
                <Status color={activityColor} />
                {dt.Activity_Name}
                <Close
                  onClick={() => {
                    activityColor === Colors.pureWhite
                      ? null
                      : setActivity(
                        activity && dt.Activity_Name === activity.Activity_Name ? null : dt
                      )
                  }}
                >
                  {dt.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Close>
              </ActHeader>
              {dt.open && (
                <>
                  <ActContent>
                    <FormTab>
                      <Form>
                        <InputComponent
                          id={`a_date_time_${dt.ID}`}
                          value={
                            moment(dt.Start_Date).format('DD/MM/YYYY') !== '01/01/0001'
                              ? ConvertLocalTimeGet(detail.Location, moment(dt.Start_Date), 'DD/MM/YYYY HH:mm')
                              : ''
                          }
                          label='Start Date'
                          InputProps={{ readOnly: true }}
                        />
                        {RUN_OUTSCOPE_FUNCTION ? (
                          <InputComponent
                            id={`a_date_time_finish_${dt.ID}`}
                            value={
                              moment(dt.Finish_Date).format('DD/MM/YYYY') !== '01/01/0001'
                                ? ConvertLocalTimeGet(detail.Location, moment(dt.Finish_Date), 'DD/MM/YYYY HH:mm')
                                : ''
                            }
                            label='Finish Date'
                            InputProps={{ readOnly: true }}
                          />
                        ) : (
                          <InputComponent
                            id={`a_modified_by_${dt.ID}`}
                            value={dt.Modified_By}
                            label='Modified By'
                            InputProps={{ readOnly: true }}
                          />
                        )}
                      </Form>
                      <Form>
                        <InputComponent
                          id={`a_percentage_${dt.ID}`}
                          value={dt.Progress}
                          label='Percentage'
                          InputProps={{ readOnly: true }}
                        />
                        {RUN_OUTSCOPE_FUNCTION && (
                          <InputComponent
                            id={`a_modified_by_${dt.ID}`}
                            value={dt.Modified_By}
                            label='Modified By'
                            InputProps={{ readOnly: true }}
                          />
                        )}
                      </Form>
                    </FormTab>
                  </ActContent>
                  {dt.Task.length > 0 &&
                    dt.Task.map((tk, i) => {
                      return (
                        <div key={i} style={{ marginLeft: 15, width: '100%' }}>
                          <TaskHeader>
                            {tk.Task}
                            <Close onClick={() => setTask(task && task.ID === tk.ID ? null : tk)}>
                              {task && tk.ID == task.ID ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Close>
                          </TaskHeader>
                          {task && tk.ID == task.ID && (
                            <TaskContent>
                              <FormTab margin={'-10px 0 -25px 0'}>
                                <Form>
                                  <InputComponent
                                    id={`t_date_time_${tk.ID}`}
                                    value={
                                      moment(tk.Start_Date).format('DD/MM/YYYY') !== '01/01/0001'
                                        ? ConvertLocalTimeGet(detail.Location, moment(tk.Start_Date), 'DD/MM/YYYY HH:mm')
                                        : ''
                                    }
                                    label='Date & Time'
                                    InputProps={{ readOnly: true }}
                                  />
                                </Form>
                                <Form>
                                  <InputComponent
                                    id={`t_percentage_${tk.ID}`}
                                    value={tk.Percentage}
                                    label='Percentage'
                                    InputProps={{ readOnly: true }}
                                  />
                                </Form>
                              </FormTab>
                              <Form>
                                <Input
                                  id={`t_remarks_${tk.ID}`}
                                  value={tk.Remarks}
                                  label='Remarks'
                                  rowsMax={3}
                                  multiline={true}
                                  InputProps={{ readOnly: true }}
                                  variant='outlined'
                                />
                              </Form>
                            </TaskContent>
                          )}
                        </div>
                      )
                    })}
                </>
              )}
            </ActWrapper>
          )
        })}
      </Content>
    )
  }

  const _delay = () => {
    return (
      <Content>
        {dataDelay.map((dt) => {
          return (
            <ActWrapper key={dt.activity_name}>
              <ActHeader>
                <Status color={detail ? detail.color : 'black'} />
                {dt.activity_name}
                <Close
                  onClick={() => {
                    setActivity(activity && dt.activity_name === activity.activity_name ? null : dt)
                  }}
                >
                  {dt.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Close>
              </ActHeader>
              {dt.open &&
                dt.delay.map((dly, i) => {
                  return (
                    <DelContent key={i} open={dly.open}>
                      <DelayHeader>
                        {dly.type}
                        <Close
                          onClick={() =>
                            setDelay(
                              delay && dly.type === delay.type && dly.act_code === delay.act_code
                                ? null
                                : dly
                            )
                          }
                        >
                          {dly.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Close>
                      </DelayHeader>
                      <div
                        style={{
                          maxHeight: '28.68vh',
                          overflowY: dly.delays.length > 1 ? 'auto' : 'hidden',
                        }}
                      >
                        {dly.open &&
                          dly.delays.map((del, i) => {
                            return (
                              <DelContentBorder key={i}>
                                <Form>
                                  <InputComponent
                                    id={`d_description_${del.ID}`}
                                    value={del.Delay_Description}
                                    label='Delay Description'
                                    InputProps={{ readOnly: true }}
                                  />
                                </Form>
                                <FormTab margin={'-20px 0px'}>
                                  <Form>
                                    <InputComponent
                                      id={`d_start_date_${del.ID}`}
                                      value={ConvertLocalTimeGet(detail.Location, del.Start_Date, 'DD/MM/YYYY HH:mm')}
                                      label='Start Date'
                                      InputProps={{ readOnly: true }}
                                    />
                                  </Form>
                                  <Form>
                                    <InputComponent
                                      id={`d_finish_date_${del.ID}`}
                                      value={
                                        del.Delay_Duration !== 0
                                          ? ConvertLocalTimeGet(detail.Location, del.Finish_Date, 'DD/MM/YYYY HH:mm')
                                          : '-'
                                      }
                                      label='Finish Date'
                                      InputProps={{ readOnly: true }}
                                    />
                                  </Form>
                                  <Form>
                                    <InputComponent
                                      id={`d_delay_duration_${del.ID}`}
                                      value={_calculateTime(del.Delay_Duration)}
                                      label='Delay Duration'
                                      InputProps={{ readOnly: true }}
                                    />
                                  </Form>
                                </FormTab>
                                <Form>
                                  <Input
                                    id={`d_remarks_${del.ID}`}
                                    value={del.Remarks}
                                    label='Remarks'
                                    rowsMax={3}
                                    multiline={true}
                                    InputProps={{ readOnly: true }}
                                    variant='outlined'
                                  />
                                </Form>
                              </DelContentBorder>
                            )
                          })}
                      </div>
                    </DelContent>
                  )
                })}
            </ActWrapper>
          )
        })}
      </Content>
    )
  }

  return (
    <ModalWrapper>
      <Header>
        <Status color={detail ? detail.Indicator_Delay : 'black'} />
        Detail Information
        <Close onClick={props.close}>X</Close>
      </Header>
      <Tabs>
        <Tab active={tab === 1} onClick={() => setTab(1)}>
          Service Request
        </Tab>
        {current && (
          <>
            <Tab active={tab === 2} onClick={() => setTab(2)}>
              Activity
            </Tab>
            <Tab active={tab === 3} onClick={() => setTab(3)}>
              Delay
              {currentActivity &&
                currentActivity.ProgressDelay &&
                currentActivity.ProgressDelay.filter((data) => {
                  return !data.Finish_Date || data.Finish_Date === '0001-01-01T00:00:00Z'
                }).length > 0 && (
                <Status left={10} color={Colors.red}>
                  <span style={{ color: Colors.pureWhite, fontSize: FontSize.small }}>!</span>
                </Status>
              )}
            </Tab>
          </>
        )}
      </Tabs>
      {tab === 1 && _sr()}
      {tab === 2 && _activity()}
      {tab === 3 && _delay()}
    </ModalWrapper>
  )
}

export default DashboardJettyModal
