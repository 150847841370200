import Tab from '@material-ui/core/Tab'
import styled from 'styled-components'
import { Colors } from '../../styles'

export const ThisTab = styled(Tab)`
color : black !important;
`
export const WrapperNotification = styled.div`
box-shadow: 0px 3px 6px #00000029;
margin-top: 10px;
`
export const StatusDiv = styled.button`
border: 0;
outline: 0;
width: max-content;
height:25px;
font-size: 15px;
font-weight: bold;
text-align:center;
align-items: center;
padding: 0px 10px;
justify-content: center;
color: white;
border-radius:5px;
background:
${(p) => (
  p.type === 'NeedsApproval'
    ? Colors.orange
    : p.type === 'NeedConfirmation' ||  p.type === 'Pre Finalized'
      ? '#00a1b5'
      :  p.type === '2hoursbeforeETA'
        ? '#84328b'
        : p.type === 'NeedToBeRevised' || p.type === 'SettlementRevised' ||  p.type === 'Unfinalized'
          ? Colors.softGreen : null)};
`
export const ButtonAction = styled.button`
border: 0;
outline: 0;
cursor:pointer;
height:32px;
width:120px;
color:white;
text-align:center;
font-size:14px;
margin-right:5px;
border-radius:5px;
padding:4px;
background:
${(p) => (
  p.type === 'Reject'
    ? Colors.red
    : p.type === 'Revise'
      ? Colors.orange
      : p.type === 'Approve'
        ? Colors.softGreen
        : Colors.softBlue)};
`
export const ButtonTab = styled.button`
  background-color: #E6E6E6;
  color: #34343480;
  float: left;
  border: none;
  display: block;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 25%;
  &: hover {
    background-color: #777;
  }
`
export const CardNeedAction = styled.div`
  padding: 20px;
  height: 78vh;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
`

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

export const ButtonAddContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
`