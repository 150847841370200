export default {
  initValCCUCode: {
    ccu_code:'',
    ccu_unit_code : ''
  },
  initValCCU : {
    ccu_code: '',
    ccu_unit_code: '',
    ccu_unit_description: '',
    on_hire_date : '',
    off_hire_date : '',
    max_gross_weight: '',
    tare_weight: '',
    remarks: '',
    inEdit: true,
    isNew: true,
    status: false,
    post: 'new'
  }
}