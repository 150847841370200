import _ from 'lodash'
import moment from 'moment'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'

import { ExecutionAPI } from '~/api'
import {
  getDurationBetweenTwoTime,
  ConvertLocalTimeGet,
  ConvertLocalTimePost
} from '~/utils'
import { useSelector } from 'react-redux'

const createForm = (dataAll) => {
  const formatted = durationToFormattedString(dataAll.Forecast_Berth_Dur)
  const splitted = formatted.split(' ')
  const forecastBerthDurHour = parseInt(splitted[0])
  const forecastBerthDurMinute = parseInt(splitted[2])
  const forecastArrival = ConvertLocalTimeGet(
    dataAll.Location,
    dataAll.Forecast_Arrival,
    'YYYY-MM-DD HH:mm'
  )
  const forecastDepart = ConvertLocalTimeGet(
    dataAll.Location,
    dataAll.Forecast_Depart,
    'YYYY-MM-DD HH:mm'
  )

  return useFormik({
    enableReinitialize: true,
    initialValues: {
      Forecast_Arrival:
        dataAll.Forecast_Arrival === '' ? null : forecastArrival,
      Forecast_Depart: dataAll.Forecast_Depart === '' ? null : forecastDepart,
      Forecast_Berth_Dur: dataAll.Forecast_Berth_Dur,
      Forecast_Berth_Dur_Hour: forecastBerthDurHour,
      Forecast_Berth_Dur_Minute: forecastBerthDurMinute
    }
  })
}

const fetchActivityData = async (srCode) => {
  try {
    const response = await ExecutionAPI.getActivityList(srCode)
    return new Promise((resolve, reject) => {
      // set the activity carousel opened by default
      const updatedActivity = (response?.data?.message?.data || []).map(
        (act) => ({ ...act, isOpen: true })
      )
      resolve(updatedActivity)
      reject(new Error('Failed to fetch activity data'))
    })
  } catch (error) {
    return error
  }
}

const useFetchActivityBySRCode = (srCode, setIsLoading, setTotalPercentage) => {
  const [activityList, setActivityList] = useState([])

  const fetchData = async () => {
    setIsLoading(true)
    const activityData = await fetchActivityData(srCode)
    const totalPercentage = _.sumBy(activityData, (act) => act.percentage)
    setTotalPercentage(totalPercentage)
    setActivityList(activityData)
    setIsLoading(false)
  }

  useEffect(() => {
    if (srCode) {
      fetchData()
    }
  }, [srCode])

  return [activityList, setActivityList]
}

// HOF: higher-order-function
const handleOpenActivityDetailHOF = (activityList, setActivityList) => {
  return (activity) => {
    const updated = activityList.map((act) => {
      if (activity.id === act.id) return { ...act, isOpen: !act.isOpen }
      return act
    })

    setActivityList(updated)
  }
}

const insertNewTask = async (taskHeaderData) => {
  try {
    const response = await ExecutionAPI.insertProgressTaskHeader(taskHeaderData)
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to insert task')
    })
  } catch (error) {
    // throw error to be caught by the source/first caller
    // You'll see similar approach in other functions
    throw new Error('Failed to insert task => ' + error.toString())
  }
}

const addNewTaskHOF = (setActivityList, location) => {
  return async (activity, taskDescription) => {
    try {
      const newTask = {
        percentage: 0,
        task: taskDescription,
        sr_code: activity.sr_code,
        activity_code: activity.code,
        created_date: ConvertLocalTimePost(location, new Date()),
        modified_date: ConvertLocalTimePost(location, new Date()),
        sort_no:
          findMaxSortNoTaskList(activity.progress_task_header_list || []) + 1
      }

      // As user add new task, the progress of the activity will adjust based on the already defined formula
      const updatedTaskHeaderList = [
        ...(activity.progress_task_header_list ?? []),
        newTask
      ]
      const { progress } = checkTasksPercentage(
        updatedTaskHeaderList,
        activity.percentage
      )
      const updatedActivity = {
        ...activity,
        progress: progress,
        progress_task_header_list: null
      }
      await updateActivityAndTaskHeader(updatedActivity, newTask)

      await insertNewTask(newTask)
      const result = await fetchActivityData(activity.sr_code)
      setActivityList(result)
    } catch (error) {
      return error
    }
  }
}

const deleteTask = async (taskId, modifiedDate) => {
  try {
    const response = await ExecutionAPI.deleteProgressTaskHeader(
      taskId,
      modifiedDate
    )
    new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to delete task')
    })
  } catch (error) {
    throw new Error('Failed to delete task => ' + error.toString())
  }
}

const deleteTaskHOF = (
  setActivityList,
  location,
  activity,
  task,
  setToggleDelete,
  setShowAlert
) => {
  return async () => {
    try {
      // Remove out the will be deleted task and recalculate activity progress
      const filteredTaskHeaderList = (
        activity.progress_task_header_list ?? []
      ).filter((ts) => ts.code !== task.code)
      const { progress } = checkTasksPercentage(
        filteredTaskHeaderList,
        activity.percentage
      )
      const finishDate = findTheLatestDateFromTasksList(filteredTaskHeaderList)
      const willBeUpdatedActivity = {
        ...activity,
        progress: progress,
        finish_date: finishDate,
        progress_task_header_list: null, // progress_task_header_list is not used on the backend when delete task
        modified_date: ConvertLocalTimePost(location, new Date())
      }

      await deleteTask(task.id, ConvertLocalTimePost(location, new Date()))
      await updateActivityAndTaskHeader(willBeUpdatedActivity, task)
      const result = await fetchActivityData(activity.sr_code)
      setActivityList(result)
      if (result) {
        setShowAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete'
        })
        setToggleDelete(false)
      } else {
        setShowAlert({
          msg: 'Cannot delete data',
          success: false,
          visible: true,
          type: 'delete'
        })
        setToggleDelete(false)
      }
    } catch (error) {
      setToggleDelete(false)
      return error
    }
  }
}

const updateActivity = async (activity) => {
  try {
    const response = await ExecutionAPI.updateProgressActivity(activity)
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to update activity and task header')
    })
  } catch (error) {
    throw new Error('Failed to update activity => ' + error.toString())
  }
}

const updateActivityHOF = (setActivityList) => {
  return async (activity) => {
    try {
      await updateActivity(activity)
      const result = await fetchActivityData(activity.sr_code)
      setActivityList(result)
    } catch (error) {
      return error
    }
  }
}

const updateActivityAndTaskHeader = async (
  activityPayload,
  taskHeaderPayload
) => {
  try {
    const response = await ExecutionAPI.updateActivityAndTaskHeader(
      activityPayload,
      taskHeaderPayload
    )
    return new Promise((resolve, reject) => {
      resolve(response.data.message.data)
      reject('Failed to update activity and task header')
    })
  } catch (error) {
    throw new Error(
      'Failed to update activity and task header => ' + error.toString()
    )
  }
}

const updateActivityAndTaskHeaderHOF = (
  activityList,
  setActivityList,
  setIsLoading
) => {
  return async (activity, task) => {
    try {
      let updatedTask
      let updatedActivity

      activityList.forEach((act) => {
        if (activity.code === act.code) {
          const updatedTaskList = (act.progress_task_header_list || []).map(
            (ts) => {
              if (ts.code === task.code) {
                const actualDurTime = getDurationBetweenTwoTime(
                  task.actual_start_time,
                  task.actual_finish_time
                )
                updatedTask = {
                  ...ts,
                  ...task,
                  actual_dur_time: actualDurTime,
                  inEdit: false
                }
                return updatedTask
              }

              return ts
            }
          )

          updatedActivity = {
            ...act,
            ...activity,
            progress_task_header_list: updatedTaskList
          }
          return updatedActivity
        }

        return act
      })

      // Set progress_task_header_list to null to enhance the marshaling performance on the Backend
      setIsLoading(true)
      await updateActivityAndTaskHeader(
        { ...updatedActivity, progress_task_header_list: null },
        updatedTask
      )
      const result = await fetchActivityData(activity.sr_code)
      setActivityList(result)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      return error
    }
  }
}

const formatDatetime = (datetime) => {
  if (!datetime) {
    // if datetime is a falsy return empty string
    return ''
  }
  return moment(datetime).format('DD MMMM YYYY HH:mm')
}

const validateWhenCheckActivity = async (dataSrCode, dataPlanArrival) => {
  const response = await ExecutionAPI.getConfirmation(dataSrCode)
  if (response.data && response.data.data) {
    if (
      response.data.data.Count_Total === 0 ||
      response.data.data.Count_Total - response.data.data.Count_False !==
        response.data.data.Count_Total ||
      moment(dataPlanArrival).diff(
        moment(new Date()).toISOString(),
        'minutes',
        true
      ) > 120
    ) {
      return new Promise((resolve, reject) => {
        resolve(false)
        reject(new Error('Failed to validate when check activity'))
      })
    }
  }

  return new Promise((resolve, reject) => {
    resolve(true)
    reject(new Error('Failed to validate when check activity'))
  })
}

const handleCheckProgressActivityHOF = (
  dataAll,
  dataSrCode,
  isInProgress,
  isTabSRValid,
  setShowAlert,
  setNotConfirm,
  setActivityList,
  dataPlanArrival,
  setOpenWarning,
  getDataExecution
) => {
  return async (activityData) => {
    try {
      if (!isTabSRValid) {
        setShowAlert({
          msg: 'Please fill in the required fields on SR Tab',
          success: false,
          visible: true,
          type: 'insert'
        })

        return
      }

      if (
        dataAll.Sr_Status !== 'In Progress' &&
        isInProgress &&
        dataAll.Sr_Type != 'Transfer Over Jetty'
      ) {
        setOpenWarning(true)
        return
      }

      const validToCheck = await validateWhenCheckActivity(
        dataSrCode,
        dataPlanArrival
      )
      if (dataAll.Sr_Status !== 'In Progress' && !validToCheck) {
        setNotConfirm(true)
        setOpenWarning(true)
        return
      }

      await ExecutionAPI.handleCheckProgressActivity(activityData)
      const result = await fetchActivityData(activityData.sr_code)
      setActivityList(result)
      getDataExecution()
    } catch (error) {
      return error
    }
  }
}

const toggleEditModeHOF = (activityList, setActivityList) => {
  return (activity, task) => {
    const updatedActivityList = activityList.map((act) => {
      if (activity.code === act.code) {
        const updatedTaskList = (act.progress_task_header_list || []).map(
          (ts) => {
            if (task.code === ts.code)
              return { ...ts, inEdit: !(ts.inEdit ?? false) }
            return ts
          }
        )

        return { ...act, progress_task_header_list: updatedTaskList }
      }

      return act
    })

    setActivityList(updatedActivityList)
  }
}

const checkTasksPercentage = (tasks, activityPercentage) => {
  // E.g: two tasks with percentage x and y
  // The formula is progress = (((x + y)/200) * activityPercentage)

  const maxPercentage = 100 * (tasks ?? []).length // Obviously, 100% is the max percentage
  const percentagesSum = _.sum(tasks.map((task) => task.percentage ?? 0))
  return {
    isExceed100: percentagesSum > maxPercentage, // True if sum of task's percentage is greater than 100% * number of tasks
    progress: (percentagesSum / maxPercentage) * activityPercentage // Calculate progress
  }
}

const findTheLatestDateFromTasksList = (tasks) => {
  // Since there is no tasks in the activity, then make the finish time null
  if (tasks.length === 0) {
    return null
  }

  // Find the latest actual finish time in a list of progress task headers
  const taskDateList = tasks.map((task) => new Date(task.actual_finish_time))
  const finishDate = moment(_.max(taskDateList)).format()

  return finishDate !== '1970-01-01T07:00:00+07:00' ? finishDate : null
}

const findMaxSortNoTaskList = (tasks) => {
  // Find the max sort number in a list of progress task headers
  const taskSortList = tasks.map((task) => task.sort_no)
  const maxSortNo = _.max(taskSortList)

  return maxSortNo || 0
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const updateTaskHeaderSortNo = async (taskHeaderList) => {
  try {
    const response = await ExecutionAPI.updateTaskHeaderSortNo(taskHeaderList)
    return new Promise((resolve, reject) => {
      resolve(response?.data?.message?.data ?? false)
      reject(new Error('Failed to update task header sort no'))
    })
  } catch (error) {
    return error
  }
}

const onDragEndHOF = (activityList, setActivityList) => {
  return async (result, activity) => {
    try {
      if (!result.destination) {
        return
      }

      if (result.destination.index === result.source.index) {
        return
      }

      const items = reorder(
        activity.progress_task_header_list || [],
        result.source.index,
        result.destination.index
      ).map((item, index) => ({ ...item, sort_no: index }))
      const updatedActivity = activityList.map((act) => {
        if (activity.id === act.id)
          return { ...act, progress_task_header_list: items }
        return act
      })

      setActivityList(updatedActivity)
      await updateTaskHeaderSortNo(items)
    } catch (error) {
      return error
    }
  }
}

const durationToFormattedString = (duration) => {
  return parseInt(duration)
    ? `${Math.floor(duration / 60)} Hours ${duration % 60} Minutes`
    : '0 Hours 0 Minutes'
}

const toggleModalHOF = (setShowModalStatus) => {
  return (activityCode, taskCode, taskName) => {
    setShowModalStatus((prev) => ({
      ...prev,
      taskCode,
      taskName,
      activityCode,
      isOpen: !prev.isOpen
    }))
  }
}

const checkIsInProgress = async (codeVessel, setIsInProgress) => {
  try {
    const { Location } = useSelector((state) => state.usersProfileReducer)

    const { LocationDescription } = Location

    const result = await ExecutionAPI.getAllExecutions(
      0,
      1000,
      LocationDescription,
      `[{"type": "text", "field" : "Vessel_Code", "value": "${codeVessel}"},{"type": "text", "field" : "Sr_Status", "value": "In Progress"}]`
    )
    if (result.data) {
      if (result.data.count > 0) {
        setIsInProgress(true)
      } else {
        setIsInProgress(false)
      }
    }
  } catch (err) {
    return err
  }
}

const updateSort = async ({ dataSort, data, handleClose, setActivityList }) => {
  try {
    const sort = []
    dataSort.forEach((item) => {
      sort.push({ Field: item.field, Dir: item.dir })
    })
    const response = await ExecutionAPI.updateTaskHeaderSort(sort, data)
    if (response.data) {
      const result = await fetchActivityData(data.sr_code)
      setActivityList(result)
      handleClose()
    }
  } catch (err) {
    console.log(err)
    handleClose()
  }
}

export default {
  createForm,
  updateSort,
  onDragEndHOF,
  addNewTaskHOF,
  deleteTaskHOF,
  formatDatetime,
  toggleModalHOF,
  checkIsInProgress,
  toggleEditModeHOF,
  updateActivityHOF,
  checkTasksPercentage,
  useFetchActivityBySRCode,
  handleOpenActivityDetailHOF,
  updateActivityAndTaskHeaderHOF,
  handleCheckProgressActivityHOF,
  findTheLatestDateFromTasksList
}
