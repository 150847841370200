import styled from 'styled-components'

import { Colors } from '../../styles'

export const ModalWrapper = styled.div`
  left: 22.39vw;
  top: 15.36vh;
  width: 31.25vw;
  height: 59.93vh;
  z-index: 1000;
  position: absolute;
  background-color: ${Colors.pureWhite};
`

export const Header = styled.div`
  width: 100%;
  height: 5.12vh;
  display: flex;
  padding: 1vh 1vw;
  font-weight: bold;
  align-items: center;
  flex-direction: row;
  color: ${Colors.pureWhite};
  font-size: 1.6vh;
  background-color: ${Colors.softBlue};
`

export const Status = styled.div`
  width: 1.5vh;
  height: 1.5vh;
  display: flex;
  font-weight: bold;
  margin-right: 1vh;
  align-items: center;
  border-radius: 2vh;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.color};
  margin-left: ${(props) => props.left || 0}px;
`

export const Close = styled.div`
  flex: 1;
  text-align-last: end;
  cursor: pointer;
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Tab = styled.div`
  width: 100%;
  height: 5.12vh;
  padding: 1vh;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.6vh;
  color: ${(props) => (props.active ? Colors.softBlue : Colors.grey)};
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  border-bottom: solid ${(props) => (props.active ? '5px' : '0px')} ${Colors.softBlue};
`

export const Content = styled.div`
  padding: 1vh;
  height: 49.18vh;
  overflow-x: ${(props) => props.overflow || 'auto'};
  overflow-y: ${(props) => props.overflow || 'auto'};
`

export const FormTab = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: ${(props) => props.margin || '0px'};
`

export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 1vh;
  flex-direction: column;
  margin: ${(props) => props.margin || '0px'};
`

export const ActWrapper = styled.div`
  flex: 1;
  display: flex;
  padding: 1vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const ActHeader = styled.div`
  width: 100%;
  height: 4vh;
  display: flex;
  padding: 1vh;
  font-weight: bold;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5vh;
  color: ${Colors.pureWhite};
  font-size: 1.4vh;
  background-color: ${Colors.softBlue};
`

export const ActContent = styled.div`
  width: 97.5%;
  padding: 1vh;
  height: 15.36vh;
  display: flex;
  flex-direction: column;
  background-color: #e1edca;
  border: solid 1px ${Colors.softGrey};
`

export const TaskHeader = styled.div`
  width: 97.5%;
  padding: 1vh;
  height: 4vh;
  display: flex;
  margin-top: 0.2vh;
  font-weight: bold;
  background: #F4F6F9;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000029;
  border: solid 1px ${Colors.softGrey};
`

export const TaskContent = styled.div`
  width: 97.5%;
  padding: 1vh;
  height: 17.41vh;
  display: flex;
  flex-direction: column;
  border: solid 1px ${Colors.softGrey};
`

export const DelContent = styled.div`
  width: 97.5%;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.open ? '29.71vh' : '4.86vh')};
`

export const DelContentBorder = styled.div`
  width: 100%;
  height: 25.1vh;
  display: flex;
  margin-top: 0.256vh;
  flex-direction: column;
  border: solid 1px ${Colors.softGrey};
`

export const DelayHeader = styled.div`
  width: 100%;
  height: 4vh;
  display: flex;
  padding: 1vh;
  font-weight: bold;
  align-items: center;
  flex-direction: row;
  color: ${Colors.black};
  font-size: 1.43vh;
  border: solid 1px ${Colors.softGrey};
`
