import _ from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import InputIcon from '@material-ui/icons/Input'
import CloseIcon from '@material-ui/icons/Close'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core/'

import { Colors } from '../../styles'
import { ServiceRequestAPI } from '../../api'
import ProductGridColumnGeneral from './column.modal_prod.json'
import ProductGridColumnIFT from './column.modal_prod_ift.json'
import { ActionCell, FlexWrapper } from '../../styles/global.styled'
import { Input, Button, TextFilterCell, ModalDel, InputSelect } from '../../components'
import {
  Form,
  FormContainer,
  FormEmptyFlex,
  ActionContainer,
  TotalPackageText,
  CargoNoContainer,
  AccordionContainer,
  SrModalGridContainer,
} from './service-request.styled'

const ProductModalContent = (props) => {
  const {
    isEdit,
    isDetail,
    isApproved,
    srProducts,
    setSrProducts,
    ServiceRequestForm,
    generalCode,
    iftCode,
    _setEmptyIfDeleted,
    pathRole,
  } = props
  const uomInitial = {
    Length: [],
    Weight: [],
    Object: [],
    Volume: [],
  }
  const [uomTypes, setUomTypes] = useState(uomInitial)
  const [isRefresh, setIsRefresh] = useState(false)
  const [dataIndex, setDataIndex] = useState(null)
  const [masterProduct, setMasterProduct] = useState([])
  const [masterProductIncludeDeleted, setMasterProductIncludeDeleted] = useState([])
  const [masterProductFilterOutSelected, setMasterProductFilterOutSelected] = useState([])
  const [otherProductCode, setOtherProductCode] = useState('')
  const [productObj, setProductObj] = useState({})
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [srProductFormData, setSrProductFormData] = useState({})
  const srProductsGrid = srProducts.filter((e) => e.Deleted_By === '')
  const [isProductFormEdit, setIsProductFormEdit] = useState(false)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true)
  const [dangerousGoodsLevel, setDangerousGoodsLevel] = useState([])
  const [defaultDangerousGoodsLevel, setDefaultDangerousGoodsLevel] = useState('')

  const { Sr_Category_Value } = ServiceRequestForm.values
  const isGeneral = Sr_Category_Value ? Sr_Category_Value.toLowerCase() === 'general' : false
  const ProductGridColumn = isGeneral ? ProductGridColumnGeneral : ProductGridColumnIFT

  const heightFactor = isDetail
    ? 575
    : isApproved
      ? 430
      : isAccordionExpanded
        ? isGeneral
          ? 775
          : 645
        : 450
  const productGridHeight =
    window.innerHeight - heightFactor > 200 ? window.innerHeight - heightFactor : 200

  const _getMasterProducts = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllMasterProducts(0, 100, filter)
      if (data && data.length) {
        data.forEach((v) => {
          if (v.Product_Name.toLowerCase() === 'others' && v.Sr_Category_Code === generalCode) {
            setOtherProductCode(v.Code)
          }
        })
        setMasterProductIncludeDeleted(data)
      }
      let filteredData = data ? data.filter((v) => v.Product_Name.toLowerCase() !== 'others') : []
      filteredData = filteredData.filter((v) => v.Sr_Category_Code === iftCode)
      setMasterProduct(filteredData)
      const tempProdObj = {}
      filteredData.forEach((v) => (tempProdObj[v.Code] = v.Product_Name))
      setProductObj(tempProdObj)
    } catch (error) {
      console.log(error)
    }
  }

  const _getDangerousGoodsLevel = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Dangerous_Goods"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(0, 100, filter)
      setDangerousGoodsLevel(data)
      data.forEach((v) => {
        if (v.Value === 'No') {
          setDefaultDangerousGoodsLevel(v.Code)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const _getSrProducts = async () => {
    try {
      const filter = `[{"field":"sr_code","type":"text","value":"${ServiceRequestForm.values.Code}"}]`
      const { data } = await ServiceRequestAPI.GetAllSrProducts(0, 1000, filter)
      let filteredData = data
        ? data.filter((v) => v.Sr_Code === ServiceRequestForm.values.Code)
        : []
      filteredData = _.orderBy(filteredData, ['Code'], ['asc'])
      setSrProducts(filteredData)
    } catch (error) {
      console.log(error)
    }
  }

  const _getUomTypes = async () => {
    try {
      const { data } = await ServiceRequestAPI.GetUomTypes()
      const filteredData = data ? data : {}
      filteredData['Length'] = filteredData['Length']
        ? filteredData['Length'].filter((v) => v.Is_Active)
        : undefined
      filteredData['Weight'] = filteredData['Weight']
        ? filteredData['Weight'].filter((v) => v.Is_Active)
        : undefined
      filteredData['Object'] = filteredData['Object']
        ? filteredData['Object'].filter((v) => v.Is_Active)
        : undefined
      filteredData['Volume'] = filteredData['Volume']
        ? filteredData['Volume'].filter((v) => v.Is_Active)
        : undefined
      setUomTypes(filteredData)
    } catch (error) {
      console.log(error)
    }
  }

  const ProductForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: srProductFormData.Id || srProductFormData.ID || 0,
      Product_Code:
        _setEmptyIfDeleted(srProductFormData.Product_Code, masterProductIncludeDeleted, 'Code') ||
        (isGeneral ? otherProductCode : ''),
      Product_Name: srProductFormData.Product_Name || '',
      Volume: srProductFormData.Volume || '',
      UOM: srProductFormData.UOM || '',
      Dangerous_Goods: srProductFormData.Dangerous_Goods || defaultDangerousGoodsLevel,
      Plb_Goods: srProductFormData.Plb_Goods || false,
      Quantity: srProductFormData.Quantity || '',
      Length: srProductFormData.Length || '',
      Width: srProductFormData.Width || '',
      Height: srProductFormData.Height || '',
      Weight: srProductFormData.Weight || '',
      Diameter: srProductFormData.Diameter || '',
      Deleted_By: srProductFormData.Deleted_By || '',
      Uom_Lwh_Code:
        _setEmptyIfDeleted(srProductFormData.Uom_Lwh_Code, uomTypes['Length'], 'Uom_Code') || '',
      Uom_Weight_Code:
        _setEmptyIfDeleted(srProductFormData.Uom_Weight_Code, uomTypes['Weight'], 'Uom_Code') || '',
      Uom_Quantity_Code:
        _setEmptyIfDeleted(srProductFormData.Uom_Quantity_Code, uomTypes['Object'], 'Uom_Code') ||
        '',
      Uom_Volume_Code:
        _setEmptyIfDeleted(srProductFormData.Uom_Volume_Code, uomTypes['Volume'], 'Uom_Code') || '',
      Uom_Volume_Text: srProductFormData.Uom_Volume_Text || '',
    },
    validationSchema: Yup.object({
      Id: Yup.number(),
      Product_Code: Yup.string().required('Required*'),
      Product_Name: Yup.string()
        .required('Required*')
        .test('unique-product-name', 'Cannot Insert Duplicate Product Name', (value) => {
          let isDuplicate = false
          if (!isProductFormEdit) {
            srProductsGrid.forEach((prod) => {
              if (value === prod.Product_Name) {
                isDuplicate = true
              }
            })
          } else {
            srProducts
              .filter((e, idx) => idx !== dataIndex && !e.Deleted_By)
              .forEach((prod) => {
                if (value === prod.Product_Name) {
                  isDuplicate = true
                }
              })
          }
          return !isDuplicate
        }),
      Volume: Yup.number(),
      UOM: Yup.string(),
      Dangerous_Goods: Yup.string(),
      Plb_Goods: Yup.bool().required('Required*'),
      Quantity: Yup.number(),
      Length: Yup.number(),
      Width: Yup.number(),
      Height: Yup.number(),
      Weight: Yup.number(),
      Diameter: Yup.number(),
      Uom_Lwh_Code: Yup.string(),
      Uom_Weight_Code: Yup.string(),
      Uom_Quantity_Code: Yup.string(),
      Uom_Volume_Code: Yup.string(),
      Uom_Volume_Text: Yup.string(),
    }),
    onSubmit: async (values, form) => {
      let data = { ...srProductFormData }
      Object.keys(values)
        .filter(
          (val) =>
            val == 'Id' ||
            val == 'Product_Code' ||
            val == 'Product_Name' ||
            val == 'Plb_Goods' ||
            val == 'Volume' ||
            val == 'UOM' ||
            val == 'Dangerous_Goods' ||
            val == 'Quantity' ||
            val == 'Length' ||
            val == 'Width' ||
            val == 'Height' ||
            val == 'Weight' ||
            val == 'Diameter' ||
            val == 'Deleted_By' ||
            val == 'Uom_Lwh_Code' ||
            val == 'Uom_Weight_Code' ||
            val == 'Uom_Quantity_Code' ||
            val == 'Uom_Volume_Code'
        )
        .forEach((vl) => {
          const numbersData = ['Volume', 'Quantity', 'Length', 'Width', 'Height', 'Weight', 'Diameter']
          if (numbersData.indexOf(vl) > -1 && values[vl] === '') {
            data[vl] = 0
          } else if (vl === 'Quantity') {
            data[vl] = parseInt(values[vl])
          } else if (numbersData.indexOf(vl) > -1) {
            if (vl === 'Width' || vl === 'Height') {
              if (values['Diameter'] === 0 || values['Diameter'] === '' ) {
                data[vl] = parseFloat(values[vl])
              } else {
                data[vl] = parseFloat(0)
              }
            } else {
              data[vl] = parseFloat(values[vl])
            }
          } else {
            data[vl] = values[vl]
          }
        })

      if (isProductFormEdit) {
        const newSrProducts = [...srProducts]
        newSrProducts.splice(dataIndex, 1, data)
        setSrProducts(newSrProducts)
        setIsProductFormEdit(false)
      } else {
        setSrProducts((prevState) => [...prevState, data])
      }
      setSrProductFormData({})
      form.resetForm()
      _setProductCode()
      setDataIndex(null)
    },
  })

  useEffect(() => {
    setMasterProductFilterOutSelected(
      masterProduct.filter((master) => {
        let isSelected = false
        srProductsGrid.forEach((prod) => {
          if (master.Code === prod.Product_Code) {
            isSelected = true
          }
        })
        return !isSelected
      })
    )
  }, [srProducts, masterProduct])

  useEffect(() => {
    setMasterProductFilterOutSelected(
      masterProduct.filter((master) => {
        let isSelected = false
        srProductsGrid.forEach((prod) => {
          if (master.Code === prod.Product_Code) {
            if (prod.Product_Code !== srProductFormData.Product_Code) {
              isSelected = true
            }
          }
        })
        return !isSelected
      })
    )
  }, [srProductFormData.Product_Code])

  useEffect(() => {
    setIsRefresh(true)
  }, [ServiceRequestForm.values.Sr_Category_Code, props.appSize.width])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
    }
  }, [isRefresh])

  useEffect(() => {
    if (generalCode && iftCode) {
      _getMasterProducts()
    }
  }, [generalCode, iftCode])

  useEffect(() => {
    _getAll()
  }, [])

  const _getAll = async () => {
    await _getDangerousGoodsLevel()
    await _getUomTypes()
  }

  useEffect(() => {
    const found = uomTypes['Length'].find(
      (item) => item.Uom_Code === ProductForm.values.Uom_Lwh_Code
    )
    if (found) {
      ProductForm.setFieldValue('Uom_Volume_Code', found.Uom_Code)
      ProductForm.setFieldValue('Uom_Volume_Text', `${found.Uom_Text}³`)
    } else {
      ProductForm.setFieldValue('Uom_Volume_Code', '')
      ProductForm.setFieldValue('Uom_Volume_Text', '')
    }
  }, [ProductForm.values.Uom_Lwh_Code])

  useEffect(() => {
    if(ProductForm.values.Diameter === '' || ProductForm.values.Diameter === 0) {
      _calculateVolume()
    } else {
      _calculateVolumeDiameter()
    }
  }, [
    ProductForm.values.Length,
    ProductForm.values.Width,
    ProductForm.values.Height,
    ProductForm.values.Quantity,
    ProductForm.values.Diameter,
  ])

  useEffect(() => {
    _getSrProducts()
  }, [ServiceRequestForm.values.Code])

  useEffect(() => {
    ProductForm.resetForm()
    _setProductCode()
  }, [ServiceRequestForm.values.Sr_Category_Value])

  useEffect(() => {
    _setProductName()
  }, [ProductForm.values.Product_Code])

  const _setProductCode = () => {
    if (isGeneral) {
      ProductForm.setFieldValue('Product_Code', otherProductCode)
      ProductForm.setFieldValue('Product_Name', '')
    }
  }

  const _setProductName = () => {
    if (!isGeneral) {
      ProductForm.setFieldValue('Product_Name', productObj[ProductForm.values.Product_Code])
    }
  }

  const _calculateVolume = () => {
    if (isGeneral) {
      const { Length, Width, Height, Quantity } = ProductForm.values
      const vol = parseFloat((Length * Width * Height * Quantity).toFixed(4))
      if (vol) {
        ProductForm.setFieldValue('Volume', vol)
      } else {
        ProductForm.setFieldValue('Volume', '')
      }
    }
  }
  const _calculateVolumeDiameter = () => {
    if (isGeneral) {
      const { Diameter, Length, Quantity } = ProductForm.values
      const vol = parseFloat(( 3.14 * (Diameter/2) * (Diameter/2) * Length * Quantity).toFixed(4))
      if (vol) {
        ProductForm.setFieldValue('Volume', vol)
      } else {
        ProductForm.setFieldValue('Volume', '')
      }
    }
  }

  const _actionCell = (props) => (
    <td style = {{ paddingTop:'1.6vh' }}>
      <ActionContainer>
        {pathRole.allowEdit
        && (dataIndex === srProducts.findIndex(e => e.Code && e.Code === props.dataItem.Code) || dataIndex === srProducts.findIndex(e => e.Product_Name === props.dataItem.Product_Name && !e.Deleted_By)) ? (
            <ActionCell
              id='sr-prod_mdl-action-close'
              onClick={() => {
                if (!isApproved) {
                  setIsProductFormEdit(false)
                  setDataIndex(null)
                  setSrProductFormData({})
                  _setProductCode()
                }
              }}
            >
              <CloseIcon htmlColor={Colors.red} />
            </ActionCell>
          ) : (
            <ActionCell
              id='sr-prod_mdl-action-cell-td'
              onClick={() => {
                if (!isApproved) {
                  setIsProductFormEdit(true)
                  setIsAccordionExpanded(true)
                  setSrProductFormData(props.dataItem)
                  let dataIdx
                  if (props.dataItem.Code) {
                    dataIdx = srProducts.findIndex((e) => e.Code === props.dataItem.Code)
                  } else {
                    dataIdx = srProducts.findIndex(
                      (e) => e.Product_Name === props.dataItem.Product_Name && !e.Deleted_By
                    )
                  }
                  setDataIndex(dataIdx)
                }
              }}
            >
              <CreateIcon htmlColor={Colors.green} />
            </ActionCell>
          )}
        {!isProductFormEdit && pathRole.allowDelete && (
          <ActionCell
            id='sr-prod_mdl-action-delete-td'
            onClick={() => {
              if (!isApproved) {
                let dataIdx
                if (props.dataItem.Code) {
                  dataIdx = srProducts.findIndex((e) => e.Code === props.dataItem.Code)
                } else {
                  dataIdx = srProducts.findIndex(
                    (e) => e.Product_Name === props.dataItem.Product_Name && !e.Deleted_By
                  )
                }
                setDataIndex(dataIdx)
                setShowModalDelete(true)
              }
            }}
          >
            <DeleteIcon htmlColor={Colors.black} />
          </ActionCell>
        )}
      </ActionContainer>
    </td>
  )

  const _plbGoodsCell = (props) => (
    <td style={{ textAlign: 'center' }}>{props.dataItem.Plb_Goods ? 'Yes' : 'No'}</td>
  )

  const _dangerousGoodsCell = (props) => (
    <td style={{ textAlign: 'center' }}>
      {_getDangerousGoodsValue(props.dataItem.Dangerous_Goods)}
    </td>
  )

  const _centeredCell = (props) => {
    return <td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>
  }

  const _productNameCell = (props) => {
    return <td>{productObj[props.dataItem.Product_Code] || props.dataItem.Product_Name}</td>
  }

  const _uomLwhCell = (props) => {
    const selectedUomType = uomTypes['Length'] || []
    const result = selectedUomType.find((item) => item.Uom_Code === props.dataItem[props.field])
    return (
      <td style={{ textAlign: 'center' }}>
        {result ? result['Uom_Text'] : props.dataItem.Uom_Lwh_Text || '-'}
      </td>
    )
  }

  const _uomWeightCell = (props) => {
    const selectedUomType = uomTypes['Weight'] || []
    const result = selectedUomType.find((item) => item.Uom_Code === props.dataItem[props.field])
    return (
      <td style={{ textAlign: 'center' }}>
        {result ? result['Uom_Text'] : props.dataItem.Uom_Weight_Text || '-'}
      </td>
    )
  }

  const _uomQtyCell = (props) => {
    const selectedUomType = uomTypes['Object'] || []
    const result = selectedUomType.find((item) => item.Uom_Code === props.dataItem[props.field])
    return (
      <td style={{ textAlign: 'center' }}>
        {result ? result['Uom_Text'] : props.dataItem.Uom_Quantity_Text || '-'}
      </td>
    )
  }

  const _uomVolCell = (props) => {
    const selectedUomType = uomTypes['Volume'] || []
    const result = selectedUomType.find((item) => item.Uom_Code === props.dataItem[props.field])
    return (
      <td style={{ textAlign: 'center' }}>
        {result ? result['Uom_Text'] : props.dataItem.Uom_Volume_Text || '-'}
      </td>
    )
  }

  const _uomGeneralVolCell = (props) => {
    const selectedUomType = uomTypes['Length'] || []
    const result = selectedUomType.find((item) => item.Uom_Code === props.dataItem['Uom_Lwh_Code'])
    return (
      <td style={{ textAlign: 'center' }}>
        {result ? `${result['Uom_Text']}³` : props.dataItem.Uom_Volume_Text || '-'}
      </td>
    )
  }

  const _getDangerousGoodsValue = (Code) => {
    const dangerousGoodsObj = {}
    dangerousGoodsLevel.forEach((v) => (dangerousGoodsObj[v.Code] = v.Value))
    return dangerousGoodsObj[Code]
  }

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_handleProductDelete}
        deleteText='Are you sure to delete the data ?'
        toggleDialog={() => {
          setShowModalDelete(false)
          setDataIndex(null)
        }}
      />
    </div>
  )

  const _handleProductDelete = () => {
    if (isEdit && !isDetail) {
      const newSrProducts = [...srProducts]
      if (newSrProducts[dataIndex].ID) {
        const userFromLocalStorage = JSON.parse(localStorage.getItem('user_info'))
        newSrProducts[dataIndex].Deleted_By =
          userFromLocalStorage && userFromLocalStorage.User
            ? userFromLocalStorage.User.Email
            : 'admin@interport.co.id'
      } else {
        newSrProducts.splice(dataIndex, 1)
      }
      setSrProducts(newSrProducts)
    } else if (!isDetail) {
      const newSrProducts = [...srProducts]
      newSrProducts.splice(dataIndex, 1)
      setSrProducts(newSrProducts)
    }
    setShowModalDelete(false)
    setDataIndex(null)
  }

  return (
    <div>
      {showModalDelete && _deleteModal()}
      {(isDetail || isApproved) && (
        <CargoNoContainer>
          <Input
            id='sr-prod_mdl-cargo_no-txt'
            label='Cargo No'
            form={ServiceRequestForm}
            name='Cargo_No'
            InputProps={{ readOnly: true }}
            value={ServiceRequestForm.values.Cargo_No}
          />
        </CargoNoContainer>
      )}
      {!isDetail && !isApproved && (
        <Accordion
          expanded={isAccordionExpanded}
          style={{ marginTop: 0, marginBottom: 0, boxShadow: '0px 2px 8px #00000033' }}
        >
          <AccordionContainer>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => {
                setIsAccordionExpanded(!isAccordionExpanded)
              }}
              id='panel1bh-header'
              style={{ padding: 0, marginRight: 10 }}
            />
            <FormContainer style={{ padding: 0 }}>
              <Form style={{ padding: 0, paddingTop: 10 }}>
                <Input
                  id='sr-prod_mdl-cargo_no-txt'
                  label='Cargo No'
                  form={ServiceRequestForm}
                  name='Cargo_No'
                  InputProps={{ readOnly: isDetail || isApproved }}
                  value={ServiceRequestForm.values.Cargo_No}
                  inputProps={{
                    tabIndex: 1,
                  }}
                />
              </Form>
              <FormEmptyFlex />
            </FormContainer>
          </AccordionContainer>
          {ServiceRequestForm.values.Sr_Category_Code && (
            <AccordionDetails
              style={{
                flexDirection: 'column',
                padding: '0px 10px 10px 10px',
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <FormContainer>
                <Form>
                  {isGeneral ? (
                    <Input
                      id='sr-prod_mdl-prod_code-select'
                      label='Product Name'
                      form={ProductForm}
                      name='Product_Name'
                      InputProps={{ readOnly: isDetail || isApproved }}
                      value={ProductForm.values.Product_Name}
                      inputProps={{
                        tabIndex: 1,
                      }}
                    />
                  ) : (
                    <InputSelect
                      id='sr-prod_mdl-prod_code-select'
                      label='Product Name'
                      name='Product_Code'
                      options={masterProductFilterOutSelected}
                      optionText='Product_Name'
                      optionValueKey='Code'
                      readOnly={isDetail || isApproved}
                      form={ProductForm}
                      tabIndex={1}
                    />
                  )}
                  <Input
                    id='sr-prod_mdl-length-txt'
                    type='number'
                    label='Length'
                    form={ProductForm}
                    name='Length'
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.Length}
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    onChange={(event) => ProductForm.setFieldValue('Length', event.target.value)}
                    inputProps={{
                      tabIndex: 4,
                    }}
                  />
                  <InputSelect
                    id='sr-prod_mdl-uom-lwh-select'
                    label='UOM LWH'
                    name='Uom_Lwh_Code'
                    options={uomTypes['Length'] || []}
                    optionText='Uom_Text'
                    optionValueKey='Uom_Code'
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    readOnly={isDetail || isApproved}
                    form={ProductForm}
                    tabIndex={7}
                  />
                  <Input
                    id='sr-prod_mdl-diameter-txt'
                    type='number'
                    label='Diameter'
                    form={ProductForm}
                    name='Diameter'
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.Diameter}
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    onChange={(event) => ProductForm.setFieldValue('Diameter', event.target.value)}
                    inputProps={{
                      tabIndex: 10,
                    }}
                  />
                  {isGeneral && (
                    <InputSelect
                      id='sr-prod_mdl-plb_goods-select'
                      label='PLB Goods'
                      name='Plb_Goods'
                      options={[
                        { Code: true, Value: 'Yes' },
                        { Code: false, Value: 'No' },
                      ]}
                      optionText='Value'
                      optionValueKey='Code'
                      readOnly={isDetail || isApproved}
                      form={ProductForm}
                      tabIndex={13}
                    />
                  )}
                  {!isGeneral && (
                    <InputSelect
                      id='sr-prod_mdl-dangerous-goods-select'
                      label='Dangerous Goods'
                      name='Dangerous_Goods'
                      options={dangerousGoodsLevel}
                      optionText='Value'
                      optionValueKey='Code'
                      readOnly={isDetail || isApproved}
                      form={ProductForm}
                      tabIndex={4}
                    />
                  )}
                </Form>
                <Form>
                  <Input
                    id='sr-prod_mdl-uom-txt'
                    label='UOM'
                    form={ProductForm}
                    name='UOM'
                    style={{ display: 'none' }}
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.UOM}
                  />
                  <Input
                    id='sr-prod_mdl-quantity-txt'
                    type='number'
                    label='Quantity'
                    form={ProductForm}
                    name='Quantity'
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.Quantity}
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    onChange={(event) =>
                      ProductForm.setFieldValue('Quantity', parseInt(event.target.value))
                    }
                    inputProps={{
                      tabIndex: 2,
                    }}
                  />
                  <Input
                    id='sr-prod_mdl-width-txt'
                    type='number'
                    label='Width'
                    form={ProductForm}
                    name='Width'
                    disabled={ !!ProductForm.values.Diameter }
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.Width}
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    onChange={(event) => ProductForm.setFieldValue('Width', event.target.value)}
                    inputProps={{
                      tabIndex: 5,
                    }}
                  />
                  <Input
                    id='sr-prod_mdl-weight-txt'
                    type='number'
                    label='Weight'
                    form={ProductForm}
                    name='Weight'
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.Weight}
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    onChange={(event) => ProductForm.setFieldValue('Weight', event.target.value)}
                    inputProps={{
                      tabIndex: 8,
                    }}
                  />
                  {!isGeneral && (
                    <Input
                      id='sr-prod_mdl-volume-txt'
                      type='number'
                      label='Volume'
                      form={ProductForm}
                      name='Volume'
                      InputProps={{ readOnly: isDetail || isApproved }}
                      value={ProductForm.values.Volume}
                      onChange={(event) => ProductForm.setFieldValue('Volume', event.target.value)}
                      inputProps={{
                        tabIndex: 2,
                      }}
                    />
                  )}
                  {isGeneral && (
                    <Input
                      id='sr-prod_mdl-volume-txt'
                      type='number'
                      label='Volume'
                      form={ProductForm}
                      name='Volume'
                      InputProps={{ readOnly: true }}
                      value={ProductForm.values.Volume}
                      inputProps={{
                        tabIndex: 11,
                      }}
                    />
                  )}
                  {isGeneral && (
                    <InputSelect
                      id='sr-prod_mdl-dangerous-goods-select'
                      label='Dangerous Goods'
                      name='Dangerous_Goods'
                      options={dangerousGoodsLevel}
                      optionText='Value'
                      optionValueKey='Code'
                      readOnly={isDetail || isApproved}
                      form={ProductForm}
                      tabIndex={13}
                    />
                  )}
                  {!isGeneral && (
                    <InputSelect
                      id='sr-prod_mdl-plb_goods-select'
                      label='PLB Goods'
                      name='Plb_Goods'
                      options={[
                        { Code: true, Value: 'Yes' },
                        { Code: false, Value: 'No' },
                      ]}
                      optionText='Value'
                      optionValueKey='Code'
                      readOnly={isDetail || isApproved}
                      form={ProductForm}
                      tabIndex={5}
                    />
                  )}
                </Form>
                <Form>
                  <InputSelect
                    id='sr-prod_mdl-uom-quantity-select'
                    label='UOM Quantity'
                    name='Uom_Quantity_Code'
                    options={uomTypes['Object'] || []}
                    optionText='Uom_Text'
                    optionValueKey='Uom_Code'
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    readOnly={isDetail || isApproved}
                    form={ProductForm}
                    tabIndex={3}
                  />
                  {!isGeneral && (
                    <InputSelect
                      id='sr-prod_mdl-uom-volume-select'
                      label='UOM Volume'
                      name='Uom_Volume_Code'
                      options={uomTypes['Volume'] || []}
                      optionText='Uom_Text'
                      optionValueKey='Uom_Code'
                      readOnly={isDetail || isApproved}
                      form={ProductForm}
                      tabIndex={3}
                    />
                  )}
                  <Input
                    id='sr-prod_mdl-height-txt'
                    type='number'
                    label='Height'
                    form={ProductForm}
                    name='Height'
                    disabled={ !!ProductForm.values.Diameter }
                    InputProps={{ readOnly: isDetail || isApproved }}
                    value={ProductForm.values.Height}
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    onChange={(event) => ProductForm.setFieldValue('Height', event.target.value)}
                    inputProps={{
                      tabIndex: 6,
                    }}
                  />
                  <InputSelect
                    id='sr-prod_mdl-uom-weight-select'
                    label='UOM Weight'
                    name='Uom_Weight_Code'
                    options={uomTypes['Weight'] || []}
                    optionText='Uom_Text'
                    optionValueKey='Uom_Code'
                    style={{ display: isGeneral ? 'inline-flex' : 'none' }}
                    readOnly={isDetail || isApproved}
                    form={ProductForm}
                    tabIndex={9}
                  />
                  {isGeneral && (
                    <Input
                      id='sr-prod_mdl-uom-volume-select'
                      type='text'
                      label='UOM Volume'
                      form={ProductForm}
                      name='Uom_Volume_Text'
                      InputProps={{ readOnly: true }}
                      value={ProductForm.values.Uom_Volume_Text}
                      inputProps={{
                        tabIndex: 12,
                      }}
                    />
                  )}
                </Form>
              </FormContainer>
              {!isDetail && !isApproved && (
                <div style={{ marginTop: isGeneral ? -65 : 0 }}>
                  <Button
                    color='green'
                    type='button'
                    id='sr-prod_mdl-insert_prod-btn'
                    style={{ float: 'right', marginRight: 10 }}
                    onClick={ProductForm.handleSubmit}
                  >
                    <FlexWrapper style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                      {
                        isProductFormEdit ?
                          <SaveIcon style={{ color: 'white', fontSize: '18px' }} />
                          :
                          <InputIcon style={{ color: 'white', fontSize: '18px' }}/>
                      }
                      <div >
                        {
                          isProductFormEdit ?
                            'SAVE' : 'INSERT'
                        }
                      </div>
                    </FlexWrapper>
                  </Button>
                </div>
              )}
            </AccordionDetails>
          )}
        </Accordion>
      )}
      {ServiceRequestForm.values.Sr_Category_Code && !isRefresh && (
        <SrModalGridContainer>
          <div
            style={{
              height: 30,
              display: 'flex',
              paddingRight: 5,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TotalPackageText>
              {`Total Package: ${[...srProducts].filter((e) => e.Deleted_By === '').length}`}
            </TotalPackageText>
          </div>
          <div>
            <Grid
              style={{ height: productGridHeight }}
              rowHeight={40}
              data={srProductsGrid}
              pageSize={20}
              total={srProductsGrid.length}
            >
              {ProductGridColumn.map((e, index) =>
                e.field === 'Action' && !isDetail && !isApproved ? (
                  <Column
                    key={index}
                    width={e.width}
                    title={e.title}
                    field={e.field}
                    cell={_actionCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Product_Code' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={props.appSize.width > 950 ? e.width : '150px'}
                    cell={_productNameCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Product_Name' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Plb_Goods' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_plbGoodsCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Dangerous_Goods' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_dangerousGoodsCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Uom_Lwh_Code' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_uomLwhCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Uom_Weight_Code' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_uomWeightCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Uom_Quantity_Code' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_uomQtyCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Uom_Volume_Code' && !isGeneral ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_uomVolCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field === 'Uom_Volume_Code' && isGeneral ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_uomGeneralVolCell}
                    filterCell={TextFilterCell}
                  />
                ) : e.field !== 'Action' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.width}
                    cell={_centeredCell}
                    filterCell={TextFilterCell}
                  />
                ) : null
              )}
            </Grid>
          </div>
        </SrModalGridContainer>
      )}
    </div>
  )
}

export default ProductModalContent
