// import moment from 'moment'
import _ from 'lodash'
import moment from 'moment'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import { Checkbox as CustomCheckbox, FormControlLabel } from '@material-ui/core'

import { Images } from '~/assets'
import {
  Row,
  Form,
  Text,
  TopIcon,
  SVGIcon,
  TextTop1,
  TextTop2,
  TextTop3,
  Container,
  TitleValue,
  ContainerTop,
  WrapperValue,
  FormContainer,
  TitleBerthing,
  LeftFormContainer,
  RightFormContainer,
  BorderLine
} from './service-request-detail.styled'
import { ServiceRequestAPI, MasterDataAPI, LintasGeneralAPI } from '../../api'
import DocGridCCU from './column.ccu_detail.json'
import { ConvertLocalTimeGet, durationToFormattedString } from '../../utils'
import KeySR from '../../utils/dataMappingKeySR.json'
import DocGridPermit from './column.work_permit.json'
import { Input, Checkbox, ButtonDetail } from '../../components'
import ModalWorkPermitCCU from './service-request.modal.work-permit-ccu.container'

const SrTabContent = ({ dataSr }) => {
  const [dataSRField, setDataSRField] = useState([])
  const [dataContract, setDataContract] = useState([])
  const [contractTitle, setContractTitle] = useState([])
  const [dataMasterPermit, setDataMasterPermit] = useState([])
  const [modalPermitCCU, setModalPermitCCU] = useState({
    modal_type: '',
    isOpen: false
  })
  const [masterData, setMasterData] = useState({})
  const [dataRecipient, setDataRecipient] = useState([])

  useEffect(() => {
    getDataSRField()
    getDataContract(dataSr)
    getDataWorkPermit()
    getAllMasterData()
  }, [dataSr])

  const getDataSRField = async () => {
    try {
      const response = await ServiceRequestAPI.GetSrField(
        dataSr.Sr_Type_Code,
        dataSr.SR_Field_Header_Code
      )
      await setDataSRField(response.data.message.data)
    } catch (err) {
      console.log('eror', err)
    }
  }
  const FormSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...dataSr
    }
  })

  const getDataWorkPermit = async () => {
    try {
      const response = await ServiceRequestAPI.GetMasterWorkPermit()
      setDataMasterPermit(response.data.data)
    } catch (error) {
      console.log('eror', error)
    }
  }

  const getDataContract = async (params) => {
    try {
      const response = await LintasGeneralAPI.GetDataContract(
        params.Client_Code
      )
      if (response?.data?.message?.Contracts) {
        setDataContract(response?.data?.message.Contracts ?? [])
      }
    } catch (error) {
      return error
    }
  }

  const getAllMasterData = async () => {
    try {
      const poList = await MasterDataAPI.GetContractTitle()
      const recipient = await MasterDataAPI.GetMasterRecipient()
      const response = await ServiceRequestAPI.GetAllMasterData()
      setMasterData(response.data.message.data[0])
      setContractTitle(poList.data.message.POList ?? [])
      setDataRecipient(recipient.data.message.data ?? [])
    } catch (error) {
      console.log('eror', error)
    }
  }

  const handleCloseModal = () => {
    setModalPermitCCU({ modal_type: '', status: false })
  }

  const checklistCell = ({ dataItem }) => {
    let indexFind
    dataSr.WorkPermits.find((event) =>
      event.Code === dataItem.Code && event.Is_Active
        ? (indexFind = true)
        : (indexFind = false)
    )
    return (
      <td style={{ textAlign: 'center' }}>
        <FormControlLabel
          style={{ marginRight: '0px' }}
          disabled={true}
          control={
            <CustomCheckbox id='m_grid_checklist-btn' checked={indexFind} />
          }
        />
      </td>
    )
  }

  const _planStartEndCell = ({ dataItem }, field) => {
    return (
      <td>
        {ConvertLocalTimeGet(
          dataSr.Location,
          dataItem[field],
          'DD MMM YYYY HH:mm'
        )}
      </td>
    )
  }

  const _formattingDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
      ? ''
      : moment(
          ConvertLocalTimeGet(dataSr.Location, date, 'YYYY/MM/DD HH:mm')
        ).format('DD/MM/YYYY HH:mm')
  }

  const _TopForm = (left) => {
    return (
      <Form padding='0px 30px 0px 30px'>
        {left && (
          <Input
            id='sr-sr_mdl-sr_no-txt'
            name='Sr_Type'
            label='Service Request Type'
            InputProps={{ readOnly: true }}
            disabled={true}
            form={FormSubmit}
          />
        )}
        {!left && (
          <Input
            id='sr-sr_mdl-sr_category-txt'
            name='Sr_Category'
            label='Service Request Category'
            InputProps={{ readOnly: true }}
            disabled={true}
            form={FormSubmit}
          />
        )}
      </Form>
    )
  }

  const _POListForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (contractTitle && contractTitle.length > 0) {
      filterMasterData = contractTitle.filter(
        (poList) => poList.code == dataSr.Contract_Title
      )
      if (filterMasterData && filterMasterData.length > 0) {
        convertMasterData = filterMasterData[0].po_no
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='sett-sr_mdl-po_list-txt'
          name='po_list'
          label='PO List'
          InputProps={{ readOnly: true }}
          disabled={true}
          form={FormSubmit}
          value={convertMasterData}
        />
      </Form>
    )
  }

  const _ContractNumberForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (dataContract && dataContract.length > 0) {
      filterMasterData = dataContract.filter(
        (contract) =>
          contract.AgreementNo == dataSr.Agreement_No &&
          contract.SapContractNo == dataSr.Contract_No
      )
      if (filterMasterData && filterMasterData.length > 0) {
        convertMasterData = filterMasterData[0].SAP_agrement_no
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='sr-sr_mdl-contract_no-txt'
          name='Contract_No'
          label='Contract Number'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={convertMasterData}
          form={FormSubmit}
        />
      </Form>
    )
  }

  const _ContractTitleForm = () => {
    let convertMasterData = ''
    let filterMasterData = []
    if (dataContract && dataContract.length > 0) {
      filterMasterData = dataContract.filter(
        (contract) =>
          contract.AgreementNo == dataSr.Agreement_No &&
          contract.SapContractNo == dataSr.Contract_No
      )
      if (filterMasterData && filterMasterData.length > 0) {
        convertMasterData = filterMasterData[0].ContractTitle
      }
    }
    return (
      <Form padding='0px 30px 0px 30px'>
        <Input
          id='sr-sr_mdl-contract_title-txt'
          name='contract_title'
          label='Contract Title'
          InputProps={{ readOnly: true }}
          disabled={true}
          value={convertMasterData}
          form={FormSubmit}
        />
      </Form>
    )
  }

  const _BottomForm = (position = 'left') => {
    let split = position === 'left' ? 1 : 0
    let listMaster = [
      'cutting_mixing_type_code',
      'plb_type_code',
      'vessel_clearance_code',
      'recipient_code',
      'destination_code',
      'origin_code'
    ]
    let htmlGenerate = (dataSRField || [])
      .filter((item) => {
        if (
          FormSubmit.values.Plb_Goods === false &&
          (item.Data_Field === 'plb_type_code' ||
            item.Data_Field === 'no_aju' ||
            item.Data_Field === 'no_pen')
        ) {
          return false
        }

        return true
      })
      .map((e, i) => {
        let recipientAddress = ''
        let convertMasterData = ''
        let filterMasterData = []
        let key = KeySR[e.Data_Field]

        if (e.Data_Field === 'cutting_mixing_type_code') {
          filterMasterData = masterData?.cutting_mixing_type?.filter(
            (e) => e.code === FormSubmit.values[key]
          )
          if (filterMasterData && filterMasterData.length > 0) {
            convertMasterData = filterMasterData[0].value
          }
        }
        if (e.Data_Field === 'plb_type_code') {
          filterMasterData = masterData?.plb_type?.filter(
            (e) => e.code === FormSubmit.values[key]
          )
          if (filterMasterData && filterMasterData.length > 0) {
            convertMasterData = filterMasterData[0].description
          }
        }

        if (e.Data_Field === 'vessel_clearance_code') {
          filterMasterData = masterData?.vessel_clearance?.filter(
            (e) => e.code === FormSubmit.values[key]
          )
          if (filterMasterData && filterMasterData.length > 0) {
            convertMasterData = filterMasterData[0].value
          }
        }

        if (e.Data_Field === 'recipient_code') {
          filterMasterData = dataRecipient?.filter(
            (e) => e.code === FormSubmit.values[key]
          )
          if (filterMasterData && filterMasterData.length > 0) {
            convertMasterData = filterMasterData[0].name
            recipientAddress =
              filterMasterData[0].address.length > 60
                ? filterMasterData[0].address.substring(0, 60) + '...'
                : filterMasterData[0].address
          }
        }

        if (
          e.Data_Field === 'destination_code' ||
          e.Data_Field === 'origin_code'
        ) {
          filterMasterData = masterData?.local_point?.filter(
            (e) => e.code === FormSubmit.values[key]
          )
          if (filterMasterData && filterMasterData.length > 0) {
            convertMasterData = filterMasterData[0].description
          }
        }
        if ((i + 1) % 2 === split) {
          if (listMaster.includes(e.Data_Field)) {
            return (
              <>
                <Input
                  id={`sr-sr_mdl-${key}-txt`}
                  name={key}
                  label={e.Data_Desc}
                  InputProps={{ readOnly: true }}
                  disabled={true}
                  form={FormSubmit}
                  key={i}
                  value={convertMasterData}
                />
                {e.Data_Field === 'recipient_code' && (
                  <div
                    style={{
                      top: -19,
                      fontSize: '12px',
                      color: '#777777',
                      position: 'relative'
                    }}
                  >
                    {recipientAddress}
                  </div>
                )}
              </>
            )
          }
          if (e.Data_Field === 'planning_duration_time') {
            return (
              <Input
                id={`sr-sr_mdl-${key}-txt`}
                name={key}
                label={e.Data_Desc}
                InputProps={{ readOnly: true }}
                disabled={true}
                form={FormSubmit}
                key={i}
                value={durationToFormattedString(FormSubmit.values[key])}
              />
            )
          }
          if (e.Data_Type === 'text') {
            return (
              <Input
                id={`sr-sr_mdl-${key}-txt`}
                name={key}
                label={e.Data_Desc}
                InputProps={{ readOnly: true }}
                disabled={true}
                form={FormSubmit}
                key={i}
              />
            )
          }

          if (e.Data_Field === 'jetty_position') {
            return (
              <Input
                id={`sr-sr_mdl-${key}-txt`}
                name={key}
                label={e.Data_Desc}
                InputProps={{ readOnly: true }}
                disabled={true}
                form={FormSubmit}
                type='number'
                key={i}
              />
            )
          }

          if (e.Data_Type === 'calendar') {
            return (
              <Input
                id={`sr-sr_mdl-${key}-txt`}
                name={key}
                label={e.Data_Desc}
                InputProps={{ readOnly: true }}
                disabled={true}
                form={FormSubmit}
                key={i}
                value={ConvertLocalTimeGet(
                  dataSr.Location,
                  dataSr[key],
                  'DD MMM YYYY HH:mm'
                )}
              />
            )
          }

          if (e.Data_Type === 'boolean') {
            return (
              <div style={{ height: '67px' }} key={i}>
                <Checkbox
                  readOnly={true}
                  form={FormSubmit}
                  name={key}
                  title={e.Data_Desc}
                />
              </div>
            )
          }

          if (e.Data_Type === 'form_detail') {
            return (
              <div style={{ height: '67px' }} key={i}>
                <ButtonDetail
                  title={e.Data_Desc}
                  isCCU={true}
                  onClick={
                    e.Data_Field === 'ccu_detail'
                      ? () => {
                          setModalPermitCCU({ type: 'ccu', status: true })
                        }
                      : e.Data_Field === 'work_permit_detail'
                      ? () => {
                          setModalPermitCCU({ type: 'permit', status: true })
                        }
                      : null
                  }
                >
                  {e.Data_Desc}
                </ButtonDetail>
                {e.Data_Field === 'ccu_detail' && (
                  <div
                    style={{
                      fontSize: '10px',
                      color: '#777777',
                      marginTop: '2px',
                      marginBottom: '5px'
                    }}
                  >
                    {dataSr.CCUS && dataSr.CCUS.length !== 0
                      ? `You have added ${dataSr.CCUS.length} CCU`
                      : 'You haven\'t added CCU'}
                  </div>
                )}
                {e.Data_Field === 'work_permit_detail' && (
                  <div
                    style={{
                      fontSize: '10px',
                      color: '#777777',
                      marginTop: '2px',
                      marginBottom: '5px'
                    }}
                  >
                    {dataSr.WorkPermits && dataSr.WorkPermits.length !== 0
                      ? `You have added ${dataSr.WorkPermits.length} Work Permit`
                      : 'You haven\'t added Work Permit'}
                  </div>
                )}
              </div>
            )
          }

          if (e.Data_Type === 'valuelist') {
            return (
              <>
                <Input
                  id={`sr-sr_mdl-${key}-txt`}
                  name={key}
                  label={e.Data_Desc}
                  InputProps={{ readOnly: true }}
                  disabled={true}
                  form={FormSubmit}
                  key={i}
                />
                {e.Data_Field === 'recipient_code' && (
                  <div
                    style={{
                      top: -19,
                      fontSize: '12px',
                      color: '#777777',
                      position: 'relative'
                    }}
                  >
                    {recipientAddress}
                  </div>
                )}
              </>
            )
          }
        }
      })
    return htmlGenerate
  }

  let padding = '0px 30px 0px 30px'
  return (
    <FormContainer>
      <LeftFormContainer>
        <Container>
          <Row>
            {_TopForm(true)}
            {_TopForm(false)}
          </Row>
          <Row>
            {_ContractNumberForm()}
            {_ContractTitleForm()}
          </Row>
          <div>
            {_POListForm()}
            <BorderLine />
          </div>
          <Row>
            <Form padding={padding}>{_BottomForm('left')}</Form>
            <Form padding={padding}>{_BottomForm('right')}</Form>
          </Row>
        </Container>
      </LeftFormContainer>
      <RightFormContainer>
        <Form>
          <>
            <ContainerTop align='baseline'>
              <TextTop1>Created By</TextTop1>
              <TextTop2>{dataSr?.Created_By.split('(')[0] || ''}</TextTop2>
            </ContainerTop>
            <ContainerTop align='center'>
              <TopIcon src={Images.company} />
              <TextTop3>
                {!_.isUndefined(dataSr?.Created_By.split('(')[1])
                  ? dataSr?.Created_By.split('(')[1].split(')')[0]
                  : '-'}
              </TextTop3>
              <TopIcon src={Images.time} />
              <TextTop3>
                {_formattingDate(dataSr.Created_Date_Non_Formatted)}
              </TextTop3>
            </ContainerTop>
            <WrapperValue>
              <TitleValue>Estimation Date</TitleValue>
              <SVGIcon src={Images.schedule} />
            </WrapperValue>
            <WrapperValue>
              <TitleBerthing>
                {_formattingDate(dataSr.Eta_Non_Formatted)}
              </TitleBerthing>
              <Text regular={true}>to</Text>
              <TitleBerthing>
                {_formattingDate(dataSr.Etd_Non_Formatted)}
              </TitleBerthing>
            </WrapperValue>
          </>
          <Input
            id='sr-sr_mdl-remarks-txt'
            multiline
            rowsMax={3}
            label='Request Description'
            variant='outlined'
            name='Remarks'
            InputProps={{ readOnly: true }}
            value={dataSr?.Remarks}
            inputProps={{
              tabIndex: 11
            }}
          />
          <Input
            id='sr-sr_mdl-client_name-txt'
            name='Client_Name'
            label='Client Name'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={dataSr?.Client}
          />
          <Input
            id='sr-sr_mdl-location-txt'
            name='Location'
            label='Location'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={dataSr?.Location}
          />
          <Input
            id='sr-sr_mdl-sr_number-txt'
            name='SR_Number'
            label='SR Number'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={dataSr?.Sr_No}
          />
          <Input
            id='sr-sr_mdl-priority-txt'
            name='Priority'
            label='Priority'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={dataSr?.Priority_Desc}
          />
        </Form>
      </RightFormContainer>
      {modalPermitCCU.status && (
        <ModalWorkPermitCCU
          dataSr={dataSr}
          handleClose={handleCloseModal}
          modalPermitCCU={modalPermitCCU}
          DocGrid={modalPermitCCU.type === 'ccu' ? DocGridCCU : DocGridPermit}
          data={modalPermitCCU.type === 'ccu' ? dataSr.CCUS : dataMasterPermit}
          title={`${
            modalPermitCCU.type === 'ccu' ? 'CCU Detail' : 'Work Permit'
          }`}
          checklistCell={checklistCell}
          planStartEndCell={_planStartEndCell}
        />
      )}
    </FormContainer>
  )
}

export default SrTabContent
