import styled from 'styled-components'

import {
  Colors,
  // FontSize
} from '../../styles'

export const PrintPreviewHeader = styled.div`
  height: 60px;
  background: ${Colors.softBlue};
  display: flex;
  align-items: center;
`
export const DivTitle = styled.div`
  width: auto;
  display: flex;
  min-width: 200px;
  margin-left: 30px;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.label`
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
  color: ${Colors.pureWhite};
  font-family: 'Open Sans', sans-serif;
`

export const Scroll = styled.div`
  width: 100vw;
  overflow: auto;
`
export const Content = styled.div`
  width: 5750px;
  height: 90vh;
`

export const Header = styled.div`
  height: 110px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 5px solid ${Colors.softBlue};
  align-items: center;
`
export const LeftHeader = styled.div`
  display: flex;
  margin: 20px 0px 0px 20px;
  font-size: 28px;
  & .left {
    border: 2px solid ${Colors.softBlue};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    min-width: 220px;
  }
  & .right {
    border: 2px solid ${Colors.softBlue};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    min-width: 220px;
  }
`

export const ImageWrapper = styled.div`
  margin: 20px 20px 0px 0px;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
`

export const FlexLegend = styled.div`
  display: flex;
  min-width: 100px;
  padding: 0px 10px;
  align-items: center;
`

export const Iconlegend = styled.div`
  width: 15px;
  height: 15px;
  background: ${(p) =>
  p.type === 'postPone'
    ? Colors.red
    : p.type === 'planned'
      ? Colors.darkGreen
      : p.type === 'allocated'
        ? Colors.purple
        : p.type === 'inProgress'
          ? Colors.orange
          : Colors.aliceBlue};
  border-radius: 4px;
`
export const Titlelegend = styled.div`
  margin: 0px 5px;
  font-size: 28px;
`
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  font-size: 28px;
`

export const ResourceWrapper = styled.div`
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.pureWhite};
  font-weight: 600;
  font-size: 28px;
`
export const HeaderWrapper = styled.div`
  height: ${(p) => (p.isMonth ? 90 : 60)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.pureWhite};
  font-size: 28px;
`

export const FooterTitle = styled.div`
  font-size: 34px;
  color: ${Colors.softBlue};
`
export const WrapperAppointment = styled.div`
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  height: 100%;
  padding: 2px 5px 0px;
  line-height: 36px;
  background: white;
  border: 3px solid black;
  /* background: ${(p) =>
  p.type === 'Postpone'
    ? Colors.red
    : p.type === 'Planned'
      ? Colors.darkGreen
      : p.type === 'Allocated'
        ? Colors.purple
        : p.type === 'In Progress'
          ? Colors.orange
          : Colors.aliceBlue}; */
`
export const WrapperScheduler = styled.div`
  /* & div.e-schedule.e-lib.e-keyboard.e-control.e-tooltip {
    & div.e-table-container {
      & div {
        & table {
          & tbody {
            & tr {
              & td {
                & div.e-content-wrap {
                  & table.e-schedule-table.e-content-table.e-auto-height {
                    & tbody {
                      & tr {
                        height: 200px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } */
  position: relative;

  .e-work-cells.e-selected-cell,
  td.e-work-cells {
    background: white !important;
  }
  td.e-header-cells {
    border-right: 7px solid white !important;
    border-left: 7px solid white !important;
  }
  td.e-resource-cells.e-child-node {
    border-top: 7px solid white !important;
    border-bottom: 7px solid white !important;
  }
`
export const DivAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 92px;
  width: 200px;
  align-items: center;
  font-size: 18px;
`

export const InfoWrapper = styled.div`
  font-size: 19.5px;
  height: 35px;
  width: 277px;
  word-spacing: -3px;
  color: black;
`
