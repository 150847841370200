import styled from 'styled-components'

import { Colors } from '../../styles'

export const Wrapper = styled.div`
  height: 10%;
  display: flex;
  padding-top: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${Colors.softBlue};
`

export const Logo = styled.img`
  width: 10%;
  height: auto;
  margin: 20px;
`
