import moment from 'moment'
import getConfig from 'next/config'
import { isNull, sortBy } from 'lodash'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'

import GridColumn from './column.json'
import { ExecutionAPI, ServiceRequestAPI } from '../../api'
import { GenerateFilterTerm } from '../../utils'
import DetailModelProgress from './service-request.modal.detail.progress.container'
import ServiceRequestModal from './service-request.modal.container'
import {
  Card,
  Alerts,
  ServiceRequestHeader,
  MasterGrid,
  CodeCell,
  ColumnOption,
  Loading
} from '../../components'

import { Colors } from '../../styles'
import { WrapperContent } from '../../styles/global.styled'
import { ConvertLocalTimeGet } from '../../utils'
import {
  AlertLabel,
  ActionContainer,
  StatusCell,
  Circular,
  LoadingContainer
} from './service-request.styled'
import { SET_SERVICE_REQUEST_DETAIL } from '../../redux/actions'

const ServiceRequest = () => {
  const router = useRouter()
  const param = router.query
  const dispatch = useDispatch()
  const { publicRuntimeConfig } = getConfig()
  const RUN_OUTSCOPE_FUNCTION =
    publicRuntimeConfig.RUN_OUTSCOPE_FUNCTION === 'true'

  const appSize = useSelector((state) => state.appReducer)
  const { roleMapping } = useSelector((state) => state.usersLoginReducer)
  const { Email: UserEmail } = useSelector(
    (state) => state.usersLoginReducer.User
  )

  const dataUserProfile = useSelector((state) => state.usersProfileReducer)
  const [activeColumns, setActiveColumns] = useState([])
  const [inactiveColumns, setInactiveColumns] = useState([])
  const [showColumnOption, setShowColumnOption] = useState(false)
  const [openDetailProgress, setOpenDetailProgress] = useState(false)
  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false)

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    filter: [],
    filterString: '[]'
  })
  const [dataGrid, setDataGrid] = useState([])
  const [remarkRevise, setRemarkRevise] = useState()
  const [gridColumnActive, setGridColumnActive] = useState([])
  const [gridColumnLoading, setGridColumnLoading] = useState(true)
  const [dataCount, setDataCount] = useState(0)
  const [progressItem, setProgressItem] = useState()
  const [enableExternalTracking, setEnableExternalTracking] = useState(false)
  const [pending, setPending] = useState(false)

  const [dataItem, setDataItem] = useState(null)
  const [detail, setDetail] = useState(false)
  const [progress, setProgreess] = useState(false)
  const [approved, setApproved] = useState(false)
  const [waitApproved, setWaitApproved] = useState(false)
  const [revise, setRevise] = useState(false)
  const [isFromQueryParam, setIsFromQueryParam] = useState(false)
  const [edited, setEdited] = useState(false)
  const [alert, setAlert] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const isInternal = localStorage.getItem('userType')
    ? localStorage.getItem('userType').toLowerCase() === 'internal'
    : true

  const pathRole = roleMapping.find((e) => e.path === router.pathname)

  const onDataStateChange = ({ data }) => {
    GenerateFilterTerm(data.filter, GridColumn)
    setDataState({
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn)
    })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchData({
        ...dataState,
        filterString: GenerateFilterTerm(dataState.filter, GridColumn)
      })
      setFirstLoad(false)
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(delay)
  }, [dataState.filter])

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take
    })

    fetchData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString
    })
  }
  const heightGrid = appSize.height - 190 > 455 ? appSize.height - 190 : 455

  const getDetailProgress = async (dataItem) => {
    setDataItem(dataItem)
    try {
      const dataProgress = await ServiceRequestAPI.GetHistoryProgress(
        dataItem.Sr_No
      )
      setProgressItem(dataProgress)
    } catch (error) {
      console.log(error)
    }
    setProgreess(!progress)
    setOpenDetailProgress(!openDetailProgress)
  }
  /*
  + VALUE_LIST
  untuk user type internal :
    location, based on user location
    client, all
    agent, all
  untuk user type Client:
    location, based on user location
    client, based on client login
    agent, all
  untuk user type agent :
    location, based on user location
    client, all
    agent, based on agent login
    // */

  const _calculateBerthDurMask = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : ''
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : ''
    return hour || minute ? `${hour} ${minute}` : '0'
  }

  const fetchData = async ({ skip, take, filterString }) => {
    try {
      setPending(true)
      const { data } = await ServiceRequestAPI.GetAllDataServiceRequest(
        skip,
        take,
        filterString
      )

      const { count: dataCount } =
        await ServiceRequestAPI.GetAllDataServiceRequestCount(
          skip,
          take,
          filterString
        )

      const response = await ServiceRequestAPI.GetColumnOption()
      if (response.status === 200) {
        const dataColumns = response.data.data
        const active = []
        const inactive = []
        let column = {}

        dataColumns.forEach((item) => {
          column = {
            id: item.Id,
            code: item.Code,
            columnName:
              item.Column_Name == 'contract_no'
                ? 'agreement_no'
                : item.Column_Name,
            columnDesc: item.Column_Description,
            sortNo: item.Sort_No,
            isActive: item.Is_Active
          }
          if (column.isActive) active.push(column)
          else inactive.push(column)
        })

        const gridSelected = GridColumn.filter((element) => {
          const activeElement = active.find(
            (ele) =>
              ele.columnName.toLowerCase() === element.field.toLowerCase()
          )
          if (element.field.toLowerCase() === 'action' || activeElement) {
            if (activeElement) {
              element['sortNo'] = activeElement.sortNo
              element['columnName'] = activeElement.columnName
              element['columnDesc'] = activeElement.columnDesc
              element['code'] = activeElement.code
              element['id'] = activeElement.id
            } else {
              element['sortNo'] = -1
            }

            return element
          } else return false
        })
        const sorted = [...gridSelected.filter((e) => e !== false)].sort(
          (a, b) => a.sortNo - b.sortNo
        )
        setActiveColumns(
          sorted.filter((e) => e.field.toLowerCase() !== 'action')
        )

        const sortedExcludeInbounds = sorted.filter(
          (e) =>
            e.field.toLowerCase() !== 'inbound_msr' &&
            e.field.toLowerCase() !== 'inbound_wo'
        )
        setGridColumnActive(isInternal ? sorted : sortedExcludeInbounds)
        setGridColumnLoading(false)

        const inactiveExcludeInbounds = inactive.filter(
          (item) =>
            item.columnName.toLowerCase() !== 'inbound_msr' &&
            item.columnName.toLowerCase() !== 'inbound_wo'
        )
        setInactiveColumns(isInternal ? inactive : inactiveExcludeInbounds)

        setDataGrid(
          isNull(data)
            ? []
            : data.map((e) => {
                ;(e['Etd_Non_Formatted'] = e.Etd),
                  (e['Eta_Non_Formatted'] = e.Eta),
                  (e['Created_Date_Non_Formatted'] = e['Created_Date'])
                e['Created_Date'] =
                  moment(e.Created_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Created_Date,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Atd'] =
                  moment(e.Atd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Atd, 'DD/MM/YYYY HH:mm')
                e['Ata'] =
                  moment(e.Ata).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm')
                e['Etd'] =
                  moment(e.Etd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Etd, 'DD/MM/YYYY HH:mm')
                e['Eta'] =
                  moment(e.Eta).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Eta, 'DD/MM/YYYY HH:mm')
                e['Modified_Date'] =
                  moment(e.Modified_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Modified_Date,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Estimate_Berth_Dur_Detail'] = _calculateBerthDurMask(
                  e.Estimate_Berth_Dur
                )
                e['Actual_Berth_Dur_Detail'] = _calculateBerthDurMask(
                  e.Actual_Berth_Dur
                )

                return e
              })
        )
        setDataCount(dataCount)
      }
      setPending(false)
    } catch (error) {
      setPending(false)
      console.log(error)
    }
  }

  const _needApproval = (dataByEmail) => {
    if (dataByEmail[0] && dataByEmail[0].User_Id === dataUserProfile.ID) {
      setWaitApproved(
        dataByEmail[0].Sr_Status.toLowerCase() === 'waiting for approval'
      )
    }
    setDetail(!detail)
    setIsFromQueryParam(true)
    setShowServiceRequestModal(!showServiceRequestModal)
  }

  const getDataByEmail = async () => {
    try {
      let { data } = await ServiceRequestAPI.GetSrByEmail(
        param.Sr_No || param.sr_no
      )
      data.map((e) => {
        e['Etd_Non_Formatted'] = e['Etd']
        e['Eta_Non_Formatted'] = e['Eta']
        e['Created_Date_Non_Formatted'] = e['Created_Date']
        e['Created_Date'] = moment(e.Created_Date).format('DD/MM/YYYY HH:mm')
      })
      data[0]['Atd'] = moment(data[0]['Atd']).format('DD/MM/YYYY HH:mm')
      data[0]['Ata'] = moment(data[0]['Ata']).format('DD/MM/YYYY HH:mm')
      setDataItem(data[0])
      _needApproval(data)
    } catch (error) {
      setAlert(true)
      console.log(error)
    }
  }

  const reorderColumns = (event) => {
    let columns = [...event.columns].filter((item) => item.field !== 'action')
    columns = sortBy(columns, ['orderIndex']).map((item1, index) => {
      const found = activeColumns.find(
        (item2) => item2.columnName === item1.field.toLowerCase()
      )
      return { ...found, sortNo: index }
    })
    setActiveColumns(columns)
    try {
      ServiceRequestAPI.UpdateColumnOption(
        columns.map((item) => ({
          Id: item.id,
          Code: item.code,
          Column_Name: item.columnName,
          Column_Description: item.columnDesc,
          Is_Active: true,
          Sort_No: item.sortNo
        }))
      )
    } catch (error) {
      return error
    }
  }

  const _getNotif = async (dataItem) => {
    try {
      const filter = `[{"field":"code_ref","type":"text","value":"${dataItem.Sr_No}"},{"field":"Is_Action_Taken","type":"bool","value":"false"},{"field":"Notification_Type_Text","type":"text","value":"NeedToBeRevised"}]`
      const { data } = await ExecutionAPI.getAllNotification(filter)
      if (data.data && data.data.length) {
        setRemarkRevise(data.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _onClose = () => {
    setAlert(false)
    window.location = '/service-request'
  }

  const _alert = () => {
    return (
      <Alerts
        open={alert}
        close={() => _onClose()}
        msg={`You don't have the privilege to open details ${param.sr_no}`}
      />
    )
  }

  const _getExternalTrackingEnableStatus = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"external_enable_tracking"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllFromTableEnum(
        0,
        100,
        filter
      )
      if (
        data &&
        data[0] &&
        data[0].Value &&
        data[0].Value.toLowerCase() === 'true'
      ) {
        setEnableExternalTracking(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getExternalTrackingEnableStatus()
  }, [])

  useEffect(() => {
    if (param.Sr_No || param.sr_no) {
      getDataByEmail()
    }
  }, [param])

  useEffect(() => {
    if (!firstLoad) {
      fetchData(dataState)
    }
  }, [showServiceRequestModal])

  const _statusCell = ({ colSpan, dataItem }) => (
    <td>
      <ActionContainer
        id={`sr_status_${dataItem.Sr_No}`}
        onClick={() => {
          RUN_OUTSCOPE_FUNCTION &&
            (isInternal || enableExternalTracking) &&
            getDetailProgress(dataItem)
        }}
        colSpan={colSpan}
      >
        {dataItem.Sr_Status.length > 0 ? (
          <StatusCell
            cursor={
              RUN_OUTSCOPE_FUNCTION && (isInternal || enableExternalTracking)
                ? 'pointer'
                : ''
            }
            backgroundColor={
              dataItem.Sr_Status.toLowerCase() === 'approved'
                ? '#136500'
                : dataItem.Sr_Status.toLowerCase() === 'in progress'
                ? '#6DB200'
                : dataItem.Sr_Status.toLowerCase() === 'completed'
                ? Colors.aliceBlue
                : dataItem.Sr_Status.toLowerCase() === 'rejected'
                ? Colors.red
                : ''
            }
            color={
              dataItem.Sr_Status.toLowerCase() === 'approved' ||
              dataItem.Sr_Status.toLowerCase() === 'rejected' ||
              dataItem.Sr_Status.toLowerCase() === 'in progress' ||
              dataItem.Sr_Status.toLowerCase() === 'completed'
                ? Colors.white
                : Colors.black
            }
          >
            {dataItem.Sr_Status}
          </StatusCell>
        ) : (
          <div />
        )}
      </ActionContainer>
    </td>
  )
  const _actionCell = ({ colSpan, dataItem }) => {
    const deletable =
      dataItem.Sr_Status && dataItem.Sr_Status.toLowerCase() === 'draft'
    const editable =
      dataItem.Created_By_Email === UserEmail &&
      dataItem.Sr_Status &&
      (dataItem.Sr_Status.toLowerCase() === 'draft' ||
        (dataItem.Sr_Status.toLowerCase() === 'waiting for approval' &&
          dataItem.Created_By_ID === dataUserProfile.ID) ||
        (dataItem.Sr_Status.toLowerCase() === 'need to be revised' &&
          dataItem.Created_By_ID === dataUserProfile.ID) ||
        dataItem.Sr_Status.toLowerCase() === 'approved')
    return (
      <td
        style={{ position: 'sticky', left: '0px' }}
        className='k-grid-content-sticky'
        id='m_clnt_action_btn'
        colSpan={colSpan}
      >
        <ActionContainer>
          {pathRole.allowEdit && (
            <div
              id='m-edit-btn'
              style={{ cursor: editable ? 'pointer' : 'not-allowed' }}
              onClick={() => {
                if (dataItem.Sr_Status.toLowerCase() === 'need to be revised') {
                  _getNotif(dataItem)
                }
                if (editable) {
                  setDataItem(dataItem)
                  setApproved(dataItem.Sr_Status.toLowerCase() === 'approved')
                  setRevise(
                    dataItem.Sr_Status.toLowerCase() === 'need to be revised'
                  )
                  setWaitApproved(
                    dataItem.Sr_Status.toLowerCase() === 'waiting for approval'
                  )
                  setShowServiceRequestModal(!showServiceRequestModal)
                  setEdited(!edited)
                }
              }}
            >
              <CreateIcon
                htmlColor={editable ? Colors.green : Colors.black}
                style={{ opacity: editable ? 1 : 0.25 }}
              />
            </div>
          )}
          {pathRole.allowDelete && (
            <div
              id='m-delete-btn'
              style={{ cursor: deletable ? 'pointer' : 'not-allowed' }}
              onClick={() => {
                if (deletable) {
                  setDataItem(dataItem)
                  setDeleted(!deleted)
                  setShowServiceRequestModal(!showServiceRequestModal)
                }
              }}
            >
              <DeleteIcon
                style={{ opacity: deletable ? 1 : 0.25 }}
                disabled
                htmlColor={Colors.black}
              />
            </div>
          )}
        </ActionContainer>
      </td>
    )
  }

  const _codeCell = ({ dataItem }) => {
    let isToday = dataItem.Created_Date
      ? dataItem.Created_Date.slice(0, 10) ===
        moment(new Date()).format('DD/MM/YYYY')
      : false
    // ADD VALIDATION BASED ON SR STATUS
    isToday =
      isToday &&
      (dataItem.Sr_Status.toLowerCase() === 'draft' ||
        dataItem.Sr_Status.toLowerCase() === 'waiting for approval')
    return (
      <CodeCell
        style={{ cursor: pathRole.allowView ? 'pointer' : 'default' }}
        disableTooltip={!pathRole.allowView}
        id={`sr_code_${dataItem.Sr_No}`}
        onClick={() => {
          if (dataItem.Sr_Status.toLowerCase() === 'need to be revised') {
            _getNotif(dataItem)
          }
          if (pathRole.allowView) {
            setDataItem(dataItem)
            setDetail(!detail)
            if (dataItem.User_Id === dataUserProfile.ID) {
              setWaitApproved(
                dataItem.Sr_Status.toLowerCase() === 'waiting for approval'
              )
            }
            // setShowServiceRequestModal(!showServiceRequestModal)
            dispatch({
              type: SET_SERVICE_REQUEST_DETAIL,
              payload: {
                ...dataItem,
                CCUS: [],
                Cargos: [],
                Activity: [],
                WorkPermits: [],
                Estimations: [],
                Cargo_ManPower: [],
                Cargo_Equipment: []
              }
            })
            router.push('/service-request-detail')
          }
        }}
        isToday={isToday}
      >
        {dataItem.Sr_No}
      </CodeCell>
    )
  }

  const _percentCell = ({ dataItem, field, dataIndex }) => {
    const percentage = Math.floor(dataItem.Total_Percentage)

    return (
      <td id={`m_grid_cell_${field}_${dataIndex}`}>
        <div>
          <div style={{ margin: '5px 60px' }}>
            <Circular text={`${percentage}%`} value={percentage} />
          </div>
        </div>
      </td>
    )
  }

  const _labelCell = ({ field, dataIndex }) => {
    return (
      <td id={`m_grid_cell_${field}_${dataIndex}`}>
        <AlertLabel icon={false} severity='success'>
          New
        </AlertLabel>
      </td>
    )
  }

  const _grid = () =>
    !gridColumnLoading ? (
      <MasterGrid
        id='m_sr_grid'
        actionLocked
        height={heightGrid}
        data={dataGrid}
        total={dataCount}
        codeCell={_codeCell}
        percentCell={_percentCell}
        dataState={dataState}
        gridColumn={
          !pathRole.allowEdit && !pathRole.allowDelete
            ? gridColumnActive.filter((e) => e.field.toLowerCase() !== 'action')
            : gridColumnActive
        }
        actionCell={_actionCell}
        customCell={_statusCell}
        labelCell={_labelCell}
        pageSizes={[20, 50, 100]}
        onPageChange={onPageChange}
        onDataStateChange={onDataStateChange}
        reorderable={true}
        onColumnReorder={reorderColumns}
        pending={pending}
      />
    ) : (
      <LoadingContainer style={{ height: '75vh', alignItems: 'center' }}>
        <Loading id='sr-grid-loading-indicator' />
      </LoadingContainer>
    )

  const _closeServiceRequestModal = (isFromApprovalQuery) => {
    setDetail(false)
    setEdited(false)
    setRevise(false)
    setRemarkRevise()
    setDeleted(false)
    setDataItem(null)
    setApproved(false)
    setProgreess(false)
    setWaitApproved(false)
    setIsFromQueryParam(false)
    setShowServiceRequestModal(false)
    if (isFromApprovalQuery) {
      window.location = '/service-request'
    }
  }

  const _closeDetailProgressModal = () => {
    setDetail(false)
    setEdited(false)
    setDeleted(false)
    setDataItem(null)
    setApproved(false)
    setProgreess(false)
    setWaitApproved(false)
    setOpenDetailProgress(false)
  }

  const _header = () => {
    return (
      <ServiceRequestHeader
        allowCreate={false}
        title='SERVICE REQUEST'
        buttonAddTitle='Add New'
        buttonColumnTitle='Column Option'
        onClickAdd={() => setShowServiceRequestModal(!showServiceRequestModal)}
        onClickColumn={() => setShowColumnOption(!showColumnOption)}
      />
    )
  }

  return (
    <WrapperContent>
      <Card>
        {_header()}
        {_grid()}
      </Card>
      {showColumnOption && (
        <ColumnOption
          dataState={dataState}
          fetchData={fetchData}
          parentActiveColumns={activeColumns}
          parentInactiveColumns={inactiveColumns}
          setShowColumnOption={setShowColumnOption}
          setGridColumnLoading={setGridColumnLoading}
          updateApi={ServiceRequestAPI.UpdateColumnOption}
        />
      )}
      {showServiceRequestModal && (
        <ServiceRequestModal
          data={dataItem}
          isEdit={edited}
          pathRole={pathRole}
          isApproved={approved}
          isWaitApproved={waitApproved}
          isRevise={revise}
          isFromQueryParam={isFromQueryParam}
          isDelete={deleted}
          isDetail={detail}
          isProgress={progress}
          remarkRevise={remarkRevise}
          showModal={showServiceRequestModal}
          closeSrModal={_closeServiceRequestModal}
          fetchData={() => fetchData(dataState)}
        />
      )}
      {openDetailProgress && (
        <DetailModelProgress
          data={dataItem}
          isEdit={edited}
          isDelete={deleted}
          isDetail={detail}
          isApproved={approved}
          isProgress={progress}
          isWaitApproved={waitApproved}
          progressItem={progressItem}
          showModal={openDetailProgress}
          fetchData={() => fetchData(dataState)}
          closeSrModal={_closeDetailProgressModal}
        />
      )}
      {_alert()}
    </WrapperContent>
  )
}

export default ServiceRequest
