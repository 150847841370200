import { useState } from 'react'

import { Alerts, ModalDel } from '../../../components'

import Funcs from './functions.delay'
import gridColumns from './columns.json'
import FC from './factory-components.delay'


const DelayModal = ({ data, inEditMode }) => {
  const { Location: location, Code: srCode } = data

  const [delay, setDelay] = useState({})
  const [isInEdit, setIsInEdit] = useState(false)
  const [toggleDelete, setToggleDelete] = useState(false)
  const filteredColumns = gridColumns.filter(col => col.field.toLowerCase() !== 'action')
  const form = Funcs.createForm()
  const activityList = Funcs.useFetchActivityListBySRCode(srCode)
  const [delayList, setDelayList] = Funcs.useConcatAllDelayList(activityList)
  const taskList = Funcs.useFindTaskList(activityList, form.values.activity_code)
  const [showAlert, setShowAlert] = useState({ visible: false, msg: '', success: false, type: '' })

  const delayTypeOptions = Funcs.useFetchDelayTypeOptions()
  const delayDescOptions = Funcs.useFetchDelayDescOptions(form.values.delay_type_code)

  const handleToggleCancel = Funcs.handleToggleCancelHOF(form, setIsInEdit, delayList, setDelayList)
  const handleToggleEdit = Funcs.handleToggleEditHOF(form, setIsInEdit, location, delayList, setDelayList)
  const handleDeleteDelay = Funcs.handleDeleteDelayHOF(delayList, setDelayList,  delay, setShowAlert, setToggleDelete)

  // This HOF function returns a function that will handle insert data and setState (extending delayList)
  const handleInsertOrUpdateDelay = Funcs.handleInsertOrUpdateDelayHOF(srCode, isInEdit, form, setDelayList, location, setIsInEdit)

  // Modified useEffect
  Funcs.useCheckChangeOfDuration(form)

  const handleRemoveModal = () => (
    <div>
      <ModalDel
        id='m_actv_delete_mdl'
        toggleDialog={() => setToggleDelete(!toggleDelete)}
        submitDelete={handleDeleteDelay}
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const deleteData=(item)=>{
    setDelay(item)
    setToggleDelete(!toggleDelete)
  }

  return (
    <div>
      {toggleDelete && handleRemoveModal()}
      <FC.DelayForm
        form={form}
        isInEdit={isInEdit}
        taskList={taskList}
        inEditMode={inEditMode}
        setDelayList={setDelayList}
        activityList={activityList}
        delayTypeOptions={delayTypeOptions}
        delayDescOptions={delayDescOptions}
        handleInsertOrUpdateDelay={handleInsertOrUpdateDelay}
      />
      <FC.DelayGrid
        data={delayList}
        location={location}
        inEditMode={inEditMode}
        handleDeleteDelay={deleteData}
        handleToggleEdit={handleToggleEdit}
        handleToggleCancel={handleToggleCancel}
        gridHeight={inEditMode ? '330px' : '380px'}
        columns={inEditMode ? gridColumns: filteredColumns}
      />
      <Alerts
        msg={showAlert.msg}
        type={showAlert.type}
        open={showAlert.visible}
        success={showAlert.success}
        close={() => {
          setShowAlert({ ...showAlert, visible: false })
        }}
      />
    </div>
  )
}

export default DelayModal