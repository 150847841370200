import { ReportAPI } from '../../api'

export default [
  { title: 'Start Period', name: 'Start_Period', func: null, selected: '' },
  { title: 'End Period', name: 'End_Period', func: null, selected: '' },
  { title: 'Port Location', name: 'Location', func: ReportAPI.GetPortLocations, selected: '' },
  { title: 'Client', name: 'Client', func: ReportAPI.GetClients, selected: '' },
  { title: 'Jetty', name: 'Jetty_Allocation', func: ReportAPI.GetJetty, selected: '' },
  { title: 'Vessel', name: 'Vessel_Name', func: ReportAPI.GetVessels, selected: '' },
  { title: 'Agent', name: 'Agent_Name', func: ReportAPI.GetAgents, selected: '' },
  { title: 'Category', name: 'Category', func: ReportAPI.GetSRCategory, selected: '' },
  { title: 'Status', name: 'Sr_Status', func: ReportAPI.GetSRStatus, selected: '' },
]