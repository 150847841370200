import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import MaterialCloseIcon from '@material-ui/icons/Close'
import MaterialDeleteIcon from '@material-ui/icons/Delete'
import MaterialKeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { Colors } from '~/styles'


const Container = styled.div`
  padding: 10px 20px;
  background: transparent;
`

const TopFormWrapper = styled.div`
  width: 100%;
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
`

const DurationInputContainer = styled.div`
  margin-top: -3px;
`

const DurationLabel = styled.label`
  font-size: 11px;
  margin: 7px 0 0 10px;
  transform-origin: top left;
  color: rgba(0, 0, 0, 0.54);
  transform: translate(0, 1.5px) scale(0.75);
`

const DurationInputWrapper = styled.div`
  display: grid;
  column-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
`

const GridContainer = styled.div`
  height: 650px;
  overflow: auto;
  margin-top: 10px;
  border: 1px solid ${Colors.borderGrey};
`

const HeaderRowGrid = styled.tr`
  & {
    color: white;
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: ${Colors.softBlue};
  }
`

const Grid = styled.table`
  width: 100%;
  height: 250px;
  overflow-y: auto;
  table-layout: auto;
  border-collapse: collapse;

  th, td {
    padding: 8px;
    height: 70px;
    border: 1px solid #ddd;
  }

  & .equipment-icon {
    cursor: pointer;
  }
`
const BodyRowGrid = styled.tr`
  height: 50px;
`

const BodyRowGridDetail = styled.tr`
  background: ${Colors.borderGrey};
`

const ActivityCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 0px !important;
  border-right: 0px !important;
`

const TaskCellWrapper = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 0px !important;
  border-right: 0px !important;
  padding-left: 45px !important;
`

const GreenCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color: green;
  }

  &.MuiCheckbox-root.Mui-checked {
    color: green;
  }
`

const KeyboardArrowDownIcon = styled(MaterialKeyboardArrowDownIcon)`
  cursor: pointer;
  margin-right: 10px;
  color: ${Colors.grey};
  transform: ${p => p.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};}
`

const InnerGrid = styled.table`
  width: 100%;
`

const InnerGridHeader = styled.tr`
  background: #ddd;
  color: ${Colors.black};
`

const SortWrapper = styled.div`
  padding:  7px 16px 7px 16px;
  border-radius:  32px;
  display:flex;
  cursor:pointer;
  justify-content:space-between;
  border: 1px solid #333333;
`
const InnerGridRow = styled.tr`

`

const DeleteIcon = styled(MaterialDeleteIcon)`
  cursor: ${p => p.disable ? 'not-allowed' : 'pointer'};
  color: ${p => p.disable ? Colors.softGrey : Colors.softRed};
`

const InnerGridActionCell = styled.td`
  display: flex;
  justify-content: space-evenly;
`

const EquipmentIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const AddTaskCell = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
`

const AddTaskInput = styled.input`
  height: 30px;
  padding: 10px;
  background: ${Colors.white};
  border: 1px solid ${Colors.borderGrey};
`

const AddTaskButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const AddTaskModalContainer = styled.div`
  width: 1050px;
  height: 700px;
  padding: 20px;
`

const AddTaskModalTop = styled.div`
  display: flex;
  justify-content: space-between;
`

const AddTaskModalTopTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
`

const CloseIcon = styled(MaterialCloseIcon)`
  cursor: pointer;

  &.MuiSvgIcon-root {
    font-size: 1.75rem;
  }
`

const AddTaskGridContainer = styled.div`
  margin-top: 15px;
`

const ButtonAddTaskContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`

const AddItemWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${Colors.softBlue};
`

const CircularProgressWrapper = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptyListCell = styled.div`
  text-align: center;
  color: ${Colors.grey};
`

const THead = styled.thead`
  top: 0;
  z-index: 99;
  position: sticky;
`

const ButtonSort = styled.button`
  min-width: 80px;
  color: #333333;
  border-radius: 32px;
  background-color: #ffff;
  border: 1px solid lightblue;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ModalSort = styled.div`
  position: absolute;
  width: 300px;
  min-height: 100px;
  right: 3%;
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid aliceblue;
  z-index: 1;
`

const SortListWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 5px;
  color: #4a576f;
  align-items: center;
`

const SortTextList = styled.div`
  border: 2px solid #dee0e5;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 400;
`

const ButtonClose = styled.button`
  min-width: 80px;
  color: #A3AAB6;
  border-radius: 5px;
  background-color: #0013340A;
  border: 1px solid lightblue;
  height: 30px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`

const ButtonFilter = styled.button`
  min-width: 80px;
  color: #FFFF;
  border-radius: 5px;
  background-color: #0A81AD;
  border: 1px solid lightblue;
  height: 30px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
`
export default {
  Grid,
  THead,
  Container,
  InnerGrid,
  CloseIcon,
  DeleteIcon,
  BodyRowGrid,
  AddTaskCell,
  SortWrapper,
  AddTaskInput,
  InnerGridRow,
  GridContainer,
  DurationLabel,

  ModalSort,
  ButtonSort,
  ButtonClose,
  SortTextList,
  ButtonFilter,
  SortListWrapper,

  HeaderRowGrid,
  GreenCheckbox,
  EmptyListCell,
  TopFormWrapper,
  AddItemWrapper,
  InnerGridHeader,
  AddTaskModalTop,
  TaskCellWrapper,
  BodyRowGridDetail,
  InnerGridActionCell,
  ActivityCellWrapper,
  AddTaskGridContainer,
  AddTaskModalTopTitle,
  AddTaskButtonWrapper,
  EquipmentIconWrapper,
  DurationInputWrapper,
  AddTaskModalContainer,
  KeyboardArrowDownIcon,
  ButtonAddTaskContainer,
  DurationInputContainer,
  CircularProgressWrapper,
}