import _ from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import { Images } from '../../../assets'
import { ExecutionAPI } from '../../../api'
import LogApi from '../../../api/master-data/log'
import { prefix, setToBase64 } from '../../../utils'
import { Lookup, ConvertLocalTimePost, ConvertLocalTimeGet } from '../../../utils'
import { XlsxIcon, DocxIcon, PdfIcon, CloudIcon, Loading, ModalDel } from '../../../components'

import DocumentGridColumn from './json/column.modal_doc.json'

import {
  TextLink,
  IconDel,
  ActionCell,
  UploadButton,
  DownloadButton,
  LoadingContainer,
  TotalPackageText,
  ModalDocContainer,
  DownloadAllButton,
  ExeModalGridContainer,
  DocumentUploadButtonContainer,
  DocumentDownloadAllButtonContainer,
} from '../execution.styled'
import { setToBase64SR } from '../../../utils/prefixBase64'

const ExeDocModalContent = (props) => {
  const [fileDownload, setFileDownload] = useState({ Filename: '', dataBase64: '' })
  const [attachmentGrid, setAttachmentGrid] = useState([])
  const [attachmentTypeCode, setAttachmentTypeCode] = useState('')
  const [fileSize, setFileSize] = useState(5)
  const [fileType, setFileType] = useState('')
  const [dataCode, setDataCode] = useState('')
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { usersProfileReducer } = useSelector((state) => state)

  const gridHeight = window.innerHeight - 571 > 200 ? window.innerHeight - 571 : 200

  // const [dataItem, setDataItem] = useState([])
  // console.log(dataItem)

  const _getAttachmentTypeCode = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Attachment_Type"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ExecutionAPI.GetAllFromTableEnum(0, 100, filter)
      data.forEach((v) => {
        if (v.Value === 'Execution Attachment') {
          setAttachmentTypeCode(v.Code)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileType = async () => {
    try {
      let parsedData = []
      const filterDocument =
        '[{"field":"type","type":"text","value":"File_Document"},{"field":"is_active","type":"bool","value":"true"}]'
      const documentEnum = await ExecutionAPI.GetAllFromTableEnum(0, 100, filterDocument)
      if (documentEnum.data.length) {
        parsedData.push(documentEnum.data[0].Value)
      }
      const filterImage =
        '[{"field":"type","type":"text","value":"File_Image"},{"field":"is_active","type":"bool","value":"true"}]'
      const imageEnum = await ExecutionAPI.GetAllFromTableEnum(0, 100, filterImage)
      if (imageEnum.data.length) {
        parsedData.push(imageEnum.data[0].Value)
      }
      parsedData = parsedData.join('|')
      parsedData = parsedData.split('|').join(', .')
      setFileType('.' + parsedData)
    } catch (error) {
      console.log(error)
    }
  }

  const _getFileSize = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"File_Size"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ExecutionAPI.GetAllFromTableEnum(0, 100, filter)
      setFileSize(parseInt(data[0].Value))
    } catch (error) {
      console.log(error)
    }
  }

  // const getAttachmentGrid = async () => {
  //   try {
  //     const result  = await ExecutionAPI.getAttachmentGrids(props.dataSr.Code)
  //     setAttachmentGrid(result.data.data)
  //   } catch (err) {
  //     console.log('eror get list product', err)
  //   }
  // }

  const _getAllAttachment = async () => {
    try {
      const filter = `[{"field":"sr_code","type":"text","value":"${props.dataSrCode}"}]`
      const { data } = await ExecutionAPI.getAttachmentGrids(0, 1000, filter)
      const filteredData = data ? data.filter((v) => v.Sr_Code === props.dataSrCode) : []
      setAttachmentGrid(filteredData)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      return error
    }
  }

  useEffect(() => {
    _gettAll()
  }, [])

  const _gettAll = async () => {
    await _getAllAttachment()
    await _getFileSize()
    await _getFileType()
    await _getAttachmentTypeCode()
  }

  const _downloadFileFromServer = async (props) => {
    try {
      let response = null
      if(props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        response = await ExecutionAPI.GetAttachmentFileSR(props.dataItem.Filename)
      } else {
        response = await ExecutionAPI.GetAttachmentFile(props.dataItem.Filename)
      }
      const baseFile64 = response.data
      const prefixBase64 = prefix(props.dataItem.Filename)
      const dataBase64 = prefixBase64 + baseFile64
      setFileDownload({ Filename: props.dataItem.Filename, dataBase64: dataBase64 })
      document.getElementById(`download-${props.dataIndex + 1}`).click()
    } catch (err) {
      console.log(err)
    }
  }

  const _downloadAll = () => {
    for (let i = 0; i < attachmentGrid.length; i++) {
      document.getElementById(`sr-doc_mdl-download-one-btn-${i + 1}`).click()
    }
  }

  const _getFileIcon = (props) => {
    const filename = props.dataItem.Filename
    const extension = filename.split('.')[filename.split('.').length - 1].toLowerCase()

    if (extension === 'doc' || extension === 'docx') {
      return <DocxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'xls' || extension === 'xlsx') {
      return <XlsxIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'pdf') {
      return <PdfIcon dataIndex={props.dataIndex} />
    }
    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
      return <img src={Images.filesIconImg} dataIndex={props.dataIndex} />
    }
  }

  const insertLog = async (type)=>{
    const dataInsert = {
      Timestamp:new Date(),
      Action: Lookup.LOG_ACTION.UPLOAD,
      Module: Lookup.LOG_MODULE.EXECUTION,
      Submodule:Lookup.LOG_SUB_MODULE.DOCUMENT,
      Detail:'File Size cannot Exceed 5 MB',
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From:'',
      To:'',
      Refno: props.dataSrCode
    }

    if (type !== '') {
      const { data } = await LogApi.PostDataLog(dataInsert)
      if (data && data.result)
        return data.result
    }
    else  return false
  }

  const _actionCell = (props) => {
    return (
      <td>
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DownloadButton
              id={`sr-doc_mdl-download-one-btn-${props.dataIndex + 1}`}
              onClick={() => {
                _downloadFileFromServer(props)
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloudIcon style={{ marginRight: 3.4 }} />
              Download
            </DownloadButton>
            <a
              href={fileDownload.dataBase64}
              download={fileDownload.Filename}
              id={`download-${props.dataIndex + 1}`}
              style={{ display: 'none' }}
            >
              download
            </a>
            {props.dataItem && !_.isUndefined(props.dataItem.Attachment_Type) &&
            props.dataItem.Attachment_Type.toLowerCase() == 'execution attachment' &&
            <ActionCell
              id='exct-doc_mdl-action-delete-td'
              onClick={() => {
                setDataCode(props.dataItem.Code)
                setShowModalDelete(true)
              }}
            >
              <IconDel />
            </ActionCell>
            }
          </div>
        </>
      </td>
    )
  }
  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_handleDocumentDelete}
        deleteText='Are you sure to delete the data ?'
        toggleDialog={() => setShowModalDelete(false)}
      />
    </div>
  )

  const _handleDocumentDelete = async () => {
    const response = await ExecutionAPI.deleteAttachmentExctByCode(dataCode)
    if (response.data && response.data.result) {
      props.setAlert({
        msg: 'Delete data has been successful',
        success: true,
        visible: true,
        type: 'delete',
      })
      _getAllAttachment()
    } else {
      props.setAlert({
        msg: 'Cannot delete data',
        success: false,
        visible: true,
        type: 'delete',
      })
    }
    setShowModalDelete(!showModalDelete)
  }

  const _filenameCell = (props) => {
    /*let counting = props.dataItem.Filename.split('.')[0].split('_').length
    let origin = props.dataItem.Filename.split('.')[0].split('_')[counting -1]
    let nameFile = props.dataItem.Filename.split('_'+origin.toString())[0] +'.'+ props.dataItem.Filename.split('.')[1]
    */

    // Somehow the textLink need to be formatted as string, especially when failed to get filename
    const textLink = (() => {
      if (props.dataItem.Path.toLowerCase().includes('lbs-attachment')) {
        const result = String(setToBase64SR(props.dataItem.Filename_Original))
        return result === '[object Promise]' ? '' : result
      }

      const result = String(String(setToBase64(props.dataItem.Filename_Original)))

      return result === '[object Promise]' ? '' : result
    })()

    return (
      <td style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 75 }}>
        <span style={{ marginRight: 10, fontSize: 30 }}> {_getFileIcon(props)} </span>
        {moment(props.dataItem.Created_Date)<moment('2021-03-01 20:00Z')?
          <TextLink
            onClick={() => {
              if (
                props.dataItem.Filename.includes('.doc') ||
              props.dataItem.Filename.includes('.xls')
              ) {
                window.open(props.dataItem.Path)
              } else {
                let win = window.open()
                win.document.write(`<iframe 
            style="
            width:100%;
            height:100%;
            "
            src="${props.dataItem.Path.toLowerCase().includes('lbs-attachment') ? setToBase64SR(props.dataItem.Filename_Base64) : setToBase64(props.dataItem.Filename_Base64)}" />`)
              }
            }}
          >
            {textLink}
          </TextLink>
          :
          <a href={props.dataItem.Path} target='_blank' rel='noopener noreferrer'>
            {props.dataItem.Filename}
          </a>
        }
      </td>
    )
  }

  const _uploadOnCell = (e) => (
    <td>
      {moment(ConvertLocalTimeGet(props.dataAll.Location, e.dataItem.Created_Date, 'YYYY/MM/DD HH:mm')).format('DD/MM/YYYY HH:mm')}
    </td>
  )

  const _fileSizeCell = (props) => <td>{parseInt(+props.dataItem.Filesize/1000) + ' kb'}</td>

  const _rowNumberCell = (props) => (
    <td>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.dataIndex + 1}
      </p>
    </td>
  )

  const _handleFileUpload = (e) => {
    setIsLoading(true)
    const fileSizeLimit = fileSize * 1024 * 1024
    let isLimitExceeded = false
    const formData = new FormData()
    const files = e.target.files
    for (let i = 0; i < files.length; i++) {
      const v = files[i]
      v.size > fileSizeLimit ? (isLimitExceeded = true) : null
      formData.append('file', v)
    }
    if (isLimitExceeded) {
      insertLog('filesize_exceed')
      props.setAlert({ msg: `One of the file size exceed ${fileSize} Mb`, success: false, visible: true })
      // document.getElementById('sr-doc_mdl-upload-form').reset()
      setIsLoading(false)
    } else {
      ExecutionAPI.UploadAttachments(formData)
        .then(async (res) => {
          const { Company, UserName, AgentName } = usersProfileReducer
          const data = res.data.data
          const result = []
          for (let i = 0; i < data.length; i++) {
            const attachment = data[i]
            const docsObj = {
              Sr_Code: props.dataSrCode,
              Filename: attachment.FileName,
              Filesize: '' + attachment.FileSize,
              Filetype: 'document',
              Path: attachment.Path,
              Created_By: `${UserName} (${AgentName.length > 0 ? AgentName : Company})`,
            }
            try {
              await ExecutionAPI.InsertAlldoc(docsObj)
              docsObj['Sr_Code'] = props.dataSrCode
              docsObj['Alldoc_Code'] = attachment.Code
              docsObj['Attachment_Type_Code'] = attachmentTypeCode
              docsObj['Is_Active'] = true
              docsObj['Created_Date'] = ConvertLocalTimePost(props.dataAll.Location, new Date())
              docsObj['Modified_Date'] = ConvertLocalTimePost(props.dataAll.Location, new Date())

              await ExecutionAPI.InsertAllAttachmentNew(docsObj)
              result.push(docsObj)
            } catch (error) {
              console.log(error)
            }
          }
          setAttachmentGrid([...result, ...attachmentGrid])
          _getAllAttachment()
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  return (
    <div>
      {showModalDelete && _deleteModal()}
      <ModalDocContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
          }}
        >
          <DocumentUploadButtonContainer>
            <form id='exe-doc_mdl-upload-form'>
              <UploadButton id='exe-doc_mdl-upload-btn' htmlFor='exe-doc_mdl-upload-input-file'>
                Upload File
              </UploadButton>
              <input
                id='exe-doc_mdl-upload-input-file'
                multiple
                type='file'
                name='exe-docs-attacments'
                style={{ display: 'none' }}
                onChange={(e) => {
                  _handleFileUpload(e)
                  e.target.value = null
                }}
                accept={fileType}
              />
            </form>
          </DocumentUploadButtonContainer>
        </div>
        {attachmentGrid.length > 0 ? (
          <DocumentDownloadAllButtonContainer style={{ cursor: 'pointer' }}>
            <DownloadAllButton id='sr-doc_mdl-download-all-btn' onClick={_downloadAll}>
              <CloudIcon style={{ marginRight: 6.4 }} />
              Download All
            </DownloadAllButton>
          </DocumentDownloadAllButtonContainer>
        ) : null}
        <ExeModalGridContainer style={{ paddingTop: 10, paddingBottom: 0 }}>
          <div
            style={{
              height: 35,
              display: 'flex',
              paddingRight: 5,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TotalPackageText>
              {`Total Document: ${[...attachmentGrid].filter((e) => !e.Deleted_By).length}`}
            </TotalPackageText>
          </div>
          {isLoading ? (
            <LoadingContainer>
              <Loading id='exe-doc_mdl-loading-indicator' />
            </LoadingContainer>
          ) : (
            <Grid
              id='exe-doc_mdl-all-data'
              total={10}
              style={{ height: gridHeight }}
              data={attachmentGrid ?
                attachmentGrid.sort((a, b)=> new Date(b.Created_Date) - new Date(a.Created_Date)) : []}
            >
              {DocumentGridColumn.map((e, index) =>
                e.field === 'Action' ? (
                  <Column
                    key={index}
                    width={e.width}
                    title={e.title}
                    field={e.field}
                    cell={_actionCell}
                  />
                ) : e.field === 'Filename' ? (
                  <Column
                    key={index}
                    width={e.width}
                    title={e.title}
                    field={e.field}
                    cell={_filenameCell}
                  />
                ) : e.field === 'Filesize' ? (
                  <Column
                    key={index}
                    width={e.width}
                    title={e.title}
                    field={e.field}
                    cell={_fileSizeCell}
                  />
                ) : e.field === 'Created_Date' ? (
                  <Column
                    key={index}
                    width={e.width}
                    title={e.title}
                    field={e.field}
                    cell={_uploadOnCell}
                  />
                ) : e.field === 'No' ? (
                  <Column
                    key={index}
                    width={e.width}
                    title={e.title}
                    field={e.field}
                    cell={_rowNumberCell}
                  />
                ) : (
                  <Column key={index} title={e.title} field={e.field} width={e.width} />
                )
              )}
            </Grid>
          )}
        </ExeModalGridContainer>
      </ModalDocContainer>
    </div>
  )
}
export default ExeDocModalContent
