export default {
  initValEquipment: {
    estimation_header_id: 0,
    estimation_detail_id: 0,
    created_date:'',
    modified_date:'',
    description: '',
    post:'new',
    qty: 0,
  },
  initValDescription: {
    description: [],
    masterDescription: [],
  }
}