import styled, { css } from 'styled-components'

import { Colors } from '../../styles'
export const Nav = styled.div`
  height: 4.1vh;
  display: flex;
  justify-content: center;
  color: ${Colors.pureWhite};
  background: ${Colors.darkBlue};
`

export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
`

export const MenuList = styled.div`
  height: 100%;
  cursor: pointer;
  min-width: 70px;
  margin: 0 1vh;
  line-height: 3.07vh;
  padding: 0.5vh 1vh;
  text-align: center;
  transition: all 0.3s ease-in-out;
  ${(p) =>
  p.active &&
    css`
      border-bottom: 0.307vh solid ${Colors.pureWhite};
    `}
`
export const ContentList = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
`
