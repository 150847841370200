import { useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import { Alerts, MasterModal } from '../../../components'
import { ServiceRequestAPI, ExecutionAPI } from '../../../api'

import { Nav, MenuList, MenuWrapper, ContentList } from '../execution.styled'

import ExeSRModalContent from './execution.modal.sr.container'
import ExeDocModalContent from './execution.modal.doc.container'
// import ExeExctModalContent from './execution.modal.exec.container'
// import ExeProdModalContent from './execution.modal.prod.container'

import ExeDelayModalContent from './delay'
import ExeActvModalContent from './activity'
import ExeCargoEquipmentModalContent from './cargo-labour-equipment'


const ExecutionModal = (props) => {
  const cref = useRef()
  const appSize = useSelector((state) => state.appReducer)

  const[menu, setMenu] = useState('sr')
  const[showAlert, setAlert] = useState({
    visible: false, msg: '', success: false, type: '', longMessage: false,
  })
  const [agreement, setAgreement] = useState()
  const[counter, setCounter] = useState(0)
  const[category, setCategory] = useState([])
  const [iftCode, setIftCode] = useState('')
  const [generalCode, setGeneralCode] = useState('')
  const [dataPlanArrival, setDataPlanArrival] = useState('')
  const { Location } = useSelector((state) => state.usersProfileReducer)

  const _handleUpdate = () => cref.current._updateServiceRequest()

  const _closeAlert = () => {
    setAlert({ msg: '', success: true, visible: false, type: '', longMessage: false })
  }

  const getPlanArrival = async (dataSrCode) => {
    const { LocationDescription } = Location

    const response = await ExecutionAPI.getAllExecutions(
      0,
      1000,
      LocationDescription,
      `[{"type":"text", "field":"Code", "value":"${dataSrCode}"}]`
    )
    setDataPlanArrival(response.data.data[0].Plan_Arrival)
  }

  useEffect(() => {
    if(menu === 'activity') {
      getPlanArrival(props.dataSrCode)
    }
  }, [menu])

  useEffect(() => {
    _getCategory()
  }, [])

  useEffect(() => {
    getSRByNo()
  }, [props.dataAll])

  const getSRByNo = async ()=> {
    try {
      let { data } = await ServiceRequestAPI.GetSrByEmail(props.dataAll.Sr_No)
      if (data && data.length>0) {
        setAgreement(data[0].Agreement_No)
      } else {
        setAgreement(props.dataAll.Agreement_No)

      }
    } catch (error) {
      console.log('[error] getSRByNo', error)
    }
  }

  const _getCategory = async () => {
    try {
      // const filter ='[{"field":"type","type":"text","value":"SR_Category"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetSRCategory()
      setCategory(data)
      const generalData = data.find((e) => e.Value.toLowerCase() === 'general')
      const iftData = data.find((e) => e.Value.toLowerCase() === 'ift')
      if (generalData) {
        setGeneralCode(generalData['Code'])
      }
      if (iftData) {
        setIftCode(iftData['Code'])
      }
    } catch (err) {
      console.log(err)
    }
  }

  return(
    <>
      {showAlert.visible ? (
        <Alerts
          id='m_dly_alert'
          longMessage={showAlert.longMessage}
          msg={showAlert.msg}
          close={() => _closeAlert()}
          open={showAlert.visible}
          type={showAlert.type}
          success={showAlert.success}
        />
      ) : null}
      <MasterModal
        style={{ height: '800px' }}
        width={100}
        height={85}
        maxWidth='lg'    // Will be removed later if stable
        showModal={props.show}
        fullWidth={true}
        isStatic={true}
        isSrModal={true}
        title='Execution'
        handleClose={() => {
          setMenu('sr')
          props.closeExeModal(!props.show)
        }}
        isExecutionSR={menu === 'sr' &&
        props.dataAll.Sr_Status !== 'In Progress' &&
        props.dataAll.Sr_Status !== 'Completed'}
        handleUpdate={_handleUpdate}
        subTitle={ 'Detail Execution' }
        subSubTitle={props.dataAll.Sr_No}
      >
        <Nav>
          <MenuWrapper>
            <MenuList id='exe-modal-menu-sr-btn' active={menu === 'sr'} onClick={() => setMenu('sr')}>
                Service Request
            </MenuList>
            <MenuList
              active={menu === 'cargo-equipment'}
              onClick={() => setMenu('cargo-equipment')}
              id='exe-modal-menu-cargo-equipment-labour-btn'
            >
                Cargo & Equipment/Labour
            </MenuList>
            {/* <MenuList
              id='-modal-menu-prod-btn'
              active={menu === 'prod'}
              onClick={() => setMenu('prod')}
            >
                Product
            </MenuList> */}
            {/* <MenuList
              id='exe-modal-menu-exct-btn'
              active={menu === 'execution'}
              onClick={() => setMenu('execution')}
            >
                Execution
            </MenuList> */}
            {props.dataAll.Sr_Status !== 'Approved' && props.dataAll.Sr_Status !== 'Postponed' &&
           <MenuList
             id='exe-modal-menu-activity-btn'
             active={menu === 'activity'}
             onClick={() => setMenu('activity')}
           >
                Activity
           </MenuList>
            }
            {props.dataAll.Sr_Status !== 'Approved' && props.dataAll.Sr_Status !== 'Postponed' &&
            <MenuList
              id='exe-modal-menu-delay-btn'
              active={menu === 'delay'}
              onClick={() => setMenu('delay')}
            >
                Delay
            </MenuList>
            }
            <MenuList
              id='exe-modal-menu-doc-btn'
              active={menu === 'doc'}
              onClick={() => setMenu('doc')}
            >
                Document
            </MenuList>
          </MenuWrapper>
        </Nav>
        <ContentList isDetail={false} active={menu === 'sr'} appSize={appSize}>
          {menu === 'sr' && (
            <ExeSRModalContent
              agreement={agreement}
              cref={cref}
              setAlert={setAlert}
              isDetail={props.isDetail}
              fromScheduler={props.fromScheduler}
              getDataExecution={props.getDataExecution}
              dataSrCode={props.dataSrCode}
              dataAll={props.dataAll}
              category={category}
            />
          )}
        </ContentList>
        <ContentList isDetail={false} active={menu === 'cargo-equipment'} appSize={appSize}>
          <ExeCargoEquipmentModalContent
            agreement={agreement}
            dataAll={props.dataAll}
          />
        </ContentList>
        {/* <ContentList isDetail={false} active={menu === 'prod'} appSize={appSize}>
          <ExeProdModalContent
            setAlert={setAlert}
            dataSrCode = {props.dataSrCode}
            isDetail={props.isDetail}
            generalCode = {generalCode}
            dataAll = {props.dataAll}
            iftCode = {iftCode}
            category = {category} />
        </ContentList> */}
        {/* <ContentList isDetail={false} active={menu === 'execution'} appSize={appSize}>
          <ExeExctModalContent
            listVessel={props.listVessel}
            isDetail={props.isDetail}
            setAlert={setAlert}
            dataAll = {props.dataAll}
            getListScheduler={props.getListScheduler}
            dataSrCode = {props.dataSrCode} />
        </ContentList> */}
        <ContentList isDetail={false} active={menu === 'activity'} appSize={appSize}>
          {menu === 'activity' && <ExeActvModalContent
            counter={counter}
            setCounter={setCounter}
            isDetail={props.isDetail}
            setAlert={setAlert}
            generalCode={generalCode}
            iftCode={iftCode}
            show={props.show}
            codeVessel={props.codeVessel}
            dataAll = {props.dataAll}
            dataPlanArrival={dataPlanArrival}
            getDataExecution={props.getDataExecution}
            dataSrCode={props.dataSrCode} />}
        </ContentList>
        <ContentList isDetail={false} active={menu === 'delay'} appSize={appSize}>
          {menu === 'delay' && <ExeDelayModalContent
            counter={counter}
            isDetail={props.isDetail}
            setAlert={setAlert}
            dataAll = {props.dataAll}
            dataSrCode = {props.dataSrCode} /> }
        </ContentList>
        <ContentList isDetail={false} active={menu === 'doc'} appSize={appSize}>
          <ExeDocModalContent
            setAlert={setAlert}
            isDetail={props.isDetail}
            dataAll = {props.dataAll}
            dataSrCode={props.dataSrCode} />
        </ContentList>
      </MasterModal>
    </>
  )
}

export default ExecutionModal