import { useEffect, useState } from 'react'
import { ConvertLocalTimeGet, GetDurationMinutes, GetDurationDays } from '../../utils'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


const Activity = ({ dataSr }) =>{
  const [activity, setActivity] = useState([])

  useEffect(() => {
    let newActivity = dataSr.Activity?.map(e =>{
      return{
        ...e,
        isOpen:true
      }
    })
    setActivity(newActivity)
  }, [dataSr])

  const openChild = (ID) =>{
    let openActivity= activity.map(e=>{
      return{
        ...e,
        isOpen: e.ID === ID ? !e.isOpen: e.isOpen
      }
    })
    setActivity(openActivity)
  }
  return(
    <div style={{ width:'95vw', margin:'20px auto' }}>
      <table style={{ width:'95vw' }}>
        <thead>
          <tr>
    	<th style={{ paddingLeft:'20px' }}>List Activity</th>
          </tr>
        </thead>
      </table>
      <tbody >
        {activity && activity.map((e, i)=>{
          return(<>
            <tr style={{ height:'40px', background:'#FFFFFF', }}>
              <td onClick={()=>{openChild(e.ID)}} style={{ paddingLeft:'20px', width:'95vw', borderTop:'1px solid #DDDDDD', display:'flex', alignItems:'center', lineHeight:'40px', cursor:'pointer' }}><div style={{ paddingTop:'10px' }}><ExpandMoreIcon/></div><div>{e.Activity_Name} ({e.Details ? e.Details.length:0})</div></td>
            </tr>
            {e.isOpen && e.Details && e.Details.length!== 0 &&
                 <tr style={{ background:'#F5F5F5' }}>
                   <table style={{ width:'90vw', margin:'0 auto', border:'collapse' }}>

                     <thead>
                       <tr style={{ height:'35px', lineHeight:'35px' }}>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'50px' }}>#</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'200px' }}>Task</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'160px' }}>Planning Start</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'160px' }}>Planning Finish</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'200px' }}>Planning Duration in Hours</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'200px' }}>Planning Duration in Days</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'160px' }}>Actual Start</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'160px' }}>Actual Finish</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'180px' }}>Actual Duration in Hours</th>
                         <th style={{ background:'#F5F5F5', color:'#777777', width:'180px' }}>Actual Duration in Days</th>
                       </tr>
                     </thead>
                     <tbody>

                       {e.isOpen && e.Details?.map(child =>{
                         let planStart = ConvertLocalTimeGet(dataSr.Location, child.plan_start_time, 'DD/MM/YYYY HH:mm')
                         let planFinish = ConvertLocalTimeGet(dataSr.Location, child.plan_finish_time, 'DD/MM/YYYY HH:mm')
                         let actualStart = ConvertLocalTimeGet(dataSr.Location, child.actual_start_time, 'DD/MM/YYYY HH:mm')
                         let actualFinish = ConvertLocalTimeGet(dataSr.Location, child.actual_finish_time, 'DD/MM/YYYY HH:mm')
                         return(
                           <>

                             <tr style={{ minHeight:'35px', lineHeight:'35px', }}>
                               <td>{i+1}.{child.sort_no}</td>
                               <td>{child.task}</td>
                               <td>{planStart}</td>
                               <td>{planFinish}</td>
                               <td>{
                                 planStart && planStart.length>0
                                && planFinish &&  planFinish.length>0
                                   ? GetDurationMinutes(child.plan_start_time, child.plan_finish_time, 0)
                                   : '0 Hours 0 Minutes'}</td>
                              <td>{
                                 planStart && planStart.length>0
                                && planFinish &&  planFinish.length>0
                                   ? GetDurationDays(child.plan_start_time, child.plan_finish_time, 0)
                                   : '0 Days'}</td>
                               <td>{actualStart}</td>
                               <td>{actualFinish}</td>
                               <td>{
                                 actualStart && actualStart.length>0
                                && actualFinish &&  actualFinish.length>0
                                   ? GetDurationMinutes(child.actual_start_time, child.actual_finish_time, 0)
                                   : '0 Hours 0 Minutes'}</td>
                                <td>{
                                 actualStart && actualStart.length>0
                                && actualFinish &&  actualFinish.length>0
                                   ? GetDurationDays(child.actual_start_time, child.actual_finish_time, 0)
                                   : '0 Days'}</td>
                             </tr>

                           </>
                         )
                       })}

                     </tbody>
                   </table>
                 </tr>
            }

          </>)
        })}
      </tbody>
    </div>
  )
}

export default Activity

