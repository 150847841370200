import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { Button } from '../../components'

import { Colors } from '../../styles'

export const Header = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
`

export const Container = styled.div`
  height: 100%;
  padding: 1px 22px;
`

export const FilterPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChecklistBox = styled.div`
  width: 49%;
  border: 1px solid ${Colors.borderGrey};
`

export const ChecklistBoxTop = styled.div`
  display: flex;
  padding: 0px 20px;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${Colors.softBlue};
`

export const ShowHideContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ChevronContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

export const ChecklistBoxTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${Colors.white};
`

export const CheckboxLabel = styled.p`
  font-size: 12px;
  color: ${Colors.white};
`

export const CheckboxCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const WhiteCheckbox = styled(Checkbox)`
  color: ${Colors.white};
`

export const ChecklistBoxContent = styled.div`
  height: 450px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 5px 20px;
  flex-direction: column;
  justify-content: flex-start;
`

export const CheckboxWLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    color: #343434;
    font-size: 14px;
    font-family: 'Open Sans', Regular
  }
`

export const CheckboxListItem = styled(Checkbox)`
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: blue;
  }
`

export const FilterListContainer = styled.div`
  flex: 2;
  display: grid;
  border-width: 3px;
  margin: 20px 10px;
  grid-gap: 20px 20px;
  padding: 20px 20px;
  border-radius: 5px;
  border-style: solid;
  border-color: ${Colors.borderGrey};
  grid-template-columns: repeat(6, 1fr);
`

export const FilterItemContainer = styled.div`
  width: 100%;
`

export const FilterItemButton = styled.div`
  display: flex;
  cursor: pointer;
  padding: 3px 5px;
  border-width: 5px;
  border-radius: 5px;
  border-style: solid;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-color: ${Colors.borderGrey};
`

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const CustomButton = styled(Button)`
  width: 152px;
  height: 36px;
  margin-left: 5px;
`

export const FilterItemTitle = styled.p`
  color: #255D45;
  font-size: 16px;
  margin: 2px 0px;
  font-weight: bold;
  font-family: Arial;
`

export const FilterItemDesc = styled.p`
  font-size: 12px;
  margin: 5px 0px;
  font-family: Arial;
`

export const CheckboxWLabelTop = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 14px;
    color: ${Colors.white}};
    font-family: 'Open Sans', Regular
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height}px;
`

export const ButtonWIcon = styled.div`
  display: flex;
  color: #343434;
  cursor: pointer;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  padding: 0 5px;
  margin: 0 10px;
  justify-content: center;
  border: 1px solid #343434;
  box-shadow: 0px 1px 3px #00000029;
  background: #F4F6F9 0% 0% no-repeat padding-box;
`

export const HeaderWrapper = styled.div`
  z-index: 1;
  width: 50%;
  display: flex;
  position: relative;
  justify-content: space-between;
`

export const TopBtnsContainer = styled.div`
  display: flex;
  margin-right: 20px;
`

export const ToolbarButton = styled.div`
  color: black;
  display: flex;
  cursor: pointer;
  align-items: center;
`

export const VerticalDivider = styled.div`
  width: 2px;
  height: 75%;
  background-color: black;
`

export const ToolbarContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
`

export const TopCheckBox = withStyles(() => ({
  root: {
    color: 'white',
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0
    },
    '&:not($checked) .MuiIconButton-label:after': {
      content: '""',
      height: 15,
      width: 15,
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1
    }
  },
  checked: {}
}))(Checkbox)