import styled, { css } from 'styled-components'

import { Colors } from '../../styles'

export const ControlPanelWrapper = styled.div`
  left: 1.04vw;
  bottom: 3.07vh;
  position: fixed;
  color: ${Colors.pureWhite};
`
export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const FlexOuterWrapper = styled.div`
  display: flex;
`
export const IconWrapper = styled.div`
  width: 4.09vh;
  height: 4.09vh;
  margin: 0.25vh 0.5vh;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: ${(p) => p.lineHeight ? p.lineHeight : '6.45vh'};
  border-radius: 4.09vh;
  background: ${Colors.softBlack};
`

export const ZoomWrapper = styled.div`
  width: 4.09vh;
  height: 8.19vh;
  display: flex;
  margin: 1vh 0.5vh 0.5vh 0.5vh;
  cursor: pointer;
  border-radius: 0.5vh;
  flex-direction: column;
  justify-content: space-between;
  background: ${Colors.softBlack};
`
export const Zoom = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1vh;
  text-align: center;
  vertical-align: middle;
  ${(p) =>
  p.firstChild &&
    css`
      border-bottom: 3px solid ${Colors.softgrey};
    `}
`

export const LegendOuterWrapper = styled.div`
  display: flex;
  margin-left: 0.52vw;
  flex-direction: column;
  justify-content: flex-end;
`

export const LegendWrapper = styled.div`
  margin: 0.5vh;
  width: 15.6vw;
  height: 14.34vh;
  display: flex;
  border-radius: 0.5vh;
  flex-direction: column;
  color: ${Colors.pureWhite};
  justify-content: space-evenly;
  background: ${Colors.softBlack};
  box-shadow: 0px 3px 6px #00000029;
`

export const LegendText = styled.div`
  padding: 0.5vh;
  display: flex;
  font-size: 1.22vh;
  font-weight: bold;
  align-items: center;
`
