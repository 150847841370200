export default {
  initValTask: {
    id: 0,
    qty: 1,
    remarks: '',
    break_time: 0,
    start_time: '',
    finish_time: '',
    duration_time: 0,
    unit_name_code: '',
    equipment_code: '',
    estimation_detail_code: '',
    estimation_header_code: '',
  },
  showModalStatus: {
    isOpen: false,
    srCode: '',
    taskCode: '',
    taskName: ''
  },
  initValDropDown: {
    unit_name_code: '',
    estimation_detail_code: '',
  }
}