// import Alert from '@material-ui/lab/Alert'
import { Colors, FontSize } from '../../styles'
import styled, { css } from 'styled-components'
// import { CircularProgressbar } from 'react-circular-progressbar'

export const Nav = styled.div`
  height: 40px;
  display: flex;
  z-index: 1000;
  color: ${Colors.grey};
  justify-content: flex-start;
  background: ${Colors.white};
  /* box-shadow: 0px 2px 8px #00000033; */
`

export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
`

export const MenuList = styled.div`
  height: 100%;
  cursor: pointer;
  min-width: 70px;
  margin: 0px 10px;
  line-height: 30px;
  padding: 5px 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  ${(p) =>
  p.active &&
    css`
      color: ${Colors.darkBlue};
      border-bottom: 3px solid ${Colors.darkBlue};
    `}
`
export const ContentList = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
  height: ${(p) => p.appSize.height - (p.isDetail ? 295 : 330)}px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 10px 0px 10px;
  overflow: ${(p) => p.overflow || 'hidden'};
`

export const LeftFormContainer = styled.div`
  width: 60%;
  height: 72vh;
  display: flex;
  background-color: #f4f6f9;
  padding: 0px 10px 5px 10px;
  overflow: auto;
`
export const RightFormContainer = styled.div`
  width: 40%;
  display: flex;
  height: 72vh;
  background-color: #ffffff;
  padding: 0px 10px 5px 10px;
  overflow: auto;

  &.scrollable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: ${(p)=>p.padding ?? '10px 30px'};
  flex-direction: column;
`

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  color: ${Colors.modalGrey};
  align-items: ${(p) => p.align};
  margin-bottom: 18px;
`

export const TextTop1 = styled.p`
  font-size: 14px;
  line-height: 140%;
  margin: 0;
  margin-right: 8px;
`
export const TextTop2 = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`
export const TextTop3 = styled.p`
  font-size: 14px;
  margin-left: 4px;
  margin-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${Colors.modalGrey};
`
export const TopIcon = styled.img`
  width: 20;
  height: auto;
`
export const WrapperValue = styled.div`
  display: flex;
  margin: 0 0 18px;
  align-items: center;
  flex-direction: row;
`
export const SVGIcon = styled.img`
  height: auto;
  margin-left: 10px;
  width: ${(p) => p.width || 20}px;
  ${(p) =>
  p.absolute &&
    css`
      left: 0;
      position: absolute;
    `}
`
export const TitleValue = styled.p`
  line-height: 0;
  color: ${Colors.modalGrey};
  font-size: ${FontSize.medium}px;
  ${(p) =>
  p.top &&
    css`
      margin-top: 20px;
    `}
`
export const TitleBerthing = styled.p`
  padding: 6px 8px;
  border-radius: 4px;
  color: ${Colors.black};
  font-size: ${FontSize.medium}px;
  background: #f2f5fa;
  margin: 0;
`
export const Text = styled.p`
  font-size: ${FontSize.smallMedium}px;
  color: ${(p) => (p.index ? Colors.softBlue : Colors.hintGrey)};
  ${(p) =>
  p.regular &&
    css`
      margin: 0 10px;
      color: ${Colors.black};
      font-size: ${FontSize.medium}px;
    `}
  ${(p) =>
      p.bold &&
    css`
      font-weight: 600;
    `}
`

export const InfoTitle = styled.h1`
  color: ${Colors.cancelTextColor};
  font-size: ${FontSize.extraLarge}px;
  margin: 0;
`
export const CargoList = styled.ul`
  padding: 0;
  width: 100%;
  list-style: none;
  max-height: 230px;
`

export const CargoItem = styled.li`
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid #dfe7f6;
  background-color: ${Colors.white};
`
export const ItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ItemTitle = styled.p`
  font-size: ${FontSize.mediumLarge}px;
  color: ${Colors.cancelTextColor};
  margin: 0;
`
export const ItemSubtitle = styled.p`
  font-size: ${FontSize.small}px;
  color: #777777;
  margin: 0;
`
export const ItemAmount = styled.p`
  font-size: ${FontSize.mediumLarge}px;
  color: ${Colors.softBlue};
  margin: 0;
`
export const InfoCounter = styled.p`
  margin: 0;
  padding-left: 5px;
  color: ${Colors.black};
  font-size: ${FontSize.medium}px;
`
export const InfoSubtitle = styled.p`
  margin: 0;
  color: #777777;
  display: inherit;
  align-items: baseline;
  font-size: ${FontSize.medium}px;
`
export const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  padding: 0px 16px 15px;
  background-color: #f4f6f9;
  justify-content: space-between;
`
export const CargoInformationCard = styled.div`
  height: 230px;
  padding: 20px 20px 10px 20px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  &.bottom {
    height: 450px;
    width: 100%;
  }
`
export const CargoInformationWrapper = styled.div`
  width: 50%;
  padding: 15px 0;
  &.left {
    margin-right: 15px;
  }
  &.right {
    margin-left: 15px;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const DocumentDownloadAllButtonContainer = styled.div`
  padding: 0;
  display: flex;
  margin: 0 20px;
  padding-top: 20px;
  padding: 0 auto;
  align-items: center;
  justify-content: flex-end;
`
export const DownloadAllButton = styled.div`
  width: 132px;
  height: 32px;
  display: flex;
  color: #0a81ad;
  cursor: pointer;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`
export const TextLink = styled.p`
  color: blue;
  text-decoration: underline;
  width: 250px;
  cursor: pointer;
`
export const SrModalGridContainer = styled.div`
  padding: 0px 20px;
`
export const TotalPackageText = styled.div`
  text-align: right;
  padding: 10px 0 10px 0;
  font-weight: bold;
`
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-around;
`
export const DownloadButton = styled.div`
  width: 98px;
  height: 26px;
  display: flex;
  color: #0a81ad;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`

export const GridWrapper = styled.div`
  margin-top:20px;
  & div.k-widget.k-grid{
    & div.k-grid-header{
      & div.k-grid-header-wrap{
        & table{
          & thead{
            & tr{
              & th{
                background:#DDEAFF !important;
                color:#333333;
              }
            }
          }
        }
      }
    }
  }
`
export const Row = styled.div`
  display:flex;
  flex-direction: row;
`
export const Container = styled.div`
  width:100%;
  margin-top:15px;
`
export const BorderLine = styled.div`
  height: 25px;
  width: 94.5%;
  border-top: 2.5px solid ${Colors.lightBlue};
  margin: auto;
  /* background: #0082ad; */
`