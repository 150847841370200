import { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Alerts, ModalDel } from '../../../components'

import SC from './styled.activity'
import Funcs from './functions.activity'
import constants from './constants.activity'
import AddEquipmentTaskModal from './add-task'
import FC from './factory-components.activity'

const ActivityModal = ({ dataAll, inEditMode }) => {
  const { Location, Code } = dataAll
  const [task, setTask] = useState({})
  const [activity, setActivity] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [toggleDelete, setToggleDelete] = useState(false)
  const [showModalStatus, setShowModalStatus] = useState(constants.showModalStatus)
  const [showAlert, setShowAlert] = useState({ visible: false, msg: '', success: false, type: '' })

  const [activityList, setActivityList] = Funcs.useFetchActivityBySRCode(Code, Location, setIsLoading)

  const addNewTask = Funcs.addNewTaskHOF(Location, setActivityList, setIsLoading)
  const handleOpenTaskDetail = Funcs.handleOpenTaskDetailHOF(activityList, setActivityList)
  const handleOpenActivityDetail = Funcs.handleOpenActivityDetailHOF(activityList, setActivityList)
  const updateActivityAndTaskHeader = Funcs.updateActivityAndTaskHeaderHOF(activityList, setActivityList, Location)
  const toggleModal = Funcs.toggleModalHOF(Code, Location, showModalStatus.isOpen, setShowModalStatus, setIsLoading, setActivityList)
  const deleteTask = Funcs.deleteTaskHOF(Location, setActivityList, activity, task, setToggleDelete, setShowAlert, setIsLoading)

  const handleRemoveModal = () => (
    <div>
      <ModalDel
        id='m_actv_delete_mdl'
        toggleDialog={() => setToggleDelete(!toggleDelete)}
        submitDelete={deleteTask}
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const deleteData=(act, ts)=>{
    setTask(ts)
    setActivity(act)
    setToggleDelete(!toggleDelete)
  }

  return (
    <SC.Container>
      {toggleDelete && handleRemoveModal()}
      {isLoading ? (
        <SC.CircularProgressWrapper>
          <CircularProgress />
        </SC.CircularProgressWrapper>
      ) : (
        <FC.ActivityGrid
          location={Location}
          data={activityList}
          inEditMode={inEditMode}
          setActivityList= {setActivityList}
          addNewTask={addNewTask}
          deleteTask={deleteData}
          handleShowModal={toggleModal}
          handleOpenTask={handleOpenTaskDetail}
          handleOpenActivity={handleOpenActivityDetail}
          updateActivityAndTaskHeader={updateActivityAndTaskHeader}
        />
      )}
      {showModalStatus.isOpen && (
        <AddEquipmentTaskModal
          dataAll={dataAll}
          srCode={dataAll.Code}
          inEditMode={inEditMode}
          handleClose={toggleModal}
          activityList={activityList}
          showModalStatus={showModalStatus}
        />
      )}
      <Alerts
        type={showAlert.type}
        msg={showAlert.msg}
        close={() => {
          setShowAlert({ ...showAlert, visible: false })
        }}
        open={showAlert.visible}
        success={showAlert.success}
      />
    </SC.Container>
  )
}

export default ActivityModal