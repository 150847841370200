import SC from './styled.cargo-equipment'

import CargoGrid from './cargo'
import EquipmentLabourGrid from './equipment-labour'

const CargoEquipmentModal = ({ isEdit, dataAll, dataContract }) => {
  return (
    <SC.Container>
      <CargoGrid
        isEdit={isEdit}
        dataAll={dataAll}
      />
      <EquipmentLabourGrid
        isEdit={isEdit}
        dataAll={dataAll}
        dataContract={dataContract}
      />
    </SC.Container>
  )
}

export default CargoEquipmentModal