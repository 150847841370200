import { useState } from 'react'
import { useFormik } from 'formik'
import { EquipmentIcon, InputDateTime, Button } from '~/components'
import moment from 'moment'
import { Colors } from '~/styles'
import {
  GetDurationMinutes,
  GetDurationDays,
  getDurationBetweenTwoTime,
  durationToFormattedString
} from '~/utils'

import SC from './styled.activity'
import Funcs from './functions.activity'
import { ConvertLocalTimeGet } from '../../../utils'
import { Images } from '../../../assets'
import { FormControl, MenuItem, Select } from '@material-ui/core'

const ActivityNameCell = ({ activity, handleOpenActivity }) => {
  const handleChange = () => {
    handleOpenActivity(activity)
  }

  return (
    <td>
      <SC.ActivityCellWrapper>
        <SC.KeyboardArrowDownIcon
          isOpen={activity.isOpen}
          onClick={handleChange}
        />
        <span>{activity.activity_name}</span>
      </SC.ActivityCellWrapper>
    </td>
  )
}

const AddTaskRow = ({ activity, addNewTask }) => {
  const [taskDescription, setTaskDescription] = useState('')

  const handleChangeTaskDescription = (event) => {
    setTaskDescription(event.target.value)
  }

  const handleAddNewTask = () => {
    addNewTask(activity, taskDescription)
    setTaskDescription('')
  }

  return (
    <tr>
      <td colSpan={9}>
        <SC.AddTaskCell>
          <SC.AddTaskInput
            placeholder='Add New Task'
            value={taskDescription}
            onChange={handleChangeTaskDescription}
          />
          <SC.AddTaskButtonWrapper>
            <Button
              disable={taskDescription === '' ? true : false}
              type='submit'
              color={taskDescription === '' ? 'grey' : Colors.newGreen}
              onClick={handleAddNewTask}
            >
              Add Task
            </Button>
          </SC.AddTaskButtonWrapper>
        </SC.AddTaskCell>
      </td>
    </tr>
  )
}

const TaskNameCell = ({ activity, index, task, handleOpenTask }) => {
  const handleChange = () => {
    handleOpenTask(activity, task)
  }

  return (
    <SC.TaskCellWrapper>
      {task.progress_task_detail_list && (
        <SC.KeyboardArrowDownIcon isOpen={task.isOpen} onClick={handleChange} />
      )}
      <span>{index}. {task.task}</span>
    </SC.TaskCellWrapper>
  )
}

const DetailGridHeader = () => (
  <thead>
    <SC.InnerGridHeader>
      <th width='auto'>Equipment/Labour</th>
      <th width='300px'>Unit/Name</th>
      <th width='100px'>Qty</th>
      <th width='200px'>Actual Start</th>
      <th width='200px'>Actual Finish</th>
      <th width='100px'>Breaktime</th>
      <th width='200px'>Duration</th>
      <th width='200px'>Billing Start</th>
      <th width='200px'>Billing Finish</th>
      <th width='200px'>Billing Duration</th>
    </SC.InnerGridHeader>
  </thead>
)

const EquipmentTaskRow = ({ taskDetail, location }) => {
  return (
    <tr>
      <td>{taskDetail.description}</td>
      <td>
        {taskDetail.unit_name_code} - {taskDetail.unit_name_description}
      </td>
      <td>{taskDetail.qty}</td>
      <td>
        {taskDetail.start_time
          ? ConvertLocalTimeGet(
              location,
              taskDetail.start_time,
              'YYYY-MM-DD HH:mm'
            )
          : null}
      </td>
      <td>
        {taskDetail.finish_time
          ? ConvertLocalTimeGet(
              location,
              taskDetail.finish_time,
              'YYYY-MM-DD HH:mm'
            )
          : null}
      </td>
      <td>{taskDetail.break_time}</td>
      <td>
        {GetDurationMinutes(
          taskDetail.start_time,
          taskDetail.finish_time,
          taskDetail.break_time
        )}
      </td>

      <td>
        {taskDetail.billing_start_time
          ? ConvertLocalTimeGet(
              location,
              taskDetail.billing_start_time,
              'YYYY-MM-DD HH:mm'
            )
          : null}
      </td>
      <td>
        {taskDetail.billing_finish_time
          ? ConvertLocalTimeGet(
              location,
              taskDetail.billing_finish_time,
              'YYYY-MM-DD HH:mm'
            )
          : null}
      </td>
      <td>
        {GetDurationMinutes(
          taskDetail.billing_start_time,
          taskDetail.billing_finish_time,
          taskDetail.break_time
        )}
      </td>
    </tr>
  )
}

const EquipmentTaskEmpty = () => {
  return (
    <tbody>
      <tr>
        <td colSpan='6'>
          <SC.EmptyListCell>No Equipment Tasks Available</SC.EmptyListCell>
        </td>
      </tr>
    </tbody>
  )
}

const EquipmentTaskGridBody = ({ location, taskDetailList }) => (
  <tbody>
    {taskDetailList.map((taskDetail) => (
      <EquipmentTaskRow
        location={location}
        key={taskDetail.id}
        taskDetail={taskDetail}
      />
    ))}
  </tbody>
)

const DetailGridBody = ({ location, taskDetailList }) => {
  return (taskDetailList ?? []).length === 0 ? (
    <EquipmentTaskEmpty />
  ) : (
    <EquipmentTaskGridBody
      location={location}
      taskDetailList={taskDetailList}
    />
  )
}

const DetailGrid = ({ location, taskDetailList, handleShowModal, activity, setActivityList }) => (
  <SC.BodyRowGridDetail>
    <td colSpan={9}>
      <SC.InnerGrid>
        <DetailGridHeader
         activity={activity}
         setActivityList={setActivityList}
        />
        <DetailGridBody
          location={location}
          taskDetailList={taskDetailList}
          handleShowModal={handleShowModal}
        />
      </SC.InnerGrid>
    </td>
  </SC.BodyRowGridDetail>
)

const HeaderGrid = () => (
  <SC.THead>
    <SC.HeaderRowGrid>
      <th width='250px'>List Activity</th>
      <th width='200px'>Actual Start</th>
      <th width='200px'>Actual Finish</th>
      <th width='160px'>Actual Duration in Hours</th>
      <th width='auto'>Actual Duration in Days</th>
      <th width='200px'>Billing Start</th>
      <th width='200px'>Billing Finish</th>
      <th width='160px'>Billing Duration in Hours</th>
      <th width='auto'>Billing Duration in Days</th>
      <th width='50px'></th>
      <th width='50px'></th>
    </SC.HeaderRowGrid>
  </SC.THead>
)

const TaskRow = ({
  task,
  index,
  activity,
  location,
  deleteTask,
  inEditMode,
  handleOpenTask,
  handleShowModal,
  updateActivityAndTaskHeader
}) => {
  const form = useFormik({
    initialValues: {
      sr_code: task.sr_code,
      code_activity: activity.code,
      actual_start_time: task.actual_start_time ?? '',
      actual_finish_time: task.actual_finish_time ?? '',
      actual_dur_time: task.actual_dur_time ?? 0,
      billng_start_time: task.billng_start_time ?? '',
      billng_finish_time: task.billng_finish_time ?? '',
      billng_dur_time: task.billng_dur_time ?? 0
    },
    enableReinitialize: true,
    validateOnChange: true,
    validate: (values) => {
      const billng_start_time =
        values.actual_start_time == task.actual_start_time
          ? values.billng_start_time
          : values.actual_start_time
      const billng_finish_time =
        values.actual_finish_time == task.actual_finish_time
          ? values.billng_finish_time
          : values.actual_finish_time
      const billng_dur_time =
        values.actual_dur_time == task.actual_dur_time
          ? values.billng_dur_time
          : values.actual_dur_time
      updateActivityAndTaskHeader(activity, {
        ...task,
        ...values,
        actual_start_time: values.actual_start_time,
        actual_finish_time: values.actual_finish_time,
        billng_start_time: billng_start_time,
        billng_finish_time: billng_finish_time,
        billng_dur_time: billng_dur_time
      })
    }
  })

  return (
    <>
      <SC.BodyRowGrid key={task.id}>
        <TaskNameCell
          task={task}
          index={index}
          activity={activity}
          handleOpenTask={handleOpenTask}
        />
        <td>
          <InputDateTime
            tabIndex={3}
            readOnly={!inEditMode}
            name='actual_start_time'
            form={form}
            id='exe-mdl-act-start-time-date'
          />
        </td>
        <td>
          <InputDateTime
            tabIndex={3}
            readOnly={!inEditMode}
            name='actual_finish_time'
            form={form}
            id='exe-mdl-act-start-time-date'
          />
        </td>
        <td>
          {Funcs.durationToFormattedStringHours(form.values.actual_dur_time)}
        </td>
        <td>
          {Funcs.durationtoFormattedStringDays(form.values.actual_dur_time)}
        </td>
        <td>
          <InputDateTime
            form={form}
            tabIndex={3}
            readOnly={!inEditMode}
            name='billng_start_time'
            id='exe-mdl-act-start-time-date'
          />
        </td>
        <td>
          <InputDateTime
            form={form}
            tabIndex={3}
            readOnly={!inEditMode}
            name='billng_finish_time'
            id='exe-mdl-act-start-time-date'
          />
        </td>
        <td>
          {Funcs.durationToFormattedStringHours(form.values.billng_dur_time)}
        </td>
        <td>
          {Funcs.durationtoFormattedStringDays(form.values.billng_dur_time)}
        </td>
        <td>
          <SC.EquipmentIconWrapper
            onClick={() => handleShowModal(activity.code, task.code)}
          >
            <EquipmentIcon width='20px' height='20px' color={Colors.softBlue} />
          </SC.EquipmentIconWrapper>
        </td>
        <td>
          <SC.EquipmentIconWrapper
            onClick={inEditMode && (() => deleteTask(activity, task))}
          >
            <SC.DeleteIcon disable={!inEditMode} />
          </SC.EquipmentIconWrapper>
        </td>
      </SC.BodyRowGrid>
      {task.isOpen && task.progress_task_detail_list ? (
        <DetailGrid
          location={location}
          taskDetailList={task.progress_task_detail_list ?? []}
        />
      ) : null}
    </>
  )
}
const SortTask = ({ activityData, setActivityList, location, modal, index, setModal }) => {
  const [dataSort, setDataSort] = useState([])
  return <div
    style={{ width: '100%',   }}
    >
    <SC.SortWrapper
    // modal===index && sort-icon is clicked then close the <modal/> else open modal based on index
      onClick={()=>setModal(
        modal===index?
        -1: index
      )}>
      <img src={Images.SortIcon} />
      sort
      </SC.SortWrapper>

      {modal===index &&
       (
        // modal===index as flagging modal showed or not
        <SortModal
        dataSort={dataSort}
        location={location}
        activityData={activityData}
        setActivityList= {setActivityList}
        setModalChild={(e) => setModal(e)}
        setDataSortChild={(e) => setDataSort(e)}
        />
      )}
  </div>
}


const SortModal = ({ setDataSortChild, dataSort, setModalChild, activityData, setActivityList, location }) => {
  const dataDropdown = [
    { field: 'task', name: 'Task', id: 1, dir: 'asc' },
    { field: 'actual_start_time', name: 'Actual Start', id: 2, dir: 'asc' },
    { field: 'actual_finish_time', name: 'Actual Finish', id: 3, dir: 'asc' },
    { field: 'billng_start_time', name: 'Billing Start', id: 4, dir: 'asc' },
    { field: 'billng_finish_time', name: 'Billing Finish', id: 5, dir: 'asc' },
  ]

  const dataFilterModule = () => {
    var dataSlice = dataDropdown
    dataSort.forEach((datal) => {
      const index = dataDropdown.findIndex((item) => item.id == datal.id)
      dataSlice.splice(index, 1)
    })
    return dataSlice
  }


  const handleAdd = (event) => {
    const arr = [...dataSort]
    arr.push(event.target.value)
    setDataSortChild(arr)
  }

  const handleRemove = (event) => {
    const arr = dataSort.filter((item) => {
      return item.id != event.id
    })
    setDataSortChild(arr)
  }
  const handleSort = (event) => {
    const arr = dataSort.map((item) => {
      if (item.id == event.id) {
        if (item.dir == 'asc') {
          return { ...item, dir: 'desc' }
        } else {
          return { ...item, dir: 'asc' }
        }
      } else {
        return item
      }
    })
    setDataSortChild(arr)
  }

  const handleClose = () => {
    setModalChild(-1)
  }

  const handleUpdate = async () => {
    const payload = {
      dataSort: dataSort,
      data: {
        activity_code: activityData.code,
        sr_code: activityData.sr_code,
        modified_date: new Date()
      },
      handleClose: () => setModalChild(-1),
      location: location,
      setActivityList: setActivityList
    }
    await Funcs.updateSort(payload)
  }
  return (
    <div style={{ position:'relative' }}>
    <SC.ModalSort>
      <div style={{ borderBottom: '1px solid black', width: '100%' }}>
        {dataSort.map((item, i) => (
          <SC.SortListWrapper key={i}>
            <SC.SortTextList>{item.name}</SC.SortTextList>
            <div style={{ display:'flex', justifyContent:'center' }}>
              <div>
                <img src={Images.sortDirIcon}
                  style={{ height:'100%', width:'100%',
                    transform: `rotate(${item.dir == 'asc' ? '0deg': '180deg'})`,
                    marginRight: 5, cursor: 'pointer', color: '#A3AAB6' }}
                  onClick={() => handleSort(item)}
                />
                </div>
                <div>
                  <img src={Images.deleteIcon}
                    style={{ height:'100%', width:'100%', cursor: 'pointer', color: '#A3AAB6' }}
                    onClick={() => handleRemove(item)}
                  />
              </div>
            </div>
          </SC.SortListWrapper>
        ))}
        <FormControl
          style={{ display: 'flex', justifyContent:'center', margin: '10px 15px 0px' }}
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 }
          }}
          size='small'
        >
          <Select
            disableUnderline
            displayEmpty={true}
            renderValue={() => '  + Add sort'}
            onChange={(e) => handleAdd(e)}
            style={{
              marginBottom: 5,
              backgroundColor: 'transparent !important'
            }}
          >
            {dataFilterModule().map((item, i) => (
              <MenuItem key={i} value={item}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          margin: '10px 0 5px 0',
          direction: 'rtl',
          width: '100%',
          display: 'flex'
        }}
      >
        <SC.ButtonFilter onClick={handleUpdate}>Update</SC.ButtonFilter>
        <SC.ButtonClose onClick={handleClose}>Close</SC.ButtonClose>
      </div>
    </SC.ModalSort>
    </div>
  )
}

const TaskList = ({
  tasks,
  activity,
  location,
  deleteTask,
  inEditMode,
  handleOpenTask,
  handleShowModal,
  updateActivityAndTaskHeader
}) => {
  return tasks.map((task, index) => (
    <TaskRow
      task={task}
      key={task.id}
      index={index+1}
      location={location}
      activity={activity}
      inEditMode={inEditMode}
      deleteTask={deleteTask}
      handleOpenTask={handleOpenTask}
      handleShowModal={handleShowModal}
      updateActivityAndTaskHeader={updateActivityAndTaskHeader}
    />
  ))
}

const EmptyList = ({ text }) => (
  <tr>
    <td colSpan={9}>
      <SC.EmptyListCell>{text}</SC.EmptyListCell>
    </td>
  </tr>
)

const ActivityList = ({
  data,
  location,
  addNewTask,
  deleteTask,
  inEditMode,
  setActivityList,
  handleOpenTask,
  handleShowModal,
  handleOpenActivity,
  updateActivityAndTaskHeader
}) => {
  const [modal, setModal] = useState(-1)
  return (data || []).map((activity, index) => {
    const actualDur = getDurationBetweenTwoTime(
      activity.start_date,
      activity.finish_date
    )
    const actualDurFormatted = durationToFormattedString(actualDur)

    return (
      <>
        <SC.BodyRowGrid showNextRow={activity.isOpen} key={activity.id}>
          <ActivityNameCell
            activity={activity}
            handleOpenActivity={handleOpenActivity}
          />
          <td>
            {activity.start_date
              ? moment(activity.start_date).format('DD-MM-YYYY HH:mm')
              : null}
          </td>
          <td>
            {activity.finish_date
              ? moment(activity.finish_date).format('DD-MM-YYYY HH:mm')
              : null}
          </td>
          <td>{actualDurFormatted}</td>
          <td>
            {GetDurationDays(activity.start_date, activity.finish_date, 0)}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td colSpan={3} style={{ paddingLeft: '9vw' }} >
          {inEditMode &&
           <SortTask
            inEditMode={inEditMode}
            activityData={activity}
            modal={modal}
            index={index}
            setModal={setModal}
            location={location}
            setActivityList={setActivityList} />}
          </td>
        </SC.BodyRowGrid>
        {activity.isOpen && (
          <TaskList
            activity={activity}
            location={location}
            inEditMode={inEditMode}
            deleteTask={deleteTask}
            handleOpenTask={handleOpenTask}
            handleShowModal={handleShowModal}
            tasks={activity.progress_task_header_list ?? []}
            updateActivityAndTaskHeader={updateActivityAndTaskHeader}
          />
        )}
        {inEditMode && (
          <AddTaskRow activity={activity} addNewTask={addNewTask} />
        )}
      </>
    )
  })
}

const BodyGrid = ({
  data,
  location,
  addNewTask,
  deleteTask,
  inEditMode,
  handleOpenTask,
  setActivityList,
  handleShowModal,
  handleOpenActivity,
  updateActivityAndTaskHeader
}) => (
  <tbody>
    {data.length === 0 ? (
      <EmptyList text='No Activities' />
    ) : (
      <ActivityList
        data={data}
        location={location}
        inEditMode={inEditMode}
        addNewTask={addNewTask}
        deleteTask={deleteTask}
        setActivityList={setActivityList}
        handleOpenTask={handleOpenTask}
        handleShowModal={handleShowModal}
        handleOpenActivity={handleOpenActivity}
        updateActivityAndTaskHeader={updateActivityAndTaskHeader}
      />
    )}
  </tbody>
)

const ActivityGrid = ({
  data,
  location,
  inEditMode,
  addNewTask,
  deleteTask,
  handleOpenTask,
  handleShowModal,
  setActivityList,
  handleOpenActivity,
  updateActivityAndTaskHeader
}) => (
  <SC.GridContainer>
    <SC.Grid>
      <HeaderGrid />
      <BodyGrid
        data={data}
        location={location}
        inEditMode={inEditMode}
        addNewTask={addNewTask}
        deleteTask={deleteTask}
        handleOpenTask={handleOpenTask}
        setActivityList={setActivityList}
        handleShowModal={handleShowModal}
        handleOpenActivity={handleOpenActivity}
        updateActivityAndTaskHeader={updateActivityAndTaskHeader}
      />
    </SC.Grid>
  </SC.GridContainer>
)

export default {
  ActivityGrid
}
