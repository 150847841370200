
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import DocumentGridColumn from './column.modal_cargo.json'
import {
  InfoTitle,
  CargoList,
  CargoItem,
  ItemTitle,
  ItemAmount,
  InfoCounter,
  ItemSubtitle,
  TitleWrapper,
  InfoSubtitle,
  OuterWrapper,
  FormContainer,
  ItemTitleWrapper,
  CargoInformationCard,
  CargoInformationWrapper,
} from './service-request-detail.styled'

import { ConvertLocalTimeGet } from '../../utils'

const CargoModalContent = (props) => {
  const { dataSr } = props

  const _centeredCell = (props) => {
    const isDecimal = DocumentGridColumn.filter((gd) => gd.field == props.field)[0].filterType == 'numeric'
    const isDate = DocumentGridColumn.filter((gd) => gd.field == props.field)[0].filterType == 'date'
    return (
      <td style={{ textAlign: 'center' }}>{
        isDecimal ? parseFloat(props.dataItem[props.field]).toFixed(3) : isDate ? ConvertLocalTimeGet( dataSr.Location, props.dataItem[props.field], 'DD MMM YYYY HH:mm') : props.dataItem[props.field]
      }</td>
    )
  }

  return (
    <OuterWrapper>
      <FormContainer height={45}>
        <CargoInformationWrapper className='left'>
          <CargoInformationCard className='cargo-wrapper'>
            <TitleWrapper>
              <InfoTitle>Labour</InfoTitle>
              <InfoSubtitle>
                Items: <InfoCounter>{dataSr.Cargo_ManPower?.length || 0}</InfoCounter>
              </InfoSubtitle>
            </TitleWrapper>
            <CargoList>
              {dataSr.Cargo_ManPower?.map((item, i) => (
                <CargoItem key={i}>
                  <ItemTitleWrapper>
                    <ItemTitle>{item.Description}</ItemTitle>
                    <ItemAmount>({item.Total})</ItemAmount>
                  </ItemTitleWrapper>
                  <ItemSubtitle>{item.Material}</ItemSubtitle>
                </CargoItem>
              ))}
            </CargoList>
          </CargoInformationCard>
        </CargoInformationWrapper>

        <CargoInformationWrapper className='right'>
          <CargoInformationCard className='cargo-wrapper'>
            <TitleWrapper>
              <InfoTitle>Equipment</InfoTitle>
              <InfoSubtitle>
                Items: <InfoCounter>{dataSr.Cargo_Equipment?.length || 0}</InfoCounter>
              </InfoSubtitle>
            </TitleWrapper>
            <CargoList>
              {dataSr.Cargo_Equipment?.map((item, i) => (
                <CargoItem key={i}>
                  <ItemTitleWrapper>
                    <ItemTitle>{item.Description}</ItemTitle>
                    <ItemAmount>({item.Total})</ItemAmount>
                  </ItemTitleWrapper>
                  <ItemSubtitle>{item.Material}</ItemSubtitle>
                </CargoItem>
              ))}
            </CargoList>
          </CargoInformationCard>
        </CargoInformationWrapper>
      </FormContainer>

      <FormContainer height={54}>
        <CargoInformationCard className='bottom'>
          <TitleWrapper>
            <InfoTitle>Cargo Information</InfoTitle>
          </TitleWrapper>
          <TitleWrapper>
            <div style={{ margin: '7px 0px' }}>Cargo No : {props.dataSr.Cargo_No}</div>
          </TitleWrapper>
          <Grid
            id='sr-doc_mdl-all-data'
            data={props.dataSr.Cargos ?
              props.dataSr.Cargos.sort((a, b) =>
                new Date(b.Modified_Date) - new Date(a.Modified_Date)): [] }
            total={0}
            style={{ height: '360px' }}
            rowHeight={5}
            pageSize={8}
          >
            {DocumentGridColumn.map((e, index) => (
              <Column
                key={index}
                title={e.title}
                field={e.field}
                width={e.width}
                cell={_centeredCell}
              />
            ))}
          </Grid>
        </CargoInformationCard>
      </FormContainer>
    </OuterWrapper>
  )
}

export default CargoModalContent
