import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { Images } from '../../../assets'

import {
  Vessel,
  Jetty12,
  Jetty1a,
  Jetty1b,
  Jetty2a,
  Jetty2b,
  Jetty3a,
  Jetty3b,
  JettyRoroa,
  JettyRorob,

  Box,
  Tooltips,
  TittleTooltips,
  TooltipsWrapper,
  ContentTooltips,
} from './dashboard-jetty.styled'
import { Colors } from '../../../styles'

const VesselSorong = (props) => {
  const [availableJetty, setAvailableJetty] = useState([])

  const appSize = useSelector((state) => state.appReducer)
  const scaleFactor = (parseInt(appSize.height) - 110 * parseInt(appSize.height)/943) / 943

  useEffect(() => {
    _setAvailableJetty()
  }, [props.dataJetty])

  const _setAvailableJetty = () => {
    let available = []
    props.dataJetty.map((dt, i) => {
      const avail = {
        position: i,
        show: dt.Detail != null && dt.Detail.length > 0,
        detail: dt.Detail ? dt.Detail.sort((a, b) => a.position - b.position) : [],
        jettyLength: dt.Length,
      }
      available.push(avail)
    })
    setAvailableJetty(available)
  }

  const _getVesselName = (name, mirror) => {
    if (name) {
      let array = name.split('|')
      let names = array[0].replace('/', '_')
      // const vname = `V${names}${array[1].replace(' ', '')}`
      if(mirror && names === 'Rig') {
        return `V${names}Mirror`
      } else {
        return `V${names}`
      }
    }
  }

  const _renderTooltips = (data, id, degree) => {
    return (
      <TooltipsWrapper deg={degree}>
        <Tooltips id={id} backgroundColor={Colors.pureWhite} textColor={Colors.black}>
          <Box>
            <TittleTooltips>Vessel Name</TittleTooltips>
            <br />
            <ContentTooltips>{data.Vessel_Name}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Client</TittleTooltips>
            <br />
            <ContentTooltips>{data.Client_Name}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Agent</TittleTooltips>
            <br />
            <ContentTooltips>{data.Agent_Name}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Service Request No</TittleTooltips>
            <br />
            <ContentTooltips>{data.SrNo}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Length Overall</TittleTooltips>
            <br />
            <ContentTooltips>{`${data.Loa} Meters`}</ContentTooltips>
          </Box>
        </Tooltips>
      </TooltipsWrapper>
    )
  }

  return (
    <div
      style={{
        right: 0,
        position: 'fixed',
        width: scaleFactor * 1918,
        height: scaleFactor * 943,
      }}
    >
      {availableJetty && availableJetty.length > 0 && availableJetty[0].show && availableJetty[0].detail && availableJetty[0].detail.length > 0 && (
        <Jetty1a key={availableJetty[0].detail[0]} id='dashboard-maps-div-jetty-1a' position={availableJetty[0].detail[0].Position}>
          <Vessel
            scaleFactor={availableJetty[0].detail[0].Loa / availableJetty[0].jettyLength * 0.225 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[0].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[0].detail[0].Jetty_Name}
          />
          {_renderTooltips(availableJetty[0].detail[0], availableJetty[0].detail[0].Jetty_Name, 60)}
        </Jetty1a>
      )}
      {availableJetty && availableJetty.length > 0 && availableJetty[0].show && availableJetty[0].detail && availableJetty[0].detail.length > 1 && (
        <Jetty1b key={availableJetty[0].detail[1]} id='dashboard-maps-div-jetty-1b' position={availableJetty[0].detail[1].Position}>
          <Vessel
            scaleFactor={availableJetty[0].detail[1].Loa / availableJetty[0].jettyLength * 0.225 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[0].detail[1].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[0].detail[1].Jetty_Name}
          />
          {_renderTooltips(availableJetty[0].detail[1], availableJetty[0].detail[1].Jetty_Name, 60)}
        </Jetty1b>
      )}
      {availableJetty && availableJetty.length > 2 && availableJetty[2].show && availableJetty[2].detail && availableJetty[2].detail.length > 0 && (
        <Jetty2a key={availableJetty[2].detail[0]} id='dashboard-maps-div-jetty-2a'>
          <Vessel
            scaleFactor={availableJetty[2].detail[0].Loa / availableJetty[2].jettyLength * 0.250 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[2].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[2].detail[0].Jetty_Name}
          />
          {_renderTooltips(availableJetty[2].detail[0], availableJetty[2].detail[0].Jetty_Name, -120)}
        </Jetty2a>
      )}
      {availableJetty && availableJetty.length > 2 && availableJetty[2].show && availableJetty[2].detail && availableJetty[2].detail.length > 1 && (
        <Jetty2b key={availableJetty[2].detail[1]} id='dashboard-maps-div-jetty-2b'>
          <Vessel
            scaleFactor={availableJetty[2].detail[1].Loa / availableJetty[2].jettyLength * 0.250 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[2].detail[1].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[2].detail[1].Jetty_Name}
          />
          {_renderTooltips(availableJetty[2].detail[1], availableJetty[2].detail[1].Jetty_Name, -120)}
        </Jetty2b>
      )}
      {availableJetty && availableJetty.length > 0 && availableJetty[1].show && availableJetty[1].detail && availableJetty[1].detail.length > 0 && (
        <Jetty12 key={availableJetty[1].detail[0]} id='dashboard-maps-div-jetty-12' position={availableJetty[1].detail[0].Position}>
          <Vessel
            scaleFactor={availableJetty[1].detail[0].Loa / availableJetty[1].jettyLength * 0.55 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[1].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[1].detail[0].Jetty_Name}
          />
          {_renderTooltips(availableJetty[1].detail[0], availableJetty[1].detail[0].Jetty_Name, 60)}
        </Jetty12>
      )}
      {availableJetty && availableJetty.length > 3 && availableJetty[3].show && availableJetty[3].detail && availableJetty[3].detail.length > 0 && (
        <Jetty3a key={availableJetty[3].detail[0]} id='dashboard-maps-div-jetty-3a'>
          <Vessel
            scaleFactor={availableJetty[3].detail[0].Loa / availableJetty[3].jettyLength * 0.225 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[3].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[3].detail[0].Jetty_Name}
          />
          {_renderTooltips(availableJetty[3].detail[0], availableJetty[3].detail[0].Jetty_Name, 180)}
        </Jetty3a>
      )}
      {availableJetty && availableJetty.length > 3 && availableJetty[3].show && availableJetty[3].detail && availableJetty[3].detail.length > 1 && (
        <Jetty3b key={availableJetty[3].detail[1]} id='dashboard-maps-div-jetty-3b'>
          <Vessel
            scaleFactor={availableJetty[3].detail[1].Loa / availableJetty[3].jettyLength * 0.225 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[3].detail[1].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[3].detail[1].Jetty_Name}
          />
          {_renderTooltips(availableJetty[3].detail[1], availableJetty[3].detail[1].Jetty_Name, 180)}
        </Jetty3b>
      )}
      {availableJetty && availableJetty.length > 4 && availableJetty[4].show && availableJetty[4].detail && availableJetty[4].detail.length > 0 && (
        <JettyRoroa key={availableJetty[4].detail[0]} id='dashboard-maps-div-jetty-roroa'>
          <Vessel
            scaleFactor={availableJetty[4].detail[0].Loa / availableJetty[4].jettyLength * 0.18 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[4].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[4].detail[0].Jetty_Name}
          />
          {_renderTooltips(availableJetty[4].detail[0], availableJetty[4].detail[0].Jetty_Name, 60)}
        </JettyRoroa>
      )}
      {availableJetty && availableJetty.length > 4 && availableJetty[4].show && availableJetty[4].detail && availableJetty[4].detail.length > 1 && (
        <JettyRorob key={availableJetty[4].detail[1]} id='dashboard-maps-div-jetty-rorob'>
          <Vessel
            scaleFactor={availableJetty[4].detail[1].Loa / availableJetty[4].jettyLength * 0.18 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[4].detail[1].Vessel_Size, true)}`]}
            data-tip
            data-for={availableJetty[4].detail[1].Jetty_Name}
          />
          {_renderTooltips(availableJetty[4].detail[1], availableJetty[4].detail[1].Jetty_Name, 60)}
        </JettyRorob>
      )}
    </div>
  )
}

export default VesselSorong
