import { Images } from '../../assets'

import { Logo, Wrapper } from './home.styled'

const Home = () => {
  return (
    <Wrapper>
      HOME
      <Logo src={Images.logo} />
    </Wrapper>
  )
}

export default Home
