import { Droppable } from 'react-drag-and-drop'

import { SchedulerPrintOut } from '../../components'
import { ConvertLocalTimeGet, dateToShortMonth, printDate } from '../../utils'
import {
  InfoWrapper,
  DivAbsolute,
  HeaderWrapper,
  ResourceWrapper,
  WrapperScheduler,
  WrapperAppointment,
} from './printout.style'

const Scheduler = (props) => {
  const { info } = props
  const group = { resources: ['Jetties'] }

  const headerMonthTemplate = (args) => {
    return (
      <HeaderWrapper isMonth={true}>
        <div>{printDate(args.date)}</div>
        <div>{dateToShortMonth(args.date)}</div>
      </HeaderWrapper>
    )
  }
  const resourceHeaderTemplate = (args) => {
    return (
      <Droppable
        types={['jetty']}
        style={{ height: '100%' }} // 200px
        id={`exe-sch-drop-${args.resourceData.Id}-area`}
      >
        <ResourceWrapper>
          <div>{args.resourceData.Jetty_Name}</div>
        </ResourceWrapper>
      </Droppable>
    )
  }

  const eventTemplate = (args) =>  (
    <WrapperAppointment type={args.Sr_Status}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {info.includes('SR No') && <InfoWrapper>{`SR NO : ${args.Sr_No}`} </InfoWrapper>}
        {info.includes('Plan Arrival') && (
          <InfoWrapper>
            {`Plan Arrival : 
                : ${ConvertLocalTimeGet(args.Location, args.Plan_Arrival, 'DD-MM-YYYY HH:mm')}`}
          </InfoWrapper>
        )}
        {info.includes('Plan Depart') && (
          <InfoWrapper>
            {`Plan Depart : 
                : ${ConvertLocalTimeGet(args.Location, args.Plan_Depart, 'DD-MM-YYYY HH:mm')}`}
          </InfoWrapper>
        )}
        {info.includes('Duration') && (
          <InfoWrapper>{`Plan Berthing Duration : ${args.Plan_Berth_Dur}`}</InfoWrapper>
        )}
        <InfoWrapper>{`Status : ${args.Sr_Status}`} </InfoWrapper>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {info.includes('Vessel') && <InfoWrapper> {`Vessel : ${args.Vessel_Name}`} </InfoWrapper>}
        {info.includes('Client') && <InfoWrapper> {`Client : ${args.Client}`} </InfoWrapper>}
        {info.includes('Category') && (
          <InfoWrapper> {`Category : ${args.Sr_Category}`} </InfoWrapper>
        )}
        {info.includes('Agent') && <InfoWrapper> {`Agent : ${args.Agent_Name}`} </InfoWrapper>}
        {info.includes('Location') && <InfoWrapper>{`Loc : ${args.Location}`} </InfoWrapper>}
      </div>
    </WrapperAppointment>
  )

  const onPopupOpen = (args) => {
    args.cancel = true
  }
  return (
    <WrapperScheduler>
      <SchedulerPrintOut
        data={props.data}
        date={props.date}
        paper={props.paper}
        group={group}
        listVessel={props.listVessel}
        onPopupOpen={onPopupOpen}
        headerMonthTemplate={headerMonthTemplate}
        resourceHeaderTemplate={resourceHeaderTemplate}
        eventTemplate={eventTemplate}
        day={props.day}
        eventRendered={(args) => {
          let temp = parseInt(args.element.style.top.slice(0, -2)) + args.data.Index * 158
          args.element.style.height = 195 + 'px'
          args.element.style.top = temp + 'px'
        }}
        {...props}
      />
      <DivAbsolute>
        <div>Date</div>
        <div style={{ height: '3px', width: '100%', background: '#0082ad' }}></div>
        <div>Jetty Name</div>
      </DivAbsolute>
    </WrapperScheduler>
  )
}

export default Scheduler
