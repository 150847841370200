import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'

import { Colors } from '~/styles'
import { ConvertLocalTimeGet, ConvertLocalTimePost } from '~/utils'
import {
  InputDateTime,
  Input,
  EquipmentIcon,
  Button,
  ModalConfirmation
} from '~/components'

import SC from './styled.activity'
import Funcs from './functions.activity'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { Images } from '../../../../assets'

// ========================= FORM SECTION=========================
const DurationInput = ({ form }) => (
  <SC.DurationInputContainer>
    <SC.DurationLabel>Forecast Berthing Duration</SC.DurationLabel>
    <SC.DurationInputWrapper>
      <Input
        form={form}
        type='number'
        placeholder='Hour'
        name='Forecast_Berth_Dur_Hour'
        id='exct-mdl-act-forecast-berth-dur-hour-input'
        InputProps={{
          readOnly: true,
          endAdornment: <InputAdornment position='end'>Hour(s)</InputAdornment>
        }}
      />
      <Input
        form={form}
        type='number'
        placeholder='Minute'
        name='Forecast_Berth_Dur_Minute'
        id='exct-mdl-act-forecast-berth-dur-minute-input'
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>Minute(s)</InputAdornment>
          )
        }}
      />
    </SC.DurationInputWrapper>
  </SC.DurationInputContainer>
)

const TopForm = ({ form }) => (
  <SC.TopFormWrapper>
    <InputDateTime
      form={form}
      readOnly={true}
      name='Forecast_Arrival'
      label='Forecast Arrival'
      id='exct-mdl-act-forecast-arrival-date'
    />
    <InputDateTime
      form={form}
      readOnly={true}
      name='Forecast_Depart'
      label='Forecast Departure'
      id='exct-mdl-act-forecast-depart-date'
    />
    <DurationInput form={form} />
  </SC.TopFormWrapper>
)

const ListActivityCell = ({
  index,
  activity,
  location,
  handleOpenActivityDetail,
  handleCheckProgressActivity
}) => {
  const handleChange = () => {
    handleOpenActivityDetail(activity)
  }

  const isChecked = (() => {
    return activity.start_date ? true : false
  })()

  const payload = (() => {
    const startDate = moment(new Date()).format()

    const finishDate = Funcs.findTheLatestDateFromTasksList(
      activity.progress_task_header_list ?? []
    )

    return {
      ...activity,
      progress: 0,
      id: activity.id,
      sr_code: activity.sr_code,
      start_date: ConvertLocalTimePost(location, startDate),
      modified_date: ConvertLocalTimePost(location, new Date()),
      finish_date:
        finishDate !== '1970-01-01T07:00:00+07:00'
          ? ConvertLocalTimePost(location, finishDate)
          : null
    }
  })()

  return (
    <SC.ActivityCellWrapper>
      <SC.KeyboardArrowDownIcon
        id={`exct-mdl-act-arrow-down-${index}`}
        isOpen={activity.isOpen}
        onClick={handleChange}
      />
      <SC.GreenCheckbox
        color='default'
        checked={isChecked}
        disabled={isChecked}
        // id={`exct-mdl-act-checkbox-${activity.code}`}
        id={`exct-mdl-act-checkbox-${index}`}
        onChange={() => handleCheckProgressActivity(payload)}
      />
      <span>{activity.activity_name}</span>
    </SC.ActivityCellWrapper>
  )
}

const HeaderGrid = () => (
  <SC.THead>
    <SC.HeaderRowGrid>
      <th width='auto'>List Activity</th>
      <th width='252px'>Start Date</th>
      <th width='252px'>Finish Date</th>
      <th width='203px'>Progress</th>
      <th width='107px'></th>
    </SC.HeaderRowGrid>
  </SC.THead>
)

const DetailGridHeader = ({ activity, setActivityList }) => (
  <thead>
    <SC.InnerGridHeader>
      <th width='100px'>Action</th>
      <th width='50px'>No</th>
      <th width='200px'>Task</th>
      <th width='280px'>Planning</th>
      <th width='auto'>Remarks</th>
      <th width='250px'>Start Date</th>
      <th width='250px'>Finish Date</th>
      <th width='200px'>Percentage</th>
      <th width='104px'>
        <ButtonOrder activity={activity} setActivityList={setActivityList} />
      </th>
    </SC.InnerGridHeader>
  </thead>
)

const ButtonOrder = ({ activity, setActivityList }) => {
  const [dataSort, setDataSort] = useState([])
  const [modal, setModal] = useState(false)
  return (
    <div style={{ position: 'relative' }}>
      <SC.ButtonSort onClick={() => setModal(true)}>
        <ImportExportIcon /> Sort
      </SC.ButtonSort>
      {modal && (
        <SortModal
          dataSort={dataSort}
          activity={activity}
          setActivityList={setActivityList}
          setModalChild={(e) => setModal(e)}
          setDataSortChild={(e) => setDataSort(e)}
        />
      )}
    </div>
  )
}
const AddTaskRow = ({ index, activity, addNewTask, provided }) => {
  const [taskDescription, setTaskDescription] = useState('')

  // The user can't add new task if the activity is not checked
  const isChecked = (() => {
    return activity.start_date ? true : false
  })()

  const disableAddTask = (() => {
    return !isChecked || taskDescription === ''
  })()

  const handleChangeTaskDescription = (event) => {
    setTaskDescription(event.target.value)
  }

  const handleAddNewTask = () => {
    addNewTask(activity, taskDescription)
    setTaskDescription('')
  }

  return (
    <tr
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <td colSpan={8}>
        <SC.AddTaskCell>
          <SC.AddTaskInput
            disabled={!isChecked}
            value={taskDescription}
            onChange={handleChangeTaskDescription}
            // id={`exct-mdl-act-add-task-input${activity.code}`}
            id={`exct-mdl-act-add-task-input-${index}`}
          />
          <SC.AddTaskButtonWrapper>
            <Button
              type='submit'
              onClick={handleAddNewTask}
              // id={`exct-mdl-act-add-task-btn${activity.code}`}
              id={`exct-mdl-act-add-task-btn-${index}`}
              disable={disableAddTask}
              color={disableAddTask ? 'grey' : Colors.newGreen}
            >
              Add Task
            </Button>
          </SC.AddTaskButtonWrapper>
        </SC.AddTaskCell>
      </td>
    </tr>
  )
}

const EmptyList = ({ text }) => (
  <tr>
    <td colSpan={6}>
      <SC.EmptyListCell>{text}</SC.EmptyListCell>
    </td>
  </tr>
)

const InViewTaskHeaderRow = ({
  index,
  task,
  activity,
  location,
  provided,
  snapshot,
  deleteTask,
  getItemStyle,
  toggleEditMode,
  handleShowModal
}) => {
  const planStart = ConvertLocalTimeGet(
    location,
    task.plan_start_time,
    'DD-MM-YYYY HH:mm'
  )
  const planFinish = ConvertLocalTimeGet(
    location,
    task.plan_finish_time,
    'DD-MM-YYYY HH:mm'
  )
  const actualStart = ConvertLocalTimeGet(
    location,
    task.actual_start_time,
    'DD-MM-YYYY HH:mm'
  )
  const actualFinish = ConvertLocalTimeGet(
    location,
    task.actual_finish_time,
    'DD-MM-YYYY HH:mm'
  )

  // The user can't update/edit tasks if the activity is not checked
  const isChecked = (() => {
    return activity.start_date ? true : false
  })()

  return (
    <tr
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      id={`exct-mdl-act-inview-tr-${index}`}
    >
      <SC.InnerGridActionCell>
        <div id={`exct-mdl-act-inview-delete-btn-${index}`}>
          <SC.DeleteIcon
            disable={!isChecked}
            onClick={isChecked && (() => deleteTask(activity, task))}
          />
        </div>
        <div id={`exct-mdl-act-inview-edit-btn-${index}`}>
          <SC.EditIcon
            disable={!isChecked}
            onClick={isChecked && (() => toggleEditMode(activity, task))}
          />
        </div>
      </SC.InnerGridActionCell>
      <td style={{ textAlign: 'center' }}>{index + 1}.</td>
      <td>{task.task}</td>
      <td>
        {planStart} - {planFinish}
      </td>
      <td>{task.remarks}</td>
      <td>{actualStart}</td>
      <td>{actualFinish}</td>
      <td>{task.percentage}</td>
      <SC.EquipmentCell>
        <SC.EquipmentIconWrapper
          disable={!isChecked}
          id={`exct-mdl-act-inview-equipment-btn-${index}`}
          onClick={
            isChecked &&
            (() => handleShowModal(activity.code, task.code, task.task))
          }
        >
          <EquipmentIcon
            width='20px'
            height='20px'
            color={isChecked ? Colors.softBlue : Colors.softGrey}
          />
        </SC.EquipmentIconWrapper>
      </SC.EquipmentCell>
    </tr>
  )
}

const InEditTaskHeaderRow = ({
  index,
  task,
  activity,
  location,
  provided,
  snapshot,
  getItemStyle,
  toggleEditMode,
  handleShowModal,
  updateActivityAndTaskHeader
}) => {
  const now = new Date(moment().subtract(1, 'm').toISOString())
  const taskHeaderList = activity.progress_task_header_list ?? []

  const planStart = ConvertLocalTimeGet(
    location,
    task.plan_start_time,
    'YYYY-MM-DD HH:mm'
  )
  const planFinish = ConvertLocalTimeGet(
    location,
    task.plan_finish_time,
    'YYYY-MM-DD HH:mm'
  )

  const taskHeaderForm = useFormik({
    initialValues: {
      task: task.task ?? '',
      remarks: task.remarks ?? '',
      percentage: task.percentage ?? 0,
      actual_start_time:
        ConvertLocalTimeGet(
          location,
          task.actual_start_time,
          'YYYY-MM-DD HH:mm'
        ) ?? '',
      actual_finish_time:
        ConvertLocalTimeGet(
          location,
          task.actual_finish_time,
          'YYYY-MM-DD HH:mm'
        ) ?? ''
    },
    validationSchema: Yup.object({
      percentage: Yup.number().max(100, 'Can not exceed 100%'),
      actual_finish_time: Yup.date()
        .test(
          'Estimate_Finish_Time',
          'Must be greater than actual start time',
          (value) => {
            if (value && taskHeaderForm.values.actual_start_time) {
              const endTime = moment(value).format('DD MMM YYYY HH:mm')
              const startTime = moment(
                taskHeaderForm.values.actual_start_time
              ).format('DD MMM YYYY HH:mm')
              const duration = moment
                .duration(
                  moment(endTime, 'DD MMM YYYY HH:mm').diff(
                    moment(startTime, 'DD MMM YYYY HH:mm')
                  )
                )
                .asSeconds()

              return duration >= 0 ? true : false
            } else {
              return true
            }
          }
        )
        .nullable(),
      actual_start_time: Yup.date()
        .min(
          new Date(activity.start_date ?? now),
          'Must be later than activity start date'
        )
        .test(
          'Estimate_Start_Time',
          'Must filled when finish time not empty',
          (value) => {
            if (taskHeaderForm.values.actual_finish_time) {
              if (value) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          }
        )
        .nullable()
    }),
    onSubmit: (values, { setFieldError }) => {
      try {
        // Adjusting the previous task header list to the newly updated task header
        const updatedTaskHeaderList = taskHeaderList.map((ts) => {
          if (ts.code === task.code) return { ...ts, ...values }
          return ts
        })

        // Prevent the list of tasks has overall progress more than 100% times the number of tasks in total
        const { isExceed100, progress } = Funcs.checkTasksPercentage(
          updatedTaskHeaderList,
          activity.percentage || 0
        )
        if (isExceed100) {
          setFieldError('percentage', 'Can not exceed 100% in total')
          return
        }

        const finishDate = Funcs.findTheLatestDateFromTasksList(
          updatedTaskHeaderList
        )
        const updatedActivity = {
          ...activity,
          progress,
          start_date: activity.start_date,
          finish_date: ConvertLocalTimePost(location, finishDate),
          modified_date: ConvertLocalTimePost(location, new Date())
        }
        const updatedTask = {
          ...task,
          ...values,
          actual_start_time: ConvertLocalTimePost(
            location,
            values.actual_start_time
          ),
          actual_finish_time: ConvertLocalTimePost(
            location,
            values.actual_finish_time
          ),
          modified_date: ConvertLocalTimePost(location, new Date())
        }
        updateActivityAndTaskHeader(updatedActivity, updatedTask)
      } catch (error) {
        return error
      }
    }
  })

  return (
    <tr
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <SC.InnerGridActionCell>
        <div id={`exct-mdl-act-inedit-save-btn-${index}`}>
          <SC.SaveIcon onClick={taskHeaderForm.handleSubmit} />
        </div>
        <div id={`exct-mdl-act-inedit-cancel-btn-${index}`}>
          <SC.CancelIcon onClick={() => toggleEditMode(activity, task)} />
        </div>
      </SC.InnerGridActionCell>
      <td style={{ textAlign: 'center' }}>{index + 1}.</td>
      <td>
        <Input
          tabIndex={1}
          name='task'
          placeholder='Task'
          form={taskHeaderForm}
          id={`exct-mdl-act-inedit-input-task-${index}`}
        />
      </td>
      <td>
        {planStart} - {planFinish}
      </td>
      <td>
        <Input
          tabIndex={2}
          name='remarks'
          placeholder='Remarks'
          form={taskHeaderForm}
          id={`exct-mdl-act-inedit-input-remarks-${index}`}
        />
      </td>
      <td>
        <InputDateTime
          tabIndex={3}
          readOnly={false}
          form={taskHeaderForm}
          name='actual_start_time'
          id={`exct-mdl-act-inedit-input-actual_start_time-${index}`}
        />
      </td>
      <td>
        <InputDateTime
          tabIndex={4}
          readOnly={false}
          form={taskHeaderForm}
          name='actual_finish_time'
          id={`exct-mdl-act-inedit-input-actual_finish_time-${index}`}
        />
      </td>
      <td>
        <Input
          tabIndex={5}
          type='number'
          name='percentage'
          form={taskHeaderForm}
          placeholder='Percentage'
          id={`exct-mdl-act-inedit-input-percentage-${index}`}
        />
      </td>
      <SC.EquipmentCell>
        <SC.EquipmentIconWrapper
          id={`exct-mdl-act-inedit-equipment-btn-${index}`}
          onClick={() => handleShowModal(activity.code, task.code)}
        >
          <EquipmentIcon width='20px' height='20px' color={Colors.softBlue} />
        </SC.EquipmentIconWrapper>
      </SC.EquipmentCell>
    </tr>
  )
}

const TaskList = ({
  activity,
  location,
  deleteTask,
  toggleEditMode,
  handleShowModal,
  progressTaskList,
  updateActivityAndTaskHeader
}) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    display: isDragging ? 'table' : '',
    background: isDragging ? '#ddd' : '',

    // styles we need to apply on draggables
    ...draggableStyle
  })

  return progressTaskList.map((task, index) => (
    <Draggable
      key={task.id}
      index={index}
      draggableId={`draggable-task-${task.id}`}
    >
      {(provided, snapshot) =>
        task.inEdit ? ( // If you don't find this task.inEdit initialization when fetching activity data, it's implicitly false
          <InEditTaskHeaderRow
            index={index}
            task={task}
            key={task.id}
            location={location}
            provided={provided}
            snapshot={snapshot}
            activity={activity}
            getItemStyle={getItemStyle}
            toggleEditMode={toggleEditMode}
            handleShowModal={handleShowModal}
            updateActivityAndTaskHeader={updateActivityAndTaskHeader}
          />
        ) : (
          <InViewTaskHeaderRow
            index={index}
            task={task}
            key={task.id}
            location={location}
            provided={provided}
            snapshot={snapshot}
            activity={activity}
            deleteTask={deleteTask}
            getItemStyle={getItemStyle}
            toggleEditMode={toggleEditMode}
            handleShowModal={handleShowModal}
          />
        )
      }
    </Draggable>
  ))
}

const DetailGridBody = ({
  index,
  activity,
  location,
  onDragEnd,
  addNewTask,
  deleteTask,
  toggleEditMode,
  handleShowModal,
  progressTaskList,
  updateActivityAndTaskHeader
}) => (
  <DragDropContext onDragEnd={(result) => onDragEnd(result, activity)}>
    <Droppable droppableId={`droppable-activity-${activity.id}`}>
      {(provided) => (
        <tbody {...provided.droppableProps} ref={provided.innerRef}>
          {progressTaskList.length === 0 ? (
            <EmptyList text='No Tasks' />
          ) : (
            <TaskList
              location={location}
              activity={activity}
              deleteTask={deleteTask}
              toggleEditMode={toggleEditMode}
              handleShowModal={handleShowModal}
              progressTaskList={progressTaskList}
              updateActivityAndTaskHeader={updateActivityAndTaskHeader}
            />
          )}
          <Draggable
            isDragDisabled
            index={progressTaskList.length}
            draggableId={`draggable-task-${progressTaskList.length + 1}`}
          >
            {(prov) => (
              <AddTaskRow
                index={index}
                provided={prov}
                activity={activity}
                addNewTask={addNewTask}
              />
            )}
          </Draggable>
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  </DragDropContext>
)

const DetailGrid = ({
  index,
  activity,
  location,
  onDragEnd,
  addNewTask,
  deleteTask,
  toggleEditMode,
  setActivityList,
  handleShowModal,
  progressTaskList,
  updateActivityAndTaskHeader
}) => (
  <SC.BodyRowGridDetail>
    <SC.DetailGridCell colSpan={5}>
      <SC.InnerGrid>
        <DetailGridHeader
          activity={activity}
          setActivityList={setActivityList}
        />
        <DetailGridBody
          index={index}
          location={location}
          activity={activity}
          onDragEnd={onDragEnd}
          addNewTask={addNewTask}
          deleteTask={deleteTask}
          toggleEditMode={toggleEditMode}
          handleShowModal={handleShowModal}
          progressTaskList={progressTaskList}
          updateActivityAndTaskHeader={updateActivityAndTaskHeader}
        />
      </SC.InnerGrid>
    </SC.DetailGridCell>
  </SC.BodyRowGridDetail>
)

const ActivityRow = ({
  index,
  location,
  activity,
  onDragEnd,
  addNewTask,
  deleteTask,
  updateActivity,
  toggleEditMode,
  setActivityList,
  handleShowModal,
  handleOpenActivityDetail,
  handleCheckProgressActivity,
  updateActivityAndTaskHeader
}) => {
  const [startDate, setStartDate] = useState(
    ConvertLocalTimeGet(location, activity.start_date, 'YYYY-MM-DD HH:mm')
  )

  const handleChangeStartDate = (values) => {
    if (values !== null) {
      setStartDate(values)
    }
  }

  const handleBlur = () => {
    if (startDate !== null) {
      updateActivity({
        ...activity,
        progress_task_header_list: null,
        modified_date: ConvertLocalTimePost(location, new Date()),
        start_date: ConvertLocalTimePost(location, moment(startDate).format())
      })
    }
  }

  const isChecked = (() => {
    return activity.start_date ? true : false
  })()

  useEffect(() => {
    setStartDate(
      ConvertLocalTimeGet(location, activity.start_date, 'YYYY-MM-DD HH:mm')
    )
  }, [activity.start_date])

  return (
    <>
      <SC.BodyRowGrid showNextRow={activity.isOpen} key={activity.id}>
        <ListActivityCell
          index={index}
          activity={activity}
          location={location}
          handleOpenActivityDetail={handleOpenActivityDetail}
          handleCheckProgressActivity={handleCheckProgressActivity}
        />
        <td>
          <InputDateTime
            readOnly={!isChecked}
            tabIndex={2}
            value={startDate}
            shouldUseOnChange
            name='actual_start_time'
            onChange={handleChangeStartDate}
            onBlur={handleBlur}
            // id={`exct-mdl-act-date-actual_start_time-${activity.code}`}
            id={`exct-mdl-act-date-actual_start_time-${index}`}
          />
        </td>
        <td>
          {ConvertLocalTimeGet(
            location,
            activity.finish_date,
            'DD-MM-YYYY HH:mm'
          )}
        </td>
        <td>
          {activity.progress ?? 0} of {activity.percentage}%
        </td>
        <td></td>
      </SC.BodyRowGrid>
      {activity.isOpen && (
        <DetailGrid
          index={index}
          location={location}
          activity={activity}
          onDragEnd={onDragEnd}
          addNewTask={addNewTask}
          deleteTask={deleteTask}
          toggleEditMode={toggleEditMode}
          handleShowModal={handleShowModal}
          setActivityList={setActivityList}
          updateActivityAndTaskHeader={updateActivityAndTaskHeader}
          progressTaskList={activity.progress_task_header_list || []}
        />
      )}
    </>
  )
}

const ActivityList = ({
  location,
  onDragEnd,
  addNewTask,
  deleteTask,
  activityList,
  toggleEditMode,
  updateActivity,
  setActivityList,
  handleShowModal,
  handleOpenActivityDetail,
  handleCheckProgressActivity,
  updateActivityAndTaskHeader
}) => {
  return activityList.map((activity, index) => (
    <ActivityRow
      index={index}
      key={activity.id}
      location={location}
      activity={activity}
      onDragEnd={onDragEnd}
      addNewTask={addNewTask}
      deleteTask={deleteTask}
      updateActivity={updateActivity}
      toggleEditMode={toggleEditMode}
      handleShowModal={handleShowModal}
      setActivityList={setActivityList}
      handleOpenActivityDetail={handleOpenActivityDetail}
      handleCheckProgressActivity={handleCheckProgressActivity}
      updateActivityAndTaskHeader={updateActivityAndTaskHeader}
    />
  ))
}

const BodyGrid = ({
  location,
  onDragEnd,
  addNewTask,
  deleteTask,
  activityList,
  updateActivity,
  toggleEditMode,
  setActivityList,
  handleShowModal,
  handleOpenActivityDetail,
  handleCheckProgressActivity,
  updateActivityAndTaskHeader
}) => (
  <tbody>
    {activityList.length === 0 ? (
      <EmptyList text='No Activities' />
    ) : (
      <ActivityList
        location={location}
        onDragEnd={onDragEnd}
        addNewTask={addNewTask}
        deleteTask={deleteTask}
        activityList={activityList}
        updateActivity={updateActivity}
        toggleEditMode={toggleEditMode}
        handleShowModal={handleShowModal}
        setActivityList={setActivityList}
        handleOpenActivityDetail={handleOpenActivityDetail}
        handleCheckProgressActivity={handleCheckProgressActivity}
        updateActivityAndTaskHeader={updateActivityAndTaskHeader}
      />
    )}
  </tbody>
)

const ActivityGrid = ({
  location,
  onDragEnd,
  addNewTask,
  deleteTask,
  activityList,
  toggleEditMode,
  updateActivity,
  setActivityList,
  handleShowModal,
  handleOpenActivityDetail,
  handleCheckProgressActivity,
  updateActivityAndTaskHeader
}) => (
  <SC.GridContainer>
    <SC.Grid>
      <HeaderGrid />
      <BodyGrid
        location={location}
        onDragEnd={onDragEnd}
        deleteTask={deleteTask}
        addNewTask={addNewTask}
        activityList={activityList}
        updateActivity={updateActivity}
        toggleEditMode={toggleEditMode}
        handleShowModal={handleShowModal}
        setActivityList={setActivityList}
        handleOpenActivityDetail={handleOpenActivityDetail}
        handleCheckProgressActivity={handleCheckProgressActivity}
        updateActivityAndTaskHeader={updateActivityAndTaskHeader}
      />
    </SC.Grid>
  </SC.GridContainer>
)

const WarningModal = ({
  dataAll,
  notConfirm,
  openWarning,
  isInProgress,
  setNotConfirm,
  setOpenWarning,
  totalPercentage
}) => {
  const message = (() => {
    if (notConfirm) {
      return 'Your Request has not been Confirmed yet'
    }

    if (totalPercentage < 100 && isInProgress) {
      return 'Activity Percentage not 100% and Vessel is still In Progress'
    }

    if (isInProgress) {
      return 'Vessel is still In Progress'
    }

    if (dataAll.Contract_No === '') {
      return 'Please Insert Agreement Number First'
    }

    return 'Activity Percentage have not reach 100% yet'
  })()

  return (
    <ModalConfirmation
      isWarn={true}
      open={openWarning}
      close={() => {
        setNotConfirm(false)
        setOpenWarning(false)
      }}
      title='ATTENTION !!'
      message={message}
      icon={
        <ErrorOutlineRoundedIcon
          style={{ fontSize: '60px', color: '#eed202' }}
        />
      }
    />
  )
}

const SortModal = ({
  setDataSortChild,
  dataSort,
  setModalChild,
  activity,
  setActivityList
}) => {
  const dataDropdown = [
    { field: 'task', name: 'Task', id: 1, dir: 'asc' },
    { field: 'plan_start_time', name: 'Planning', id: 2, dir: 'asc' },
    { field: 'actual_start_time', name: 'Start Date', id: 3, dir: 'asc' },
    { field: 'actual_finish_time', name: 'Finish Date', id: 4, dir: 'asc' },
    { field: 'percentage', name: 'Percentage', id: 5, dir: 'asc' },
    { field: 'remarks', name: 'Remarks', id: 6, dir: 'asc' }
  ]

  const dataFilterModule = () => {
    var dataSlice = dataDropdown
    dataSort.forEach((datal) => {
      const index = dataDropdown.findIndex((item) => item.id == datal.id)
      dataSlice.splice(index, 1)
    })
    return dataSlice
  }

  const handleAdd = (event) => {
    const arr = [...dataSort]
    arr.push(event.target.value)
    setDataSortChild(arr)
  }

  const handleRemove = (event) => {
    const arr = dataSort.filter((item) => {
      return item.id != event.id
    })
    setDataSortChild(arr)
  }
  const handleSort = (event) => {
    const arr = dataSort.map((item) => {
      if (item.id == event.id) {
        if (item.dir == 'asc') {
          return { ...item, dir: 'desc' }
        } else {
          return { ...item, dir: 'asc' }
        }
      } else {
        return item
      }
    })
    setDataSortChild(arr)
  }

  const handleClose = () => {
    setModalChild(false)
  }

  const handleUpdate = async () => {
    const payload = {
      dataSort: dataSort,
      data: {
        activity_code: activity.code,
        sr_code: activity.sr_code,
        modified_date: new Date()
      },
      handleClose: () => setModalChild(false),
      setActivityList: setActivityList
    }
    await Funcs.updateSort(payload)
  }
  return (
    <SC.ModalSort>
      <div style={{ borderBottom: '1px solid black', width: '100%' }}>
        {dataSort.map((item, i) => (
          <SC.SortListWrapper key={i}>
            <SC.SortTextList>{item.name}</SC.SortTextList>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <img
                  src={Images.sortDirIcon}
                  style={{
                    height: '100%',
                    width: '100%',
                    transform: `rotate(${
                      item.dir == 'asc' ? '0deg' : '180deg'
                    })`,
                    marginRight: 5,
                    cursor: 'pointer',
                    color: '#A3AAB6'
                  }}
                  onClick={() => handleSort(item)}
                />
              </div>
              <div>
                <img
                  src={Images.deleteIcon}
                  style={{
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer',
                    color: '#A3AAB6'
                  }}
                  onClick={() => handleRemove(item)}
                />
              </div>
            </div>
          </SC.SortListWrapper>
        ))}
        <FormControl
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '10px 15px 0px'
          }}
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 }
          }}
          size='small'
        >
          <Select
            disableUnderline
            displayEmpty={true}
            renderValue={() => '  + Add sort'}
            onChange={(e) => handleAdd(e)}
            style={{
              marginBottom: 5,
              backgroundColor: 'transparent !important'
            }}
          >
            {dataFilterModule().map((item, i) => (
              <MenuItem key={i} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          margin: '10px 0 5px 0',
          direction: 'rtl',
          width: '100%',
          display: 'flex'
        }}
      >
        <SC.ButtonFilter onClick={handleUpdate}>Update</SC.ButtonFilter>
        <SC.ButtonClose onClick={handleClose}>Close</SC.ButtonClose>
      </div>
    </SC.ModalSort>
  )
}

export default {
  TopForm,
  WarningModal,
  ActivityGrid
}
