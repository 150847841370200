import * as Yup from 'yup'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { LogAPI, ServiceRequestAPI } from '../../api'
import {
  MasterModal,
  Alerts,
  ModalDel,
  ModalCancel,
  ModalReject,
  ModalApprove,
} from '../../components'
import SrModalContent from './service-request.modal.sr.container'
import DocModalContent from './service-request.modal.doc.container'
import ProdModalContent from './service-request.modal.prod.container'
import CargoModalContent from './service-request.modal.cargo.container'
import { Nav, MenuList, MenuWrapper, ContentList } from './service-request.styled'
import { messageErrorFormikLog } from '../../utils/message'

import { Lookup } from '../../utils'

const ServiceRequestModal = (props) => {
  const {
    isEdit,
    isDetail,
    isDelete,
    isRevise,
    isApproved,
    isWaitApproved,
    isFromQueryParam,
    data: dataSr,
    pathRole,
  } = props
  const appSize = useSelector((state) => state.appReducer)
  const { Email:UserEmail }  = useSelector((state) => state.usersLoginReducer.User)
  const [menu, setMenu] = useState('sr')
  const [category, setCategory] = useState([])
  const [srProducts, setSrProducts] = useState([])
  const [agent, setAgent] = useState([])
  const [client, setClient] = useState([])
  const [srType, setSrType] = useState([])
  const [vessel, setVessel] = useState([])
  const [portLocation, setPortLocation] = useState([])
  const [attachments, setAttachments] = useState([])
  const [statusCodes, setStatusCodes] = useState({})
  const [generalCode, setGeneralCode] = useState('')
  const [iftCode, setIftCode] = useState('')
  const [reject, setReject] = useState(false)
  const [approve, setApprove] = useState(false)
  const [statusType, setStatusType] = useState()
  const [isCanceling, setIsCanceling] = useState(false)
  const [isLocationLocked, setIsLocationLocked] = useState(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false })
  const [isLoading, setIsLoading] = useState(true)
  const [isClicked, setIsClicked] = useState(false)

  const _insertServiceRequest = async (form, data) => {
    try {
      const bulkData = { ...data }
      bulkData['sr_product_array'] = srProducts
      bulkData['attachment_array'] = attachments
      bulkData['Sr_Status_Code'] = statusCodes['Waiting for Approval']
      if (srProducts.length > 0 && attachments.length > 0) {
        const response = await ServiceRequestAPI.InsertMany(bulkData)
        if (response && response.result) {
          // form.resetForm()
          setAlert({ msg: 'Service Request Has Been Submitted', success: true, visible: true })
        } else {
          let messageError = `Failed to Submit Service Request. 
            ${response ? response.message : ''}`

          setAlert({ msg: messageError, success: false, visible: true })
        }
      } else {
        setAlert({
          msg: 'Please Add Minimum 1 Product & 1 Document',
          success: false,
          visible: true,
        })
      }
      setIsClicked(false)
      _changeButtonStateAfterClick()
    } catch (err) {
      setIsClicked(false)
      console.log(err)
      _changeButtonStateAfterClick()
      return err
    }
  }

  const _updateServiceRequest = async (form, data) => {
    try {
      const bulkData = { ...data }
      bulkData['sr_product_array'] = srProducts
      bulkData['attachment_array'] = attachments
      bulkData['Sr_Status_Code'] = statusCodes['Waiting for Approval']

      if (srProducts.length > 0 && attachments.length > 0) {
        const response = await ServiceRequestAPI.UpdateMany(bulkData)
        if (response && response.result) {
          if (isWaitApproved && isEdit) {
            setAlert({ msg: 'Service Request Has Been updated', success: true, visible: true })
          } else if (isWaitApproved && isDetail) {
            setAlert({
              msg: 'Service Request No ' + bulkData.Sr_No + ' has been Approved',
              success: true,
              visible: true,
            })
          } else {
            setAlert({ msg: 'Service Request Has Been Submitted', success: true, visible: true })
          }
        } else {
          if (isWaitApproved && isEdit) {
            let messageError = `Failed to Update Service Request. 
              ${response ? response.message : ''}`

            setAlert({ msg: messageError, success: false, visible: true })
          } else if (isWaitApproved && isDetail) {
            setAlert({
              msg: 'Service Request No ' + bulkData.Sr_No + ' Failed to Approve',
              success: false,
              visible: true,
            })
          } else {
            let messageError = `Failed to Submit Service Request. 
              ${response ? response.message : ''}`

            setAlert({ msg: messageError, success: false, visible: true })
          }
        }
      } else {
        setAlert({
          msg: 'Please Add Minimum 1 Product & 1 Document',
          success: false,
          visible: true,
        })
      }
      setIsClicked(false)
      _changeButtonStateAfterClick()
    } catch (err) {
      setIsClicked(false)
      console.log(err)
      _changeButtonStateAfterClick()
      return err
    }
  }

  const _saveAsDraftServiceRequest = async (form, data) => {
    try {
      const bulkData = { ...data }
      if (!bulkData.Eta || !bulkData.Etd) {
        bulkData.Estimate_Berth_Dur = 0
      }
      bulkData['sr_product_array'] = srProducts
      bulkData['attachment_array'] = attachments
      bulkData['Sr_Status_Code'] = statusCodes['Draft']
      let response
      if (isEdit) {
        response = await ServiceRequestAPI.UpdateMany(bulkData)
      } else {
        response = await ServiceRequestAPI.InsertMany(bulkData)
      }
      if (response && response.result) {
        if (isEdit) {
          setAlert({ msg: 'Update Draft Has Been Successful', success: true, visible: true })
        } else {
          setAlert({ msg: 'Add Draft Has Been Successful', success: true, visible: true })
        }
      } else {
        if (isEdit) {
          setAlert({ msg: 'Failed to Update Draft', success: false, visible: true })
        } else {
          setAlert({ msg: 'Failed to Add Draft', success: false, visible: true })
        }
      }
      setIsClicked(false)
      _changeButtonStateAfterClick()
    } catch (err) {
      setIsClicked(false)
      console.log(err)
      _changeButtonStateAfterClick()
      return err
    }
  }

  const _deleteClient = async () => {
    try {
      const response = await ServiceRequestAPI.Delete(props.data.Id)
      if (response && response.result) {
        props.fetchData()
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
      } else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _checkError = () => {
    const {
      Sr_Type_Code,
      Location_Code,
      Sr_Category_Code,
      Client_Code,
      Agent_Code,
      Vessel_Code,
      Eta,
      Etd,
      Estimate_Berth_Dur,
      Cargo_No,
    } = ServiceRequestForm.errors
    if (
      Sr_Type_Code ||
      Location_Code ||
      Sr_Category_Code ||
      Client_Code ||
      Agent_Code ||
      Vessel_Code ||
      Eta ||
      Etd ||
      Estimate_Berth_Dur
    ) {
      setMenu('sr')
    } else if (Cargo_No) {
      setMenu('prod')
    }
  }

  const _changeButtonStateAfterClick = () => {
    setIsSubmitButtonDisabled(false)
  }

  const _submitFormikForm = () => {
    ServiceRequestForm.handleSubmit()
    //Need _changeButtonStateAfterClick because handleSubmit wont run if there's validation error
    if(!ServiceRequestForm.isValid) {
      _changeButtonStateAfterClick()
    }
  }

  const _handleFormSubmission = () => {
    _checkError()
    insertLog({
      save: Lookup.LOG_ACTION.SUBMIT,
      detail: messageErrorFormikLog(ServiceRequestForm.errors),
    })

    setIsSubmitButtonDisabled(true)
    setTimeout(() => {
      _submitFormikForm()
    }, 100)
  }

  const ServiceRequestRejectForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      RejectRemarks: '',
    },
    validationSchema: Yup.object({
      RejectRemarks: Yup.string().required('Required*'),
    }),
    onSubmit: async (values) => {
      statusType === 'revise' ? _handleRevise(values) : _handleReject(values)
    },
  })

  const closeReject = () => {
    ServiceRequestRejectForm.resetForm()
    setStatusType()
    setReject(false)
  }

  const closeApprove = () => {
    setApprove(false)
  }

  const _modalReject = () => (
    <div>
      <ModalReject
        ServiceRequestRejectForm={ServiceRequestRejectForm}
        typeModal={statusType}
        rejectText={statusType === 'revise' ? 'Are you sure to revise?' : 'Are you sure to reject?'}
        toggleDialog={() => closeReject()}
      />
    </div>
  )

  const _approveServiceRequest = async () => {
    const dataTemp = await {
      Code: props.data.Code,
      SR_No: props.data.Sr_No,
      SR_Status_Code: statusCodes['Waiting for Approval'],
      Action: 'Approved',
      Head_Client: props.data.User_Id,
      Remarks: props.data.Remarks,
      Location_Code: props.data.Location_Code,
    }
    const response = await ServiceRequestAPI.SubmitConfirm(dataTemp)
    if (response.data) {
      await closeApprove()
      await setAlert({
        msg: 'Service Request No ' + props.data.Sr_No + ' has been Approved',
        success: true,
        visible: true,
      })
    } else {
      setAlert({
        msg: 'Service Request No ' + props.data.Sr_No + ' Failed to Approve',
        success: false,
        visible: true,
      })
    }
  }

  const _modalApprove = () => (
    <div>
      <ModalApprove
        handleApprove={() => {
          setIsClicked(true)
          _approveServiceRequest()
        }}
        isClicked={isClicked}
        approveText='Are you sure to approve?'
        toggleDialog={() => closeApprove()}
      />
    </div>
  )

  const openModalApprove = () => {
    setApprove(true)
  }

  const openModalRevise = () => {
    setStatusType('revise')
  }

  const openModalReject = () => {
    setReject(true)
  }

  const _handleReject = async (insert) => {
    const tempData = {
      Code: props.data.Code,
      SR_No: props.data.Sr_No,
      SR_Status_Code: statusCodes['Waiting for Approval'],
      Action: 'Rejected',
      Head_Client: props.data.User_Id,
      Remarks: insert.RejectRemarks,
      Location_Code: props.data.Location_Code,
    }
    try {
      const response = await ServiceRequestAPI.SubmitConfirm(tempData)
      if (response.data) {
        await closeReject()
        await setAlert({
          msg: 'Service Request No ' + props.data.Sr_No + ' has been rejected',
          success: true,
          visible: true,
        })
      } else {
        setAlert({
          msg: 'Service Request No ' + props.data.Sr_No + ' Failed to rejected',
          success: false,
          visible: true,
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _handleRevise = async (insert) => {
    const tempData = {
      Code: props.data.Code,
      SR_No: props.data.Sr_No,
      SR_Status_Code: statusCodes['Waiting for Approval'],
      Action: 'Revised',
      Head_Client: props.data.User_Id,
      Remarks: insert.RejectRemarks,
      Location_Code: props.data.Location_Code,
    }
    try {
      const response = await ServiceRequestAPI.SubmitConfirm(tempData)
      if (response.data) {
        await closeReject()
        await setAlert({
          msg: 'Data has been Returned to be Revised',
          success: true,
          visible: true,
        })
      } else {
        setAlert({
          msg: 'Service Request No ' + props.data.Sr_No + ' Failed to revise',
          success: false,
          visible: true,
        })
      }
    } catch (err) {
      console.log(err)
      return err
    }
  }

  const _handleFormSaveAsDraft = () => {
    setIsSubmitButtonDisabled(true)
    setTimeout(() => {
      const form = ServiceRequestForm
      let messageError = ''
      form.setTouched({})
      const data = { ...props.data }
      Object.keys(form.values)
        .filter(
          (val) =>
            val == 'Id' ||
            val == 'Sr_No' ||
            val == 'Sr_Type_Code' ||
            val == 'Location_Code' ||
            val == 'Sr_Category_Code' ||
            val == 'Client_Code' ||
            val == 'Agent_Code' ||
            val == 'Vessel_Code' ||
            val == 'Eta' ||
            val == 'Etd' ||
            val == 'Estimate_Berth_Dur' ||
            val == 'Remarks' ||
            val == 'Cargo_No'
        )
        .forEach((vl) => {
          if (vl === 'Eta' || vl === 'Etd') {
            // send datetime as UTC
            data[vl] = moment(form.values[vl]).toISOString()
          } else {
            data[vl] = form.values[vl]
          }
        })

      if (data['Location_Code']) {
        if (
          data['Eta'] &&
          !(data['Eta'] > moment(new Date(moment().subtract(1, 'm').toISOString())).toISOString())
        ) {
          form.setFieldTouched('Eta', true)
          messageError = 'Eta cant be backdated'
          setMenu('sr')
          insertLog({
            save: Lookup.LOG_ACTION.SAVE_DRAFT,
            detail: messageError,
          })

          return _changeButtonStateAfterClick()
        }
        if (
          data['Etd'] &&
          !(data['Etd'] > moment(new Date(moment().subtract(1, 'm').toISOString())).toISOString())
        ) {
          form.setFieldTouched('Etd', true)
          setMenu('sr')
          messageError = 'ETD must be greater than ETA'

          insertLog({
            save: Lookup.LOG_ACTION.SAVE_DRAFT,
            detail: messageError,
          })

          return _changeButtonStateAfterClick()
        }
        if (data['Eta'] && data['Etd'] && data['Eta'] > data['Etd']) {
          form.setFieldTouched('Eta', true)
          form.setFieldTouched('Etd', true)
          setMenu('sr')
          messageError = ' ETD must be greater than ETA'
          insertLog({
            save: Lookup.LOG_ACTION.SAVE_DRAFT,
            detail: messageError,
          })
          return _changeButtonStateAfterClick()
        }
        _saveAsDraftServiceRequest(form, data)
      } else {
        messageError = messageError + messageError.length > 0 ? ',' : '' + ' Location is required'

        form.setFieldTouched('Location_Code', true)
        setMenu('sr')
        insertLog({
          save: Lookup.LOG_ACTION.SAVE_DRAFT,
          detail: messageError,
        })

        _changeButtonStateAfterClick()
      }
    }, 100)
  }

  const _submitCancel = async ({ CanceledRemarks }) => {
    try {
      const response = await ServiceRequestAPI.CancelSr({
        // Id: ServiceRequestForm.values.Id, Sr_Status_Code: statusCodes.Canceled, Canceled_Remarks: CanceledRemarks,
        SR_No: ServiceRequestForm.values.Sr_No, // 'SR0062011028',
        SR_Status_Code: ServiceRequestForm.values.Sr_Status_Code, // 'ENC0038',
        Action: 'Rejected',
        Remarks: CanceledRemarks, // 'Notif Confirm',
        Location_Code: ServiceRequestForm.values.Location_Code, // 6,
      })
      if (response && response.data) {
        setAlert({
          msg: 'Service request has been successfully cancelled',
          success: true,
          visible: true,
        })
      } else {
        setAlert({ msg: 'Failed to Cancel Service Request', success: false, visible: true })
      }
      setIsClicked(false)
    } catch (error) {
      setIsClicked(false)
      console.log(error)
    }
  }

  const _closeAlert = () => {
    if (isFromQueryParam) {
      props.closeSrModal(isFromQueryParam)
    } else if (showAlert.success) {
      props.closeSrModal()
    } else if (isDelete) {
      props.closeSrModal()
    }
    setAlert({ msg: '', success: true, visible: false })
  }

  const _getCategory = async () => {
    try {
      // const filter ='[{"field":"type","type":"text","value":"SR_Category"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetSRCategory()
      setCategory(data)
      const generalData = data.find((e) => e.Value.toLowerCase() === 'general')
      const iftData = data.find((e) => e.Value.toLowerCase() === 'ift')
      if (generalData) {
        setGeneralCode(generalData['Code'])
      }
      if (iftData) {
        setIftCode(iftData['Code'])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const _getStatusCodes = async () => {
    try {
      // const filter ='[{"field":"type","type":"text","value":"SR_Status"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetSRStatus()
      if (data) {
        const obj = {}
        data.forEach((v) => (obj[v.Value] = v.Code))
        setStatusCodes(obj)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getAgent = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllMasterDataAgent(0, 100, filter)
      setAgent(data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getClient = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllMasterDataClient(0, 100, filter)
      setClient(data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getSrType = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllServiceRequestType(0, 100, filter)
      setSrType(data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getVessel = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllMasterDataVessel(0, 100, filter)
      setVessel(data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getLocation = async () => {
    try {
      const filter = '[{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await ServiceRequestAPI.GetAllLocation(0, 100, filter)
      setPortLocation(data)
    } catch (error) {
      console.log(error)
    }
  }

  const _deleteModal = () => (
    <div>
      <ModalDel
        submitDelete={_deleteClient}
        deleteText='Are you sure want to delete'
        toggleDialog={() => props.closeSrModal()}
      />
    </div>
  )

  const _getSrCategoryValue = (Code) => {
    const categoryObj = {}
    category.forEach((v) => (categoryObj[v.Code] = v.Value))
    return categoryObj[Code]
  }

  const _setSrCategoryValue = () => {
    ServiceRequestForm.setFieldValue(
      'Sr_Category_Value',
      _getSrCategoryValue(ServiceRequestForm.values.Sr_Category_Code)
    )
  }

  const _setEmptyIfDeleted = (value, options, optionValueKey) => {
    if (options) {
      const foundValue = options.find((e) => e[optionValueKey] === value)
      return foundValue ? value : ''
    } else ''
  }

  const insertLog = async (data) => {
    const dataInsert = {
      Timestamp: moment().toISOString(),
      Action: data.save,
      Module: Lookup.LOG_MODULE.SERVICE_REQUEST,
      Submodule: Lookup.LOG_SUB_MODULE.SERVICE_REQUEST + '[' + data.save + ']',
      Detail: data.detail,
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From: '',
      To: '',
      Refno: ServiceRequestForm.values.Code,
    }

    if (!ServiceRequestForm.isValid && data.detail && data.detail.length > 0) {
      const { data } = await LogAPI.PostDataLog(dataInsert)
      if (data && data.result) return data.result
    } else return false
  }
  const ServiceRequestForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      Id: isEdit || isDetail ? props.data.Id : 0,
      Code: isEdit || isDetail ? props.data.Code : '',
      Sr_No: isEdit || isDetail ? props.data.Sr_No : 'SRXXXXXXXXXX',
      Sr_Status_Code: isEdit || isDetail ? props.data.Sr_Status_Code : '',
      Sr_Type_Code:
        isEdit || isDetail ? _setEmptyIfDeleted(props.data.Sr_Type_Code, srType, 'Code') : '',
      Location_Code:
        isEdit || isDetail ? _setEmptyIfDeleted(props.data.Location_Code, portLocation, 'Id') : '',
      Sr_Category_Code:
        isEdit || isDetail ? _setEmptyIfDeleted(props.data.Sr_Category_Code, category, 'Code') : '',
      Sr_Category_Value:
        isEdit || isDetail
          ? _getSrCategoryValue(_setEmptyIfDeleted(props.data.Sr_Category_Code, category, 'Code'))
          : '',
      Client_Code:
        isEdit || isDetail
          ? _setEmptyIfDeleted(props.data.Client_Code, client, 'SAP_Customer_No')
          : '',
      Agent_Code:
        isEdit || isDetail ? _setEmptyIfDeleted(props.data.Agent_Code, agent, 'Agent_Code') : '',
      Vessel_Code:
        isEdit || isDetail ? _setEmptyIfDeleted(props.data.Vessel_Code, vessel, 'Code') : '',
      Eta: isEdit || isDetail ? props.data.Eta_Non_Formatted : null,
      Etd: isEdit || isDetail ? props.data.Etd_Non_Formatted : null,
      Estimate_Berth_Dur: isEdit || isDetail ? props.data.Estimate_Berth_Dur : 0,
      Estimate_Berth_Dur_Hour: '',
      Estimate_Berth_Dur_Minute: '',
      Remarks: isEdit || isDetail ? props.data.Remarks : '',
      Created_Date: isEdit || isDetail ? props.data.Created_Date_Non_Formatted : null,
      Created_By: isEdit || isDetail ? props.data.Created_By : '',
      Cargo_No: isEdit || isDetail ? props.data.Cargo_No : '',
    },
    validationSchema: Yup.object({
      Id: Yup.number(),
      Sr_No: Yup.string(),
      Sr_Type_Code: Yup.string().required('Required*'),
      Location_Code: Yup.number().required('Required*'),
      Sr_Category_Code: Yup.string().required('Required*'),
      Client_Code: Yup.string().required('Required*'),
      Agent_Code: Yup.string().required('Required*'),
      Vessel_Code: Yup.string().required('Required*'),
      Eta: Yup.date()
        .required('Required*')
        .nullable()
        .min(new Date(moment().subtract(1, 'm').toISOString()), 'Cannot be Backdated'),
      Etd: Yup.date()
        .required('Required*')
        .nullable()
        .min(Yup.ref('Eta'), 'Must be greater than ETA'),
      Estimate_Berth_Dur: Yup.number().required('Required*'),
      Remarks: Yup.string(),
      Cargo_No: Yup.string().required('Required*'),
    }),
    onSubmit: async (values, form) => {
      setIsClicked(true)
      const data = { ...props.data }
      Object.keys(values)
        .filter(
          (val) =>
            val == 'Id' ||
            val == 'Sr_No' ||
            val == 'Sr_Type_Code' ||
            val == 'Location_Code' ||
            val == 'Sr_Category_Code' ||
            val == 'Client_Code' ||
            val == 'Agent_Code' ||
            val == 'Vessel_Code' ||
            val == 'Eta' ||
            val == 'Etd' ||
            val == 'Estimate_Berth_Dur' ||
            val == 'Remarks' ||
            val == 'Cargo_No'
        )
        .forEach((vl) => {
          if (vl === 'Eta' || vl === 'Etd') {
            // send datetime as UTC
            data[vl] = moment(values[vl]).toISOString()
          } else {
            data[vl] = values[vl]
          }
        })
      if (isEdit) {
        _updateServiceRequest(form, data)
      } else {
        _insertServiceRequest(form, data)
      }
    },
  })

  const ServiceRequestCancelForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      CanceledRemarks: '',
    },
    validationSchema: Yup.object({
      CanceledRemarks: Yup.string().required('Required*'),
    }),
    onSubmit: async (values) => {
      setIsClicked(true)
      _submitCancel(values)
    },
  })

  const _cancelModal = () => (
    <div>
      <ModalCancel
        isClicked={isClicked}
        ServiceRequestCancelForm={ServiceRequestCancelForm}
        deleteText='Are you sure to cancel service request? If Yes, please type cancellation reason'
        toggleDialog={() => setIsCanceling(!isCanceling)}
      />
    </div>
  )

  useEffect(() => {
    _getAll()
  }, [])

  const _getAll = async () => {
    await _getAgent()
    await _getClient()
    await _getSrType()
    await _getVessel()
    await _getLocation()
    await _getCategory()
    await _getStatusCodes()
  }

  useEffect(() => {
    const isStatusCodesAvailable = Object.keys(statusCodes).length
    if (isStatusCodesAvailable) {
      setIsLoading(false)
    }
  }, [statusCodes])

  useEffect(() => {
    if (props.data && props.data.Location_Code) {
      setIsLocationLocked(true)
    }
  }, [props])

  useEffect(() => {
    _setSrCategoryValue()
  }, [ServiceRequestForm.values.Sr_Category_Code])

  return (
    <>
      {showAlert.visible ? (
        <Alerts
          id='m_dly_alert'
          msg={showAlert.msg}
          close={() => _closeAlert()}
          open={showAlert.visible}
          success={showAlert.success}
        />
      ) : isDelete ? (
        _deleteModal()
      ) : isCanceling ? (
        _cancelModal()
      ) : null}
      {!isDelete && !isCanceling && (
        <MasterModal
          step={props.data ? props.data.Sr_Status : ''}
          width={80}
          maxWidth='lg'
          height={825}
          heightType={'px'}
          add={!isDetail}
          isStatic={true}
          isSrModal={true}
          fullWidth={true}
          isRevise={isRevise}
          isApproved={isApproved}
          isWaitApproved={isWaitApproved}
          isDetailSrModal={isDetail}
          title='Service Request'
          showModal={props.showModal}
          handleReject={openModalReject}
          handleApprove={openModalApprove}
          handleRevise={openModalRevise}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          handleClose={() => props.closeSrModal(isFromQueryParam)}
          hasSubtitle={false}
          subTitle={
            isEdit
              ? 'Edit Service Request'
              : isDetail
                ? 'Detail Service Request'
                : 'Add Service Request'
          }
          handleSave={_handleFormSubmission}
          handleSaveDraft={_handleFormSaveAsDraft}
          cancelable={isApproved && props.data.Created_By_Email === UserEmail}
          handleCancel={() => setIsCanceling(true)}
          utilizeLoading={true}
          isLoading={isLoading}
        >
          {reject && _modalReject()}
          {statusType === 'revise' && _modalReject()}
          {approve && _modalApprove()}
          <Nav>
            <MenuWrapper>
              <MenuList
                id='sr-modal-menu-sr-btn'
                active={menu === 'sr'}
                onClick={() => setMenu('sr')}
              >
                Service Request
              </MenuList>
              {/* <MenuList
                id='sr-modal-menu-prod-btn'
                active={menu === 'prod'}
                onClick={() => setMenu('prod')}
              >
                Product
              </MenuList> */}
              <MenuList
                id='sr-modal-menu-cargo-btn'
                active={menu === 'cargo'}
                onClick={() => setMenu('cargo')}
              >
                Cargo &amp; Labour/Equipment
              </MenuList>
              <MenuList
                id='sr-modal-menu-doc-btn'
                active={menu === 'doc'}
                onClick={() => setMenu('doc')}
              >
                Document
              </MenuList>
            </MenuWrapper>
          </Nav>
          <div style={{ height: '92%' }}>
            <ContentList isDetail={isDetail} active={menu === 'sr'} appSize={appSize}>
              <SrModalContent
                agent={agent}
                client={client}
                srType={srType}
                vessel={vessel}
                portLocation={portLocation}
                dataSr={dataSr}
                isEdit={isEdit}
                isDetail={isDetail}
                category={category}
                srProducts={srProducts}
                isApproved={isApproved}
                isWaitApproved={isWaitApproved}
                remarkRevise={props.remarkRevise}
                isLocationLocked={isLocationLocked}
                ServiceRequestForm={ServiceRequestForm}
              />
            </ContentList>
            <ContentList isDetail={isDetail} active={menu === 'cargo'} appSize={appSize}>
              <CargoModalContent dataSr={dataSr}/>
            </ContentList>
            <ContentList isDetail={isDetail} active={menu === 'prod'} appSize={appSize}>
              <ProdModalContent
                isEdit={isEdit}
                appSize={appSize}
                isDetail={isDetail}
                pathRole={pathRole}
                srProducts={srProducts}
                isApproved={isApproved}
                isWaitApproved={isWaitApproved}
                generalCode={generalCode}
                iftCode={iftCode}
                setSrProducts={setSrProducts}
                ServiceRequestForm={ServiceRequestForm}
                _setEmptyIfDeleted={_setEmptyIfDeleted}
              />
            </ContentList>
            <ContentList isDetail={isDetail} active={menu === 'doc'} appSize={appSize}>
              <DocModalContent
                isEdit={isEdit}
                isDetail={isDetail}
                setAlert={setAlert}
                pathRole={pathRole}
                isApproved={isApproved}
                isWaitApproved={isWaitApproved}
                attachments={attachments}
                setAttachments={setAttachments}
                ServiceRequestForm={ServiceRequestForm}
                setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
              />
            </ContentList>
          </div>
        </MasterModal>
      )}
    </>
  )
}

export default ServiceRequestModal
