import _ from 'lodash'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import { ConvertLocalTimePost, ConvertLocalTimeGet } from '~/utils'
import { ExecutionAPI, MasterDataAPI } from '~/api'
import {
  InlineEditableGrid,
  ModalUpload,
  ButtonGrid,
  ModalDel,
  Alerts
} from '../../../../../components'

import SC from '../styled.cargo-equipment'
import { useCalculateTableWidth } from '../functions.cargo-equipment'

import gridColumns from './column'
import constants from './constants.cargo'
import moment from 'moment'
import union from 'lodash/union'

const CargoGrid = (props) => {
  const tableWidth = useCalculateTableWidth(gridColumns())

  const [id, setId] = useState(0)
  const [edit, setEdit] = useState(false)
  const [dataDel, setDataDel] = useState({})
  const [prodCode, setProdCode] = useState()
  const [material, setMaterial] = useState()
  const [inEdit, setInedit] = useState(false)
  const [cargoData, setCargoData] = useState([])
  const [productCode, setProductCode] = useState()
  const [newCargoData, setNewCargoData] = useState([])
  const [toggleDelete, setToggleDelete] = useState(false)
  const [modalDeleteAll, setModalDeleteAll] = useState(false)

  const [changeCargoNo, setChangeCargoNo] = useState(cargoNo)
  const [cargoNo, setCargoNo] = useState(props.dataAll.Cargo_No)
  const [uomData, setUomData] = useState(constants.initValUOM)
  const [inEditValues, setInEditValues] = useState(constants.initValCargo)
  const [showAlert, setAlert] = useState({
    visible: false,
    msg: '',
    success: false,
    type: ''
  })

  const [modalUpload, setModalUpload] = useState(false)
  const toggleModalUpload = () => setModalUpload(!modalUpload)

  const [disabled, setDisabled] = useState(false)
  const [disabledCr, setDisabledCr] = useState(false)
  const [cargoNoEdit, setCargoNoEdit] = useState(false)
  const [checkedCargos, setCheckedCargos] = useState([])
  const [checkedAllCargos, setCheckedAllCargo] = useState(false)

  const [search, setSearch] = useState('')
  const [addSearch, setAddSearch] = useState('')

  const onKeyPress = (e) => {
    if (e.charCode == 13) {
      setAddSearch(search)
    }
  }
  const toggleEdit = () => {
    setCargoNoEdit(!cargoNoEdit)
    setChangeCargoNo(cargoNo)
  }

  const formSubmitCargo = useFormik({
    initialValues: inEditValues,
    enableReinitialize: true,
    validateOnChange: true,
    validate: (values) => {
      setInEditValues((prev) => {
        const isLWHChanging =
          prev.length !== values.length ||
          prev.width !== values.width ||
          prev.height !== values.height
        const volBasedOnLWH = values.length * values.width * values.height

        return {
          ...values,
          volume: isLWHChanging ? volBasedOnLWH : values.volume
        }
      })
    },
    // validationSchema: Yup.object({

    //   width: Yup.number().required('*Number*'),
    //   volume: Yup.number().required('*Number*'),
    //   weight: Yup.number().required('*Number*'),
    //   height: Yup.number().required('*Number*'),
    //   length: Yup.number().required('*Number*'),
    //   quantity: Yup.number().required('*Number*'),
    //   diameter: Yup.number().required('*Number*'),
    //   uom_lwh: Yup.string().required('This field can’t be empty'),
    //   uom_volume: Yup.string().required('This field can’t be empty'),
    //   uom_weight: Yup.string().required('This field can’t be empty'),
    // product_name: Yup.string().required('This field can’t be empty'),
    //   product_type: Yup.string().required('This field can’t be empty'),
    //   uom_diameter: Yup.string().required('This field can’t be empty'),
    //   uom_quantity: Yup.string().required('This field can’t be empty'),
    //   dangerous_goods: Yup.string().required('This field can’t be empty'),
    // }),
    onSubmit: async (values) => {
      setDisabled(true)
      let matCode =
        material.length > 0
          ? material.filter((f) => f.value == values.material_status)
          : ''
      const dataSave = {
        data: {
          ID: id !== 0 ? id : 0,
          Header_Code: '',
          SR_Code: props.dataAll.Code,
          Product_Code: productCode !== undefined ? productCode.code : '',
          Product_Type: values.product_type,
          Product_Name: values.product_name,
          Cargo_No: cargoNo,
          Quantity: parseFloat(values.quantity),
          UOM_Quantity: values.uom_quantity,
          Length: parseFloat(values.length),
          Width: parseFloat(values.width),
          Height: parseFloat(values.height),
          UOM_LWH: values.uom_lwh,
          Weight: parseFloat(values.weight),
          UOM_Weight: values.uom_weight,
          Diameter: parseFloat(values.diameter),
          UOM_Diameter: values.uom_diameter,
          Volume: parseFloat(values.volume),
          UOM_Volume: values.uom_volume,
          Product_Number: values.product_number,
          Dangerous_Goods: values.dangerous_goods,
          Material_Status: matCode.length > 0 ? matCode[0].code : '',
          Material_Date: values.material_date
            ? ConvertLocalTimePost(props.dataAll.Location, values.material_date)
            : '',
          created_date:
            values.created_date === ''
              ? ConvertLocalTimePost(props.dataAll.Location, new Date())
              : values.created_date,
          modified_date: ConvertLocalTimePost(
            props.dataAll.Location,
            new Date()
          )
        }
      }
      if (edit) {
        try {
          const result = await ExecutionAPI.EditCargo(dataSave)
          if (result.result) {
            setInedit(false)
            setId(0)
            setEdit(false)
            await getData()
          }
        } catch (error) {
          return error
        }
      } else {
        try {
          const result = await ExecutionAPI.InsertCargo(dataSave)
          if (result.result) {
            setInedit(false)
            setCheckedAllCargo(false)
            await getData()
          }
        } catch (error) {
          return error
        }
      }
      setDisabled(false)
    }
  })

  // handle check all cargo
  const handleCheckAllCargo = () => {
    const cargoIds = cargoData.map((f) => f.cargo_detail_id)

    setCheckedAllCargo(!checkedAllCargos)

    if (checkedAllCargos) {
      // reset when its true, and unchecked all cargo
      setCheckedCargos([])
    } else {
      // checked all checkboxes when its false
      const mergedCargos = union(checkedCargos, cargoIds)
      setCheckedCargos(mergedCargos)
    }
  }

  // handle check cargo single item
  const handleCheckCargo = (cargoId) => {
    const cargoIds = checkedCargos.find((f) => f === cargoId)

    let updatedCargos = []
    if (!cargoIds) {
      // Add new checklist data item
      updatedCargos = [...checkedCargos, cargoId]
      setCheckedCargos(updatedCargos)
    } else {
      // Unchecked data item
      updatedCargos = checkedCargos.filter((f) => f !== cargoId)
      setCheckedCargos(updatedCargos)
    }

    // set checked all checkbox when checkedCargos equal cargo
    const forcedCheckedAll = updatedCargos.length === cargoData.length
    setCheckedAllCargo(forcedCheckedAll)
  }

  const handleToggleDeleteAll = () => setModalDeleteAll(!modalDeleteAll)
  const handleToggleDelete = () => setToggleDelete(!toggleDelete)

  const handleDeleteAllCargo = async () => {
    if (checkedCargos.length === 0) return

    const FormData = {
      data_deleted: checkedCargos
    }

    const response = await ExecutionAPI.DeleteMultipleCargo(FormData)

    if (response && response.error == '') {
      getData()
      setCheckedAllCargo(false)
      setCheckedCargos([])
    }

    handleToggleDeleteAll()
  }

  const _generateProductNumber = (newData, cargo) => {
    let maxNumber,
      maxNumber1,
      maxNumber2,
      newID,
      data,
      typeNumber,
      productNumber

    maxNumber1 =
      newData.length > 0
        ? Math.max.apply(
            0,
            newData.map((e) => {
              return e.product_number !== '' ? e.cargo_detail_id : 0
            })
          )
        : 1
    maxNumber2 =
      cargo.length > 0
        ? Math.max.apply(
            0,
            cargo.map((e) => {
              return e.product_number !== '' ? e.cargo_detail_id : 0
            })
          )
        : 1

    maxNumber1 >= maxNumber2 && newData.length > 0
      ? ((maxNumber = maxNumber1), (data = newData))
      : ((maxNumber = maxNumber2), (data = cargo))

    newID = data.length > 0 ? maxNumber + 1 : maxNumber

    typeNumber = props.dataAll.Sr_No.split('-').length == 1 ? '-0' : ''

    productNumber =
      props.dataAll.Sr_No +
      typeNumber +
      '-MAT-' +
      (data.length > 0
        ? maxNumber !== 0 &&
          !_.isUndefined(
            data[data.findIndex((e) => e.cargo_detail_id === maxNumber)]
              .product_number
          )
          ? Number(
              data[
                data.findIndex((e) => e.cargo_detail_id === maxNumber)
              ].product_number.split('-')[3]
            ) + 1
          : 1
        : 1)

    return { product_number: productNumber, id: newID }
  }

  const _importCargo = async (dataImport, cargoData) => {
    let newCargo = []

    const submitCargo = async (values) => {
      let product = prodCode.filter(
        (f) => f.description === values.product_type
      )
      let materials =
        material && material.length > 0
          ? material.filter((mt) => mt.value == values.material_status)
          : []

      const dataForm = {
        data: {
          Header_Code: '',
          SR_Code: props.dataAll.Code,
          Product_Code: product.length !== 0 ? product[0].code : '',
          ID: values.cargo_detail_id !== 0 ? values.cargo_detail_id : 0,
          Product_Type: product.length !== 0 ? values.product_type : '',
          Product_Name: values.product_name,
          Cargo_No: cargoNo,
          Quantity: parseFloat(values.quantity),
          UOM_Quantity: values.uom_quantity,
          Length: parseFloat(values.length),
          Width: parseFloat(values.width),
          Height: parseFloat(values.height),
          UOM_LWH: values.uom_lwh,
          Weight: parseFloat(values.weight),
          UOM_Weight: values.uom_weight,
          Diameter: parseFloat(values.diameter),
          UOM_Diameter: values.uom_diameter,
          Volume: parseFloat(values.volume),
          UOM_Volume: values.uom_volume,
          Dangerous_Goods: values.dangerous_goods,
          Product_Number: values.product_number,
          Material_Status: materials.length > 0 ? materials[0].code : '',
          Material_Date: moment(values.material_date).utc().toISOString(),
          source: 'excel',
          created_date: ConvertLocalTimePost(
            props.dataAll.Location,
            new Date()
          ),
          modified_date: ConvertLocalTimePost(
            props.dataAll.Location,
            new Date()
          )
        }
      }

      values.post == 'edit'
        ? await ExecutionAPI.EditCargo(dataForm)
        : await ExecutionAPI.InsertCargo(dataForm)
    }

    if (cargoData.length !== 0) {
      dataImport.map((e) => {
        if (e.is_deleted === 'FALSE' && e.product_number === '') {
          const response = _generateProductNumber(newCargo, cargoData)
          newCargo.push({
            ...e,
            post: 'new',
            cargo_detail_id: response.id,
            product_number: response.product_number
          })
        } else if (e.is_deleted === 'FALSE' && e.product_number !== '') {
          const indexData = cargoData.findIndex(
            (record) => record.product_number === e.product_number
          )

          indexData !== -1
            ? (newCargo[indexData] = {
                ...e,
                cargo_detail_id: cargoData[indexData].cargo_detail_id,
                product_number: cargoData[indexData].product_number,
                post: 'edit'
              })
            : null
        } else if (e.is_deleted === 'TRUE' && e.product_number !== '') {
          const indexData = cargoData.findIndex(
            (record) => record.product_number === e.product_number
          )

          indexData !== -1
            ? (newCargo[indexData] = {
                ...e,
                post: 'delete',
                cargo_detail_id: cargoData[indexData].cargo_detail_id
              })
            : null
        }
      })
    } else {
      dataImport.map((e) => {
        if (e.is_deleted === 'FALSE' && e.product_number === '') {
          const response = _generateProductNumber(newCargo, cargoData)
          newCargo.push({
            ...e,
            post: 'new',
            cargo_detail_id: response.id,
            product_number: response.product_number
          })
        }
      })
    }

    await newCargo.map((e) => {
      if (!_.isUndefined(e.post)) {
        e.post === 'new' || e.post === 'edit' ? submitCargo(e) : handleRemove(e)
      }
    })
    await getData()
  }

  const addCargo = () => {
    setInedit(true)
    _getAllDataType()
    formSubmitCargo.resetForm()
    const product = _generateProductNumber([], cargoData)
    const newCargo = {
      ...constants.initValCargo,
      edit: true,
      product_number: product.product_number
    }
    const updatedData = [newCargo, ...cargoData].map((item, index) => ({
      ...item,
      editIndex: index
    }))
    setInEditValues(newCargo)
    setCargoData(updatedData)
  }

  const handleSave = () => {
    if (formSubmitCargo.values.product_type !== '') {
      let data_code = []
      let data = prodCode
      for (var j = 0; j < data.length; j++) {
        if (data[j]['product_type'] === formSubmitCargo.values.product_type) {
          data_code.push(data[j])
        }
      }
      setProductCode(data_code[0])
    }
    formSubmitCargo.submitForm()
  }

  const handleCancel = () => {
    setEdit(false)
    setInedit(false)
    setId(0)
    setInEditValues({
      product_name: ''
    })
    getData()
  }

  const handleEdit = (item) => {
    setEdit(true)
    setInedit(true)
    setId(item.cargo_detail_id)
    const wantTobeUpdatedItem = {
      ...item,
      edit: true,
      material_date: item.material_date
        ? new Date(
            ConvertLocalTimeGet(
              props.dataAll.Location,
              item.material_date,
              'MM-DD-YYYY HH:mm'
            )
          )
        : item.material_date
    }
    const updatedData = cargoData.map((val, index) => {
      if (item.editIndex === val.editIndex)
        return { ...val, edit: true, editIndex: index }
      return { ...val, editIndex: index }
    })
    setInEditValues(wantTobeUpdatedItem)
    setCargoData(updatedData)
  }

  const handleRemove = async (item) => {
    const dataRemove = {
      data: {
        id: item.cargo_detail_id,
        modified_date: new Date()
      }
    }
    try {
      const result = await ExecutionAPI.DeleteCargo(dataRemove)
      if (result) {
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete'
        })
        getData()
        setToggleDelete(!toggleDelete)

        // Reset deleted cargos checklist
        const indexCheckedCargo = checkedCargos.findIndex(
          (f) => f === item.cargo_detail_id
        )

        // if cargo is available, exclude the cargo_detail_id from checkedCargos
        if (indexCheckedCargo !== -1) {
          const excludeCargo = checkedCargos.filter(
            (f) => f !== item.cargo_detail_id
          )
          setCheckedCargos(excludeCargo)
        }
      } else {
        setAlert({
          msg: 'Cannot delete data',
          success: false,
          visible: true,
          type: 'delete'
        })
      }
    } catch (error) {
      return error
    }
  }

  const editCargoNo = async () => {
    setDisabledCr(true)
    const cargoSelected = cargoData.map((element) => {
      let ele = { ...element }
      const materialSelected = uomData.material_status.find(
        (e) => e.value === ele.material_status
      )
      if (materialSelected) {
        ele.material_status = materialSelected.code
      }
      return ele
    })

    const cargoOrdered =
      _.orderBy(cargoSelected, ['cargo_detail_id'], ['asc']) ?? []
    const edt = {
      data: {
        Header_Code: '',
        Cargo_No: changeCargoNo,
        SR_Code: props.dataAll.Code,
        created_date: props.dataAll.Created_Date,
        modified_date: ConvertLocalTimePost(props.dataAll.Location, new Date()),
        Cargo_Detail: cargoOrdered
      }
    }
    try {
      const result = await ExecutionAPI.EditCargoNo(edt)
      if (result.result) {
        setCargoNo(changeCargoNo)
        setCargoNoEdit(false)
      }
    } catch (error) {
      return error
    }
    setDisabledCr(false)
  }

  const getData = async () => {
    try {
      const result = await ExecutionAPI.getCargoData(props.dataAll.Sr_No)
      const updatedResult = (result.data.data ?? []).map((item, index) => {
        let data = { ...item, editIndex: index }
        return data
      })
      const newResult = _.orderBy(updatedResult, ['modified_date'], ['desc'])
      setCargoData(newResult)
      setNewCargoData(newResult)
    } catch (error) {
      return error
    }
  }

  const _getAllDataType = async () => {
    try {
      const response = await MasterDataAPI.GetMasterDataType()
      const dangerous_goods = await MasterDataAPI.GetFilteredMasterDataEnum(
        'good'
      )
      const material_status = await MasterDataAPI.GetFilteredMasterDataEnum(
        'material_status'
      )
      const product_type = await MasterDataAPI.GetMasterProductType()
      if (
        response &&
        dangerous_goods &&
        product_type &&
        response.data.message.data &&
        dangerous_goods.message.data &&
        material_status.message.data &&
        product_type.data.message.data
      ) {
        setUomData({
          uom_lwh: response.data.message.data.filter(
            (f) => f.uom_type == 'MUT1'
          ),
          uom_volume: response.data.message.data.filter(
            (f) => f.uom_type == 'MUT2'
          ),
          uom_weight: response.data.message.data.filter(
            (f) => f.uom_type == 'MUT3'
          ),
          uom_diameter: response.data.message.data.filter(
            (f) => f.uom_type == 'MUT1'
          ),
          uom_quantity: response.data.message.data.filter(
            (f) => f.uom_type == 'MUT4'
          ),
          product_type: product_type.data.message.data.sort((a, b) =>
            a.description.localeCompare(b.description)
          ),
          dangerous_goods: dangerous_goods.message.data.sort((a, b) =>
            a.value.localeCompare(b.value)
          ),
          material_status: material_status.message.data.sort((a, b) =>
            a.value.localeCompare(b.value)
          )
        })
        setProdCode(product_type.data.message.data)
        setMaterial(material_status.message.data)
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    _getAllDataType()
    getData()
  }, [])

  const handleRemoveModal = (item, type) => (
    <div>
      <ModalDel
        id='m_actv_delete_mdl'
        toggleDialog={() =>
          type === 'all' ? handleToggleDeleteAll() : handleToggleDelete()
        }
        submitDelete={() =>
          type === 'all' ? handleDeleteAllCargo() : handleRemove(item)
        }
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const setDataDelete = async (item) => {
    await setDataDel(item)
    handleToggleDelete()
  }

  return (
    <div>
      {toggleDelete && handleRemoveModal(dataDel, 'single')}
      {modalDeleteAll && handleRemoveModal(null, 'all')}

      <div>
        <SC.Title>Cargo</SC.Title>
      </div>
      <SC.TopContentCargo>
        <SC.TextFieldContainer>
          <TextField
            label=''
            style={{ width: '400px' }}
            disabled={!cargoNoEdit}
            value={cargoNoEdit ? changeCargoNo : cargoNo}
            onChange={(e) => setChangeCargoNo(e.target.value)}
            id='execution-mdl-tab-cargo-equipment-cargo_no-txt'
          />
          {!cargoNoEdit ? (
            <SC.IconContainer onClick={() => toggleEdit()}>
              <SC.EditIcon />
            </SC.IconContainer>
          ) : (
            <SC.IconContainer onClick={() => toggleEdit()}>
              <SC.CancelIcon />
            </SC.IconContainer>
          )}
          {cargoNoEdit && (
            <SC.IconContainer
              disabled={disabledCr}
              onClick={() => {
                changeCargoNo.length > 60
                  ? setAlert({
                      msg: 'Cargo Number Max. 60 Characters.',
                      success: false,
                      visible: true,
                      type: 'delete'
                    })
                  : disabledCr
                  ? {}
                  : editCargoNo()
              }}
            >
              <SC.SaveIcon disabled={disabledCr} />
            </SC.IconContainer>
          )}
        </SC.TextFieldContainer>
        <ButtonGrid
          enableSearch
          inEdit={inEdit}
          onAdd={addCargo}
          search={search}
          enableUpload={true}
          enableDelete={checkedCargos.length > 0}
          onDelete={() => handleToggleDeleteAll()}
          onChangeSearch={(e) => {
            setSearch(e.target.value)
          }}
          onKeyPress={onKeyPress}
          placeholder='Search by Material Desc'
          onSearchClick={() => setAddSearch(search)}
          onClickReset={() => {
            setSearch('')
            setAddSearch('')
          }}
          reset={search.length > 0 ? true : false}
          onUpload={toggleModalUpload}
        />
      </SC.TopContentCargo>
      <div>
        <InlineEditableGrid
          sticky={true}
          forceEdit={true}
          isFixedCell
          rowData={uomData}
          tableHeight='215px'
          disabled={disabled}
          location={props.dataAll.Location}
          columns={gridColumns(
            handleCheckAllCargo,
            handleCheckCargo,
            checkedCargos,
            checkedAllCargos
          )}
          tableWidth={tableWidth}
          handleEdit={handleEdit}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleRemove={setDataDelete}
          formSubmit={formSubmitCargo}
          data={cargoData.filter(
            (c) =>
              c.product_name.toLowerCase().includes(addSearch.toLowerCase()) ||
              addSearch == ''
          )}
        />
      </div>
      <ModalUpload
        data={cargoData}
        params={props.dataAll}
        showModal={modalUpload}
        handleClose={toggleModalUpload}
        importCargo={async (e) => {
          await handleCancel()
          await _importCargo(e, newCargoData)
        }}
      />
      <Alerts
        type={showAlert.type}
        open={showAlert.visible}
        close={setAlert}
        msg={showAlert.msg}
        success={showAlert.success}
      />
    </div>
  )
}

export default CargoGrid
