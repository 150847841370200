// import moment from 'moment'
import { sortBy } from 'lodash'
import getConfig from 'next/config'
import GridColumn from './column.json'
import { useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'

import { NotificationAPI } from '../../api'
import { MasterGrid, ColumnOption, Loading } from '../../components'
import { ConvertLocalTimeGet, GenerateFilterTerm } from '../../utils'
import { LoadingContainer } from './notification.styled.container'

const NotifHistory = (props) => {
  const [state, setState] = useState([])
  const { publicRuntimeConfig } = getConfig()
  const [dataCount, setDataCount] = useState(0)
  const [activeColumns, setActiveColumns] = useState([])
  const [inactiveColumns, setInactiveColumns] = useState([])
  const [gridColumnActive, setGridColumnActive] = useState([])
  const [gridColumnLoading, setGridColumnLoading] = useState(true)
  const appSize = useSelector((state) => state.appReducer)
  const heightGrid = appSize.height - 190 > 455 ? appSize.height - 268 : 455
  const [dataState, setDataState] = useState({ skip: 0, take: 20, filter: [], filterString: '[]' })
  const [pending, setPending] = useState(false)

  const onDataStateChange = async ({ data }) => {
    setDataState({
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })

    setPending(true)
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchData({ ...dataState,
        filterString: GenerateFilterTerm(dataState.filter, GridColumn) })
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(delay)
  }, [dataState.filter])

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    })

    fetchData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString,
    })
  }

  const reorderColumns = (event) => {
    let columns = [...event.columns].filter((item) => item.field !== 'action')
    columns = sortBy(columns, ['orderIndex']).map((item1, index) => {
      const found = activeColumns.find((item2) => item2.columnName === item1.field.toLowerCase())
      return { ...found, sortNo: index }
    })
    setActiveColumns(columns)
    try {
      NotificationAPI.UpdateColumnOption(
        columns.map((item) => ({
          Id: item.id,
          Code: item.code,
          Column_Name: item.columnName,
          Column_Description: item.columnDesc,
          Is_Active: true,
          Sort_No: item.sortNo,
        }))
      )
    } catch (error) {
      return error
    }
  }

  const mainGrid = () => {
    if (gridColumnLoading) {
      return (
        <LoadingContainer style={{ height: '75vh', alignItems: 'center' }}>
          <Loading id='settlement-grid-loading-indicator' />
        </LoadingContainer>
      )
    }

    return (
      <MasterGrid
        data={state}
        total={dataCount}
        pending={pending}
        reorderable={true}
        height={heightGrid}
        dataState={dataState}
        pageSizes={[20, 50, 100]}
        gridColumn={gridColumnActive}
        onColumnReorder={reorderColumns}
        onPageChange={(e) => onPageChange(e)}
        onDataStateChange={(e) => onDataStateChange(e)}
      />
    )
  }

  const fetchData = async ({ skip, take, filterString }) => {
    let res = encodeURIComponent(filterString)
    try {
      const response = await NotificationAPI.GetHistoryNotif(skip, take, res)
      if (response.status === 200) {
        const resCols = await NotificationAPI.GetColumnOption()
        if (resCols.status === 200) {
          const dataColumns = resCols.data.data
          const active = []
          const inactive = []
          let column = {}
          dataColumns.forEach((item) => {
            column = {
              id: item.Id,
              code: item.Code,
              columnName: item.Column_Name,
              columnDesc: item.Column_Description,
              sortNo: item.Sort_No,
              isActive: item.Is_Active,
            }
            if (column.isActive) active.push(column)
            else inactive.push(column)
          })

          const gridSelected = GridColumn.filter((element) => {
            const activeElement = active.find(
              (ele) => ele.columnName.toLowerCase() === element.field.toLowerCase()
            )
            if (element.field.toLowerCase() === 'action' || activeElement) {
              if (activeElement) {
                element['sortNo'] = activeElement.sortNo
                element['columnName'] = activeElement.columnName
                element['columnDesc'] = activeElement.columnDesc
                element['code'] = activeElement.code
                element['id'] = activeElement.id
              } else {
                element['sortNo'] = -1
              }

              return element
            } else return false
          })

          const sorted = sortBy(gridSelected, ['sortNo'])

          setActiveColumns(sorted)
          setInactiveColumns(inactive)
          setGridColumnActive(sorted)
          setGridColumnLoading(false)

          setState(response.data.data.map((e)=> {
            e['Modified_Date'] = ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm')
            e['Etd'] = ConvertLocalTimeGet(e.Location, e.Etd, 'DD/MM/YYYY HH:mm')
            e['Eta'] = ConvertLocalTimeGet(e.Location, e.Eta, 'DD/MM/YYYY HH:mm')
            e['Ata'] = e.Ata ? ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm') : ''
            e['Atd'] = e.Atd ? ConvertLocalTimeGet(e.Location, e.Atd, 'DD/MM/YYYY HH:mm') : ''
            e['Created_Date'] = ConvertLocalTimeGet(e.Location, e.Created_Date, 'DD/MM/YYYY HH:mm')

            return e
          }))
          setDataCount(response.data.count)
        }
        setPending(false)
      }
    } catch (err) {
      setPending(false)
      console.log('eror get list history', err)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData(dataState)
    }, 5000)
    return () => clearInterval(intervalId)
  }, [dataState])

  useEffect(() => {
    fetchData(dataState)
  }, [])

  return (
    <>
      { mainGrid() }
      { props.showColumnOption && (
        <ColumnOption
          dataState={dataState}
          fetchData={fetchData}
          parentActiveColumns={activeColumns}
          parentInactiveColumns={inactiveColumns}
          setShowColumnOption={props.setShowColumnOption}
          setGridColumnLoading={setGridColumnLoading}
          updateApi={NotificationAPI.UpdateColumnOption}
        />
      )}
    </>
  )
}
export default NotifHistory