import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { Images } from '../../../assets'

import {
  Vessel,
  Jetty1,
  Jetty2,
  Jetty3,
  Jetty4,
  Jetty5,
  Jetty5a,
  Jetty5b,
  Jetty6,
  Jetty6a,
  Jetty6b,
  Jetty7,

  Box,
  Tooltips,
  TittleTooltips,
  TooltipsWrapper,
  ContentTooltips,
} from './dashboard-jetty.styled'
import { Colors } from '../../../styles'

const VesselKariangau = (props) => {
  const [availableJetty, setAvailableJetty] = useState([])

  const appSize = useSelector((state) => state.appReducer)
  const scaleFactor = (parseInt(appSize.height) - 110 * parseInt(appSize.height)/943) / 943

  useEffect(() => {
    _setAvailableJetty()
  }, [props.dataJetty])

  const _setAvailableJetty = () => {
    let available = []
    props.dataJetty.map((dt, i) => {
      const avail = {
        position: i,
        show: dt.Detail != null && dt.Detail.length > 0,
        detail: dt.Detail ? dt.Detail.sort((a, b) => a.position - b.position) : [],
        jettyLength: dt.Length,
      }
      available.push(avail)
    })
    setAvailableJetty(available)
  }

  const _getVesselName = (name, mirror) => {
    if (name) {
      let array = name.split('|')
      let names = array[0].replace('/', '_')
      // const vname = `V${names}${array[1].replace(' ', '')}`
      if(mirror && names === 'Rig') {
        return `V${names}Mirror`
      } else {
        return `V${names}`
      }
    }
  }

  const _renderTooltips = (data, id, degree) => {
    return (
      <TooltipsWrapper deg={degree}>
        <Tooltips id={id} backgroundColor={Colors.pureWhite} textColor={Colors.black}>
          <Box>
            <TittleTooltips>Vessel Name</TittleTooltips>
            <br />
            <ContentTooltips>{data.Vessel_Name}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Client</TittleTooltips>
            <br />
            <ContentTooltips>{data.Client_Name}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Agent</TittleTooltips>
            <br />
            <ContentTooltips>{data.Agent_Name}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Service Request No</TittleTooltips>
            <br />
            <ContentTooltips>{data.SrNo}</ContentTooltips>
          </Box>
          <Box>
            <TittleTooltips>Length Overall</TittleTooltips>
            <br />
            <ContentTooltips>{`${data.Loa} Meters`}</ContentTooltips>
          </Box>
        </Tooltips>
      </TooltipsWrapper>
    )
  }

  return (
    <div
      style={{
        right: 0,
        position: 'fixed',
        width: scaleFactor * 1918,
        height: scaleFactor * 943,
      }}
    >
      {availableJetty.length > 0 && availableJetty[0].show && (
        <Jetty1 id='dashboard-maps-div-jetty-1'>
          <Vessel
            scaleFactor={availableJetty[0].detail[0].Loa / availableJetty[0].jettyLength * 0.3 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[0].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for='jetty1'
          />
          {_renderTooltips(availableJetty[0].detail[0], 'jetty1', -158)}
        </Jetty1>
      )}
      {availableJetty.length > 1 && availableJetty[1].show && (
        <Jetty2 id='dashboard-maps-div-jetty-2'>
          <Vessel
            scaleFactor={availableJetty[1].detail[0].Loa / availableJetty[1].jettyLength * 0.1875 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[1].detail[0].Vessel_Size)}`]}
            data-tip
            data-for='jetty2'
          />
          {_renderTooltips(availableJetty[1].detail[0], 'jetty2', 21)}
        </Jetty2>
      )}
      {availableJetty.length > 2 && availableJetty[2].show && (
        <Jetty3 id='dashboard-maps-div-jetty-3'>
          <Vessel
            scaleFactor={availableJetty[2].detail[0].Loa / availableJetty[2].jettyLength * 0.18 * scaleFactor * 1918 / 345}
            transformOrigin='bottom'
            src={Images[`${_getVesselName(availableJetty[2].detail[0].Vessel_Size)}`]}
            data-tip
            data-for='jetty3'
          />
          {_renderTooltips(availableJetty[2].detail[0], 'jetty3', 0)}
        </Jetty3>
      )}
      {availableJetty.length > 3 && availableJetty[3].show && (
        <Jetty4 id='dashboard-maps-div-jetty-4'>
          <Vessel
            scaleFactor={availableJetty[3].detail[0].Loa / availableJetty[3].jettyLength * 0.1775 * scaleFactor * 1918 / 345}
            transformOrigin='top'
            src={Images[`${_getVesselName(availableJetty[3].detail[0].Vessel_Size, true)}`]}
            data-tip
            data-for='jetty4'
          />
          {_renderTooltips(availableJetty[3].detail[0], 'jetty4', 90)}
        </Jetty4>
      )}
      {availableJetty.length > 4 &&
          availableJetty[4].show &&
          availableJetty[4].detail.map((jt, i) => {
            return (
              <Jetty5 key={i} bank={jt.Position > 1} id='dashboard-maps-div-jetty-5'>
                <Vessel
                  scaleFactor={jt.Loa / availableJetty[4].jettyLength * 0.22 * scaleFactor * 1918 / 345}
                  transformOrigin='bottom'
                  src={Images[`${_getVesselName(jt.Vessel_Size)}`]}
                  data-tip
                  data-for={`jetty5${i}`}
                />
                {_renderTooltips(jt, `jetty5${i}`, 90)}
              </Jetty5>
            )
          })}
      {availableJetty.length > 5 &&
          availableJetty[5].show &&
          availableJetty[5].detail.map((jt, i) => {
            return (
              <Jetty5a key={i} bank={jt.Position > 1} id='dashboard-maps-div-jetty-5a'>
                <Vessel
                  scaleFactor={jt.Loa / availableJetty[5].jettyLength * 0.11 * scaleFactor * 1918 / 345}
                  transformOrigin='right bottom'
                  src={Images[`${_getVesselName(jt.Vessel_Size)}`]}
                  data-tip
                  data-for={`jetty5a${i}`}
                />
                {_renderTooltips(jt, `jetty5a${i}`, 90)}
              </Jetty5a>
            )
          })}
      {availableJetty.length > 6 &&
          availableJetty[6].show &&
          availableJetty[6].detail.map((jt, i) => {
            return (
              <Jetty5b key={i} bank={jt.Position > 1} id='dashboard-maps-div-jetty-5b'>
                <Vessel
                  scaleFactor={jt.Loa / availableJetty[6].jettyLength * 0.11 * scaleFactor * 1918 / 345}
                  transformOrigin='left bottom'
                  src={Images[`${_getVesselName(jt.Vessel_Size)}`]}
                  data-tip
                  data-for={`jetty5b${i}`}
                />
                {_renderTooltips(jt, `jetty5b${i}`, 90)}
              </Jetty5b>
            )
          })}
      {availableJetty.length > 7 &&
          availableJetty[7].show &&
          availableJetty[7].detail.map((jt, i) => {
            return (
              <Jetty6 key={i} bank={jt.Position > 1} id='dashboard-maps-div-jetty-6'>
                <Vessel
                  scaleFactor={jt.Loa / availableJetty[7].jettyLength * 0.26 * scaleFactor * 1918 / 345}
                  transformOrigin='bottom'
                  src={Images[`${_getVesselName(jt.Vessel_Size)}`]}
                  data-tip
                  data-for={`jetty6${i}`}
                />
                {_renderTooltips(jt, `jetty6${i}`, 0)}
              </Jetty6>
            )
          })}
      {availableJetty.length > 8 &&
            availableJetty[8].show &&
            availableJetty[8].detail.map((jt, i) => {
              return (
                <Jetty6a key={i} bank={jt.Position > 1} id='dashboard-maps-div-jetty-6a'>
                  <Vessel
                    scaleFactor={jt.Loa / availableJetty[8].jettyLength * 0.13 * scaleFactor * 1918 / 345}
                    transformOrigin='right bottom'
                    src={Images[`${_getVesselName(jt.Vessel_Size)}`]}
                    data-tip
                    data-for={`jetty6a${i}`}
                  />
                  {_renderTooltips(jt, `jetty6a${i}`, 0)}
                </Jetty6a>
              )
            })}
      {availableJetty.length > 9 &&
            availableJetty[9].show &&
            availableJetty[9].detail.map((jt, i) => {
              return (
                <Jetty6b key={i} bank={jt.Position > 1} id='dashboard-maps-div-jetty-6b'>
                  <Vessel
                    scaleFactor={jt.Loa / availableJetty[9].jettyLength * 0.13 * scaleFactor * 1918 / 345}
                    transformOrigin='left bottom'
                    src={Images[`${_getVesselName(jt.Vessel_Size)}`]}
                    data-tip
                    data-for={`jetty6b${i}`}
                  />
                  {_renderTooltips(jt, `jetty6b${i}`, 0)}
                </Jetty6b>
              )
            })}
      {availableJetty.length > 10 && availableJetty[10].show && (
        <Jetty7 id='dashboard-maps-div-jetty-7'>
          <Vessel
            scaleFactor={availableJetty[10].detail[0].Loa / availableJetty[10].jettyLength * 0.13 * scaleFactor * 1918 / 345}
            transformOrigin='right'
            src={Images[`${_getVesselName(availableJetty[10].detail[0].Vessel_Size)}`]}
            data-tip
            data-for='jetty7'
          />
          {_renderTooltips(availableJetty[10].detail[0], 'jetty7', -90)}
        </Jetty7>
      )}
    </div>
  )
}

export default VesselKariangau
