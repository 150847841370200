import * as Yup from 'yup'
import moment from 'moment'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Colors } from '../../styles'
import { ReportAPI } from '../../api'
import { Loading, InputDate, InputSelect } from '../../components'

import FilterList from './filterlist'
import SRStatus from './srStatus.json'

import { SET_COLUMNS, SET_FILTER_TABULAR, RESET_FILTER_COLUMNS, RESET_COLUMNS } from '../../redux/actions'

import {
  TopCheckBox,
  CustomButton,
  ChecklistBox,
  CheckboxWLabel,
  ChecklistBoxTop,
  ButtonContainer,
  CheckboxListItem,
  ChevronContainer,
  LoadingContainer,
  ShowHideContainer,
  ChecklistBoxTitle,
  CheckboxWLabelTop,
  FilterPageContainer,
  FilterItemContainer,
  FilterListContainer,
  ChecklistBoxContent,
} from './report.styled'


const ReportFilter = ({ setIsTabular }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [filterlist, setFilterlist] = useState(null)
  const [showColumns, setShowColumns] = useState([])
  const [hideColumns, setHideColumns] = useState([])
  const [showChecklisted, setShowChecklisted] = useState([])
  const [hideChecklisted, setHideChecklisted] = useState([])
  const reportState = useSelector((state) => state.reportReducer)
  const { UserLocations } = useSelector((state) => state.usersLoginReducer)

  const appSize = useSelector((state) => state.appReducer)
  const height = appSize.height - 190 > 455 ? appSize.height - 190 : 455


  const getColumns = async (isReset) => {
    setLoading(true)
    if (isReset || ((reportState.showColumns || []).length === 0 && (reportState.hideColumns || []).length === 0)) {
      try {
        const { data } = await ReportAPI.GetAllReportColumns()
        const show = [], hide = []
        data.forEach(item => {
          const splitted = item.Value.split('|')
          const newItem = {
            code: item.Code,
            field: splitted[0],
            desc: splitted[1],
            status: splitted[2] === 'TRUE' ? true : false,
            num: splitted[3]
          }
          if (newItem.status) {
            show.push(newItem)
          } else {
            hide.push(newItem)
          }
        })

        setShowColumns(show)
        setHideColumns(hide)
        return { show, hide }
      } catch(error) {
        return error
      }
    } else {
      setShowColumns(reportState.showColumns)
      setHideColumns(reportState.hideColumns)
    }
  }

  const getFilters = async () => {
    try {
      const filter = await Promise.all(FilterList.map(async item => {
        let data = []
        if (item.func !== null) {
          const response = await item.func()
          data = response.data
        } else {
          data = []
        }
        return {
          ...item,
          id: `report-filter-item-${item.title}`,
          options: data
        }
      }))
      setFilterlist(filter)
      setLoading(false)
    } catch(error) {
      return error
    }
  }

  const ReportFilterForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      Start_Period: reportState.filterTabular.Start_Period ? new Date(reportState.filterTabular.Start_Period) : null,
      End_Period: reportState.filterTabular.End_Period ? new Date(reportState.filterTabular.End_Period) : null,
      Location: reportState.filterTabular.Location || 'All Location',
      Client: reportState.filterTabular.Client || 'All Client',
      Jetty_Allocation: reportState.filterTabular.Jetty_Allocation || 'All Jetty',
      Vessel_Name: reportState.filterTabular.Vessel_Name || 'All Vessel',
      Agent_Name: reportState.filterTabular.Agent_Name || 'All Agent',
      Category: reportState.filterTabular.Category || 'All Category',
      Sr_Status: reportState.filterTabular.Sr_Status || 'All Status'
    },
    validationSchema: Yup.object({
      Start_Period: Yup.date().nullable(),
      End_Period: Yup.date().nullable(),
      Location: Yup.string(),
      Client: Yup.string(),
      Jetty_Allocation: Yup.string(),
      Vessel_Name: Yup.string(),
      Agent_Name: Yup.string(),
      Category: Yup.string(),
      Sr_Status: Yup.string()
    }),
    onSubmit: async (values) => {
      if (showColumns.length !== 0) {
        dispatch({ type: SET_COLUMNS, payload: {
          showColumns: showColumns,
          hideColumns: hideColumns
        } })
        dispatch({ type: SET_FILTER_TABULAR, payload: values })
        setIsTabular(true)
      }
    },
  })

  const changeSide = (left) => {
    if (left) {
      const filteredArray = hideColumns.filter(value => !hideChecklisted.includes(value))
      setShowChecklisted([...showChecklisted])
      setHideChecklisted([])
      setShowColumns([...showColumns, ...hideChecklisted])
      setHideColumns(filteredArray)
    } else {
      const filteredArray = showColumns.filter(value => !showChecklisted.includes(value))
      setHideChecklisted([...hideChecklisted])
      setShowChecklisted([])
      setHideColumns([...hideColumns, ...showChecklisted])
      setShowColumns(filteredArray)
    }
  }

  useEffect(() => {
    getColumns(false)
    getFilters()
  }, [])

  const _filterComponents = () => (
    <FilterListContainer>
      {filterlist.map(item => {
        let options = []
        let optionText = ''
        if (item.title === 'Port Location') {
          optionText = 'Location_Text'
          options = [{ Code: 'all', Location_Text: 'All Location' }, ...(UserLocations.map(x => ({
            Code: `${x.ID}`,
            Location_Text: x.LocationDescription
          })))]
        } else if (item.title === 'Client') {
          optionText = 'Customer_Name'
          options = [{ Code: 'all', Customer_Name: 'All Client' }, ...item.options]
        } else if (item.title === 'Jetty') {
          optionText = 'Jetty_Name'
          options = [{ Code: 'all', Jetty_Name: 'All Jetty' }, ...item.options]
        } else if (item.title === 'Vessel') {
          optionText = 'Vessel_Name'
          options = [{ Code: 'all', Vessel_Name: 'All Vessel' }, ...item.options]
        }  else if (item.title === 'Agent') {
          optionText = 'Agent_Name'
          options = [{ Code: 'all', Agent_Name: 'All Agent' }, ...item.options]
        } else if (item.title === 'Category') {
          optionText = 'Value'
          options = [{ Code: 'all', Value: 'All Category' }, ...item.options]
        } else if (item.title === 'Status') {
          optionText = 'Value'
          options = [...SRStatus]
        } else {
          optionText = ''
        }

        if (item.title === 'Start Period') {
          return (
            <FilterItemContainer key={item.id}>
              <InputDate
                form={ReportFilterForm}
                style={{ width: '100%' }}
                name='Start_Period'
                label='Start Period'
                id={item.id}
                placeholder='DD/MM/YYYY'
                value={ReportFilterForm.values.Start_Period}
                onChange={(v) => ReportFilterForm.setFieldValue('Start_Period', moment(v).format('YYYY-MM-DD'))}
              />
            </FilterItemContainer>
          )
        } else if (item.title === 'End Period') {
          return (
            <FilterItemContainer key={item.id}>
              <InputDate
                style={{ width: '100%' }}
                form={ReportFilterForm}
                name='End_Period'
                label='End Period'
                id={item.id}
                placeholder='DD/MM/YYYY'
                value={ReportFilterForm.values.End_Period}
                onChange={(v) => ReportFilterForm.setFieldValue('End_Period', moment(v).format('YYYY-MM-DD'))}
              />
            </FilterItemContainer>
          )
        }

        return (
          <FilterItemContainer key={item.id}>
            <InputSelect
              form={ReportFilterForm}
              name={item.name}
              label={item.title}
              id={item.id}
              options={options}
              optionText={optionText}
              optionValueKey={optionText}
            />
          </FilterItemContainer>
        )
      })}
    </FilterListContainer>
  )

  const _checklistBox = (title, list, checklisted, setChecklisted) => (
    <ChecklistBox>
      <ChecklistBoxTop>
        <ChecklistBoxTitle>{title}</ChecklistBoxTitle>
        <CheckboxWLabelTop
          label='Select All'
          control={<TopCheckBox onChange={(event) => {
            if (event.target.checked) setChecklisted([...list])
            else setChecklisted([])
          }}/>}
        />
      </ChecklistBoxTop>
      <ChecklistBoxContent>
        {list.map(item => {
          return (
            <CheckboxWLabel
              id={`report-select-column-${item.code}`}
              key={item.code}
              label={item.desc}
              checked={checklisted.some(x => item.code === x.code)}
              control={<CheckboxListItem onChange={(event) => {
                if (event.target.checked) setChecklisted([...checklisted, item])
                else setChecklisted(checklisted.filter(x => x.code !== item.code))
              }} />}
            />
          )
        })}
      </ChecklistBoxContent>
    </ChecklistBox>
  )

  // RENDERING MAIN PART
  if (loading) {
    return (
      <LoadingContainer height={height}>
        <Loading />
      </LoadingContainer>
    )
  } else {
    return (
      <FilterPageContainer>
        {_filterComponents()}
        <ShowHideContainer>
          {_checklistBox('Show Column', showColumns, showChecklisted, setShowChecklisted)}
          <ChevronContainer>
            <div>
              <ChevronLeftIcon
                id='report-change-side-left'
                onClick={() => changeSide(true)}
                style={{ color: 'black', fontSize: 30, width: '100%', cursor: 'pointer' }}
              />
              <ChevronRightIcon
                id='report-change-side-right'
                onClick={() => changeSide(false)}
                style={{ color: 'black', fontSize: 30, width: '100%', cursor: 'pointer' }}
              />
            </div>
          </ChevronContainer>
          {_checklistBox('Hide Column', hideColumns, hideChecklisted, setHideChecklisted)}
        </ShowHideContainer>
        <ButtonContainer>
          <CustomButton
            id='report-reset-filter-btn'
            onClick={() => {
              dispatch({ type: RESET_FILTER_COLUMNS })
              dispatch({ type: RESET_COLUMNS })
              setHideChecklisted([])
              setShowChecklisted([])
              getColumns(true)
              getFilters()
              ReportFilterForm.resetForm()
            }}
          >RESET</CustomButton>
          <CustomButton
            id='report-apply-filter-btn'
            color={Colors.softGreen}
            onClick={() => {
              ReportFilterForm.submitForm()
            }}
          >APPLY</CustomButton>
        </ButtonContainer>
      </FilterPageContainer>
    )
  }
}

export default ReportFilter