import { useEffect, useState } from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import GetAppIcon from '@material-ui/icons/GetApp'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid'

import { Pager, TextFilterCell } from '../../components'
import { GridContainer } from '../../styles/global.styled'

import { ToolbarButton, VerticalDivider } from './report.styled'

const MasterGrid = (props) => {
  const [gridData, setGridData] = useState(props.gridColumn)
  const [isRefresh, setIsRefresh] = useState(false)

  const getTotalWidth = () => {
    const data = props.gridColumn
      .filter((dt) => dt.width != '*')
      .map((dtt) => {
        dtt.width.replace('px', '')
        return parseInt(dtt.width)
      })
    return data.reduce((t, ts) => t + ts, 0)
  }
  /*
  const getTotalMinWidth = () => {
    const data = props.gridColumn
      .filter((dt) => dt.width == '*')
      .map((dtt) => {
        return dtt.minWidth
      })
    return data.reduce((t, ts) => t + ts, 0)
  }
// */
  const checkResize = () => {
    const width = window.innerWidth - getTotalWidth()
    const minWidth =
      Math.abs(window.innerWidth - 234) / props.gridColumn.filter((e) => e.minWidth).length //- getTotalMinWidth()
    const data = props.gridColumn.map((dt) => {
      if (dt.minWidth) {
        dt.responsive = dt.width === '*' ? (minWidth > dt.minWidth ? '*' : dt.minWidth) : dt.width
      } else {
        dt.responsive = dt.width === '*' ? (width > 150 ? '*' : '150px') : dt.width
      }
      return dt
    })
    setGridData(data)
    setIsRefresh(true)
  }

  useEffect(() => {
    checkResize()
    // window.addEventListener('resize', checkResize)
  }, [])

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
    }
  }, [isRefresh])

  const _otherCell = (props) => {
    return (
      <td id={`m_grid_cell_${props.field}_${props.dataIndex}`}>{props.dataItem[props.field]}</td>
    )
  }

  return (
    <>
      {!isRefresh ? (
        <GridContainer>
          <ExcelExport
            fileName='port-services-report.xlsx'
            ref={exporter => props.exportToExcelRef.current = exporter}
          >
            {gridData.map((e, index) => {
              e.render === 'Code' ? (
                <ExcelExportColumn
                  key={index}
                  title={e.title}
                  field={e.field}
                  width={e.responsive}
                  className={'e-grid-mid-width'}
                />
              ) : <ExcelExportColumn
                key={index}
                title={e.title}
                field={e.field}
                width={e.responsive}
                className={'e-grid-mid-width'}
              />
            })}
            <Grid
              id='report_grid_all_data'
              reorderable={props.reorderable}
              onColumnReorder={props.onColumnReorder}
              data={props.data}
              total={props.total}
              {...props.dataState}
              filterable
              style={{ height: props.height }}
              onPageChange={(e) => props.onPageChange(e)}
              onDataStateChange={(e) => props.onDataStateChange(e)}
              pageable={{ pageSizes: props.pageSizes, buttonCount: 4 }}
              pager={Pager}
              ref={grid => props.gridRef.current = grid}
            >
              <GridToolbar>
                <ToolbarButton
                  id='report-export-excel-btn'
                  style={{ margin: '0px 5px' }}
                  onClick={props.exportToExcel}
                >
                  <GetAppIcon color='black' />
                  Download Report
                </ToolbarButton>
                <VerticalDivider />
                <ToolbarButton
                  id='report-to-filter-btn'
                  style={{ margin: '0px 5px' }}
                  onClick={() => props.setIsTabular(false)}
                >
                  <TuneIcon color='black' />
                  Advance Filter
                </ToolbarButton>
              </GridToolbar>
              <GridNoRecords>
                <p id='grid-no-records'>No records available</p>
              </GridNoRecords>
              {gridData.map((e, index) =>
                e.render === 'Action' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    filterable={false}
                    locked={props.actionLocked || false}
                    width={e.responsive}
                    cell={props.actionCell}
                    // filter={e.filterType}
                    // filterCell={TextFilterCell}
                    className={'e-grid-mid-width'}
                    reorderable={props.reorderable && false}
                  />
                ) : e.render === 'Code' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.responsive}
                    cell={props.codeCell}
                    filter={e.filterType}
                    filterCell={TextFilterCell}
                    className={'e-grid-mid-width'}
                    reorderable={props.reorderable && false}
                  />
                ) : e.render === 'Custom' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.responsive}
                    cell={props.customCell}
                    filter={e.filterType}
                    filterCell={TextFilterCell}
                    className={'e-grid-mid-width'}
                  />
                ) : e.render === 'Total_Percentage' ? (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.responsive}
                    cell={props.percentCell}
                    filter={e.filterType}
                    filterCell={TextFilterCell}
                    className={'e-grid-mid-width'}
                  />
                ) : (
                  <Column
                    key={index}
                    title={e.title}
                    field={e.field}
                    width={e.responsive}
                    filter={e.filterType}
                    cell={_otherCell}
                    filterCell={TextFilterCell}
                    className={'e-grid-mid-width'}
                  />
                )
              )}
            </Grid>
          </ExcelExport>
        </GridContainer>
      ) : null}
    </>
  )
}

export default MasterGrid
