import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ConvertLocalTimeGet, Lookup } from '../../utils'
import { Images } from '../../assets'
import { DashboardJettyAPI } from '../../api'
import Modal from './dashboard-jetty-modal.container'
import { SET_USER_PROFILE_LOCATION } from '../../redux/actions'
import { DropdownLocSide } from './dashboard-dropdown.container'
import VesselKariangau from './dashboard-jetty-kariangau/dashboard-jetty.container'
import VesselSorong from './dashboard-jetty-sorong/dashboard-jetty.container'

import {
  Jetty,
  Title,
  Width,
  Detail,
  Legend,
  Wrapper,
  TabTitle,
  Circular,
  JettyBox,
  Overflow,
  HeaderTitle,
  JettyWrapper,
  LegendDetail,
  LegendStatus,
  LocationSide,
  HeaderWrapper,
  DetailWrapper,
  ChartContainer,
} from './dashboard-jetty.styled'
import moment from 'moment'

const DashboardJetty = () => {
  const width = '100vw'
  const height = 'calc(88.72vh)'
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState(null)
  const [current, setCurrent] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [dataJetty, setDataJetty] = useState([])
  const [jettyLocations, setJettyLocations] = useState([])
  const [dataNextComing, setDataNextComing] = useState([])
  const appSize = useSelector((state) => state.appReducer)
  const [intervalDataJetty, setIntervalDataJetty] = useState(15)
  const { Location } = useSelector((state) => state.usersProfileReducer)
  const { UserLocations } = useSelector((state) => state.usersLoginReducer)

  const _getData = async () => {
    setLoading(true)
    const filter =
      Location && Location.LocationID
        ? `[{ "type": "number", "value": "${Location.LocationID}", "field": "location_code" }]`
        : '[]'

    const { data } = await (current
      ? DashboardJettyAPI.GetOnProgress(filter)
      : DashboardJettyAPI.GetNextComing(filter))

    if (data) {
      current ? setDataJetty(data) : setDataNextComing(data)
    }
    setLoading(false)
  }

  const _getIntervalDataJetty = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"Interval_Jetty"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await DashboardJettyAPI.GetIntervalDataJetty(0, 100, filter)
      if(data && data[0] && data[0].Value) {
        setIntervalDataJetty(parseInt(data[0].Value))
      }
    } catch (err) {
      console.log('error', err)
    }
  }

  const _getMapsJettyLocation = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"JettyMapsLocation"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await DashboardJettyAPI.GetMapsJettyLocation(0, 10, filter)
      if(data && data.length > 0) {
        setJettyLocations(data)
      }
    } catch (err) {
      console.log('error', err)
    }
  }

  useEffect(() => {
    _getData()
    _getIntervalDataJetty()
    _getMapsJettyLocation()
    setFirstLoad(false)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(()=>{
      _getData()
    }, intervalDataJetty * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [intervalDataJetty])

  useEffect(() => {
    if (!firstLoad) {
      _getData()
    }
    setModal(false)
  }, [current, Location])

  const _setLocation = (value) => {
    const valueLocation = UserLocations.find((e) => e.LocationDescription === value)

    dispatch({
      type: SET_USER_PROFILE_LOCATION,
      payload: valueLocation, //? valueLocation : { LocationID: 0 },
    })
  }

  const _mappingMapsBG = () => {
    let loc = jettyLocations.filter((jt) => jt.Value.split('|')[0] == Location.LocationID)
    if(loc.length > 0) {
      let name = loc[0].Value.split('|')[1]
      switch (name.toLowerCase()) {
      case 'kariangau':
        return {
          jetty: Images.MapsJetty,
          background: Images.MapsJettyBackground
        }
      case 'sorong':
        return {
          jetty: Images.MapsJettySorong,
          background: Images.MapsJettyBackgroundSorong
        }
      default:
        return {
          jetty: Images.MapsJetty,
          background: Images.MapsJettyBackground
        }
      }
    }
  }

  const _mappingMapsVessel = () => {
    let loc = jettyLocations.filter((jt) => jt.Value.split('|')[0] == Location.LocationID)
    let allJetty = dataJetty.filter((dt) => {
      let data = dt
      if (dt.Detail && dt.Detail.length > 0) {
        data.Detail = dt.Detail.filter((dtl) => dtl.Value.toLowerCase() == 'in progress'
          || (dtl.Value.toLowerCase() == 'completed'
            && moment().format('DD-MM-YYYY') == ConvertLocalTimeGet(dt.Location, dtl.LastStatusDate, 'DD-MM-YYYY')))
      }
      return data
    })

    if(loc.length > 0) {
      let name = loc[0].Value.split('|')[1]
      switch (name.toLowerCase()) {
      case 'kariangau':
        return <VesselKariangau dataJetty={dataJetty}/>
      case 'balikpapan':
        return <VesselKariangau dataJetty={dataJetty}/>
      case 'sorong':
        return <VesselSorong dataJetty={allJetty}/>
      }
      return null
    }
  }

  const _legend = () => {
    return (
      <>
        <LocationSide>
          <DropdownLocSide
            location={Location}
            dataList={UserLocations}
            setLocation={_setLocation}
          />
        </LocationSide>
        <Legend top='16.9vh'>
          {Lookup.LEGEND_JETTY_MAPS.map((lg, i) => {
            return (
              <LegendDetail key={i}>
                <LegendStatus color={lg.color} />
                {lg.label}
              </LegendDetail>
            )
          })}
        </Legend>
        {_chart()}
      </>
    )
  }

  const _chart = () => {
    return (
      <ChartContainer
        innerHeight={appSize.height - 200} // after location finish//
        top='22.02vh'
      >
        <TabTitle>
          <Title
            id='maps-jetty-chart-tab-on-progress'
            active={current}
            onClick={() => setCurrent(true)}
          >
            On Progress
          </Title>
          <Title
            id='maps-jetty-chart-tab-up-coming'
            active={!current}
            onClick={() => setCurrent(false)}
          >
            Up Coming
          </Title>
        </TabTitle>
        {_headerList()}
        {_contentList()}
      </ChartContainer>
    )
  }

  const _headerList = () => {
    return (
      <HeaderWrapper>
        {Lookup.HEADER_JETTY_MAPS.filter((dt) => current || dt.current === current).map((hd, i) => {
          return (
            <HeaderTitle key={i} width={current ? hd.width : hd.next_width}>
              {hd.label !== 'ATA' || current ? hd.label : 'Planning Arrival'}
            </HeaderTitle>
          )
        })}
      </HeaderWrapper>
    )
  }

  const _contentList = () => {
    return (
      <Overflow>
        {(current ? dataJetty : dataNextComing).map((dt, i) => {
          return (
            <div key={i}>
              <JettyWrapper>
                <JettyBox>{dt.Jetty_Name}</JettyBox>
              </JettyWrapper>
              {dt.Detail &&
                dt.Detail.map((dtl, i) => {
                  return (
                    <DetailWrapper
                      current={current}
                      id={`detail-wrapper-${dtl.SrNo}`}
                      key={i}
                      onClick={() => {
                        setDetail(dtl)
                        setModal(true)
                      }}
                    >
                      {current && (
                        <Width
                          width={'10%'}
                          data-indicator-delay-value={
                            dtl.Indicator_Delay === '#8EBB3C'
                              ? 'green'
                              : dtl.Indicator_Delay === '#F2C300'
                                ? 'yellow'
                                : 'red'
                          }
                          id={`indicator-delay-${dtl.Jetty_Name.replace(/\s/, '')}-${i}`}
                        >
                          <LegendStatus color={dtl.Indicator_Delay} />
                        </Width>
                      )}
                      <Width width={current ? '25%' : '35%'}>
                        <Detail>{dtl.Vessel_Name}</Detail>
                      </Width>
                      <Width width={current ? '22.5%' : '27.5%'}>
                        <Detail>
                          {ConvertLocalTimeGet(dt.Location, current ? dtl.Ata : dtl.Plan_Arrival, 'DD-MM-YYYY')}
                          <br />
                          {ConvertLocalTimeGet(dt.Location, current ? dtl.Ata : dtl.Plan_Arrival, 'HH:mm')}
                        </Detail>
                      </Width>
                      <Width width={current ? '22.5%' : '27.5%'}>
                        <Detail>
                          {ConvertLocalTimeGet(dt.Location, dtl.Plan_Depart, 'DD-MM-YYYY')}
                          <br />
                          {ConvertLocalTimeGet(dt.Location, dtl.Plan_Depart, 'HH:mm')}
                        </Detail>
                      </Width>
                      {current && (
                        <Width
                          width={'20%'}
                          data-progress-value={dtl.Total_Progress_Activity}
                          id={`total-progress-activity-${dtl.Jetty_Name.replace(/\s/, '')}-${i}`}
                        >
                          <Circular
                            text={`${dtl.Total_Progress_Activity}%`}
                            value={dtl.Total_Progress_Activity}
                          />
                        </Width>
                      )}
                    </DetailWrapper>
                  )
                })}
            </div>
          )
        })}
      </Overflow>
    )
  }

  return (
    <Wrapper width={width} height={height} backgroundImage={_mappingMapsBG() && _mappingMapsBG().background}>
      <Jetty src={_mappingMapsBG() && _mappingMapsBG().jetty} targetHeight={height} />
      {!loading && _mappingMapsVessel()}
      {_legend()}
      {modal && <Modal detail={detail} close={() => setModal(false)} current={current} />}
    </Wrapper>
  )
}

export default DashboardJetty