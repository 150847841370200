import 'react-datepicker/dist/react-datepicker.css'

// import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { useState, useEffect } from 'react'
import { Draggable } from 'react-drag-and-drop'
import TextField from '@material-ui/core/TextField'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import { useDispatch, useSelector } from 'react-redux'
import ToggleButton from '@material-ui/lab/ToggleButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import {
  Search,
  ArrowBackIos,
  ArrowForwardIos,
  PrintOutlined
} from '@material-ui/icons'

import { Images } from '../../assets'
import { Card } from '../../components'
import { ExecutionAPI } from '../../api'
import { ConvertLocalTimeGet, dateToString } from '../../utils'
import { WrapperContent, TitleMenu, Title } from '../../styles/global.styled'
import {
  SET_DATE_EXECUTION,
  ADD_DAY_DATE_EXECUTION,
  SET_TYPE_VIEW_EXECUTION,
  MINUS_DAY_DATE_EXECUTION,
  SET_USER_PROFILE_LOCATION,
  SET_SERVICE_REQUEST_ALL
} from '../../redux/actions'

import ExecutionTabular from './execution_tabular.container'
import ExecutionLocation from './execution.location.container'
import SchedulerCointainer from './scheduler/scheduler.container'
import SchedulerPrintOutModal from './scheduler/scheduler.printout.modal'
import {
  Flex,
  Button,
  GreenDiv,
  FlexLegend,
  Iconlegend,
  ButtonPrint,
  Titlelegend,
  IconWrapper,
  ListWrapper,
  TimeWrapper,
  TitleWrapper,
  FieldWrapper,
  SearchWrapper,
  ControlWrapper,
  UnallocatedCard,
  CardUnallocated,
  TitleUnallocated,
  CustomDatepicker,
  DatetitleWrapper,
  DatepickerWrapper,
  ButtonAddContainer
} from './execution.styled'

const Execution = () => {
  const dispatch = useDispatch()
  const [showUnallocated, setShowUnallocated] = useState(false)
  const [dataSrUnallocatedCode, setDataSrUnallocatedCode] = useState('')
  const [modalPrint, setModalPrint] = useState(false)
  const [search, setSearch] = useState('')
  const typeViewReducer = useSelector((state) => state.executionViewType)
  const executionDateReducer = useSelector(
    (state) => state.executionDateReducer
  )
  const [unAllocatedData, setUnAllocatedData] = useState([])
  const [searchUnAllocatedData, setSearchUnAllocatedData] = useState([])
  const [showColumnOption, setShowColumnOption] = useState(false)
  const appSize = useSelector((state) => state.appReducer)
  const [typeViewPage, setTypeViewPage] = useState('calendar')
  const { UserLocations } = useSelector((state) => state.usersLoginReducer)
  const { Location } = useSelector((state) => state.usersProfileReducer)

  const closeModalUnallocated = (props) => {
    setShowUnallocated(props)
  }
  const getDataUnAllocated = async () => {
    try {
      const result = await ExecutionAPI.getAllUnAllocated(
        0,
        1000,
        '[]',
        Location.LocationDescription ? Location.LocationDescription : '-'
      )

      setUnAllocatedData(result.data.data ?? [])
      setSearchUnAllocatedData(result.data.data ?? [])
      eventRender()
      dispatch({
        type: SET_SERVICE_REQUEST_ALL,
        payload: result?.data?.data
      })
    } catch (err) {
      console.log('err', err)
    }
  }

  const changeView = (type) => {
    if (type === 'day') {
      document
        .getElementsByClassName('e-toolbar-item e-views e-timeline-day')[0]
        .click()
    }
    if (type === 'week') {
      document
        .getElementsByClassName('e-toolbar-item e-views e-timeline-week')[0]
        .click()
    }
    if (type === 'month') {
      document
        .getElementsByClassName('e-toolbar-item e-views e-timeline-day')[1]
        .click()
    }
    dispatch({ type: SET_TYPE_VIEW_EXECUTION, payload: type })
  }

  useEffect(() => {
    getDataUnAllocated()
    dispatch({ type: SET_DATE_EXECUTION, payload: new Date() })
    dispatch({ type: SET_TYPE_VIEW_EXECUTION, payload: 'day' })
  }, [Location.LocationDescription])

  const addDay = () => {
    dispatch({ type: ADD_DAY_DATE_EXECUTION, payload: '' })
  }
  const minusDay = () => {
    dispatch({ type: MINUS_DAY_DATE_EXECUTION, payload: '' })
  }

  const height = appSize.height - 360

  const remove = () => {
    const comp = document.getElementsByClassName('outerClass')
    if (comp.length > 0) {
      comp[0].remove()
    }
  }

  const createElement = () => {
    const comp = document.getElementsByClassName('e-resource-text')
    if (comp.length > 0) {
      remove()
      const outer = document.createElement('div')
      const top = document.createElement('div')
      const bottom = document.createElement('div')
      const divider = document.createElement('div')
      outer.className = 'outerClass'
      divider.className = 'dividerClass'
      top.className = 'topClass'
      bottom.className = 'bottomClass'
      top.append('Date')
      bottom.append('Jetty Name')
      outer.append(top, divider, bottom)
      comp[0].append(outer)
    }
  }

  const closeModalPrint = () => {
    setModalPrint(false)
    eventRender()
  }

  const handleSearch = async () => {
    let newArray = unAllocatedData
      ? await unAllocatedData.filter((e) => {
          if (
            e.Sr_No.toLowerCase().includes(search.toLowerCase()) ||
            e.Vessel_Name.toLowerCase().includes(search.toLowerCase())
          ) {
            return e
          }
          return
        })
      : []
    await setSearchUnAllocatedData(newArray)
  }

  const eventRender = (props) => {
    setTimeout(() => {
      createElement(props)
    }, 3000)
  }

  eventRender()

  useEffect(() => {
    handleSearch()
  }, [search, unAllocatedData])

  const UnAllocatedCard = () => {
    return (
      <UnallocatedCard>
        <TitleUnallocated>Jetty Unallocated</TitleUnallocated>
        <SearchWrapper>
          <TextField
            id='exe-unallocated-search-btn'
            label='Search'
            type='search'
            variant='outlined'
            size='small'
            style={{ width: '100%' }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              eventRender()
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Search />
                </InputAdornment>
              )
            }}
          />
        </SearchWrapper>
        <ListWrapper height={height}>
          {searchUnAllocatedData &&
            searchUnAllocatedData.map((data, index) => (
              <Draggable
                key={index}
                type='jetty'
                data={JSON.stringify(data)}
                style={{ cursor: 'grab' }}
                id={`exe-crd-drag-${index}-dragabble`}
              >
                <CardUnallocated
                  style={{ display: 'flex' }}
                  onClick={() => {
                    setShowUnallocated(true)
                    setDataSrUnallocatedCode(data.Code)
                  }}
                  key={index}
                  id={`exe-crd-drag-${index}`}
                >
                  <Grid container spacing={0}>
                    <Grid item md={1} style={{ maxWidth: '5%' }}>
                      <GreenDiv />
                    </Grid>
                    <Grid item md={11}>
                      <FieldWrapper>
                        <TitleWrapper>
                          <div className='left'> #{data.Sr_No}</div>
                          <div className='right'>{data.Vessel_Name}</div>
                        </TitleWrapper>
                        <TimeWrapper>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                              <Flex>
                                <div className='estimated-time'>ETA</div>
                                <div className='datetime'>
                                  <div>
                                    {ConvertLocalTimeGet(
                                      data.Location,
                                      data.Eta,
                                      'DD/MM/YYYY'
                                    ) ||
                                      ConvertLocalTimeGet(
                                        Location.LocationDescription,
                                        data.Eta,
                                        'DD/MM/YYYY'
                                      )}
                                  </div>
                                  <div>
                                    {ConvertLocalTimeGet(
                                      data.Location,
                                      data.Eta,
                                      'HH:mm'
                                    ) ||
                                      ConvertLocalTimeGet(
                                        Location.LocationDescription,
                                        data.Eta,
                                        'HH:mm'
                                      )}
                                  </div>
                                </div>
                              </Flex>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                              <Flex>
                                <div className='estimated-time'>ETD</div>
                                <div className='datetime'>
                                  <div>
                                    {ConvertLocalTimeGet(
                                      data.Location,
                                      data.Etd,
                                      'DD/MM/YYYY'
                                    ) ||
                                      ConvertLocalTimeGet(
                                        Location.LocationDescription,
                                        data.Etd,
                                        'DD/MM/YYYY'
                                      )}
                                  </div>
                                  <div>
                                    {ConvertLocalTimeGet(
                                      data.Location,
                                      data.Etd,
                                      'HH:mm'
                                    ) ||
                                      ConvertLocalTimeGet(
                                        Location.LocationDescription,
                                        data.Etd,
                                        'HH:mm'
                                      )}
                                  </div>
                                </div>
                              </Flex>
                            </Grid>
                          </Grid>
                        </TimeWrapper>
                      </FieldWrapper>
                    </Grid>
                  </Grid>
                  {data.Flag_Postpone && (
                    <img
                      src={Images.posponed}
                      style={{
                        width: '30%',
                        height: '30%',
                        float: 'right',
                        marginTop: '5px',
                        marginRight: '5px'
                        // marginTop: '11px',
                        // marginRight: '5px',
                      }}
                    />
                  )}
                </CardUnallocated>
              </Draggable>
            ))}
        </ListWrapper>
      </UnallocatedCard>
    )
  }

  const ControlPanel = () => {
    return (
      <ControlWrapper>
        <Flex>
          <Button
            active={typeViewReducer === 'day'}
            onClick={() => {
              changeView('day')
            }}
            id='exe-type-day-btn'
            position='first'
          >
            Day
          </Button>
          <Button
            active={typeViewReducer === 'week'}
            onClick={() => {
              changeView('week')
            }}
            id='exe-type-week-btn'
            position='middle'
          >
            Week
          </Button>
          <Button
            active={typeViewReducer === 'month'}
            onClick={() => {
              changeView('month')
            }}
            id='exe-type-month-btn'
            position='last'
          >
            Month
          </Button>
          <ButtonPrint
            onClick={() => {
              setModalPrint(true)
            }}
            id='exe-print-btn'
          >
            <div>
              <PrintOutlined />
            </div>
            <div style={{ marginLeft: '5px' }}> Print</div>
          </ButtonPrint>
        </Flex>
        <Flex>
          <IconWrapper
            onClick={() => {
              minusDay()
            }}
            id='exe-date-mns-btn'
          >
            <ArrowBackIos />
          </IconWrapper>
          <div>
            <DatetitleWrapper
              onClick={() => {
                document.getElementById('exe-datepicker-btn').click()
              }}
              id='exe-date-datepicker-btn'
            >
              {dateToString(executionDateReducer)}
            </DatetitleWrapper>
            <DatepickerWrapper>
              <CustomDatepicker
                selected={executionDateReducer}
                onChange={(date) => {
                  dispatch({ type: SET_DATE_EXECUTION, payload: date })
                }}
                id='exe-datepicker-btn'
              />
            </DatepickerWrapper>
          </div>
          <IconWrapper
            onClick={() => {
              addDay()
            }}
            id='exe-date-pls-btn'
          >
            <ArrowForwardIos />
          </IconWrapper>
        </Flex>
        <Flex>
          <FlexLegend>
            <Iconlegend type='overTime'></Iconlegend>
            <Titlelegend>Over Time</Titlelegend>
          </FlexLegend>
          <FlexLegend>
            <Iconlegend type='inProgress'></Iconlegend>
            <Titlelegend>In Progress</Titlelegend>
          </FlexLegend>
          <FlexLegend>
            <Iconlegend type='allocated'></Iconlegend>
            <Titlelegend>Allocated</Titlelegend>
          </FlexLegend>
          {/* <FlexLegend>
            <Iconlegend type='inProgress'></Iconlegend>
            <Titlelegend>In Progress</Titlelegend>
          </FlexLegend> */}
        </Flex>
      </ControlWrapper>
    )
  }

  const updateLocation = (location) => {
    const locationValue = UserLocations.find(
      (e) => e.LocationDescription === location
    )
    dispatch({
      type: SET_USER_PROFILE_LOCATION,
      payload: locationValue
    })
  }

  const handleChangeViewType = (event, nextView) => {
    if (nextView !== null) {
      setTypeViewPage(nextView)
    }
  }
  const ViewTypePage = () => {
    return (
      <ToggleButtonGroup
        style={{ height: '32px', marginBottom: '2px' }}
        value={typeViewPage}
        exclusive
        onChange={handleChangeViewType}
      >
        <ToggleButton
          style={{ borderRadius: '25px 0px 0px 25px' }}
          value='calendar'
        >
          <img
            style={{ background: 'gray', width: 18 }}
            src={Images.CalendarType}
          />
        </ToggleButton>
        <ToggleButton
          style={{ borderRadius: '0px 25px 25px 0px' }}
          value='tabular'
        >
          <FormatAlignJustifyIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      // {/* <button onClick={() => {
      //   console.log('masuk click calendar')
      //   setTypeViewPage('calendar')
      // }}> calendar </button>
      // <button onClick={() => {
      //   console.log('masuk click tabular')
      //   setTypeViewPage('tabular')
      // }}> tabular </button> */}
    )
  }

  return (
    <WrapperContent>
      <Card>
        <TitleMenu>
          <Grid container>
            <Grid item md={6}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title>Execution</Title>
                <div style={{ marginLeft: 30 }}>{ViewTypePage()}</div>
              </div>
            </Grid>
            <Grid item md={6}>
              {typeViewPage === 'tabular' && (
                <div style={{ float: 'right', display: 'flex' }}>
                  <div style={{ display: 'flex', marginRight: 10 }}>
                    <ButtonPrint
                      onClick={() => {
                        setModalPrint(true)
                      }}
                      id='exe-print-btn'
                    >
                      <div>
                        <PrintOutlined />
                      </div>
                      <div style={{ marginLeft: '5px' }}> Print</div>
                    </ButtonPrint>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <ButtonAddContainer
                      id='m-add-option-btn'
                      onClick={() => setShowColumnOption(!showColumnOption)}
                    >
                      <ArtTrackIcon />
                      &nbsp;{'Column Option'}
                    </ButtonAddContainer>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </TitleMenu>
        {typeViewPage === 'calendar' && (
          <Grid container spacing={2}>
            <Grid item md={2}>
              <ExecutionLocation
                dataList={UserLocations}
                location={
                  Location && Location.LocationDescription
                    ? Location.LocationDescription
                    : ''
                }
                setLocation={updateLocation}
              />
              {UnAllocatedCard()}
            </Grid>
            <Grid item md={10}>
              {ControlPanel()}
              <div>
                <SchedulerCointainer
                  handlerRender={eventRender}
                  getDataUnAllocated={getDataUnAllocated}
                  closeModalUnallocated={closeModalUnallocated}
                  showUnallocated={showUnallocated}
                  dataSrUnallocatedCode={dataSrUnallocatedCode}
                />
              </div>
            </Grid>
          </Grid>
        )}

        {typeViewPage === 'tabular' && (
          <ExecutionTabular
            showColumnOption={showColumnOption}
            setShowColumnOption={setShowColumnOption}
            getDataUnAllocated={getDataUnAllocated}
          />
        )}
      </Card>
      <SchedulerPrintOutModal
        showModal={modalPrint}
        closeModalPrint={closeModalPrint}
      />
    </WrapperContent>
  )
}

export default Execution
