/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import { Loading } from '../../components'
import DocumentGridColumn from './column.modal_cargo.json'
import {
  InfoTitle,
  CargoList,
  CargoItem,
  ItemTitle,
  ItemAmount,
  InfoCounter,
  ItemSubtitle,
  TitleWrapper,
  InfoSubtitle,
  OuterWrapper,
  FormContainer,
  ItemTitleWrapper,
  CargoInformationCard,
  CargoInformationWrapper,
  LoadingContainer,
} from './service-request.styled'

const CargoModalContent = (props) => {
  const { dataSr } = props
  const [isLoading, setIsLoading] = useState(false)
  const manPower = [
    { title: 'Foreman', amount: 2 },
    { title: 'foreboy', amount: 2 },
    { title: 'Banksman', amount: 3 },
    { title: 'Supervisor', amount: 1 },
  ]

  const equipment = [
    { title: '200 T Crawler Crane', amount: 1 },
    { title: 'Pipe Handler', amount: 3 },
  ]
  const _centeredCell = (props) => (<td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>)
  return (
    <OuterWrapper>
      <FormContainer height={45}>
        <CargoInformationWrapper className='left'>
          <CargoInformationCard className='cargo-wrapper'>
            <TitleWrapper>
              <InfoTitle>Labour</InfoTitle>
              <InfoSubtitle>Items: <InfoCounter>6</InfoCounter></InfoSubtitle>
            </TitleWrapper>
            <CargoList>
              {manPower.map(item => (
                <CargoItem key={item.title}>
                  <ItemTitleWrapper>
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemAmount>({item.amount})</ItemAmount>
                  </ItemTitleWrapper>
                </CargoItem>
              ))}
            </CargoList>
          </CargoInformationCard>
        </CargoInformationWrapper>

        <CargoInformationWrapper className='right'>
          <CargoInformationCard className='cargo-wrapper'>
            <TitleWrapper>
              <InfoTitle>Equipment</InfoTitle>
              <InfoSubtitle>Items: <InfoCounter>6</InfoCounter></InfoSubtitle>
            </TitleWrapper>
            <CargoList>
              {equipment.map(item => (
                <CargoItem key={item.title}>
                  <ItemTitleWrapper>
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemAmount>({item.amount})</ItemAmount>
                  </ItemTitleWrapper>
                  <ItemSubtitle>Material type</ItemSubtitle>
                </CargoItem>
              ))}

            </CargoList>
          </CargoInformationCard>
        </CargoInformationWrapper>
      </FormContainer>

      <FormContainer height={54} >
        {isLoading ? (
          <LoadingContainer>
            <Loading id='sr-doc_mdl-loading-indicator' />
          </LoadingContainer>
        ) : (
          <CargoInformationCard className='bottom'>
            <TitleWrapper>
              <InfoTitle>Cargo Information</InfoTitle>
            </TitleWrapper>
            <TitleWrapper>
              <div style={{ margin:'7px 0px' }}>Cargo No - {props.dataSr.Cargo_No}</div>
            </TitleWrapper>
            <Grid
              id='sr-doc_mdl-all-data'
              data={props.dataSr.Cargos}
              total={0}
              style={{ height: '220px' }}
              rowHeight={5}
              pageSize={8}

            >
              {DocumentGridColumn.map((e, index) =>
                <Column
                  key={index}
                  title={e.title}
                  field={e.field}
                  width={e.width}
                  // cell={_centeredCell}
                />
              )}
            </Grid>
          </CargoInformationCard>
        )}
      </FormContainer>
    </OuterWrapper>
  )
}

export default CargoModalContent