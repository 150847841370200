import * as React from 'react'

const NotificationLoader = (props) => {
  return props.pending && <LoadingPanel />
}

const LoadingPanel = () => {
  return (
    <div className='k-loading-mask'>
      <span className='k-loading-text'>Loading</span>
      <div className='k-loading-image'></div>
      <div ></div>
    </div>
  )
}

export default NotificationLoader