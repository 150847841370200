import { useState } from 'react'

import DashboardMap from './dashboard-googlemap.container'
import DashboardJetty from './dashboard-jetty.container'

import { Nav, MenuWrapper, MenuList, ContentList } from './dashboard.styled'

const Dashboard = () => {
  const [Menu, setMenu] = useState('maps')
  return (
    <div>
      <Nav>
        <MenuWrapper>
          <MenuList active={Menu === 'maps'} onClick={() => setMenu('maps')} id='dsb-menu-maps-btn'>
            Maps
          </MenuList>
          <MenuList
            id='dsb-menu-jetty-btn'
            active={Menu === 'jetty'}
            onClick={() => setMenu('jetty')}
          >
            Maps Jetty
          </MenuList>
        </MenuWrapper>
      </Nav>
      <div>
        <ContentList active={true}>
          {Menu === 'maps' && <DashboardMap />}
          {Menu === 'jetty' && <DashboardJetty />}
        </ContentList>
      </div>
    </div>
  )
}

export default Dashboard
