import styled from 'styled-components'

import { Colors, FontSize } from '~/styles'
import { Edit, Save, Cancel } from '@material-ui/icons'

const Container = styled.div`
  padding: 20px;
  padding-top: 5px;
  background-color: ${Colors.backgroundColor};
`

const AddItemWrapper = styled.div`
  display: flex;
  cursor: ${(p) => (p.disable ? 'not-allowed' : 'pointer')};
  align-items: center;
  color: ${Colors.softBlue};
`

const TopContent = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
`

const TopContentCargo = styled.div`
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
  justify-content: space-between;
`

const Title = styled.p`
  font-weight: 600;
  font-size: ${FontSize.large};
`

const ContractText = styled.p`
  font-weight: 500;
  color: ${Colors.grey};
  font-size: ${FontSize.medium};
`

const ContractTextSpan = styled.span`
  color: ${Colors.grey};
  font-size: ${FontSize.mediumLarge};
`

const WrapperButton = styled.div`
  display: flex;
  cursor: pointer;
`

const IconButton = styled.img`
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  user-select: none;
  font-size: 1.5rem;
  fill: currentColor;
  margin-right: 3px;
`

const UploaderWrapper = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 25px;
  align-items: center;
  color: ${Colors.newGreen};
`

const TextFieldContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
`

const IconContainer = styled.div`
  width: 22px;
  margin-right: 10px;
  cursor: ${(p) => p.disabled ? 'default' : 'pointer'};
`

const CancelIcon = styled(Cancel)`
  cursor: pointer;
  color: ${Colors.red};
`

const EditIcon = styled(Edit)`
  cursor: pointer;
  color: ${Colors.newGreen};
`

const SaveIcon = styled(Save)`
  cursor: ${(p) => p.disabled ? 'default' : 'pointer'};
  color: ${(p) => p.disabled ? Colors.hintGrey : Colors.softBlue};
`

const EquipmentTopLeftWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const EquipmentTitle = styled.p`
  font-weight: 600;
  margin-block-end: 5px;
  margin-block-start: 5px;
  font-size: ${FontSize.large};
`

const EquipmentContractText = styled.p`
  font-weight: 500;
  color: ${Colors.grey};
  margin-block-end: 5px;
  margin-block-start: 5px;
  font-size: ${FontSize.large}
  font-size: ${FontSize.medium};
`

const EquipmentWrapper = styled.div`
  margin-top: 10px;
`

export default {
  Title,
  EditIcon,
  SaveIcon,
  Container,
  CancelIcon,
  TopContent,
  IconButton,
  ContractText,
  WrapperButton,
  IconContainer,
  EquipmentTitle,
  AddItemWrapper,
  TopContentCargo,
  UploaderWrapper,
  EquipmentWrapper,
  ContractTextSpan,
  TextFieldContainer,
  EquipmentContractText,
  EquipmentTopLeftWrapper,
}