import moment from 'moment'
import { useRouter } from 'next/router'
import { PrintOutlined } from '@material-ui/icons'
import { useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import Scheduler from './scheduler.printout.container'
import DotIcon from '@material-ui/icons/FiberManualRecord'

import {
  // Flex,
  Title,
  Header,
  Footer,
  DivTitle,
  LeftHeader,
  // FlexLegend,
  // Iconlegend,
  FooterTitle,
  // Titlelegend,
  ImageWrapper,
  PrintPreviewHeader,
} from './printout.style'

import { Colors } from '../../styles'
import { Images } from '../../assets'
import { Button } from '../../components'
import { ExecutionAPI } from '../../api'
import { useSelector } from 'react-redux'

const PrintOut = () => {
  const [listVessel, setListVessel] = useState([])
  const router = useRouter()
  const query = router.query
  const [location, setLocation] = useState(0)
  const [client, setClient] = useState('')
  const [paper, setPaper] = useState('')
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState([])
  const [planArrival, setPlanArrival] = useState(moment().format('YYYY-MM-DD[T00:00:00Z]'))
  const [planDepart, setplanDepart] = useState(moment().format('YYYY-MM-DD[T00:00:00Z]'))
  const [info, setInfo] = useState([])
  const [locationList, setLocationList] = useState([])
  const [clientList, setClientList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [data, setData] = useState(data)
  const [arrayScheduler, setArrayScheduler] = useState([])
  const [isLoading, setIsloading] = useState(true)
  let pdfExportComponent = useRef(null)

  useEffect(() => {
    getMasterData()
  }, [])

  const getMasterData = async () => {
    await setIsloading(true)
    await getLocation()
    await getClient()
    await getCategory()
    await getListJetty()
    await setIsloading(false)
  }

  const getLocation = async () => {
    try {
      const result = await ExecutionAPI.GetDataLocations()
      // await setIsloading(true)
      await setLocationList(result.data.data)
      // await setIsloading(false)
    } catch (err) {
      console.log('err', err)
    }
  }
  const getClient = async () => {
    try {
      const result = await ExecutionAPI.GetDataCLients()
      // await setIsloading(true)
      await setClientList([...result.data.data, { Customer_Name: 'All Customer', Code: 'all' }])
      // await setIsloading(false)
    } catch (err) {
      console.log('err', err)
    }
  }
  const getCategory = async () => {
    try {
      const result = await ExecutionAPI.GetDataCategory()
      // await setIsloading(true)
      await setCategoryList([...result.data.data, { Value: 'All Category', Code: 'all' }])
      // await setIsloading(false)
    } catch (err) {
      console.log('err', err)
    }
  }

  useEffect(() => {
    handleDate()
  }, [query])

  useEffect(() => {
    if (query.Start_Period) {
      getData()
    }
  }, [query])

  const handleDate = async () => {
    await setLocation(parseInt(query.Location))
    await setClient(query.Client)
    await setPaper(query.Paper)
    await setCategory(query.Category)
    await setStatus(
      !query.Status ? [] : typeof query.Status === 'string' ? [query.Status] : query.Status
    )
    await setPlanArrival(query.Start_Period)
    await setplanDepart(query.End_Period)
    await setInfo(!query.Info ? [] : typeof query.Info === 'string' ? [query.Info] : query.Info)

    let start = moment(moment(query.Start_Period).format('YYYY-MM-DD', 'YYYY-MM-DD'))
    let end = moment(moment(query.End_Period).format('YYYY-MM-DD', 'YYYY-MM-DD'))
    let totalDay = moment.duration(end.diff(start)).asDays()
    //devider change max day per page
    let devider = query.Paper === 'A3' ? 10 : 7
    let valueDevide = parseInt(totalDay / devider)
    let valueMod = totalDay % devider

    let arrayDay = []
    for (let i = 0; i < valueDevide; i++) {
      arrayDay = [...arrayDay, devider]
    }
    arrayDay = [...arrayDay, valueMod + 1]
    // await setIsloading(true)
    await setArrayScheduler(arrayDay)
    // await setIsloading(false)
  }
  const getData = async () => {
    const queryStatus = !query.Status
      ? []
      : typeof query.Status === 'string'
        ? [query.Status]
        : query.Status
    const queryArrival = query.Start_Period
    const queryDepart =
      query.End_Period === query.Start_Period
        ? moment(query.End_Period).add(1, 'days').format()
        : query.End_Period
    const queryLocation = parseInt(query.Location)
    const queryClient = query.Client
    const queryCategory = query.Category
    const data = {
      SR_status:
        queryStatus && queryStatus.length > 0
          ? queryStatus.map((e) => {
            return { Status: e }
          })
          : [],
      Plan_Arrival: moment(queryArrival).format('YYYY-MM-DD[T00:00:00Z]'),
      Plan_Depart: moment(queryDepart).format('YYYY-MM-DD[T00:00:00Z]'),
      Location_Code: queryLocation === 'all' ? 0 : queryLocation,
      Client_Code: queryClient === 'all' ? '' : queryClient,
      SR_Category_Code: queryCategory === 'all' ? '' : queryCategory,
    }
    // await setIsloading(true)
    const respons = await ExecutionAPI.GetDataPrintOut(data)
    if (respons && respons.data && respons.data.data) {
      await setData(respons.data.data)
    }

    // await setIsloading(false)
  }

  const { Location } = useSelector((state) => state.usersProfileReducer)

  const getListJetty = async () => {
    try {
      const result = await ExecutionAPI.getAllJetty(Location.LocationDescription)
      // await setIsloading(true)
      if (result && result.data && result.data.data) {
        await setListVessel(result.data.data)
      }
      // await setIsloading(false)
    } catch (err) {
      console.log('eror get list jetty', err, status)
    }
  }
  const exportPDFWithComponent = () => {
    pdfExportComponent.save()
  }

  const functionCountDays = (index) => {
    let total = 0
    if (index === 0) {
      return 0
    } else {
      for (let i = 0; i < index; i++) {
        total = total + arrayScheduler[i]
      }
    }
    return total
  }

  // const remove = () => {
  //   const comp = document.getElementsByClassName('outerClass')
  //   if (comp.length > 0) {
  //     comp[0].remove()
  //   }
  // }

  // const createElement = () => {
  //   const comp = document.getElementsByClassName('e-resource-text')
  //   if (comp.length > 0) {
  //     remove()
  //     const outer = document.createElement('div')
  //     const top = document.createElement('div')
  //     const bottom = document.createElement('div')
  //     const divider = document.createElement('div')
  //     outer.className = 'outerClass'
  //     divider.className = 'dividerClass'
  //     top.className = 'topClass'
  //     bottom.className = 'bottomClass'
  //     top.append('Date')
  //     bottom.append('Jetty Name')
  //     outer.append(top, divider, bottom)
  //     comp[0].append(outer)
  //   }
  // }

  // const eventRender = (props) => {
  //   setTimeout(() => {
  //     createElement(props)
  //   }, 3000)
  // }

  // eventRender()

  const HeaderLegend = () => {
    return (
      <div style={{ margin: '20px 0px' }}>
        <Header>
          <LeftHeader>
            <div className='left'>
              <div>
                Period :{' '}
                {`${moment(planArrival).format('DD/MM/YYYY')} - ${moment(planDepart).format(
                  'DD/MM/YYYY'
                )}`}
              </div>
              <div>
                Location :{' '}
                {location === 'all'
                  ? 'All Client'
                  : locationList.filter((e) => e.Id === location).length > 0
                    ? locationList.filter((e) => e.Id === location)[0].Location_Text
                    : ''}
              </div>
            </div>
            <div className='right'>
              <div>
                Client :{' '}
                {category === 'all'
                  ? 'All Clients'
                  : clientList.filter((e) => e.Code === client).length > 0
                    ? clientList.filter((e) => e.Code === client)[0].Customer_Name
                    : ''}
              </div>
              <div>
                Category :{' '}
                {category === 'all'
                  ? 'All Categories'
                  : categoryList.filter((e) => e.Code === category).length > 0
                    ? categoryList.filter((e) => e.Code === category)[0].Value
                    : ''}
              </div>
            </div>
          </LeftHeader>
          <FooterTitle>Vessel Schedule</FooterTitle>
          <ImageWrapper>
            <img src={Images.Beyond} style={{ height: '94px', marginTop: '-7px' }} />
          </ImageWrapper>
        </Header>
      </div>
    )
  }

  const FooterPage = (page) => {
    return (
      <Footer>
        <div>Print Out Date: {moment().format('DD MMMM YYYY')}</div>

        <div>
          Page {page} Of {arrayScheduler.length * 3}
        </div>
      </Footer>
    )
  }

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'auto' }}>
      <PrintPreviewHeader style={{ width: paper === 'A3' ? '5750px' : '4000px' }}>
        <DivTitle>
          <DotIcon htmlColor={Colors.green} />
          <Title>Print Preview</Title>
          {!isLoading && (
            <Button color={'green'} onClick={exportPDFWithComponent} id='print'>
              <div style={{ margin: '0px 3px' }}>
                <PrintOutlined />
              </div>
              <div>Download</div>
            </Button>
          )}
        </DivTitle>
      </PrintPreviewHeader>
      <div>
        <div style={{ width: paper === 'A3' ? '5750px' : '4000px' }}>
          <PDFExport
            ref={(component) => (pdfExportComponent = component)}
            paperSize={paper}
            margin={20}
            fileName={`Report for ${new Date().getFullYear()}`}
            author='KendoReact Team'
            landscape={true}
            scale={0.2}
            forcePageBreak='.page-break'
          >
            {arrayScheduler.map((e, i) => {
              let isDone = false
              let addDay = functionCountDays(i)
              let page = i * 3 + 1
              let page2 = i * 3 + 2
              let page3 = i * 3 + 3

              let dateScheduler = new Date(moment(planArrival).add(addDay, 'days').format())
              isDone = true

              return (
                <div key={i}>
                  <div className={page === 1 ? '' : 'page-break'}>
                    {HeaderLegend()}
                    <div>
                      <div>
                        {!isLoading && isDone && (
                          <Scheduler
                            data={data}
                            date={dateScheduler}
                            listVessel={listVessel.slice(0, Math.ceil(listVessel.length / 3))}
                            paper={paper}
                            info={info}
                            location={location}
                            day={e}
                          />
                        )}
                      </div>
                    </div>
                    {FooterPage(page)}
                  </div>
                  <div className={page2 === 1 ? '' : 'page-break'}>
                    {HeaderLegend()}
                    <div>
                      <div>
                        {!isLoading && isDone && (
                          <Scheduler
                            data={data}
                            date={dateScheduler}
                            listVessel={listVessel.slice(
                              Math.ceil(listVessel.length / 3),
                              2 * Math.ceil(listVessel.length / 3)
                            )}
                            paper={paper}
                            info={info}
                            location={location}
                            day={e}
                          />
                        )}
                      </div>
                    </div>
                    {FooterPage(page2)}
                  </div>
                  <div className={page3 === 1 ? '' : 'page-break'}>
                    {HeaderLegend()}
                    <div>
                      <div>
                        {!isLoading && isDone && (
                          <Scheduler
                            data={data}
                            date={dateScheduler}
                            listVessel={listVessel.slice(2 * Math.ceil(listVessel.length / 3))}
                            paper={paper}
                            info={info}
                            location={location}
                            day={e}
                          />
                        )}
                      </div>
                    </div>
                    {FooterPage(page3)}
                  </div>
                </div>
              )
            })}
          </PDFExport>
        </div>
      </div>
    </div>
  )
}

export default PrintOut
