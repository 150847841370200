import moment from 'moment'
import { Droppable } from 'react-drag-and-drop'
import CloseIcon from '@material-ui/icons/Close'
import { useEffect, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { useSelector, useDispatch } from 'react-redux'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'

import { ConvertLocalTimeGet, Lookup, ConvertLocalTimePost, ConvertLocalTimePostUTC } from '../../../utils'
import { Colors } from '../../../styles'
import { ExecutionAPI, LintasGeneralAPI } from '../../../api'
import LogApi from '../../../api/master-data/log'
import { ModalConfirmation, ModalDel, Scheduler } from '../../../components'
import { dateToShortMonth, printDate, printDay, dateToString } from '../../../utils'
import {
  // ADD_DATASTATE_EXECUTION,
  SET_DATASTATE_EXECUTION,
  UPDATE_DATASTATE_EXECUTION,
  DELETE_DATASTATE_EXECUTION,
  MODAL_CONFIRMATION_EXECUTION,
  RESET_MODAL_CONFIRMATION_EXECUTION,
} from '../../../redux/actions'

import ExecutionModal from '../modal/execution.modal.container'

import {
  ToolTip,
  // Caution,
  DayWrapper,
  CloseButton,
  HeaderWrapper,
  ResourceWrapper,
  WrapperAppointment,
} from './scheduler.styled.container'

const SchedulerCointainer = (props) => {
  let schedule = useRef(null)
  const [dataDelete, setDataDelete] = useState({})
  const [dataAll, setDataAll] = useState({})
  const [listVessel, setListVessel] = useState([])
  const [dataAfter, setDataAfter] = useState({})
  const [dataSrCode, setDataSrCode] = useState('')
  const [show, setShow] = useState(false)
  const [fromScheduler, setFromScheduler] = useState(false)
  const [codeVessel, setCodeVessel] = useState('')

  const dataState = useSelector((state) => state.executionDataStateReducer)
  const typeViewReducer = useSelector((state) => state.executionViewType)
  const modalConfirm = useSelector((state) => state.executionModalConfirmReducer)
  const executionDateReducer = useSelector((state) => state.executionDateReducer)
  const { Location } = useSelector((state) => state.usersProfileReducer)

  const dispatch = useDispatch()
  let pdfExportComponent = useRef(null)
  const appSize = useSelector((state) => state.appReducer)

  useEffect(() => {
    getListJetty()
    props.handlerRender()
  }, [Location])

  useEffect(() => {
    getListScheduler()
    props.handlerRender()
  }, [executionDateReducer, typeViewReducer, Location])

  const getListJetty = async () => {
    try {
      const result = await ExecutionAPI.getAllJetty(Location.LocationDescription)
      if (result.data && result.data.data) {
        setListVessel(result.data.data)
      }
    } catch (err) {
      console.log('eror get list jetty', err)
    }
    props.handlerRender()
  }
  props.handlerRender()
  const getListScheduler = async () => {
    try {
      const result = await ExecutionAPI.getAllScheduler(
        typeViewReducer,
        moment(executionDateReducer).format('YYYY-MM-DD[T][00]:[00]:[00].[000Z]'),
        Location.LocationDescription ? Location.LocationDescription : '-'
      )
      dispatch({
        type: SET_DATASTATE_EXECUTION,
        payload: result.data.data.map((e) => {
          e['Created_Date'] =
              (e.Created_Date) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Created_Date, '').slice(0, 19)
          e['Atd'] =
              (e.Atd) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Atd, '').slice(0, 19)
          e['Ata'] =
              (e.Ata) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Ata, '').slice(0, 19)
          e['Etd'] =
              (e.Etd) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Etd, '').slice(0, 19)
          e['Eta'] =
              (e.Eta) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Eta, '').slice(0, 19)
          e['Modified_Date'] =
              (e.Modified_Date) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Modified_Date, '').slice(0, 19)
          e['Sr_Completed_Date'] =
              (e.Plan_Arrival) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Plan_Arrival, '').slice(0, 19)
          e['Sr_Finalized_Date'] =
              (e.Plan_Depart) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Plan_Depart, '').slice(0, 19)
          e['Postponed_Date'] =
              (e.Postponed_Date) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Postponed_Date, '').slice(0, 19)
          e['Plan_Arrival'] =
              (e.Plan_Arrival) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Plan_Arrival, '').slice(0, 19)
          e['Plan_Depart'] =
              (e.Plan_Depart) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Plan_Depart, '').slice(0, 19)
          e['Max_Arrival'] =
              (e.Max_Arrival) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Max_Arrival, '').slice(0, 19)
          e['Min_Arrival'] =
                  (e.Min_Arrival) === ''
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Min_Arrival, '').slice(0, 19)
          e['Max_Depart'] =
              (e.Max_Depart) === ''
                ? ''
                : ConvertLocalTimeGet(e.Location, e.Max_Depart, '').slice(0, 19)

          return e
        })
         ?? [] })
    } catch (err) {
      console.log('error getListScheduler()', err)
    }
    props.handlerRender()
  }

  const onPopupOpen = (args) => {
    args.cancel = true
  }

  const onEventDragResizeStop = async (args) => {
    //filter data dengan jetty yang sama
    //filter data dengan ETA yang sama
    let dataPerJetty = dataState.filter(
      (e) =>
        e.Jetty_Code === args.data.Jetty_Code &&
        moment(e.Min_Arrival).format() === moment(args.data.Min_Arrival).format() &&
        e.Code !== args.data.Code
    )

    //cant backdate
    if (
      moment().format() <= moment(args.data.Max_Arrival).format() &&
      (args.data.Sr_Status === 'Planned' || args.data.Sr_Status === 'Allocated')
    ) {
      if (dataPerJetty.length === 0) {
        const formData = {
          Id: args.data.Id,
          Plan_Arrival:ConvertLocalTimePost(args.data.Location, args.data.Max_Arrival),
          Plan_Depart: ConvertLocalTimePost(args.data.Location, args.data.Max_Depart),
          Jetty_Code: args.data.Jetty_Code,
          Modified_Date: ConvertLocalTimePostUTC(new Date()),
        }
        const response = await ExecutionAPI.resizeAndDrag(formData)
        if (response && response.data) {
          // dispatch({ type: UPDATE_DATASTATE_EXECUTION, payload: args.data })
          // props.handlerRender()
          getListScheduler()
        } else {
          args.cancel = true
        }
      } else {
        //kalo ada yang sama (ETA dan Jetty yang Sama) munculin pop up

        args.cancel = true
        setDataAfter(args.data)
        dispatch({
          type: MODAL_CONFIRMATION_EXECUTION,
          payload: { show: true, type: 'drop', message: '' },
        })
      }
    } else {
      insertLog('drop_backdate', args.data.Code)
      args.cancel = true
      dispatch({
        type: MODAL_CONFIRMATION_EXECUTION,
        payload: {
          show: true,
          type: 'warn',
          message: `Can't change data because status SR is ${args.data.Sr_Status} and can't back date`,
        },
      })
    }
    props.handlerRender()
  }
  // const onResizeStop = (args) => {
  //   console.log('resize', args)
  //   //selesai di resize masuk sini hit dah tuh api dan redux
  // }
  const onDrop = async (source, destination) => {
    //drag and drop from unAllocated to scheduler
    //source from dragable always string, cant object. if you want send object JSON.stringfy first from drag able
    // and parse source
    // hit backend and update redux

    let parseSource = JSON.parse(source.jetty)
    if (parseSource.Sr_Status === 'Approved') {
      // let newObject = {
      //   ...parseSource,
      //   Sr_Status: 'Allocated',
      //   Jetty_Code: destination.Jetty_Code,
      //   Jetty_code: destination.Jetty_Code,
      //   Plan_Arrival: parseSource.Eta,
      //   Plan_Depart: parseSource.Etd,
      // }
      // console.log('new object', newObject)
      const planArrival = ConvertLocalTimeGet(parseSource.Location, parseSource.Plan_Arrival, 'YYYY/MM/DD HH:mm')
      const planDepart = ConvertLocalTimeGet(parseSource.Location, parseSource.Plan_Depart, 'YYYY/MM/DD HH:mm')

      let formData = {
        Id: parseSource.Id,
        Jetty_Code: destination.Jetty_Code,
        Plan_Arrival: ConvertLocalTimePost(parseSource.Location, planArrival),
        Plan_Depart:  ConvertLocalTimePost(parseSource.Location, planDepart),
        Position: parseSource.Position === 0 ? 1 : parseSource.Position,
        Modified_Date: ConvertLocalTimePostUTC(new Date()),
      }
      const response = await ExecutionAPI.unAllocatedToScheduler(formData)
      if (response && response.data) {
        props.getDataUnAllocated()
        // dispatch({ type: ADD_DATASTATE_EXECUTION, payload: newObject })
        getListScheduler()
      }
    }
    props.handlerRender()
  }

  const insertLog = async (type, code)=>{
    const dataInsert = {
      Timestamp:new Date(),
      Action: Lookup.LOG_ACTION.UPDATE,
      Module: Lookup.LOG_MODULE.EXECUTION,
      Submodule: Lookup.LOG_SUB_MODULE.SCHEDULER,
      Detail:'Cannot Drag to Backdated',
      Logtype: Lookup.LOG_TYPE.SYSTEM_LOG,
      Errorlevel: Lookup.LOG_ERROR_LEVEL.ERROR,
      From:'',
      To:'',
      Refno: `${code}`
    }

    if (type !== '') {
      const { data } = await LogApi.PostDataLog(dataInsert)
      if (data && data.result)
        return data.result
    }
    else  return false
  }

  const onClickClose = async (args) => {
    setDataDelete(args)
    dispatch({
      type: MODAL_CONFIRMATION_EXECUTION,
      payload: { show: true, type: 'delete', message: '' },
    })
    props.handlerRender()
  }
  const onClickEvent = async (args) => {
    setDataSrCode(args.Code)
    setCodeVessel(args.Vessel_Code)
    if (dataAll) {
      setFromScheduler(true)
      setShow(true)
    }
    props.handlerRender()
  }

  const getStartToday = (date) => {
    return moment(date).format('DD-MM-YYYY') != moment(schedule.current.date).format('DD-MM-YYYY')
      ? moment(schedule.current.date).startOf('day')
      : moment(date)
  }

  const getStartWeek = (date) => {
    return moment(date).isBefore(moment(schedule.current.date))
      ? moment(schedule.current.date).startOf('day')
      : moment(date)
  }

  const getStartMonth = (date) => {
    return moment(date).isBefore(moment(schedule.current.date))
      ? moment(schedule.current.date).startOf('day')
      : moment(date)
  }

  const getEndToday = (date) => {
    return moment(date).format('DD-MM-YYYY') != moment(schedule.current.date).format('DD-MM-YYYY')
      ? moment(schedule.current.date).endOf('day')
      : moment(date)
  }

  const getEndWeek = (date) => {
    return moment(date).isAfter(moment(schedule.current.date).add('6', 'days'))
      ? moment(schedule.current.date).add('6', 'days').endOf('day')
      : moment(date)
  }

  const getEndMonth = (date) => {
    return moment(date).isAfter(moment(schedule.current.date).add('30', 'days'))
      ? moment(schedule.current.date).add('30', 'days').endOf('day')
      : moment(date)
  }

  const eventTemplate = (args) => {
    const atd = args.Atd === '' ? moment() : args.Atd

    const { displayName } = schedule.current.activeViewOptions
    const startToday =
      displayName.toLowerCase() === 'day'
        ? getStartToday(args.Max_Arrival)
        : displayName.toLowerCase() === 'week'
          ? getStartWeek(args.Max_Arrival)
          : getStartMonth(args.Max_Arrival)
    const endToday =
      displayName.toLowerCase() === 'day'
        ? getEndToday(args.Max_Depart)
        : displayName.toLowerCase() === 'week'
          ? getEndWeek(args.Max_Depart)
          : getEndMonth(args.Max_Depart)

    const startPlanToday =
      displayName.toLowerCase() === 'day'
        ? getStartToday(args.Ata)
        : displayName.toLowerCase() === 'week'
          ? getStartWeek(args.Ata)
          : getStartMonth(args.Ata)
    const endPlanToday =
      displayName.toLowerCase() === 'day'
        ? getEndToday(args.Plan_Depart)
        : displayName.toLowerCase() === 'week'
          ? getEndWeek(args.Plan_Depart)
          : getEndMonth(args.Plan_Depart)

    const startActToday =
      displayName.toLowerCase() === 'day'
        ? getStartToday(args.Ata)
        : displayName.toLowerCase() === 'week'
          ? getStartWeek(args.Ata)
          : getStartMonth(args.Ata)
    const endActToday =
      displayName.toLowerCase() === 'day'
        ? getEndToday(atd)
        : displayName.toLowerCase() === 'week'
          ? getEndWeek(atd)
          : getEndMonth(atd)

    const range = endToday.diff(startToday, 'minute')
    const rangePlan = endPlanToday.diff(startPlanToday, 'minute')
    const rangeAct = endActToday.diff(startActToday, 'minute')
    const percentPlan = ((range - rangePlan) / range) * 100
    const percentAct = ((range - rangeAct) / range) * 100

    const startPlan = startPlanToday.diff(startToday, 'minute')
    const startActual = startActToday.diff(startToday, 'minute')
    const marPlan = (startPlan / range) * 100
    const marAct = isNaN(startActual) ? 0 : (startActual / range) * 100

    const caution = args.Inbound_MSR.toLowerCase() !== 'succeed'
    const close = args.Sr_Status.toLowerCase() === 'allocated'

    const isDay =
      displayName.toLowerCase() == 'day' &&
      moment(schedule.current.date).endOf('day').isSameOrAfter(moment(args.Plan_Arrival)) &&
      moment(schedule.current.date).startOf('day').isSameOrBefore(moment(args.Plan_Depart))

    const isDayAta =
      displayName.toLowerCase() == 'day' &&
      moment(schedule.current.date).endOf('day').isSameOrAfter(moment(args.Ata)) &&
      moment(schedule.current.date).startOf('day').isSameOrBefore(moment(atd))

    const isWeek =
      displayName.toLowerCase() == 'week' &&
      (moment(args.Plan_Arrival).format('DD-MM-YYYY') <=
        moment(schedule.current.date).format('DD-MM-YYYY') ||
        moment(args.Plan_Depart).format('DD-MM-YYYY') >=
          moment(schedule.current.date).format('DD-MM-YYYY'))

    const isWeekAta =
      displayName.toLowerCase() == 'week' &&
      (moment(args.Ata).format('DD-MM-YYYY') <=
        moment(schedule.current.date).format('DD-MM-YYYY') ||
        moment(atd).format('DD-MM-YYYY') >= moment(schedule.current.date).format('DD-MM-YYYY'))

    const isMonth =
      displayName.toLowerCase() == 'month' &&
      (moment(args.Plan_Arrival).isBetween(
        moment(schedule.current.date).startOf('day'),
        moment(schedule.current.date).add(30, 'days').endOf('day')
      ) ||
        moment(args.Plan_Depart).isBetween(
          moment(schedule.current.date).startOf('day'),
          moment(schedule.current.date).add(30, 'days').endOf('day')
        ))

    const isMonthAta =
      displayName.toLowerCase() == 'month' &&
      (moment(args.Ata).isBetween(
        moment(schedule.current.date).startOf('day'),
        moment(schedule.current.date).add(30, 'days').endOf('day')
      ) ||
        moment(atd).isBetween(
          moment(schedule.current.date).startOf('day'),
          moment(schedule.current.date).add(30, 'days').endOf('day')
        ))
    return (
      <WrapperAppointment onClick={() => onClickEvent(args)} type={args.Sr_Status} id={args.Sr_No}>
        {!isNaN(startActToday) && (isDayAta || isWeekAta || isMonthAta) && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: `${100 - (percentAct)}%`,
              backgroundColor: '#F43838',
              marginLeft: `${marAct}%`,
              opacity: '1',
            }}
          />
        )}
        {!isNaN(startActToday) && (isDay || isWeek || isMonth) && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              opacity: `${args.Sr_Status === 'In Progress' ? 1 : 1}`,
              width: `${100 - percentPlan}%`,
              marginLeft: `${
                  args.Sr_Status === 'In Progress'
                    ? marAct : marPlan
                }%`,
              backgroundColor: `${
                    args.Sr_Status === 'Allocated'
                      ? Colors.purple
                      : args.Sr_Status === 'In Progress'
                        ? '#6DB200'
                        : Colors.aliceBlue
                }`,
            }}
          />
        )}
        {close && (
          <div
            style={{
              height: 30,
              width: 30,
              backgroundColor: Colors.red,
              marginTop: 'auto',
              marginBottom: 'auto',
              borderRadius: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 3,
              position: 'absolute',
              marginLeft: 5,
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation()
              onClickClose(args)
            }}
            id={`close_${args.Code}`}
          >
            <CloseIcon style={{ fontSize: 20, fontWeight: 'bold' }} />
          </div>
        )}
        <div
          style={{
            fontSize: '11px',
            position: 'absolute',
            left: close ? 30 : 5,
            marginLeft: 10,
            color: Colors.white,
          }}
        >
          #{args.Sr_No} {args.Vessel_Name} {moment(args.Min_Arrival).format('HH:mm')}
          {'-'}
          {moment(args.Max_Depart).format('HH:mm')}
        </div>
        {caution && <CloseButton>!</CloseButton>}
      </WrapperAppointment>
    )
  }
  const headerDayTemplate = (args) => {
    return (
      <HeaderWrapper>
        <DayWrapper>{printDay(args.date)}</DayWrapper>
        <div>{dateToString(args.date)}</div>
      </HeaderWrapper>
    )
  }
  const headerWeekTemplate = (args) => {
    return (
      <HeaderWrapper>
        <DayWrapper>{printDay(args.date)}</DayWrapper>
        <div>{dateToString(args.date)}</div>
      </HeaderWrapper>
    )
  }
  const headerMonthTemplate = (args) => {
    return (
      <HeaderWrapper isMonth={true}>
        <div>{printDate(args.date)}</div>
        <div>{dateToShortMonth(args.date)}</div>
      </HeaderWrapper>
    )
  }
  const resourceHeaderTemplate = (args) => {
    return (
      <Droppable
        types={['jetty']}
        onDrop={(data) => onDrop(data, args.resourceData)}
        style={{ height: '100%' }}
        id={`${args.resourceData.Jetty_Name}`}
      >
        <ResourceWrapper>
          <div>{args.resourceData.Jetty_Name}</div>
        </ResourceWrapper>
      </Droppable>
    )
  }

  const tooltipTemplate = (args) => {
    return (
      <ToolTip>
        <ToolTip>
          #{args.Sr_No} {args.Vessel_Name}
        </ToolTip>
        <ToolTip>Plan Arrival : {moment(args.Plan_Arrival).format('YYYY/MM/DD HH:mm')}</ToolTip>
        <ToolTip>Plan Depart : {moment(args.Plan_Depart).format('YYYY/MM/DD HH:mm')}</ToolTip>
      </ToolTip>
    )
  }

  // const handleResize = (args) => {
  //   if (args.data.Sr_Status === 'Planned' || args.data.Sr_Status === 'Allocated') {
  //     args.cancel = false
  //   } else {
  //     args.cancel = true
  //   }
  // }

  // const handleDrag = (args) => {
  //   if (args.data.Sr_Status === 'Planned' || args.data.Sr_Status === 'Allocated') {
  //     args.cancel = false
  //   } else {
  //     args.cancel = true
  //   }
  // }

  const deleteModal = () => (
    <div>
      <ModalDel
        type='unallocated'
        submitDelete={deleteEvent}
        deleteText='Are you sure to remove Allocated jetty to Unallocated Jetty?'
        toggleDialog={closeModalConfirm}
      />
    </div>
  )

  const onClickYes = async () => {
    const formData = {
      Id: dataAfter.Id,
      Plan_Arrival: ConvertLocalTimePost(dataAfter.Location, dataAfter.Min_Arrival),
      Plan_Depart: ConvertLocalTimePost(dataAfter.Location, dataAfter.Max_Depart),
      Jetty_Code: dataAfter.Jetty_Code,
      Modified_Date: ConvertLocalTimePostUTC(new Date()),
    }
    const response = await ExecutionAPI.resizeAndDrag(formData)
    if (response && response.data) {
      dispatch({ type: UPDATE_DATASTATE_EXECUTION, payload: dataAfter })
    }
    closeModalConfirm()
    props.handlerRender()
  }

  const dropModal = () => (
    <ModalConfirmation
      open={modalConfirm.type === 'drop'}
      close={() => {
        closeModalConfirm()
      }}
      isConfirm={true}
      onClickYes={onClickYes}
      title='Vessel !'
      message='Are you sure will arrange vessel in parallel at this jetty? '
      icon={<DirectionsBoatIcon style={{ fontSize: '60px', color: Colors.softBlue }} />}
    />
  )

  const warnModal = () => (
    <ModalConfirmation
      open={modalConfirm.type === 'warn'}
      close={() => {
        closeModalConfirm()
      }}
      isWarn={true}
      title='Warning !'
      message={`${modalConfirm.message}`}
      icon={<ErrorOutlineRoundedIcon style={{ fontSize: '60px', color: Colors.yellowWarn }} />}
    />
  )

  const closeModalConfirm = () => {
    dispatch({ type: RESET_MODAL_CONFIRMATION_EXECUTION, payload: {} })
    props.handlerRender()
  }

  const closeExeModal = (prop) => {
    props.closeModalUnallocated(prop)
    props.getDataUnAllocated()
    getListScheduler()
    setFromScheduler(false)
    setShow(prop)
    setDataSrCode('')
    setDataAll({})
    props.handlerRender()
  }

  const _getDataContract = async (params) => {
    try {
      const response = await LintasGeneralAPI.GetDataContract(
        params?.Client_Code
      )
      if (response?.data?.message?.Contracts) {
        return response.data.message.Contracts
      }
    } catch (error) {
      console.log("[error] execution-sch _getDataContract", error )
      return []
    }
  }


  const getDataExecution = async () => {
    try {
      const result = await ExecutionAPI.getDataExecution(dataSrCode)
      if (result.data && result.data.data) {
        let Plan_Arrival_No_Format = result.data.data.Plan_Arrival
        let Plan_Depart_No_Format = result.data.data.Plan_Depart
        const e = result.data.data
        const contracts = await _getDataContract(e.Client_Code)
        setDataAll({ ...e,
          Contracts: contracts,
          Plan_Arrival_Non_Format : moment(Plan_Arrival_No_Format).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, Plan_Arrival_No_Format, 'MM/DD/YYYY HH:mm'),
          Plan_Depart_Non_Format : moment(Plan_Depart_No_Format).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, Plan_Depart_No_Format, 'MM/DD/YYYY HH:mm'),
          Plan_Arrival :
          moment(e.Plan_Arrival).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.Plan_Arrival, 'DD/MM/YYYY HH:mm'),
          Plan_Depart :
          moment(e.Plan_Depart).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.Plan_Depart, 'DD/MM/YYYY HH:mm'),
          Forecast_Arrival :
          moment(e.Forecast_Arrival).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.Forecast_Arrival, 'MM/DD/YYYY HH:mm'),
          Forecast_Depart :
          moment(e.Forecast_Depart).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.Forecast_Depart, 'MM/DD/YYYY HH:mm'),
          No_Later_Than :
          moment(e.No_Later_Than).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.No_Later_Than, 'MM/DD/YYYY HH:mm'),
          Postponed_Date :
            moment(e.Postponed_Date).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Postponed_Date, 'MM/DD/YYYY HH:mm'),
          Acivated_Date :
            moment(e.Acivated_Date).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Acivated_Date, 'MM/DD/YYYY HH:mm'),
          Created_Date :
            moment(e.Created_Date).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Created_Date, 'MM/DD/YYYY HH:mm'),
          Eta :
          moment(e.Eta).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.Eta, 'MM/DD/YYYY HH:mm'),
          Etd :
            moment(e.Etd).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Etd, 'MM/DD/YYYY HH:mm'),
          Ata :
          moment(e.Ata).format('MM/DD/YYYY HH:mm') === 'Invalid date'
            ? '' : ConvertLocalTimeGet(e.Location, e.Ata, 'MM/DD/YYYY HH:mm'),
          Atd :
            moment(e.Atd).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Atd, 'MM/DD/YYYY HH:mm'),

          Max_Arrival :
            moment(e.Max_Arrival).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Max_Arrival, 'MM/DD/YYYY HH:mm'),
          Max_Depart :
            moment(e.Max_Depart).format('MM/DD/YYYY HH:mm') === 'Invalid date'
              ? '' : ConvertLocalTimeGet(e.Location, e.Max_Depart, 'MM/DD/YYYY HH:mm')
        })
      } else {
        setDataAll({})
      }
    } catch (err) {
      console.log('eror get list product', err)
    }
  }
  useEffect(() => {
    setShow(props.showUnallocated)
    setDataSrCode(props.dataSrUnallocatedCode)
    props.handlerRender()
  }, [props.showUnallocated])

  useEffect(() => {
    if (dataSrCode) {
      getDataExecution()
    }
  }, [show])

  const deleteEvent = async () => {
    const formData = { Id: dataDelete.Id }
    const response = await ExecutionAPI.backToUnAllocated(formData)
    if (response && response.data) {
      props.getDataUnAllocated()
      dispatch({
        type: DELETE_DATASTATE_EXECUTION,
        payload: dataDelete,
      })
    }

    closeModalConfirm()
    props.handlerRender()
  }
  const exportPDFWithComponent = () => {
    pdfExportComponent.save()
  }

  const height = appSize.height - 240

  const group = { resources: ['Jetties'] }

  return (
    <div>
      <button onClick={exportPDFWithComponent} id='print' style={{ display: 'none' }}>
        Print
      </button>
      <PDFExport
        ref={(component) => (pdfExportComponent = component)}
        paperSize={['150mm', '297mm']}
        margin={20}
        fileName={`Report for ${new Date().getFullYear()}`}
        author='KendoReact Team'
        landscape={true}
        scale={0.3}
      >
        <Scheduler
          refs={schedule}
          group={group}
          height={height}
          data={dataState}
          listVessel={listVessel}
          // handleDrag={handleDrag}
          onPopupOpen={onPopupOpen}
          // handleResize={handleResize}
          date={executionDateReducer}
          eventTemplate={eventTemplate}
          tooltipTemplate={tooltipTemplate}
          handlerRender={props.handlerRender}
          onResizeStop={onEventDragResizeStop}
          headerDayTemplate={headerDayTemplate}
          onEventDragStop={onEventDragResizeStop}
          headerWeekTemplate={headerWeekTemplate}
          headerMonthTemplate={headerMonthTemplate}
          resourceHeaderTemplate={resourceHeaderTemplate}
        />
      </PDFExport>
      {modalConfirm.show && modalConfirm.type === 'delete' && deleteModal()}
      {modalConfirm.show && modalConfirm.type === 'drop' && dropModal()}
      {modalConfirm.show && modalConfirm.type === 'warn' && warnModal()}
      {show && Object.keys(dataAll).length > 0 && dataAll.constructor === Object && (
        <ExecutionModal
          dataAll={dataAll}
          dataSrCode={dataSrCode}
          getDataExecution={getDataExecution}
          closeExeModal={closeExeModal}
          listVessel={listVessel}
          codeVessel={codeVessel}
          show={show}
          fromScheduler={fromScheduler}
          getListScheduler={getListScheduler}
          getDataUnAllocated={props.getDataUnAllocated}
          showUnallocated={props.showUnallocated}
        />
      )}
    </div>
  )
}

export default SchedulerCointainer
