import { useState, useEffect } from 'react'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { Checkbox as CustomCheckbox, FormControlLabel } from '@material-ui/core'

import { ExecutionAPI } from '~/api'
import { MasterModal } from '~/components'
import { ConvertLocalTimePost } from '~/utils'
import WPColumns from '../json/column.modal_work_permit.json'
import { GridWrapper } from '../../execution.styled'

const WorkPermiGrid = (props) => {
  const [check, setCheck] = useState(false)
  const [dataWorkPermit, setDataWorkpermit] = useState([])

  const heightFactor = 525
  const documentGridHeight =
    window.innerHeight - heightFactor > 200 ? window.innerHeight - heightFactor : 200

  const _getDataWorkPermit = async (params) => {
    try {
      const response = await ExecutionAPI.GetListWorkPermit(params.Code)
      response.data.message.data.length > 0 ?
        response.data.message.data.map((e) => {
          e['post'] = 'none'
          return e }) : []
      setDataWorkpermit(response.data.message.data ?? [])
      props.setData(response.data.message.data ?? [])
      props.setDataTotalPermit(response.data.message.data.filter((e) => e.is_active).length || 0)
    } catch (error) {
      return
    }
  }
  const handleChange = async (dataItem) => {
    let data = {
      id : 0,
      post: '',
      is_active: false,
      sr_code: props.dataAll.Code,
      work_permit_code: dataItem.code,
      modified_date: ConvertLocalTimePost(props.dataAll.Location, new Date()),
    }

    const indexFind = dataWorkPermit.length !== 0
      ? dataWorkPermit.findIndex(event => event.code === dataItem.code
      ) : -1

    indexFind === -1 ?
      data = { ...data,  is_active : true,  post : 'new' }
      : data = { ...data,
        id : dataWorkPermit[indexFind].id,
        is_active: dataWorkPermit[indexFind].is_active ? false : true,
        post: 'edit'
      }

    try {
      const response = await ExecutionAPI.InsertUpdateWorkPermit(data)
      if (response && response.data.message.data) {
        _getDataWorkPermit(props.dataAll)
      }
    } catch (error) {
      return error
    }
  }

  const _checklistCell = ({ dataItem, field, dataIndex }) => {
    let indexFind
    dataWorkPermit.find((event) =>
      event.code === dataItem.code && event.is_active ? (indexFind = true) : (indexFind = false)
    )

    return (
      <td style={{ textAlign: 'center' }} id={`m_grid-${field}-${dataIndex}`}>
        <FormControlLabel
          style={{ marginRight: '0px' }}
          disabled={props.readOnly}
          control={<CustomCheckbox
            id='m_grid_checklist-btn'
            checked={indexFind}
            onChange={() => handleChange(dataItem)}/>}
        />
      </td>
    )
  }

  const _commonCell = ({ dataItem, field, dataIndex }) => {
    return (
      <td id={`m_grid-${field}-${dataIndex}`}>{dataItem[field]}</td>
    )
  }

  const handleClick = async () => {
    setCheck(!check)

    const workPermitList = props.data.map(workPermit => {
      const workPermitFound = (dataWorkPermit || []).find(dwp => workPermit.code === dwp.code)
      if (workPermitFound === undefined) {
        if (!check) {
          return {
            id : 0,
            post: 'new',
            is_active: true,
            sr_code: props.dataAll.Code,
            work_permit_code: workPermit.code,
            modified_date: ConvertLocalTimePost(props.dataAll.Location, new Date()),
          }
        } else {
          return {
            id : 0,
            post: 'new',
            is_active: false,
            sr_code: props.dataAll.Code,
            work_permit_code: workPermit.code,
            modified_date: ConvertLocalTimePost(props.dataAll.Location, new Date()),
          }
        }
      } else {
        if (!check) {
          return {
            id : workPermitFound.id,
            post: 'edit',
            is_active: true,
            sr_code: props.dataAll.Code,
            work_permit_code: workPermit.code,
            modified_date: ConvertLocalTimePost(props.dataAll.Location, new Date()),
          }
        } else {
          return {
            id : workPermitFound.id,
            post: 'edit',
            is_active: false,
            sr_code: props.dataAll.Code,
            work_permit_code: workPermit.code,
            modified_date: ConvertLocalTimePost(props.dataAll.Location, new Date()),
          }
        }
      }
    })

    try {
      const response = await ExecutionAPI.InsertUpdateWorkPermitList(workPermitList)
      if (response && response.data.message.data) {
        _getDataWorkPermit(props.dataAll)
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    _getDataWorkPermit(props.dataAll)
  }, [])

  return (
    <>
      <MasterModal
        width={55}
        maxWidth='lg'
        isStatic={true}
        hasSubtitle={false}
        title={props.title}
        showModal={props.showModal}
        handleClose={props.handleClose}
        // whiteheader={'true'}
        isDetail={true}
      >
        <div id='m_grid_checklist-all' style={{ display: 'flex', paddingLeft: '30px', marginTop:'20px' }} >
          <FormControlLabel control={
            <CustomCheckbox
              id='m_grid_checklist-btn'
              checked={check}
              disabled={props.readOnly}
              onChange={() => handleClick()}
            />
          }/>
          <div id='m_grid_checklist-all-txt' style={{ paddingTop: '10px', paddingLeft: '35px' }}>Select All</div>
        </div>
        <GridWrapper>
          <Grid
            id={'exec-permit-mdl-all-data'}
            data={props.data}
            total={0}
            style={{ height: documentGridHeight }}
            rowHeight={20}
            pageSize={8}
          >
            {WPColumns.map((e, index) =>
              e.render === 'checklist' ? (
                <Column
                  key={index}
                  title={e.title}
                  width={e.width}
                  cell={_checklistCell}
                  field={e.field.toLowerCase()}
                  className={'e-grid-mid-width'}
                  headerCell={() => <div></div>}
                />
              ) : (
                <Column
                  key={index}
                  title={e.title}
                  field={e.field}
                  width={e.width}
                  className={'e-grid-mid-width'}
                  cell={_commonCell}
                />
              )
            )}
          </Grid>
        </GridWrapper>
      </MasterModal>
    </>
  )
}

export default WorkPermiGrid
