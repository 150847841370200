/* eslint-disable no-unused-vars */
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'

import SrTabContent from './service-request-detail.tab-sr'
import Activity from './service-request-detail.tab-activity'
import DocModalContent from './service-request-detail.tab-doc'
import CargoModalContent from './service-request-detail.tab-cargo'
import ProgressContainer from './service-request-detail.progress_bar'
import {
  ContentList,
  MenuList,
  MenuWrapper,
  Nav
} from './service-request-detail.styled'

import { Colors } from '../../styles'
import { ServiceRequestAPI } from '../../api'
import { SET_SERVICE_REQUEST_DETAIL } from '../../redux/actions'

const ServiceRequestDetail = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [menu, setMenu] = useState('sr')
  const appSize = useSelector((state) => state.appReducer)
  const { dataSr } = useSelector((state) => state.serviceRequestDetailReducer)
  const [attachments, setAttachments] = useState([])
  const [didMount, setDidMount] = useState(false)

  const _getDetailServiceRequest = async (dataSr) => {
    try {
      const response = await ServiceRequestAPI.GetDetailServiceRequest(
        dataSr.Code
      )
      dispatch({
        type: SET_SERVICE_REQUEST_DETAIL,
        payload: {
          ...dataSr,
          CCUS: response?.data?.CCUS ?? [],
          Cargos: response?.data?.Cargos ?? [],
          Activity: response?.data?.Activity ?? [],
          WorkPermits: response?.data?.WorkPermits ?? [],
          Estimations: response?.data?.Estimations ?? [],
          Cargo_ManPower: response?.data?.Cargo_ManPower ?? [],
          Cargo_Equipment: response?.data?.Cargo_Equipment ?? []
        }
      })
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    setDidMount(true)
    _getDetailServiceRequest(dataSr)
    return () => setDidMount(false)
  }, [])

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <ProgressContainer step={dataSr ? dataSr.Sr_Status : ''} />

        <div
          style={{
            position: 'absolute',
            left: '1.5%',
            top: '5%',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            lineHeight: '14px',
            cursor: 'pointer'
          }}
          onClick={() => {
            router.push('/service-request')
          }}
        >
          <div>
            <ArrowBackRoundedIcon style={{ fontSize: '25px' }} />
          </div>
          <div>
            Detail Service Request
            <div
              style={{
                color: Colors.softGrey,
                fontSize: '14px',
                marginTop: '5px'
              }}
            >
              {dataSr.Sr_No}
            </div>
          </div>
        </div>
      </div>
      <Nav>
        <MenuWrapper>
          <MenuList
            id='sr-modal-menu-sr-btn'
            active={menu === 'sr'}
            onClick={() => setMenu('sr')}
          >
            Service Request
          </MenuList>
          {/* <MenuList
                id='sr-modal-menu-prod-btn'
                active={menu === 'prod'}
                onClick={() => setMenu('prod')}
              >
                Product
              </MenuList> */}
          <MenuList
            id='sr-modal-menu-cargo-btn'
            active={menu === 'cargo'}
            onClick={() => setMenu('cargo')}
          >
            Cargo &amp; Labour/Equipment
          </MenuList>
          <MenuList
            id='sr-modal-menu-activiy-btn'
            active={menu === 'activity'}
            onClick={() => setMenu('activity')}
          >
            Activity
          </MenuList>
          <MenuList
            id='sr-modal-menu-doc-btn'
            active={menu === 'doc'}
            onClick={() => setMenu('doc')}
          >
            Document
          </MenuList>
        </MenuWrapper>
      </Nav>
      <div>
        <ContentList isDetail={true} active={menu === 'sr'} appSize={appSize}>
          <SrTabContent dataSr={dataSr} />
        </ContentList>
        <ContentList
          isDetail={true}
          active={menu === 'cargo'}
          appSize={appSize}
        >
          <CargoModalContent dataSr={dataSr} />
        </ContentList>
        <ContentList
          isDetail={true}
          active={menu === 'activity'}
          appSize={appSize}
        >
          <Activity dataSr={dataSr} />
        </ContentList>
        <ContentList isDetail={true} active={menu === 'doc'} appSize={appSize}>
          <DocModalContent
            dataSr={dataSr}
            attachments={attachments}
            setAttachments={setAttachments}
            // pathRole={pathRole}
          />
        </ContentList>
      </div>
    </div>
  )
}

export default ServiceRequestDetail
