import styled from 'styled-components'

import { InputDateTime, Input, InputSelect } from '~/components'

const FormContainerDelay = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
`
const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;

  & div.MuiFormControl-root.MuiTextField-root.sc-ainUS.jstCnf {
    & p.MuiFormHelperText-root {
      color: ${(p) => (p.typeHelper === 'redColor' ? 'red' : 'black')};
    }
  }
`

const InputDateDelay = styled(InputDateTime)`
  margin-top: -5px;
  margin-bottom: -10px;
`
const SelectFieldDelay = styled(InputSelect)`
  margin: 10px;
  margin-bottom: 10px;
`

const InputField = styled(Input)`
  margin: 10px;
  margin-bottom: -10px;
`

const InputFieldMiniFlex = styled(Input)`
  margin: 10px;
  margin-top: 0;
  margin-bottom: -10px;
`

const ActionCellWrapper = styled.div`
  cursor: pointer;
`

const RowIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const RowWrapper = styled.div`
  width: 100%;
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(3,1fr);

  & div.MuiFormControl-root.MuiTextField-root.sc-ainUS.jstCnf {
    & p.MuiFormHelperText-root {
      color: ${(p) => (p.typeHelper === 'redColor' ? 'red' : 'black')};
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: -15px;
  justify-content: flex-end;
`

const DurationInputContainer = styled.div`
  margin-top: -3px;
`

const DurationLabel = styled.label`
  font-size: 10px;
  transform-origin: top left;
  color: rgba(0, 0, 0, 0.54);
  transform: translate(0, 1.5px) scale(0.75);
`

const DurationInputWrapper = styled.div`
  display: grid;
  column-gap: 5px;
  grid-template-columns: repeat(2, 1fr);
`

const CircularProgressWrapper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default {
  Form,
  Input,
  RowIcon,
  RowWrapper,
  InputField,
  DurationLabel,
  InputDateDelay,
  ButtonContainer,
  SelectFieldDelay,
  ActionCellWrapper,
  FormContainerDelay,
  InputFieldMiniFlex,
  DurationInputWrapper,
  DurationInputContainer,
  CircularProgressWrapper,
}