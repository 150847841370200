import moment from 'moment'
import { isNull, sortBy } from 'lodash'
import { useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'

import { ReportAPI } from '../../api'
import GridColumn from './column.json'
import ReportGrid from './report-grid'
import { Loading } from '../../components'
import { ConvertLocalTimeGet, GenerateFilterTerm } from '../../utils'
import { LoadingContainer } from './report.styled'

const ReportTabular = ({ setIsTabular }) => {
  const gridRef = useRef(null)
  const exportToExcelRef = useRef(null)
  const appSize = useSelector((state) => state.appReducer)
  const reportState = useSelector((state) => state.reportReducer)
  const height = appSize.height - 190 > 455 ? appSize.height - 190 : 455

  const [loading, setLoading] = useState(false)
  const [dataReports, setDataReports] = useState([])
  const [totalReports, setTotalReports] = useState(0)
  const [activeColumns, setActiveColumns] = useState([])
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    filter: [],
    sortable: true,
    filterable: true,
    filterString: '[]',
  })

  const _getData = async (loading, { skip, take, filter }) => {
    setLoading(loading)
    try {
      const startPeriod = reportState.filterTabular.Start_Period
      const endPeriod = reportState.filterTabular.End_Period
      let period = []
      if (startPeriod === null || endPeriod === null) {
        period = []
      } else {
        period = [{
          start: startPeriod,
          end: endPeriod
        }]
      }
      const mainFilterString =  GenerateFilterTerm({ logic: 'and', filters: [..._filterRows(), ...(filter.filters || [])] }, GridColumn)
      const { data, count } = await ReportAPI.GetAllReports(skip, take, mainFilterString, JSON.stringify(period))

      setDataReports(
        isNull(data)
          ? []
          : _formattingDate(data)
      )
      setTotalReports(count)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const _onDataStateChange = ({ data }) => {
    GenerateFilterTerm(data.filter, GridColumn)
    setDataState({
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })

    _getData(false, {
      ...dataState,
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn),
    })
  }

  const _onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take,
    })

    _getData(false, {
      skip: event.page.skip,
      take: event.page.take,
      filter: dataState.filter,
      filterString: dataState.filterString,
    })
  }

  const _filteringColumns = () => {
    const filtered = GridColumn.filter(item => {
      const found = reportState.showColumns.find(item2 => {
        return item.field.toLowerCase() === item2.field.toLowerCase()
      })
      return found !== undefined ? true : false
    })
    setActiveColumns(filtered)
  }

  const _filterRows = () => {
    const rows = []
    const keys = Object.keys(reportState.filterTabular).filter(item => item !== 'Start_Period' && item !== 'End_Period')
    keys.forEach(item => {
      if (!(reportState.filterTabular[item].includes('All'))) {
        rows.push({
          field: item,
          operator: 'contains',
          value: reportState.filterTabular[item]
        })
      }
    })

    return rows
  }

  const _calculateBerthDurMask = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : ''
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : ''
    return hour || minute ? `${hour} ${minute}` : '0'
  }

  const _formattingDate = (data) => {
    const formatted = data.map((e) => {
      e['Etd_Non_Formatted'] = e['Etd']
      e['Eta_Non_Formatted'] = e['Eta']
      e['Created_Date_Non_Formatted'] = e['Created_Date']
      e['Created_Date'] =
          moment(e.Created_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
            ? ''
            : ConvertLocalTimeGet(e.Location, e.Created_Date, 'DD/MM/YYYY HH:mm')

      e['Atd'] =
          moment(e.Atd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
            ? ''
            : ConvertLocalTimeGet(e.Location, e.Atd, 'DD/MM/YYYY HH:mm')

      e['Ata'] =
          moment(e.Ata).format('DD/MM/YYYY HH:mm') === 'Invalid date'
            ? ''
            : ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm')

      e['Etd'] =
          moment(e.Etd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
            ? ''
            : ConvertLocalTimeGet(e.Location, e.Etd, 'DD/MM/YYYY HH:mm')

      e['Eta'] =
          moment(e.Eta).format('DD/MM/YYYY HH:mm') === 'Invalid date'
            ? ''
            : ConvertLocalTimeGet(e.Location, e.Eta, 'DD/MM/YYYY HH:mm')

      e['Plan_Arrival'] =
            moment(e.Plan_Arrival).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Plan_Arrival, 'DD/MM/YYYY HH:mm')

      e['Plan_Depart'] =
            moment(e.Plan_Depart).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Plan_Depart, 'DD/MM/YYYY HH:mm')

      e['Forecast_Arrival'] =
            moment(e.Forecast_Arrival).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Forecast_Arrival, 'DD/MM/YYYY HH:mm')

      e['Forecast_Depart'] =
            moment(e.Forecast_Depart).format('DD/MM/YYYY HH:mm') === 'Invalid date'
              ? ''
              : ConvertLocalTimeGet(e.Location, e.Forecast_Depart, 'DD/MM/YYYY HH:mm')

      e['Modified_Date'] =
      moment(e.Modified_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
        ? ''
        : ConvertLocalTimeGet(e.Location, e.Modified_Date, 'DD/MM/YYYY HH:mm')

      e['Billing_Time_Arrival'] =
      moment(e.Billing_Time_Arrival).format('DD/MM/YYYY HH:mm') === 'Invalid date'
        ? ''
        : ConvertLocalTimeGet(e.Location, e.Billing_Time_Arrival, 'DD/MM/YYYY HH:mm')

      e['Billing_Time_Depart'] =
      moment(e.Billing_Time_Depart).format('DD/MM/YYYY HH:mm') === 'Invalid date'
        ? ''
        : ConvertLocalTimeGet(e.Location, e.Billing_Time_Depart, 'DD/MM/YYYY HH:mm')
      e['Start_Date'] =
        moment(e.Start_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Start_Date, 'DD/MM/YYYY HH:mm')
      e['Finish_Date'] =
        moment(e.Finish_Date).format('DD/MM/YYYY HH:mm') === 'Invalid date'
          ? ''
          : ConvertLocalTimeGet(e.Location, e.Finish_Date, 'DD/MM/YYYY HH:mm')




      e['Estimate_Berth_Dur_Detail'] = _calculateBerthDurMask(e.Estimate_Berth_Dur)
      e['Actual_Berth_Dur_Detail'] = _calculateBerthDurMask(e.Actual_Berth_Dur)
      return e
    })

    return formatted
  }


  const exportToExcel = async () => {
    const startPeriod = reportState.filterTabular.Start_Period
    const endPeriod = reportState.filterTabular.End_Period
    let period = []
    if (startPeriod === null || endPeriod === null) {
      period = []
    } else {
      period = [{
        start: startPeriod,
        end: endPeriod
      }]
    }
    const mainFilterString =  GenerateFilterTerm({ logic: 'and', filters: [..._filterRows(), ...(dataState.filter.filters || [])] }, GridColumn)
    const { data } = await ReportAPI.GetAllReports(0, totalReports, mainFilterString, JSON.stringify(period))
    const formatted = _formattingDate(data)
    const sortedColumns = sortBy(gridRef.current.columns, ['orderIndex'])
    exportToExcelRef.current.save(formatted, sortedColumns)
  }

  const reorderColumns = (event) => {
    const columns = activeColumns.map(item1 => {
      const found = event.columns.find(item2 => item1.field === item2.field)
      return { ...item1, sortNo: found.orderIndex }
    })
    setActiveColumns(sortBy(columns, ['sortNo']))
  }

  useEffect(() => {
    _filteringColumns()
    _getData(true, dataState)

    return () => {
      console.log('cleaned up')
    }
  }, [])

  if (loading) {
    return (
      <LoadingContainer height={height}>
        <Loading />
      </LoadingContainer>
    )
  } else {
    return (
      <div style={{ marginTop: -40, backgroundColor: 'transparent' }} id='report-grid-container'>
        <ReportGrid
          isExportExcel={true}
          exportToExcelRef={exportToExcelRef}
          exportToExcel={exportToExcel}
          setIsTabular={setIsTabular}
          codeCell={null}
          height={height}
          id='report_grid'
          actionCell={null}
          data={dataReports}
          reorderable={true}
          total={totalReports}
          dataState={dataState}
          pageSizes={[20, 50, 100, 250, 500]}
          gridColumn={activeColumns}
          onPageChange={(e) => _onPageChange(e)}
          onDataStateChange={(e) => _onDataStateChange(e)}
          gridRef={gridRef}
          onColumnReorder={reorderColumns}
        />
      </div>
    )
  }
}

export default ReportTabular