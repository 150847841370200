/* eslint-disable no-unused-vars */
import _, { get } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Add, Edit } from '@material-ui/icons'
import { useState, useEffect } from 'react'

import { Images } from '~/assets'
import { ExecutionAPI } from '~/api'
import { InlineEditableGrid, MasterModal } from '~/components'
import { Actions, ConvertLocalTimeGet, ConvertLocalTimePost } from '../../../../utils'

import SC from './styled.ccu'

import gridColumns from '../json/column.modal_ccu_detail.json'
import gridColumnRO from './columns.json'
import constants from './constant.ccu'

const CCUGrid = (props) => {
  const [id, setId] = useState(0)
  const [edit, setEdit] = useState(false)
  const [ccuData, setCCUData] = useState([])
  const [inEdit, setInedit] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [ccuUnitList, setCCUUnitList] = useState([])
  const [ccuType, setCCUCtype] = useState(constants.initValCCUCode)
  const [inEditValues, setInEditValues] = useState(constants.initValCCU)

  const formSubmitCCU = useFormik({
    initialValues: inEditValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      ccu_code: Yup.string().required('*Required field'),
      ccu_unit_code: Yup.string().required('*Required field'),
      on_hire_date: Yup.date().required('*Required field'),
      off_hire_date: Yup.date().required('*Required field')
        .min(Yup.ref('on_hire_date'), 'Must be greater than On Hire Date')
    }),
    onSubmit: async (values) => {
      setDisabled(true)
      const dataSave = {
        action : values.post == 'new' ? Actions.INSERT_CCU : Actions.UPDATE_CCU,
        data: {
          'id': id ? id : 0,
          'sr_code' : props.dataAll.Code,
          'ccu_code': values.ccu_code,
          'ccu_unit_code': values.ccu_unit_code,
          'on_hire_date': ConvertLocalTimePost(props.dataAll.Location, values.on_hire_date),
          'off_hire_date': ConvertLocalTimePost(props.dataAll.Location, values.off_hire_date),
          'created_date': ConvertLocalTimePost(props.dataAll.Location, new Date()),
          'modified_date': ConvertLocalTimePost(props.dataAll.Location, new Date())
        }
      }
      if (edit) {
        try {
          const response = await ExecutionAPI.InsertUpdateCCU(dataSave)
          if(response && response.data.message.data) {
            setInedit(false)
            setId(0)
            setEdit(false)
            await _getData()
            await props.getData(props.dataAll)
          }
          setDisabled(false)
        } catch(error) {
          return error
        }
      } else {
        try {
          const response = await ExecutionAPI.InsertUpdateCCU(dataSave)
          if(response && response.data.message.data) {
            setInedit(false)
            await _getData()
            await props.getData(props.dataAll)
          }
          setDisabled(false)
        } catch(error) {
          return error
        }
      }
    }
  })

  const addCCU = () => {
    setInedit(true)
    formSubmitCCU.resetForm()
    const newCCU = { ...constants.initValCCU,
      edit: true,
      on_hire_date : props.dataAll.Eta,
      off_hire_date : props.dataAll.Etd
    }
    const updatedData = [newCCU, ...ccuData].map((item, index) => ({ ...item, editIndex: index }))
    setInEditValues(newCCU)
    setCCUData(updatedData)
  }

  const handleSave = () => {
    formSubmitCCU.submitForm()
  }

  const filterOutSelectedCCU = (willBeFiltered, filterWith) => {
    const result = (willBeFiltered || []).filter(ccu => {
      // Remember that array.find will return undefined if not found
      const sameCCUFound = (filterWith || []).find(item => {
        return item.ccu_unit_code === ccu.code
      })
      return sameCCUFound === undefined
    })

    return result
  }

  const handleCancel = () => {
    setEdit(false)
    setInedit(false)
    setId(0)
    _getData()
  }

  const handleEdit = async (item) => {
    setEdit(true)
    setInedit(true)
    setId(item.id)
    const wantTobeUpdatedItem = { ...item, edit: true }
    const updatedData = ccuData.map((val, index) => {
      if (item.editIndex === val.editIndex) return { ...val, edit: true, editIndex: index }
      return { ...val, editIndex: index }
    })
    const filtered = filterOutSelectedCCU(ccuUnitList, ccuData)
    const willBeEdittedCCUDropDown = ccuUnitList.find(dt => dt.code === item.ccu_unit_code)

    setCCUCtype({ ccu_code : [...filtered, willBeEdittedCCUDropDown] })
    setInEditValues(wantTobeUpdatedItem)
    setCCUData(updatedData)
  }

  const handleRemove = async (item) => {
    const dataRemove = {
      action: Actions.DELETE_CCU,
      data : {
        'id' : item.id,
        'modified_date': ConvertLocalTimePost(props.dataAll.Location, new Date())
      }
    }
    try {
      const result = await ExecutionAPI.InsertUpdateCCU(dataRemove)
      if (result) {
        _getData()
      }
    } catch(error) {
      return error
    }
  }

  const _getDataCCUHired = async (event) => {
    try {
      const obj = {
        code : event.code,
        ccu_code : event.ccu_code,
        ccu_unit_code : event.ccu_unit_code,
        on_hire_date : moment(ConvertLocalTimePost(props.dataAll.Location, event.on_hire_date)).format('YYYY-MM-DD[T]HH:mm:[00].[000Z]'),
        off_hire_date: moment(ConvertLocalTimePost(props.dataAll.Location, event.off_hire_date)).format('YYYY-MM-DD[T]HH:mm:[00].[000Z]'),
      }

      const response = await ExecutionAPI.GetCCUHired(obj)
      if (response && response.data.message.data) {
        if (response.data.message.data.length !== 0) {
          const minDate = ConvertLocalTimeGet(props.dataAll.Location, (Math.min.apply(null, response.data.message.data
            .map((e) => {
              return new Date(e.on_hire_date)
            }))), 'DD MMM YYYY HH:mm')

          const maxDate = ConvertLocalTimeGet(props.dataAll.Location, (Math.max.apply(null, response.data.message.data
            .map((e) => {
              return new Date(e.off_hire_date)
            }))), 'DD MMM YYYY HH:mm')

          formSubmitCCU.setFieldValue('status', true)
          formSubmitCCU.setFieldValue('remarks', `CCU is booked for ${minDate} - ${maxDate}`)
        } else {
          formSubmitCCU.setFieldValue('status', false)
          formSubmitCCU.setFieldValue('remarks', '')
        }
      }
    } catch (error) {
      return error
    }
  }

  const _getDataCCUDetail = async (params) => {
    try {
      const response = await ExecutionAPI.GetListCCU(params.Code)
      if (response && response.data.message.data) {
        const result = response.data.message.data.length > 0 ?
          response.data.message.data.map((e, index) => {
            e['editIndex'] =  index,
            e['on_hire_date'] = e.on_hire_date ?
              ConvertLocalTimeGet(params.Location, e.on_hire_date, 'YYYY-MM-DD HH:mm') : ''
            e['off_hire_date'] = e.off_hire_date ?
              ConvertLocalTimeGet(params.Location, e.off_hire_date, 'YYYY-MM-DD HH:mm') : ''
            e['remarks'] = e.status ?
                        `CCU is booked for ${ConvertLocalTimeGet(params.Location, e.min_date, 'DD MMM YYYY HH:mm')} - ${ConvertLocalTimeGet(params.Location, e.max_date, 'DD MMM YYYY HH:mm')}` : ''
            return e
          }) : []
        return new Promise((resolve, reject) => {
          resolve(result)
          reject(new Error('Error get ccu detail list'))
        })
      }
    } catch (error) {
      return error
    }
  }

  const _getAllDataType= async (params)=>{
    try {
      const response = await ExecutionAPI.GetCCUUnit(params.Location_Code)
      if(response && response.data.message.data) {
        return new Promise((resolve, reject) => {
          resolve(response?.data?.message?.data || [])
          reject(new Error('Error get ccu type list'))
        })
      }
    } catch (error) {
      return error
    }
  }

  const _getData = async () => {
    const dataTypeList = await _getAllDataType(props.dataAll)
    const ccuDetailList = await _getDataCCUDetail(props.dataAll)
    const filtered = filterOutSelectedCCU(dataTypeList, ccuDetailList)

    setCCUUnitList(dataTypeList)
    setCCUCtype({ ccu_code : filtered })
    setCCUData(ccuDetailList)
  }


  useEffect(() => {
    _getData()
  }, [])

  useEffect(() => {
    if (!_.isEmpty(formSubmitCCU.values['ccu_code']) && !_.isEmpty(formSubmitCCU.values['ccu_unit_code'])) {
      _getDataCCUHired(formSubmitCCU.values)
    }
  }, [formSubmitCCU.values])

  return (
    <MasterModal
      showModal={props.showModal}
      width={70}
      maxWidth='xl'
      isStatic={true}
      hasSubtitle={false}
      title={props.title}
      handleClose={() => {
        handleCancel()
        props.handleClose()
      }}
      // whiteheader={'true'}
      isDetail={true}
    >
      <div>
        {!props.readOnly && (
          <SC.TopContent>
            <SC.AddItemWrapper
              id='ccu-add_item-txt'
              disable={inEdit}
              onClick={inEdit ? () => {} : addCCU}>
              <Add /> Add Item
            </SC.AddItemWrapper>
          </SC.TopContent>)}
        <div style={{ marginTop :'20px' }}>
          <InlineEditableGrid
            isFixedCell
            data={ccuData}
            rowData={ccuType}
            tableHeight='400px'
            tableWidth={'100%'}
            disabled={disabled}
            handleEdit={handleEdit}
            handleSave={handleSave}
            readOnly={props.readOnly}
            handleRemove={handleRemove}
            handleCancel={handleCancel}
            formSubmit={formSubmitCCU}
            columns={props.readOnly ? gridColumnRO : gridColumns}
          />
        </div>
      </div>
    </MasterModal>
  )
}

export default CCUGrid