/* eslint-disable no-unused-vars */
import moment from 'moment'
import {
  Checkbox as CustomCheckbox,
  FormControlLabel,
} from '@material-ui/core'

import { Images } from '~/assets'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Input, Checkbox, ButtonDetail, } from '../../components'
import {
  Form,
  Text,
  TopIcon,
  TextTop1,
  TextTop2,
  TextTop3,
  SVGIcon,
  TitleValue,
  ContainerTop,
  WrapperValue,
  TitleBerthing,
  FormContainer,
  LeftFormContainer,
  RightFormContainer,
} from './service-request.styled'
import { ServiceRequestAPI } from '../../api'
import KeySR from '../../utils/dataMappingKeySR.json'
import ModalWorkPermitCCU from './service-request.modal.work-permit-ccu.container'

import DocGridPermit from './column.work_permit.json'
import DocGridCCU from './column.ccu_detail.json'
import { ConvertLocalTimeGet } from '../../utils'

const ServiceRequestModalContent = (props) => {
  const { ServiceRequestForm } = props
  let { portLocation, agent, client } = props

  const [isFromManualTrigger, setIsFromManualTrigger] = useState(false)
  const usersLoginReducer = useSelector((state) => state.usersLoginReducer)
  const usersProfileReducer = useSelector((state) => state.usersProfileReducer)
  const [dataSRField, setDataSRField] = useState([])
  const [dataMasterPermit, setDataMasterPermit] = useState([])
  const [modalPermitCCU, setModalPermitCCU] = useState({ modal_type:'', isOpen:false })


  if (
    usersLoginReducer &&
    usersLoginReducer.UserLocations &&
    usersProfileReducer &&
    usersProfileReducer.ID > 0
  ) {
    const userType = usersProfileReducer.UserType.toLowerCase()
    portLocation = portLocation.filter((e) =>
      usersLoginReducer.UserLocations.find((ele) => ele.LocationCode === e.Location_Code)
    )
    if (userType === 'client') {
      client = client.filter(
        (e) => usersLoginReducer.User.CompanySapCustomerNo === e.SAP_Customer_No
      )
    } else if (userType === 'agent') {
      agent = agent.filter((e) => usersProfileReducer.Company === e.Agent_Code)
    }
  }

  // HANDLE HOUR & MINUTE CHANGE
  useEffect(() => {
    // ONLY UPDATE WHEN MANUALLY TRIGGERED ON UI
    if (isFromManualTrigger) {
      const { Eta, Estimate_Berth_Dur_Hour, Estimate_Berth_Dur_Minute } = ServiceRequestForm.values
      const hr = Estimate_Berth_Dur_Hour ? Estimate_Berth_Dur_Hour : 0
      const mm = Estimate_Berth_Dur_Minute ? Estimate_Berth_Dur_Minute : 0
      const minuteResult = parseInt(hr) * 60 + parseInt(mm)
      const estimateDeparture = moment(Eta ? Eta : new Date()).add(minuteResult, 'minutes').format('MM/DD/yyyy HH:mm')
      ServiceRequestForm.setFieldValue('Estimate_Berth_Dur', minuteResult)
      ServiceRequestForm.setFieldValue('Etd', estimateDeparture)
      setIsFromManualTrigger(false)
    }
  }, [
    ServiceRequestForm.values.Estimate_Berth_Dur_Hour,
    ServiceRequestForm.values.Estimate_Berth_Dur_Minute,
  ])

  //HANDLE ETA & ETD CHANGE
  useEffect(() => {
    const { values } = ServiceRequestForm
    const Eta = moment(values.Eta)
    const Etd = moment(values.Etd)
    const min = Etd.diff(Eta, 'minutes', true)
    ServiceRequestForm.setFieldValue('Estimate_Berth_Dur', Math.ceil(min))
  }, [ServiceRequestForm.values.Eta, ServiceRequestForm.values.Etd])

  // HANDLE ESTIMATE BERTH DUR CHANGE
  useEffect(() => {
    if (ServiceRequestForm.values.Estimate_Berth_Dur) {
      const min = moment.duration(ServiceRequestForm.values.Estimate_Berth_Dur, 'minutes')
      const hh = parseInt(min.asHours())
      const mm = parseInt(min.asMinutes()) % 60
      ServiceRequestForm.setFieldValue('Estimate_Berth_Dur_Hour', hh)
      ServiceRequestForm.setFieldValue('Estimate_Berth_Dur_Minute', mm)
    }
  }, [ServiceRequestForm.values.Estimate_Berth_Dur])

  useEffect(()=>{
    getDataSRField()
    getDataWorkPermit()
  }, [])

  const getDataSRField = async() =>{
    try{
      const response = await ServiceRequestAPI.GetSrField(props.dataSr.Sr_Type_Code, props.dataSr.Sr_Type)
      await setDataSRField(response.data.message.data)
    }
    catch(err) {
      console.log('eror', err)
    }
  }
  const FormSubmit = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...props.dataSr
    },
  })

  const getDataWorkPermit = async () =>{
    try{
      const response = await ServiceRequestAPI.GetMasterWorkPermit()
      setDataMasterPermit(response.data.data)
    }
    catch(error) {
      console.log('eror', error)
    }
  }

  const handleCloseModal = () =>{
    setModalPermitCCU({ modal_type:'', status:false })
  }

  const checklistCell = ({ dataItem }) => {
    let indexFind
    props.dataSr.WorkPermits.find(event =>
      event.Code === dataItem.Code && event.Is_Active
        ? indexFind = true : indexFind = false
    )
    return (
      <td style={{ textAlign:'center' }}>
        <FormControlLabel
          style={{ marginRight:'0px' }}
          disabled={true}
          control={
            <CustomCheckbox
              id='m_grid_checklist-btn'
              checked={indexFind}
            />
          }/>
      </td>
    )
  }

  const _planStartEndCell = ({ dataItem }, field) => {
    return (
      <td>
        {moment(dataItem[field]).format('DD MMM YYYY HH:mm')}
      </td>
    )}



  const generateInput = (position = 'left') =>{
    let split = position ==='left' ? 1 : 0
    let htmlGenerate =  dataSRField?.map((e, i)=>{
      let key = KeySR[e.Data_Field]
      if((i+1)%2 === split) {
        if(e.Data_Type === 'text') {
          return(
            <>
              <Input
                id={`sr-sr_mdl-${key}-txt`}
                name={key}
                label={e.Data_Desc}
                InputProps={{ readOnly: true }}
                disabled={true}
                form={FormSubmit}
                key={i}
              />
            </>
          )
        }

        if(e.Data_Type==='calendar') {
          return(
            <>
              <Input
                id={`sr-sr_mdl-${key}-txt`}
                name={key}
                label={e.Data_Desc}
                InputProps={{ readOnly: true }}
                disabled={true}
                form={FormSubmit}
                key={i}
                value={moment(props.dataSr[key]).format('DD MMMM YYYY HH:mm')}
              />
            </>
          )
        }

        if(e.Data_Type ==='boolean') {
          return(
            <>
              <div style={{ height:'67px' }} key={i}>
                <Checkbox
                  readOnly={true}
                  form={FormSubmit}
                  name={key}
                  title={e.Data_Desc}
                />
              </div>
            </>
          )
        }

        if(e.Data_Type ==='form_detail') {
          return(
            <>
              <div style={{ height:'67px' }} key={i}>
                <ButtonDetail title={e.Data_Desc} isCCU={e.Data_Field === 'ccu_detail'} onClick={e.Data_Field === 'ccu_detail' ? ()=>{setModalPermitCCU({ type:'ccu', status:true })}: e.Data_Field === 'work_permit_detail' ? ()=>{setModalPermitCCU({ type:'permit', status:true })}: null}>
                  {e.Data_Desc}
                </ButtonDetail>
                {e.Data_Field === 'ccu_detail' &&  <div style={{ fontSize:'10px', color:'#777777', marginTop:'2px', marginBottom:'5px' }}>
                  {props.dataSr.CCUS && props.dataSr.CCUS.length !== 0 ? `You have added ${ props.dataSr.CCUS.length} CCU` :'You haven\'t added CCU' }
                </div>}
              </div>
            </>
          )
        }

        if(e.Data_Type ==='valuelist') {
          return (<>
            <Input
              id={`sr-sr_mdl-${key}-txt`}
              name={key}
              label={e.Data_Desc}
              InputProps={{ readOnly: true }}
              disabled={true}
              form={FormSubmit}
              key={i}
            />
          </>)
        }
      }
    })
    return htmlGenerate
  }
  return (
    <FormContainer>
      <LeftFormContainer>
        <Form>
          <Input
            id='sr-sr_mdl-sr_no-txt'
            name='Sr_Type'
            label='Service Request Type'
            InputProps={{ readOnly: true }}
            disabled={true}
            form={FormSubmit}
          />
          {generateInput('left')}
        </Form>
        <Form>
          <Input
            id='sr-sr_mdl-contract_number-txt'
            name='Contract_No'
            label='Contract Number'
            InputProps={{ readOnly: true }}
            disabled={true}
            form={FormSubmit}
          />
          {generateInput('right')}
        </Form>
      </LeftFormContainer>
      <RightFormContainer>
        <Form>
          <>
            <ContainerTop align='baseline'>
              <TextTop1>Created By</TextTop1>
              <TextTop2>{props.dataSr.Created_By}</TextTop2>
            </ContainerTop>
            <ContainerTop align='center'>
              <TopIcon src={Images.company}/>
              <TextTop3>{props.dataSr.Client}</TextTop3>
              <TopIcon src={Images.time}/>
              <TextTop3>
                {ConvertLocalTimeGet(props.dataSr.Location, props.dataSr.Created_Date_Non_Formatted, 'DD MMM YYYY HH:mm')}
              </TextTop3>
            </ContainerTop>
            <WrapperValue>
              <TitleValue>Estimation Date</TitleValue>
              <SVGIcon src={Images.schedule}/>
            </WrapperValue>
            <WrapperValue>
              <TitleBerthing>
                {/* {moment(props.dataSr.Plan_depart).format('DD MMMM YYYY HH:mm')} */}
                {ConvertLocalTimeGet(props.dataSr.Location, props.dataSr.Plan_depart, 'DD MMM YYYY HH:mm')}
              </TitleBerthing>
              <Text regular={true}>to</Text>
              <TitleBerthing>
                {/* {moment(props.dataSr.Plan_Arrival).format('DD MMMM YYYY HH:mm')} */}
                {ConvertLocalTimeGet(props.dataSr.Location, props.dataSr.Plan_Arrival, 'DD MMM YYYY HH:mm')}
              </TitleBerthing>
            </WrapperValue>
          </>
          <Input
            id='sr-sr_mdl-remarks-txt'
            multiline
            rowsMax={3}
            label='Request Description'
            variant='outlined'
            name='Remarks'
            InputProps={{ readOnly: true }}
            value={props.dataSr.Remarks}
            inputProps={{
              tabIndex: 11,
            }}
          />
          <Input
            id='sr-sr_mdl-client_name-txt'
            name='Client_Name'
            label='Client Name'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataSr.Client}
          />
          <Input
            id='sr-sr_mdl-location-txt'
            name='Location'
            label='Location'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataSr.Location}
          />
          <Input
            id='sr-sr_mdl-sr_number-txt'
            name='SR_Number'
            label='SR Number'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataSr.Sr_No}
          />
          <Input
            id='sr-sr_mdl-priority-txt'
            name='Priority'
            label='Priority'
            InputProps={{ readOnly: true }}
            disabled={true}
            value={props.dataSr.Priority_Desc}
          />
        </Form>
      </RightFormContainer>
      {modalPermitCCU.status && <ModalWorkPermitCCU dataSr={props.dataSr}
        handleClose={handleCloseModal}
        modalPermitCCU={modalPermitCCU}
        DocGrid={modalPermitCCU.type ==='ccu' ? DocGridCCU : DocGridPermit}
        data={modalPermitCCU.type ==='ccu' ? props.dataSr.CCUS : dataMasterPermit}
        title={`${modalPermitCCU.type ==='ccu'? 'CCU Detail' : 'Work Permit'}`}
        checklistCell={checklistCell}
        planStartEndCell={_planStartEndCell}
      />}

    </FormContainer>
  )
}

export default ServiceRequestModalContent
