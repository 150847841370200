import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useState, useEffect } from 'react'

import { ExecutionAPI, MasterDataAPI } from '~/api'
import { InlineEditableGrid, ButtonGrid } from '~/components'

import SC from '../styled.cargo-equipment'

import gridColumns from './column.json'
import constants from './constants.equipment-labout'
import { ConvertLocalTimePost } from '../../../../../utils'
import { Alerts, ModalDel } from '../../../../../components'


const EquipmentLabourGrid = (props) => {
  const [id, setId] = useState()
  const [edit, setEdit] = useState(false)
  const [descript, setDescript] = useState()
  const [dataDel, setDataDel] = useState({})
  const [inEdit, setInedit] = useState(false)
  const [toggleDelete, setToggleDelete] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [description, setDescription] = useState()
  const [equipmentData, setEquipmentData] = useState([])
  const [inEditValues, setInEditValues] = useState(constants.initValEquipment)
  const [descriptionData, setDescriptionData] = useState(constants.initValDescription)
  const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })

  const formSubmitEquipment = useFormik({
    initialValues: inEditValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      description: Yup.string().required('This field can’t be empty'),
    }),
    onSubmit: async (values) => {
      setDisabled(true)
      const dataSave = {
        data: {
          'id': id !== 0 ? id : 0,
          'sr_code': props.dataAll.Code,
          'sr_No': props.dataAll.Sr_No,
          'estimation_header_code': '',
          'Sales_Document_Item': descript.sales_document_item,
          'sales_document_no': descript.sales_document_no,
          'location_id': descript.location_id,
          'material': descript.material,
          'description': values.description,
          'quantity_edit': parseFloat(values.qty),
          'created_date': values.created_date === '' ? ConvertLocalTimePost(props.dataAll.Location, new Date()) : values.created_date,
          'modified_date': ConvertLocalTimePost(props.dataAll.Location, new Date()),
          'source': 'execution'
        }
      }
      if(edit) {
        try {
          const result = await ExecutionAPI.EditEquipment(dataSave)
          if(result) {
            setEdit(false)
            setInedit(false)
            setId(0)
            await getData()
          }
          setDisabled(false)
        } catch(error) {
          return error
        }
      } else {
        try {
          const result = await ExecutionAPI.InsertEquipment(dataSave)
          if(result.result) {
            setInedit(false)
            await getData()
          }
          setDisabled(false)
        } catch(error) {
          return error
        }
      }
    }
  })

  const addEquipment = () => {
    setId(0)
    setInedit(true)
    setEdit(false)
    handleDescription(equipmentData)
    formSubmitEquipment.resetForm()
    formSubmitEquipment.setFieldValue('qty', 0)
    formSubmitEquipment.setFieldValue('description', '')
    const newEquipment = { ...constants.initValEquipment, edit: true }
    const updatedData = [newEquipment, ...equipmentData].map((item, index) => ({ ...item, editIndex: index }))
    setInEditValues(newEquipment)
    setEquipmentData(updatedData)
  }

  const handleSave = () => {
    if(formSubmitEquipment.values.description !== '') {
      let data_equip = []
      let data = description
      for (var j = 0; j < data.length; j++) {
        if (data[j]['description'] === formSubmitEquipment.values.description) {
          data_equip.push(data[j])
        }
      }
      setDescript(data_equip[0])
    }

    formSubmitEquipment.submitForm()
  }

  const handleCancel = () => {
    setEdit(false)
    setInedit(false)
    setId(0)
    getData()
  }

  const handleDescription =(equipment, item)=>{
    let resultDescription = []
    if (equipment && equipment.length>0) {
      resultDescription = descriptionData.masterDescription.filter(element=>
        item && item.description === element.description ||
        equipment.findIndex(ele=>  ele.description===element.description)<0
        )
      setDescriptionData({
        ...descriptionData,
        description: resultDescription,
      })
    }
  }
  const handleEdit = (item) => {
    setEdit(true)
    setInedit(true)
    handleDescription(equipmentData, item)
    setId(item.estimation_detail_id)
    const wantTobeUpdatedItem = { ...item, edit: true }
    const updatedData = equipmentData.map((val, index) => {
      if (item.editIndex === val.editIndex) return { ...val, edit: true, editIndex: index }
      return { ...val, editIndex: index }
    })
    setInEditValues(wantTobeUpdatedItem)
    setEquipmentData(updatedData)
  }

  const handleRemove = async (item) => {
    const dataRemove = {
      data : {
        'id': item.estimation_detail_id,
        'modified_date':new Date()
      }
    }
    try {
      const result = await ExecutionAPI.DeleteEquipment(dataRemove)
      if (result) {
        setAlert({
          msg: 'Delete data has been successful',
          success: true,
          visible: true,
          type: 'delete',
        })
        getData()
        setToggleDelete(!toggleDelete)
      }
      else {
        setAlert({ msg: 'Cannot delete data', success: false, visible: true, type: 'delete' })
      }
    } catch(error) {
      return error
    }
  }

  const getData = async () => {
    try {
      const result = await ExecutionAPI.getEquipmentData(props.dataAll.Sr_No)
      const updatedResult = (result.data.data ?? []).map((item, index) => ({ ...item, editIndex: index }))
      setEquipmentData(updatedResult)
    } catch(error) {
      return error
    }
  }

  const checkContractLumpsum =(contracts, contract_no)=>{
    if (contracts && contracts.length>0) {
      const result = contracts.find(element=>element.SapContractNo===contract_no)
      if (result && result.Lumpsum) {
        return result.Lumpsum
      } else return false
    } else return false
  }

  const _getDescription= async ()=>{
    try {
      let description = []
      const contractno =  props.dataAll.Contract_No
      if (props.dataAll.Contracts) {
          if (checkContractLumpsum(props.dataAll.Contracts, contractno)) {
          const query = {
            filters : [{
            'type': 'text|number',
            'field':'sales_document_no|location_id',
            'value': `${contractno}|${props.dataAll.Location_Code}`
            }]
          }
          description = await MasterDataAPI.GetMasterContractDetailsWithMaterial(query)
        } else {
          description = await MasterDataAPI.GetMasterContractDetails(props.dataAll.Contract_No)
        }
      }
      if(description) {
        const masterdescription = description.data.message.data?
        description.data.message.data.sort((a, b) => a.description.localeCompare(b.description))
        :[]
        setDescriptionData({
          description: masterdescription,
          masterDescription:masterdescription,
        })
        setDescription(description.data.message.data)
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    _getDescription()
    getData()
  }, [])

  const handleRemoveModal = (item) => (
    <div>
      <ModalDel
        id='m_actv_delete_mdl'
        toggleDialog={() => setToggleDelete(!toggleDelete)}
        submitDelete={() => handleRemove(item)}
        deleteText='Are you sure to delete the data ?'
      />
    </div>
  )

  const setDataDelete = async(item) =>{
    await setDataDel(item)
    setToggleDelete(!toggleDelete)
  }


  return (
    <SC.EquipmentWrapper>
      {toggleDelete && handleRemoveModal(dataDel)}
      <SC.TopContent>
        <div>
          <SC.EquipmentTitle>Equipment/Labour</SC.EquipmentTitle>
          <SC.EquipmentContractText>Contract No&nbsp;<span>{`${props.dataAll.Contract_No} - ${props.agreement}`}</span></SC.EquipmentContractText>
        </div>
        <SC.EquipmentTopLeftWrapper>
          <ButtonGrid
            inEdit={inEdit}
            onAdd={addEquipment}
          />
        </SC.EquipmentTopLeftWrapper>
      </SC.TopContent>
      <div>
        <InlineEditableGrid
          tableWidth={'100%'}
          subModule={'execution'}
          tableHeight='215px'
          disabled={disabled}
          data={equipmentData}
          columns={gridColumns}
          handleEdit={handleEdit}
          handleSave={handleSave}
          rowData={descriptionData}
          handleCancel={handleCancel}
          handleRemove={setDataDelete}
          formSubmit={formSubmitEquipment}
        />
        <Alerts
          type={showAlert.type}
          open={showAlert.visible}
          close={setAlert}
          msg={showAlert.msg}
          success={showAlert.success}
        />
      </div>
    </SC.EquipmentWrapper>
  )
}

export default EquipmentLabourGrid