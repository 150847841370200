import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { CircularProgressbar } from 'react-circular-progressbar'

import { Colors, FontSize } from '../../../styles'

export const Wrapper = styled.div`
  width: ${(props) => props.width || '1920px'};
  height: ${(props) => props.height || '960px'};
  background: ${Colors.seaBlue} url('${(props) => props.backgroundImage}');
  background-size: auto ${(props) => props.height};
`

export const DashboardLocation = styled.img`
  position: absolute;
  right: 0;
  z-index: ${(props) => props.zIndex || 0};
  height: ${(props) => props.targetHeight || '960px'};
`

export const Jetty = styled.img`
  position: absolute;
  right: 0;
  z-index: ${(props) => props.zIndex || 0};
  height: ${(props) => props.targetHeight || '960px'};
`
export const LocationSide = styled.div`
  left: 1.5626vw;
  top: 12.82vh;
  position: absolute;
  width: 19.27vw;
  height: 1.5626vw;
  border-radius: 0.5vh 0.5vh 0px 0px;
  background-color: ${Colors.pureWhite};
`
export const Legend = styled.div`
  left: 1.5626vw;
  top: ${(props) => (props.top ? props.top : '12.82vh')};
  width: 19.27vw;
  height: 4vh;
  position: absolute;
  border-radius: 0.5vh 0.5vh 0px 0px;
  background-color: ${Colors.pureWhite};

  padding: 0.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

export const LegendDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const LegendStatus = styled.div`
  width: 1.5vh;
  height: 1.5vh;
  margin-right: 1vh;
  border-radius: 20px;
  background-color: ${(props) => props.color};
`

export const ChartContainer = styled.div`
  left: 1.5626vw;
  top: ${(props) => (props.top ? props.top : '17.9vh')};
  width: 19.27vw;
  height: 72.5%;
  max-height: ${(props) => props.innerHeight}px;
  min-height: 375px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  border-radius: 0px 0px 5px 5px;
  background-color: ${Colors.pureWhite};
  font-size: 1.2vh;
`

export const Overflow = styled.div`
  overflow-x: hidden;
  max-height: 83.5%;
  overflow-y: auto;
  padding-bottom: 5px;
`

export const TabTitle = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  width: 50%;
  height: 5.12vh;
  padding: 1vh;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.6vh;
  color: ${(props) => (props.active ? Colors.pureWhite : Colors.grey)};
  ${(props) =>
  !props.active
    ? 'box-shadow: 0 0px 0px 0 rgba(0,0,0,0.2), 0 3px 5px 0 rgba(0,0,0,0.19);'
    : `background-color: ${Colors.softBlue};`}
`

export const HeaderWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
`

export const HeaderTitle = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
`

export const JettyBox = styled.div`
  width: 17.18vw;
  height: 4.61vh;
  display: flex;
  font-size: 1.4vh;
  font-weight: bold;
  padding-left: 20px;
  border-radius: 0.5vh;
  flex-direction: row;
  align-items: center;
  color: ${Colors.pureWhite};
  background-color: ${Colors.softBlue};
`

export const JettyWrapper = styled.div`
  height: 6.14vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const DetailWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.current ? '0 1vw' : '0')};
`

export const Width = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
`

export const Detail = styled.p`
  text-align: center;
`

export const Circular = styled(CircularProgressbar)`
  width: 5.12vh;
  height: 5.12vh;

  & .CircularProgressbar-path {
    stroke: ${Colors.softBlue} !important;
  }

  &.CircularProgressbar-text {
    font-size: ${FontSize.medium}px;
    color: ${Colors.black};
  }
`

export const Vessel = styled.img`
  transform: scale(${(props) => props.scaleFactor || 1});
  transform-origin: ${(props) => props.transformOrigin || '50% 50% 0'};
  &:hover {
    cursor: pointer;
  }
`

export const TooltipsWrapper = styled.div`
  transform: rotate(${(props) => props.deg}deg);
`

export const Box = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Tooltips = styled(ReactTooltip)`
  min-width: 175px;
`

export const TittleTooltips = styled.span`
  color: ${Colors.grey};
`

export const ContentTooltips = styled.div`
  padding-left: 10px;
  color: ${Colors.black};
`

export const Jetty1 = styled.div`
  z-index: 2;
  top: 10%;
  right: 13%;
  width: 30%;
  height: 18%;
  display: flex;
  object-fit: none;
  position: absolute;
  align-items: flex-start;
  justify-content: center;
  transform: rotate(159deg);
  &:hover {
    z-index: 3;
  }
`

export const Jetty2 = styled.div`
  top: 21%;
  right: 1.5%;
  z-index: 2;
  width: 18.75%;
  height: 14%;
  object-fit: none;
  position: absolute;
  transform: rotate(-21deg);

  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => props.justify || 'center'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty3 = styled.div`
  top: 77.5%;
  right: 2.5%;
  z-index: 2;
  width: 18%;
  height: 12.5%;
  object-fit: none;
  position: absolute;

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'center'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty4 = styled.div`
  top: 53%;
  right: 11%;
  z-index: 2;
  width: 17.75%;
  height: 14.5%;
  object-fit: none;
  position: absolute;
  transform: rotate(-90deg);

  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => props.justify || 'center'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty5 = styled.div`
  top: 58%;
  right: ${(props) => (props.bank ? 34 : 21.5)}%;
  z-index: 2;
  height: 16%;
  width: 22%;
  object-fit: none;
  position: absolute;
  transform: rotate(-90deg);

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'center'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty5a = styled.div`
  top: 46%;
  right: ${(props) => (props.bank ? 35.5 : 26.5)}%;
  z-index: 2;
  height: 16%;
  width: 11%;
  object-fit: none;
  position: absolute;
  transform: rotate(-90deg);

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'flex-end'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty5b = styled.div`
  top: 71%;
  right: ${(props) => (props.bank ? 35.5 : 26.5)}%;
  z-index: 2;
  height: 16%;
  width: 11%;
  object-fit: none;
  position: absolute;
  transform: rotate(-90deg);

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'flex-start'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty5Bank = styled.div`
  top: 59%;
  right: 31.75%;
  z-index: 2;
  height: 12.5%;
  width: 15.75%;
  object-fit: none;
  position: absolute;
  transform: rotate(-90deg);

  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || 'center'};
`

export const Jetty6 = styled.div`
  width: 26%;
  top: ${(props) => (props.bank ? 63 : 77)}%;
  right: 38%;
  z-index: 2;
  height: 12.5%;
  object-fit: none;
  position: absolute;

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'center'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty6a = styled.div`
  width: 13%;
  top: ${(props) => (props.bank ? 63 : 77)}%;
  right: 38%;
  z-index: 2;
  height: 12.5%;
  object-fit: none;
  position: absolute;

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'flex-end'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty6b = styled.div`
  width: 13%;
  top: ${(props) => (props.bank ? 63 : 77)}%;
  right: 51%;
  z-index: 2;
  height: 12.5%;
  object-fit: none;
  position: absolute;

  display: flex;
  align-items: flex-end;
  justify-content: ${(props) => props.justify || 'flex-start'};
  &:hover {
    z-index: 3;
  }
`

export const Jetty6Bank = styled.div`
  width: 13%;
  top: 66.5%;
  right: 50.5%;
  z-index: 2;
  height: 12.5%;
  object-fit: none;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || 'center'};
`

export const Jetty7 = styled.div`
  width: 13%;
  top: 70%;
  right: 62%;
  z-index: 2;
  height: 15%;
  object-fit: none;
  position: absolute;
  transform: rotate(90deg);

  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || 'flex-end'};
  &:hover {
    z-index: 3;
  }
`
