import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'

import { Colors } from '~/styles'
import { Button, Input } from '~/components'
import {
  customHooks,
  ConvertLocalTimeGet,
} from '~/utils'

import SC from './styled.delay'
import Funcs from './functions.delay'


const DurationInput = ({ form }) => (
  <SC.DurationInputContainer>
    <SC.DurationLabel>Delay Duration</SC.DurationLabel>
    <SC.DurationInputWrapper>
      <Input
        id='exe-exct_mdl-act_berth_dura-hour-txt'
        style={{ marginBottom: 10 }}
        form={form}
        type='number'
        name='delay_dur_hour'
        placeholder='Hour'
        InputProps={{
          readOnly: true,
          endAdornment: <InputAdornment position='end'>Hour(s)</InputAdornment>,
        }}
        onChange={form.handleChange}
      />
      <Input
        id='exe-exct_mdl-act_berth_dura-minute-txt'
        form={form}
        style={{ marginBottom: 10 }}
        type='number'
        name='delay_dur_minute'
        placeholder='Minute'
        InputProps={{
          readOnly: true,
          endAdornment: <InputAdornment position='end'>Minute(s)</InputAdornment>,
        }}
        onChange={form.handleChange}
      />
    </SC.DurationInputWrapper>
  </SC.DurationInputContainer>
)

const TopRowForm = ({
  form,
  taskList,
  activityList,
  delayTypeOptions
}) => (
  <SC.RowWrapper>
    <SC.SelectFieldDelay
      form={form}
      tabIndex={1}
      readOnly={false}
      isOptionsNotOrdered
      name='activity_code'
      label='List Activity'
      optionValueKey='code'
      options={activityList}
      optionText='activity_name'
      id='exct-mdl-delay-activity-name-select'
    />
    <SC.SelectFieldDelay
      form={form}
      tabIndex={2}
      readOnly={false}
      label='List Task'
      optionText='task'
      options={taskList}
      optionValueKey='code'
      name='task_header_code'
      id='exct-mdl-delay-task-name-select'
    />
    <SC.SelectFieldDelay
      form={form}
      tabIndex={3}
      readOnly={false}
      label='Delay Type'
      name='delay_type_code'
      options={delayTypeOptions}
      optionText='delay_type'
      id='exct-mdl-delay-delay-type-select'
      optionValueKey='code'
    />
  </SC.RowWrapper>
)

const MiddleRowForm = ({ form, delayDescOptions }) => (
  <SC.RowWrapper>
    <SC.SelectFieldDelay
      id='exct-mdl-delay-delay-description-select'
      label='Delay Description'
      name='delay_code'
      options={delayDescOptions}
      optionText='delay_description'
      optionValueKey='delay_code'
      readOnly={false}
      form={form}
      tabIndex={4}
    />
    <SC.InputDateDelay
      form={form}
      id='exct-mdl-delay-start-date-calendar'
      name='start_date'
      label='Start Date'
      readOnly={false}
      tabIndex={5}
    />

    <SC.InputDateDelay
      form={form}
      id='exct-mdl-delay-finish-date-calendar'
      name='finish_date'
      label='Finish Date'
      readOnly={false}
      tabIndex={6}
    />
  </SC.RowWrapper>
)


const BottomRowForm = ({ form }) => (
  <SC.RowWrapper>
    <DurationInput form={form} />
    <Input
      form={form}
      style={{ width: '100%' }} // TODO: Remove this inline style later
      id='exct-mdl-delay-remarks-txt'
      rowsMax={3}
      variant='outlined'
      name='remarks'
      label='Remarks'
      multiline={true}
      InputProps={{ readOnly: false }}
      value={form.values.remarks}
      inputProps={{
        tabIndex: 7,
      }}
    />
  </SC.RowWrapper>
)

const CustomButton = ({ isInEdit, handleInsertOrUpdateDelay }) => {
  const handleClick = customHooks.usePreventMultipleClicks(handleInsertOrUpdateDelay)

  return (
    <Button
      type='submit'
      color={Colors.softGreen}
      id='mdl_dialog_save_delay_btn'
      style={{ width: '40px', marginLeft: '220px', marginTop: '10px' }}
      onClick={handleClick}
    >
      <AddIcon />
      {isInEdit ? 'Save' : 'Add'}
    </Button>
  )
}

const DelayForm = ({
  form,
  taskList,
  isInEdit,
  activityList,
  delayTypeOptions,
  delayDescOptions,
  handleInsertOrUpdateDelay,
}) => {
  return (
    <SC.FormContainerDelay>
      <TopRowForm
        form={form}
        taskList={taskList}
        activityList={activityList}
        delayTypeOptions={delayTypeOptions}
      />
      <MiddleRowForm
        form={form}
        delayDescOptions={delayDescOptions}
      />
      <BottomRowForm form={form} />
      <SC.ButtonContainer>
        <CustomButton
          isInEdit={isInEdit}
          handleInsertOrUpdateDelay={handleInsertOrUpdateDelay}
        />
      </SC.ButtonContainer>
    </SC.FormContainerDelay>
  )
}

const ActionCell = ({ dataItem, colSpan, handleDeleteDelay, handleToggleEdit, handleToggleCancel }) => {
  return (
    <td>
      { !dataItem?.isInEdit ?
        <SC.RowIcon colSpan={colSpan}>
          <SC.ActionCellWrapper
            id='m-edit-btn'
            onClick={() => handleToggleEdit(dataItem)}
          >
            <EditIcon style={{ color: Colors.softGreen }} />
          </SC.ActionCellWrapper>
          <SC.ActionCellWrapper
            id='m-delete-btn'
            onClick={() => handleDeleteDelay(dataItem)}
          >
            <DeleteIcon style={{ color: Colors.red }} />
          </SC.ActionCellWrapper>
        </SC.RowIcon>
        :
        <SC.RowIcon colSpan={colSpan}>
          <SC.ActionCellWrapper
            id='m-cancel-btn'
            onClick={() => handleToggleCancel(dataItem)}
          >
            <CancelIcon style={{ color: Colors.red }} />
          </SC.ActionCellWrapper>
        </SC.RowIcon>
      }
    </td>
  )
}

const ActivityNameCell = ({ dataItem }) => {
  return (
    <td style={{ textAlign: 'center', overflowWrap: 'anywhere' }}>
      {dataItem['activity_name']} - {dataItem['task']}
    </td>
  )
}

const DurationCell = ({ dataItem }) => {
  return (
    <td style={{ textAlign: 'center', overflowWrap: 'anywhere' }}>
      {Funcs.durationToFormattedString(dataItem['delay_duration'])}
    </td>
  )
}

const DatetimeCell = ({ dataItem, field, location }) => {
  return (
    <td style={{ textAlign: 'center', overflowWrap: 'anywhere' }}>
      {ConvertLocalTimeGet(location, dataItem[field], 'DD-MM-YYYY HH:mm')}
    </td>
  )
}

const DelayGrid = ({
  data,
  columns,
  location,
  gridHeight,
  handleToggleEdit,
  handleDeleteDelay,
  handleToggleCancel,
}) => {
  return (
    <div style={{ background: '#f4f6f9', marginTop: '75px', padding: '0 20px' }}>
      <Grid
        id='exe-delay_mdl-all-data'
        total={10}
        style={{ height: gridHeight }}
        data={data}
      >
        {columns.map((e, index) => {
          if (e.field === 'action') {
            return (
              <Column
                key={index}
                width={e.width}
                title={e.title}
                field={e.field}
                cell={(props) => (
                  <ActionCell
                    {...props}
                    handleToggleEdit={handleToggleEdit}
                    handleToggleCancel={handleToggleCancel}
                    handleDeleteDelay={handleDeleteDelay}
                  />
                )}
              />
            )
          }

          if (e.field === 'activity_name') {
            return (
              <Column
                key={index}
                title={e.title}
                field={e.field}
                width={e.width}
                cell={(props) => <ActivityNameCell {...props} />}
              />
            )
          }

          if (e.field === 'delay_duration') {
            return (
              <Column
                key={index}
                title={e.title}
                field={e.field}
                width={e.width}
                cell={(props) => <DurationCell {...props} />}
              />
            )
          }

          if (e.field === 'start_date' || e.field === 'finish_date') {
            return (
              <Column
                key={index}
                title={e.title}
                field={e.field}
                width={e.width}
                cell={(props) => <DatetimeCell {...props} location={location} />}
              />
            )
          }

          return (
            <Column key={index} title={e.title} field={e.field} width={e.width} />
          )
        })}
      </Grid>
    </div>
  )
}

export default {
  DelayForm,
  DelayGrid,
}