import { Checkbox } from '@material-ui/core'

const column = (
  handleCheckAllCargo = undefined,
  handleCheckCargo = undefined,
  checkedCargos = [],
  checkedAllCargos = false
) => [
  {
    width: '50',
    filterType: '',
    render: '',
    customRender: (data, index) => {
      // Will not render the checkbox when add new cargo
      if (!data.cargo_detail_id) return null

      return (
        <div>
          <Checkbox
            id={`checkbox-cell-act-${index}-cargo`}
            checked={checkedCargos.includes(data.cargo_detail_id)}
            onChange={() => handleCheckCargo(data.cargo_detail_id)}
          />
        </div>
      )
    },
    customRenderHeader: (
      <Checkbox
        checked={checkedAllCargos}
        onChange={() => handleCheckAllCargo()}
      />
    ),
    title: '',
    field: 'checklist',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'action',
    title: 'ACTION',
    field: 'action',
    minWidth: 0
  },
  {
    width: '200',
    filterType: '',
    render: 'textfield',
    editor: 'text',
    editable: true,
    title: 'Material Desc',
    field: 'product_name',
    minWidth: 0
  },
  {
    width: '250',
    filterType: '',
    render: 'dropdown',
    options: 'product_type',
    text: 'description',
    value: 'description',
    title: 'Material Type',
    field: 'product_type',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: true,
    title: 'Quantity',
    field: 'quantity',
    minWidth: 0
  },
  {
    width: '150',
    filterType: '',
    render: 'dropdown',
    options: 'uom_quantity',
    text: 'uom',
    value: 'uom',
    title: 'Uom Quantity',
    field: 'uom_quantity',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: true,
    title: 'Length',
    field: 'length',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: true,
    title: 'Width',
    field: 'width',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: true,
    title: 'Height',
    field: 'height',
    minWidth: 0
  },
  {
    width: '150',
    filterType: '',
    render: 'dropdown',
    options: 'uom_lwh',
    text: 'uom',
    value: 'uom',
    title: 'Uom LWH',
    field: 'uom_lwh',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: true,
    title: 'Weight',
    field: 'weight',
    minWidth: 0
  },
  {
    width: '150',
    filterType: '',
    render: 'dropdown',
    options: 'uom_weight',
    text: 'uom',
    value: 'uom',
    title: 'Uom Weight',
    field: 'uom_weight',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: true,
    title: 'Diameter',
    field: 'diameter',
    minWidth: 0
  },
  {
    width: '150',
    filterType: '',
    render: 'dropdown',
    options: 'uom_diameter',
    text: 'uom',
    value: 'uom',
    title: 'Uom Diameter',
    field: 'uom_diameter',
    minWidth: 0
  },
  {
    width: '100',
    filterType: '',
    render: 'float',
    editor: 'numeric',
    editable: false,
    title: 'Volume',
    field: 'volume',
    minWidth: 0
  },
  {
    width: '150',
    filterType: '',
    render: 'dropdown',
    options: 'uom_volume',
    text: 'uom',
    value: 'uom',
    title: 'Uom Volume',
    field: 'uom_volume',
    minWidth: 0
  },
  {
    width: '200',
    filterType: '',
    render: 'dropdown',
    options: 'dangerous_goods',
    text: 'value',
    value: 'value',
    title: 'Dangerous Goods',
    field: 'dangerous_goods',
    minWidth: 0
  },
  {
    width: '200',
    filterType: '',
    render: 'dropdown',
    options: 'material_status',
    text: 'value',
    value: 'value',
    title: 'Material Status',
    field: 'material_status',
    minWidth: 0
  },
  {
    width: '200',
    filterType: '',
    render: 'calendar',
    editor: 'calendar',
    editable: true,
    title: 'Material Date',
    field: 'material_date',
    minWidth: 0
  }
]

export default column
