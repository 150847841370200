import styled, { css } from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'
import { Input, InputDateTime } from '../../components'
import { Colors, FontSize } from '../../styles'

export const ButtonAddContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  justify-content: space-between;
`

export const TextLink = styled.p`
  color: blue;
  text-decoration: underline;
  width: 250px;
  cursor: pointer;
`

export const StatusCell = styled.div`
  width: 100%;
  display: flex;
  cursor: ${(p) => (p.cursor ? p.cursor : 'pointer')};
  padding: 0px 5px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
  border: solid ${(p) => (p.color == Colors.black ? '1px' : '0px')} black;
`

export const Circular = styled(CircularProgressbar)`
  width: 50px;
  height: 50px;

  & .CircularProgressbar-path {
    stroke: ${Colors.softBlue} !important;
  }

  &.CircularProgressbar-text {
    font-size: ${FontSize.medium}px;
    color: ${Colors.black};
  }
`

export const Nav = styled.div`
  height: 40px;
  display: flex;
  z-index: 1000;
  color: ${Colors.grey};
  justify-content: flex-start;
  background: ${Colors.pureWhite};
  box-shadow: 0px 2px 8px #00000033;
`

export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
`

export const MenuList = styled.div`
  height: 100%;
  cursor: pointer;
  min-width: 70px;
  margin: 0px 10px;
  line-height: 30px;
  padding: 5px 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  ${(p) =>
    p.active &&
    css`
      color: ${Colors.darkBlue};
      border-bottom: 3px solid ${Colors.darkBlue};
    `}
`

export const ContentList = styled.div`
  display: ${(p) => (p.active ? 'block' : 'none')};
  height: ${(p) => p.appSize.height - 300}px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 10px 5px 10px;
`

export const Form = styled.div`
  flex: 1;
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
`

export const SettlementModalGridContainer = styled.div`
  padding: 0px 20px;
`

export const CargoNoContainer = styled.div`
  margin-bottom: -30px;
  padding: 20px 20px 0 35px;
`

export const ActionCell = styled.div`
  cursor: pointer;
`

export const AccordionContainer = styled.div`
  display: flex;
  padding: 0px 20px;
  padding-bottom: 0px;
  box-shadow: 0px 2px 8px #00000033;
`

export const FormEmptyFlex = styled.div`
  flex: 1;
`

export const TotalPackageText = styled.div`
  text-align: right;
  padding: 10px 0 10px 0;
  font-weight: bold;
`

export const ModalDocContainer = styled.div`
  width: 100%;
`

export const DocumentUploadButtonContainer = styled.div`
  width: 584px;
  height: 50px;
  display: flex;
  margin: 25px auto 5px auto;
  /* padding: 20px auto;
  align-items: center;
  justify-content: center; */
  background-color: #f8f8f8;
  border: 1px dashed #707070;
`

export const UploadButton = styled.label`
  width: 129px;
  height: 25px;
  display: flex;
  font-size: ${FontSize.small}px;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  background: #eaeaea 0% 0% no-repeat padding-box;
`

export const DocumentDownloadAllButtonContainer = styled.div`
  padding: 0;
  display: flex;
  margin: 0 20px;
  padding-top: 20px;
  padding: 0 auto;
  align-items: center;
  justify-content: flex-end;
`

export const DownloadAllButton = styled.div`
  width: 132px;
  height: 32px;
  display: flex;
  color: #0a81ad;
  cursor: pointer;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`

export const DownloadButton = styled.div`
  width: 98px;
  height: 26px;
  display: flex;
  color: #0a81ad;
  font-size: 14px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #0a81ad;
  box-shadow: 0px 1px 3px #00000029;
  background: #ffffff 0% 0% no-repeat padding-box;
`

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

export const WrapperGrid = styled.div`
  & div.k-widget.k-grid {
    & div.k-grid-header {
      & div.k-grid-header-wrap {
        & table {
          & thead {
            & tr {
              & th.k-header {
                color: black;
                background: #dddddd !important;
              }
            }
          }
        }
      }
    }
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ImageHeader = styled.img`
  width: 180px;
  margin: 0px 30px 0px 0px;
`
export const VesselSchedule = styled.div`
  font-size: 24px;
  color: ${Colors.softBlue};
  font-weight: bold;
  margin: 0px 0px 0px 30px;
`
export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 30px;
`
export const WrapperData = styled.div`
  display: flex;
  font-size: 18px;
`
export const Title = styled.div`
  width: 300px;
  font-weight: bold;
`
export const DashedTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 2px solid ${Colors.grey};
`
export const ThStyled = styled.th`
  background: ${Colors.softGrey};
  font-size: ${FontSize.mediumLarge}px;
  padding: 0px 20px;

  &.left {
    text-align: left;
  }
`
export const TdStyled = styled.td`
  text-align: center;
  font-size: ${FontSize.mediumLarge}px;

  &.padded-align-left {
    padding: 0px 20px;
    text-align: left;
  }
`
export const BackgroundSign = styled.div`
  display: flex;
  align-items: center;
  min-height: 75px;
`

export const InboundButton = styled.div`
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
`
export const InputSettle = styled(Input)`
  margin-bottom: -5px;
`
export const InputSettleDate = styled(InputDateTime)``

export const GridWrapper = styled.div`
  margin-top: 20px;
  & div.k-widget.k-grid {
    & div.k-grid-header {
      & div.k-grid-header-wrap {
        & table {
          & thead {
            & tr {
              & th {
                background: #ddeaff !important;
                color: #${Colors.cancelTextColor};
              }
            }
          }
        }
      }
    }
  }
`
export const RevisedBy = styled.div`
  color: ${Colors.newGreen};
  font-size: 14px;
  margin-top: -10px;
`

export const InputSelectSignWrapper = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
`

export const InputSelectWrapperInner = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
`

export const InputSelectText = styled.span`
  padding-top: 5px;
`

export const InputSelectWrapper = styled.div`
  width: 250px;
`

export const WetSignRow = styled.tr`
  height: 75px;
  border: 1px solid ${Colors.borderGrey};
`

export const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  border: 1px solid ${Colors.softGrey};
`

export const Thead = styled.thead``

export const Tbody = styled.tbody``

export const TheadRow = styled.tr``

export const TheadCell = styled.th`
  background: ${Colors.softGrey};
  padding: 10px;
  font-weight: bold;
  font-style: normal;
  font-size: ${FontSize.mediumLarge}px;
  font-color: ${Colors.white};
`

export const TbodyRow = styled.tr``

export const TbodyCell = styled.td`
  padding: 10px;
  font-style: normal;
  vertical-align: text-top;
  overflow-wrap: break-word;
  font-size: ${FontSize.medium}px;
  color: ${Colors.cancelTextColor};
  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
`

export const TCellSeparator = styled.td`
  height: 20px;
  background-color: ${Colors.softGrey};
`

export const BlueCell = styled.td`
  vertical-align: text-top;
  color: ${Colors.softBlue};
  font-size: ${FontSize.medium}px;
`

export const TBodyCustom = styled(Tbody)`
  border-top: 5px solid lightgray;
  margin-bottom: 15px;
`

export const THCellCustom = styled(TheadCell)`
  color: ${Colors.white};
  text-align: left;
`

export const BorderLine = styled.div`
  height: 25px;
  width: 94%;
  border-top: 2.5px solid ${Colors.lightBlue};
  margin: auto;
  /* background: #0082ad; */
`
