import moment from 'moment'
import getConfig from 'next/config'
import { isNull, sortBy } from 'lodash'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import CreateIcon from '@material-ui/icons/Create'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
// import ExitToAppIcon from '@material-ui/icons/ExitToApp'
// import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'

import GridColumn from './column.json'
import { SettlementAPI, ExecutionAPI } from '../../api'
import { ConvertLocalTimeGet, GenerateFilterTerm } from '../../utils'
import ProgressHistory from './settlement.modal.detail.progress.container'
import {
  Card,
  MasterGrid,
  CodeCell,
  ColumnOption,
  Loading,
  Alerts
  // ModalConfirmation,
} from '../../components'
import SettlementModal from './settlement.modal.container'
import {
  ButtonAddContainer,
  ActionContainer,
  StatusCell,
  Circular,
  LoadingContainer
  // InboundButton,
} from './settlement.styled'

import { Colors } from '../../styles'
import { WrapperContent, Title, TitleMenu } from '../../styles/global.styled'
import {
  // SET_SERVICE_REQUEST_ALL,
  SET_SERVICE_REQUEST_DETAIL
} from '../../redux/actions'

const Settlement = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const param = router.query
  const { publicRuntimeConfig } = getConfig()
  const RUN_OUTSCOPE_FUNCTION =
    publicRuntimeConfig.RUN_OUTSCOPE_FUNCTION === 'true'

  const [firstLoad, setFirstLoad] = useState(true)
  const [progress, setProgreess] = useState(false)
  const [progressItem, setProgressItem] = useState()
  const appSize = useSelector((state) => state.appReducer)
  const { roleMapping } = useSelector((state) => state.usersLoginReducer)
  const [activeColumns, setActiveColumns] = useState([])
  const [inactiveColumns, setInactiveColumns] = useState([])
  const [showColumnOption, setShowColumnOption] = useState(false)
  const [openDetailProgress, setOpenDetailProgress] = useState(false)
  const [showSettlementModal, setShowSettlementModal] = useState(false)
  const [alert, setAlertFail] = useState(false)
  const [enableExternalTracking, setEnableExternalTracking] = useState(false)
  const [pending, setPending] = useState(false)

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    filter: [],
    filterString: '[]'
  })
  const [dataGrid, setDataGrid] = useState([])

  const [gridColumnActive, setGridColumnActive] = useState([])
  const [gridColumnLoading, setGridColumnLoading] = useState(true)
  const [dataCount, setDataCount] = useState(0)
  const isInternal = localStorage.getItem('userType')
    ? localStorage.getItem('userType').toLowerCase() === 'internal'
    : true

  const pathRole = roleMapping.find((e) => e.path === router.pathname)

  const [dataItem, setDataItem] = useState(null)
  const [edited, setEdited] = useState(false)
  // const [inboundData, setInboundData] = useState([])
  const [reviseCode, setReviseCode] = useState('')
  const [dataRevise, setDataRevise] = useState({})
  // const [showAlert, setAlert] = useState({ visible: false, msg: '', success: false, type: '' })

  const onDataStateChange = ({ data }) => {
    GenerateFilterTerm(data.filter, GridColumn)

    setDataState({
      ...data,
      filter: data.filter,
      filterString: GenerateFilterTerm(data.filter, GridColumn)
    })
  }

  useEffect(() => {
    const delay = setTimeout(() => {
      if (!firstLoad) {
        fetchData({
          ...dataState,
          filterString: GenerateFilterTerm(dataState.filter, GridColumn)
        })
      }
    }, publicRuntimeConfig.FILTER_TIMEOUT)
    return () => clearTimeout(delay)
  }, [dataState.filter])

  const onPageChange = (event) => {
    setDataState({
      ...dataState,
      skip: event.page.skip,
      take: event.page.take
    })

    fetchData({
      skip: event.page.skip,
      take: event.page.take,
      filterString: dataState.filterString
    })
  }
  const heightGrid = appSize.height - 190 > 455 ? appSize.height - 190 : 455

  const _calculateBerthDurMask = (minutes) => {
    const min = moment.duration(minutes, 'minutes')
    const hh = parseInt(min.asHours())
    const hour = hh ? `${hh} ${hh > 1 ? 'hours' : 'hour'}` : ''
    const mm = parseInt(min.asMinutes()) % 60
    const minute = mm ? `${mm} ${mm > 1 ? 'minutes' : 'minute'}` : ''
    return hour || minute ? `${hour} ${minute}` : '0'
  }
  /*

  const _getUser = async () => {
    try {
      const { data: dataUser } = await UserAPI.getUser(usersProfileReducer.ID)
      const { data: userProfileRequest } = dataUser
      const { ApprovalLevelText } = userProfileRequest

      dispatch({
        type: SET_USER_PROFILE,
        payload: JSON.stringify({
          ...usersProfileReducer,
          ApprovalLevelText: ApprovalLevelText,
        }),
      })
    } catch (error) {
      console.log(error)
    }
  }
  // */
  const fetchData = async ({ skip, take, filterString }) => {
    try {
      setPending(true)
      const { data, dataCount } = await SettlementAPI.GetAllSettlement(
        skip,
        take,
        filterString
      )

      const response = await SettlementAPI.GetColumnOption()

      if (response.status === 200) {
        const dataColumns = response.data.data
        const active = []
        const inactive = []
        let column = {}

        dataColumns.forEach((item) => {
          column = {
            id: item.Id,
            code: item.Code,
            columnName:
              item.Column_Name == 'contract_no'
                ? 'agreement_no'
                : item.Column_Name,
            columnDesc: item.Column_Description,
            sortNo: item.Sort_No,
            isActive: item.Is_Active
          }
          if (column.isActive) active.push(column)
          else inactive.push(column)
        })

        const gridSelected = GridColumn.filter((element) => {
          const activeElement = active.find(
            (ele) =>
              ele.columnName.toLowerCase() === element.field.toLowerCase()
          )
          if (element.field.toLowerCase() === 'action' || activeElement) {
            if (activeElement) {
              element['sortNo'] = activeElement.sortNo
              element['columnName'] = activeElement.columnName
              element['columnDesc'] = activeElement.columnDesc
              element['code'] = activeElement.code
              element['id'] = activeElement.id
            } else {
              element['sortNo'] = -1
            }

            return element
          } else return false
        })


        const sorted = [...gridSelected.filter((e) => e !== false)].sort(
          (a, b) => a.sortNo - b.sortNo
        )

        setActiveColumns(
          sorted.filter((e) => e.field.toLowerCase() !== 'action')
        )

        const sortedExcludeActionAndInbounds = sorted.filter(
          (e) =>
            /* column action is managed based on role (14/7/2023)
          e.field.toLowerCase() !== 'action' &&
          //  */
            e.field.toLowerCase() !== 'inbound_msr' &&
            e.field.toLowerCase() !== 'inbound_wo'
        )
        setGridColumnActive(
          isInternal ? sorted : sortedExcludeActionAndInbounds
        )
        setGridColumnLoading(false)

        const inactiveExcludeInbounds = inactive.filter(
          (item) =>
            item.columnName.toLowerCase() !== 'inbound_msr' &&
            item.columnName.toLowerCase() !== 'inbound_wo'
        )
        setInactiveColumns(isInternal ? inactive : inactiveExcludeInbounds)

        setDataGrid(
          isNull(data)
            ? []
            : data.map((e) => {
                e['Ata_No_Format'] = e['Ata']
                e['Atd_No_Format'] = e['Atd']
                e['Eta_No_Format'] = e['Eta']
                e['Etd_No_Format'] = e['Etd']
                e['Created_Date_No_Format'] = e['Created_Date']
                e['Modified_Date_No_Format'] = e['Modified_Date']
                e['Created_Date'] =
                  moment(e.Created_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Created_Date,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Atd'] =
                  moment(e.Atd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Atd, 'DD/MM/YYYY HH:mm')
                e['Ata'] =
                  moment(e.Ata).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Ata, 'DD/MM/YYYY HH:mm')
                e['Etd'] =
                  moment(e.Etd).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Etd, 'DD/MM/YYYY HH:mm')
                e['Eta'] =
                  moment(e.Eta).format('DD/MM/YYYY HH:mm') === 'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(e.Location, e.Eta, 'DD/MM/YYYY HH:mm')
                e['Modified_Date'] =
                  moment(e.Modified_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Modified_Date,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Sr_Completed_Date'] =
                  moment(e.Sr_Completed_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Sr_Completed_Date,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Sr_Finalized_Date'] =
                  moment(e.Sr_Finalized_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Sr_Finalized_Date,
                        'DD/MM/YYYY HH:mm'
                      )
                e['Plan_Arrival'] =
                  moment(e.Plan_Arrival).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Plan_Arrival,
                        'YYYY/MM/DD HH:mm'
                      )
                e['Plan_Depart'] =
                  moment(e.Plan_Depart).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Plan_Depart,
                        'YYYY/MM/DD HH:mm'
                      )
                e['Forecast_Arrival'] =
                  moment(e.Forecast_Arrival).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Forecast_Arrival,
                        'YYYY/MM/DD HH:mm'
                      )
                e['Forecast_Depart'] =
                  moment(e.Forecast_Depart).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Forecast_Depart,
                        'YYYY/MM/DD HH:mm'
                      )
                e['No_Later_Than'] =
                  moment(e.No_Later_Than).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.No_Later_Than,
                        'YYYY/MM/DD HH:mm'
                      )
                e['Postponed_Date'] =
                  moment(e.Postponed_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Postponed_Date,
                        'YYYY/MM/DD HH:mm'
                      )
                e['Acivated_Date'] =
                  moment(e.Acivated_Date).format('DD/MM/YYYY HH:mm') ===
                  'Invalid date'
                    ? ''
                    : ConvertLocalTimeGet(
                        e.Location,
                        e.Acivated_Date,
                        'YYYY/MM/DD HH:mm'
                      )
                e['Estimate_Berth_Dur_Detail'] = _calculateBerthDurMask(
                  e.Estimate_Berth_Dur
                )
                e['Actual_Berth_Dur_Detail'] = _calculateBerthDurMask(
                  e.Actual_Berth_Dur
                )
                // e['Sr_Status'] = e.Sr_Status === 'Completed without Approval'
                //   ? 'Completed'
                //   : e.Sr_Status === 'Pre Finalized'
                //     ? 'Need Confirmation' : e.Sr_Status

                return e
              })
        )
        setDataCount(dataCount)
      }
      setPending(false)
    } catch (error) {
      setPending(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (reviseCode !== '') {
      getDataRevise()
    }
  }, [reviseCode])

  const getDataRevise = async () => {
    try {
      const result = await ExecutionAPI.getAllNotification(
        `[{"type": "text", "field" : "Sr_Code", "value": "${reviseCode}"}, {"type": "text", "field" : "Notification_Type_Text", "value": "SettlementRevised"}, {"type": "bool", "field" : "Is_Action_Taken", "value": "false"}]`
      )
      if (result.data && result.data.data) {
        await setDataRevise(result.data.data)
      }
      setShowSettlementModal(true)
    } catch (err) {
      console.log('eror get list product', err)
    }
  }

  const getDetailProgress = async (dataItem) => {
    setDataItem(dataItem)
    try {
      const dataProgress = await SettlementAPI.GetHistoryProgress(
        dataItem.Sr_No
      )
      setProgressItem(dataProgress)
    } catch (error) {
      console.log(error)
    }
    setProgreess(!progress)
    setOpenDetailProgress(!openDetailProgress)
  }

  // const inboundToPbms = async (code, srNo, msrStatus) => {
  //   try {
  //     const result = await SettlementAPI.InboundToPBMS(code, msrStatus)
  //     if (!result.data.error) {
  //       setInboundData([
  //         ...inboundData,
  //         {
  //           Code: result.data.data.SR_Code,
  //           Status: result.data.data.Status,
  //           Remarks: '',
  //           Created_Date: '',
  //         },
  //       ])
  //       setAlert({
  //         msg: `Inbound to PBMS with SR No. ${srNo} is In Progress`,
  //         success: true,
  //         visible: true,
  //         type: 'insert',
  //       })
  //     } else {
  //       setAlert({
  //         msg: `Inbound to PBMS with SR No. ${srNo} is Failed`,
  //         success: false,
  //         visible: true,
  //         type: 'insert',
  //       })
  //     }
  //     return result
  //   } catch (err) {
  //     console.log('inbound to pbms err: ', err)
  //     return err
  //   }
  // }

  const reorderColumns = (event) => {
    let columns = [...event.columns].filter((item) => item.field !== 'action')
    columns = sortBy(columns, ['orderIndex']).map((item1, index) => {
      const found = activeColumns.find(
        (item2) => item2.columnName === item1.field.toLowerCase()
      )
      return { ...found, sortNo: index }
    })
    setActiveColumns(columns)
    try {
      SettlementAPI.UpdateColumnOption(
        columns.map((item) => ({
          Id: item.id,
          Code: item.code,
          Column_Name: item.columnName,
          Column_Description: item.columnDesc,
          Is_Active: true,
          Sort_No: item.sortNo
        }))
      )
    } catch (error) {
      return error
    }
  }

  const _closeDetailProgressModal = () => {
    setDataItem(null)
    setProgreess(false)
    setOpenDetailProgress(false)
  }

  const _confirm = (data) => {
    setDataItem(data[0])
    setShowSettlementModal(!showSettlementModal)
  }

  const getDataByEmail = async () => {
    try {
      const { data } = await SettlementAPI.GetSettlementByParam(
        param.Sr_No || param.sr_no
      )
      if (data.length !== 0) {
        let temp = data.map((e) => {
          return {
            ...e,
            Eta_No_Format: e.Eta ? e.Eta : '',
            Etd_No_Format: e.Etd ? e.Etd : ''
          }
        })
        _confirm(temp)
      } else {
        setAlertFail(true)
      }
    } catch (error) {
      setAlertFail(true)
      console.log(error)
    }
  }

  const _onClose = () => {
    setAlertFail(false)
    window.location = '/settlement'
  }

  const _alert = () => {
    return (
      <Alerts
        open={alert}
        close={() => _onClose()}
        msg={`You don't have the privilege to open details ${param.sr_no}`}
      />
    )
  }

  const _getExternalTrackingEnableStatus = async () => {
    try {
      const filter =
        '[{"field":"type","type":"text","value":"external_enable_tracking"},{"field":"is_active","type":"bool","value":"true"}]'
      const { data } = await SettlementAPI.GetAllFromTableEnum(0, 100, filter)
      if (
        data &&
        data[0] &&
        data[0].Value &&
        data[0].Value.toLowerCase() === 'true'
      ) {
        setEnableExternalTracking(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getExternalTrackingEnableStatus()
    fetchData(dataState)
    setFirstLoad(false)
  }, [])

  useEffect(() => {
    if (!firstLoad) {
      fetchData(dataState)
    }
    // _getUser()
  }, [showSettlementModal])

  useEffect(() => {
    if (param.Sr_No || param.sr_no) {
      getDataByEmail()
    }
  }, [param])

  const _statusCell = ({ colSpan, dataItem }) => {
    const itemStatus =
      dataItem.Sr_Status === 'Completed without Approval'
        ? 'Completed'
        : dataItem.Sr_Status === 'Pre Finalized'
        ? 'Need Confirmation'
        : dataItem.Sr_Status

    return (
      <td>
        <ActionContainer
          id={`settlement_status_${dataItem.Sr_No}`}
          onClick={() =>
            RUN_OUTSCOPE_FUNCTION &&
            (isInternal || enableExternalTracking) &&
            getDetailProgress(dataItem)
          }
          colSpan={colSpan}
        >
          {itemStatus.length > 0 ? (
            <StatusCell
              cursor={
                RUN_OUTSCOPE_FUNCTION && (isInternal || enableExternalTracking)
                  ? 'pointer'
                  : 'default'
              }
              backgroundColor={
                itemStatus.toLowerCase() === 'approved'
                  ? Colors.green
                  : itemStatus.toLowerCase() === 'in progress'
                  ? Colors.orange
                  : itemStatus.toLowerCase() === 'completed'
                  ? Colors.aliceBlue
                  : ''
              }
              color={
                itemStatus.toLowerCase() === 'approved' ||
                itemStatus.toLowerCase() === 'in progress' ||
                itemStatus.toLowerCase() === 'completed'
                  ? Colors.white
                  : Colors.black
              }
            >
              {itemStatus}
            </StatusCell>
          ) : (
            <div />
          )}
        </ActionContainer>
      </td>
    )
  }

  const specStatus = ['completed', 'completed without approval']
  const _actionCell = (props) => {
    const { colSpan, dataItem, dataIndex } = props
    const editable = specStatus.includes(dataItem.Sr_Status.toLowerCase())
    return (
      <td
        style={{ position: 'sticky', left: '0px' }}
        className='k-grid-content-sticky'
        colSpan={colSpan}
      >
        <ActionContainer>
          {pathRole.allowEdit && (
            <div
              id={`settlement_action_edit_btn_${dataIndex}`}
              style={{
                cursor: editable ? 'pointer' : 'not-allowed',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => {
                if (editable) {
                  setReviseCode(dataItem.Code)
                  setDataItem(dataItem)
                  setEdited(true)
                }
              }}
            >
              <CreateIcon
                htmlColor={editable ? Colors.green : Colors.black}
                style={{ opacity: editable ? 1 : 0.25 }}
              />
            </div>
          )}
        </ActionContainer>
      </td>
    )
  }

  const _codeCell = ({ dataItem }) => {
    return (
      <CodeCell
        lock={true}
        style={{ cursor: pathRole.allowView ? 'pointer' : 'default' }}
        disableTooltip={!pathRole.allowView}
        id={`settlement_code_${dataItem.Sr_No}`}
        onClick={() => {
          if (pathRole.allowView) {
            console.log(dataItem)
            setReviseCode(dataItem.Code)
            setDataItem(dataItem)
            dispatch({
              type: SET_SERVICE_REQUEST_DETAIL,
              payload: {
                ...dataItem
              }
            })
          }
        }}
      >
        {dataItem.Sr_No}
      </CodeCell>
    )
  }

  const _percentCell = ({ dataItem }) => {
    const percentage = Math.floor(dataItem.Total_Percentage)

    return (
      <td>
        <div>
          <div style={{ margin: '5px 60px' }}>
            <Circular text={`${percentage}%`} value={percentage} />
          </div>
        </div>
      </td>
    )
  }

  const _header = () => {
    return (
      <TitleMenu>
        <Title>SETTLEMENT</Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <ButtonAddContainer
            id='m-add-option-btn'
            onClick={() => setShowColumnOption(!showColumnOption)}
          >
            <ArtTrackIcon />
            &nbsp;{'Column Option'}
          </ButtonAddContainer>
        </div>
      </TitleMenu>
    )
  }

  const _grid = () =>
    !gridColumnLoading ? (
      <MasterGrid
        id='m_settlement_grid'
        actionLocked
        height={heightGrid}
        data={dataGrid}
        total={dataCount}
        codeCell={_codeCell}
        percentCell={_percentCell}
        dataState={dataState}
        gridColumn={
          !pathRole.allowEdit && !pathRole.allowDelete
            ? gridColumnActive.filter((e) => e.field.toLowerCase() !== 'action')
            : gridColumnActive
        }
        actionCell={_actionCell}
        customCell={_statusCell}
        pageSizes={[20, 50, 100]}
        onPageChange={onPageChange}
        onDataStateChange={onDataStateChange}
        reorderable={true}
        onColumnReorder={reorderColumns}
        pending={pending}
      />
    ) : (
      <LoadingContainer style={{ height: '75vh', alignItems: 'center' }}>
        <Loading id='settlement-grid-loading-indicator' />
      </LoadingContainer>
    )

  const _closeSettlementModal = () => {
    setReviseCode('')
    setDataRevise({})
    setDataItem(null)
    setShowSettlementModal(false)
    setEdited(false)
    param.sr_no != null ? (window.location = '/settlement') : null
  }
  return (
    <WrapperContent>
      <Card>
        {_header()}
        {_grid()}
      </Card>
      {showColumnOption && (
        <ColumnOption
          dataState={dataState}
          fetchData={fetchData}
          parentActiveColumns={activeColumns}
          parentInactiveColumns={inactiveColumns}
          setShowColumnOption={setShowColumnOption}
          setGridColumnLoading={setGridColumnLoading}
          updateApi={SettlementAPI.UpdateColumnOption}
        />
      )}
      {showSettlementModal && (
        <SettlementModal
          data={dataItem}
          dataRevise={dataRevise}
          isEdit={edited}
          pathRole={pathRole}
          isInternal={isInternal}
          showModal={showSettlementModal}
          closeSettlementModal={_closeSettlementModal}
        />
      )}
      {openDetailProgress && (
        <ProgressHistory
          data={dataItem}
          isProgress={progress}
          progressItem={progressItem}
          showModal={openDetailProgress}
          fetchData={() => fetchData(dataState)}
          closeSrModal={_closeDetailProgressModal}
        />
      )}
      {_alert()}
    </WrapperContent>
  )
}

export default Settlement
